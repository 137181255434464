import {
  getQuoteOrMatchingInfo,
  isPropSupportGraphicCode,
} from '@/utils/utils';
import {
  DEFAULT_AVATAR,
  getQuoteOriginProp,
  STATUS_ICON,
  tempValueDisplay,
} from '@linkpi/core';
import dayjs from 'dayjs';
import type { PiGanttModules } from '../core';
import type { positionType } from './_baseComponent';
import BaseComponent from './_baseComponent';
import Attachment from './attachment';
import ExportGraphicCodeIcon from './exportGraphicCodeIcon';
import OpenQuoteListIcon from './openQuoteListIcon';

export default class FixedCol extends BaseComponent {
  public data: any;

  constructor(
    position: positionType,
    moduleInstances: PiGanttModules,
    data: any,
  ) {
    super(position, moduleInstances);
    this.data = data;
  }

  public onClick() {
    console.log('onclick');
  }

  public drawIcon(x: number, y: number, color: string, unicode: string) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;

    ctx.save();
    Draw.attr({
      fillStyle: color,
      textBaseline: 'middle',
      textAlign: 'start',
      font: `${Draw.npx(15)}px iconfont`,
    });

    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  public drawTxt(str: string, x: number, y: number, _gap?: number) {
    const { Draw } = this.moduleInstances;
    const { width } = this.position;

    Draw.attr({
      fillStyle: Draw.style.node.color,
      textBaseline: 'middle',
      textAlign: 'left',
      font: `400 ${Draw.npx(14)}px  sans-serif`,
    });

    const rightGap = 30;
    const gap = _gap || 0;
    const t = Draw.newFittingString(str, width - rightGap - gap);
    Draw.fillText(t, x, y);
  }

  public getUser(id: string) {
    const userMap = this.moduleInstances.Render.config.userMap;
    return (
      userMap[id] || {
        avatar: DEFAULT_AVATAR,
        nick_name: '未知用户',
      }
    );
  }

  public drawImg(src: string, x: number, y: number) {
    const { Draw, ImageLoader } = this.moduleInstances;

    const img = ImageLoader.loadImage(src, 'avatar');
    Draw.drawRoundImg(30, x, y, 30, 30, img);
  }

  public getAttachmentValue(propIndex: number) {
    const node: PiNode = this.data.node.node;
    const propValue = node.tempInfo.prop;
    const fileNames = propValue[propIndex] || [];
    const _sys_attach = (node.prop || {})._sys_attach || {};
    return (
      Array.isArray(_sys_attach[propIndex]) ? _sys_attach[propIndex] : []
    ).map((src: string, j: number) => ({
      src,
      fileName: fileNames[j] || '未知文件名',
    }));
  }

  public renderConditionMatchingAttr = (data: any) => {
    if (!data.data) return;
    this.drawTxt('', 0, 0);

    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;
    const { x, y, width, height } = this.position;
    let textWidth = Draw.measureTextWidth(data.data);

    const { isSupportGraphicCode, propIndex, type } = data;

    const componentHeight = 14;
    // 一个 查看主题列表icon 一个二维码
    textWidth += 16;
    if (isSupportGraphicCode) textWidth += 16;

    const componentWidth = textWidth > width - 20 ? width - 20 : textWidth;

    // 文字
    ctx.save();
    Draw.attr({
      fillStyle: Draw.style.node.color,
      textBaseline: 'top',
      textAlign: 'start',
      font: `400 ${Draw.npx(14)}px  sans-serif`,
    });

    let startX = x + 10;
    // 整体的宽度要减去icon的宽度来算文本宽度
    let strWidth = componentWidth - 16; // 查看主题列表icon
    if (isSupportGraphicCode) strWidth -= 16; // code

    const fittingStr = Draw.newFittingString(data.data, strWidth);
    let iconStartX = startX + Draw.measureTextWidth(fittingStr) + 2;
    let graphicCodeIconStartX = iconStartX + 16;

    Draw.fillText(fittingStr, startX, y + (height - componentHeight) / 2);
    ctx.restore();

    //查看主题列表icon
    const openQuoteListIcon = new OpenQuoteListIcon(
      {
        x: iconStartX,
        y: y + (height - componentHeight) / 2,
        height: 14,
        width: 14,
      },
      this.moduleInstances,
      { ...data, node: this.data.node.node },
    );
    openQuoteListIcon.render();

    if (isSupportGraphicCode) {
      const exportGraphicCodeIcon = new ExportGraphicCodeIcon(
        {
          x: graphicCodeIconStartX,
          y: y + (height - componentHeight) / 2,
          height: 14,
          width: 14,
        },
        this.moduleInstances,
        {
          node: this.data.node.node,
          prop: Draw.config.tempConfig.prop[propIndex],
          text: data.data,
        },
      );
      exportGraphicCodeIcon.render();
    }
  };

  public render() {
    const { Render, Draw } = this.moduleInstances;
    const { x, y, height, width } = this.position;
    const { f, node } = this.data;
    const piNode: PiNode = node.node;
    const paddingLeft = 10;
    const statusWidth = 20;
    const txtY = y + height / 2;
    const avatarWidth = 30;
    let fProp: any = { propConfig: {} };
    let conditionMatching = false;
    let matchingValue: any = false;
    // console.log(piNode);

    if (f.slice(0, 4) === 'prop') {
      const propIndex = Number(f.split('_')[1]);
      const propConfig = Render.config.tempConfig.prop[propIndex];
      conditionMatching = propConfig.conditionMatching;
      matchingValue = getQuoteOrMatchingInfo(propConfig);
      fProp = {
        propConfig,
        propIndex,
      };
    }

    // quote
    if (fProp.propConfig.type === 'quote') {
      const originProp: any = getQuoteOriginProp(
        fProp.propConfig,
        Render.config.tempMap,
      );
      if (!originProp) return;

      const quoteData = tempValueDisplay({
        propConfig: fProp.propConfig,
        propValue: piNode.tempInfo.prop[fProp.propIndex],
        userMap: Render.config.userMap,
        tempMap: Render.config.tempMap,
        sysCascade: piNode.prop._sys_cascade,
        propIndex: fProp.propIndex,
        departmentMap: {},
      });

      let attachmentValue = null;

      if (typeof originProp === 'object' && originProp.type === 'attachment') {
        attachmentValue = this.getAttachmentValue(fProp.propIndex);
      }

      if (attachmentValue) {
        let g = 10;
        for (let i = 0; i < attachmentValue.length; i++) {
          const attachment = new Attachment(
            {
              height: 32,
              width: 32,
              x: x + g,
              y: (height - 32) / 2 + y,
            },
            this.moduleInstances,
            attachmentValue[i],
          );
          attachment.render();
          g += 42;
          // 是否还有空间画下一张图片
          if (width - g < 80 && i !== attachmentValue.length - 1) {
            // 展示附件数量
            this.drawTxt(`+${attachmentValue.length}`, x + g, txtY);
            break;
          }
        }
        return;
      }

      this.drawTxt(quoteData as string, x + paddingLeft, txtY);

      return;
    }

    if (f === 'index')
      return this.drawTxt(piNode.prop._sys_node_seq, x + paddingLeft, txtY);

    if (f === 'order') return this.drawTxt(node.index, x + paddingLeft, txtY);

    if (f === 'title') return this.drawTxt(piNode.title, x + paddingLeft, txtY);

    if (f === 'status') {
      const status = piNode.tempInfo.status;
      const statusInfo = Render.config.tempConfig.task_status[status];
      if (!statusInfo || statusInfo.delete) return;
      const iconInfo = STATUS_ICON[statusInfo.icon as keyof typeof STATUS_ICON];
      if (!iconInfo) return;
      this.drawIcon(x + paddingLeft, txtY, iconInfo.color, iconInfo.unicode);
      this.drawTxt(statusInfo.name || '-', x + paddingLeft + statusWidth, txtY);
      return;
    }

    if (f === 'createTime') {
      const timeStr = dayjs(Number(piNode.createTime)).format(
        'YYYY/MM/DD HH:mm',
      );
      this.drawTxt(timeStr, x + paddingLeft, txtY);
      return;
    }

    if (['statusProp_common_2', 'statusProp_common_3'].includes(f)) {
      const i = f.split('_')[2];
      const v = piNode.tempInfo.statusProp[i];
      if (!v) return;
      this.drawTxt(
        dayjs(Number(v)).format('YYYY/MM/DD'),
        x + paddingLeft,
        txtY,
      );
    }

    if (
      ['statusProp_common_0', 'statusProp_common_1', 'creator'].includes(f) ||
      fProp.propConfig.type === 'user'
    ) {
      let v = null;
      if (f === 'statusProp_common_0') v = piNode.tempInfo.statusProp[0];
      if (f === 'statusProp_common_1') v = piNode.tempInfo.statusProp[1];
      if (f === 'creator') v = piNode.creator;
      if (fProp.propConfig.type === 'user')
        v = piNode.tempInfo.prop[fProp.propIndex];
      v = v ? (Array.isArray(v) ? v : [v]) : [];
      if (v.length === 0) return;

      if (v.length === 1) {
        const user = this.getUser(v[0]);

        this.drawTxt(
          user.nick_name,
          x + paddingLeft + avatarWidth + 5,
          txtY,
          40,
        );
        this.drawImg(
          user.avatar || DEFAULT_AVATAR,
          x + paddingLeft,
          y + (height - 30) / 2,
        );
        return;
      }

      // 不只一个人
      for (let i = 0; i < v.length; i++) {
        const user = this.getUser(v[i]);
        const g = i * 35 + paddingLeft;
        if (g > width - paddingLeft - avatarWidth) {
          // 展示人数
          this.drawTxt(` +${v.length - i}`, x + g, txtY);
          break;
        }
        this.drawImg(
          user.avatar || DEFAULT_AVATAR,
          x + g,
          y + (height - 30) / 2,
        );
      }
    }

    if (['tag', 'enum'].includes(fProp.propConfig.type)) {
      const v = tempValueDisplay({
        propConfig: fProp.propConfig,
        propValue: piNode.tempInfo.prop[fProp.propIndex],
        propIndex: fProp.propIndex,
        userMap: Render.config.userMap,
        tempMap: {},
        departmentMap: {},
      }) as string;

      if (conditionMatching && matchingValue) {
        this.renderConditionMatchingAttr({
          data: v,
          type: matchingValue,
          propIndex: fProp.propIndex,
        });
        return;
      }

      this.drawTxt(v, x + paddingLeft, txtY);
    }

    if (fProp.propConfig.type === 'number') {
      const v = tempValueDisplay({
        propConfig: fProp.propConfig,
        propValue: piNode.tempInfo.prop[fProp.propIndex],
        userMap: {},
        tempMap: {},
        departmentMap: {},
      });
      if (conditionMatching && matchingValue) {
        this.renderConditionMatchingAttr({
          data: v,
          type: matchingValue,
          propIndex: fProp.propIndex,
        });
        return;
      }

      this.drawTxt(v as string, x + paddingLeft, txtY);
    }

    if (
      ['str', 'text', 'positioning', 'address'].includes(fProp.propConfig.type)
    ) {
      const isSupportGraphicCode =
        isPropSupportGraphicCode(fProp.propConfig) &&
        piNode.tempInfo.prop[fProp.propIndex];

      if (conditionMatching && matchingValue) {
        this.renderConditionMatchingAttr({
          data: piNode.tempInfo.prop[fProp.propIndex] || '',
          type: matchingValue,
          propIndex: fProp.propIndex,
          isSupportGraphicCode,
        });
        return;
      }

      this.drawTxt(
        piNode.tempInfo.prop[fProp.propIndex] || '',
        x + paddingLeft,
        txtY,
      );

      if (['positioning', 'address'].includes(fProp.propConfig.type)) return;

      if (isSupportGraphicCode) {
        const exportGraphicCodeIcon = new ExportGraphicCodeIcon(
          {
            x:
              x +
              paddingLeft +
              Draw.measureTextWidth(piNode.tempInfo.prop[fProp.propIndex]) +
              5,
            y: y + (height - 14) / 2,
            height: 14,
            width: 14,
          },
          this.moduleInstances,
          {
            node: piNode,
            prop: fProp.propConfig,
            text: piNode.tempInfo.prop[fProp.propIndex],
          },
        );
        exportGraphicCodeIcon.render();
      }
    }

    if (fProp.propConfig.type === 'cascade') {
      this.drawTxt(
        tempValueDisplay({
          propConfig: fProp.propConfig,
          propValue: piNode.tempInfo.prop[fProp.propIndex],
          propIndex: fProp.propIndex,
          sysCascade: piNode.prop._sys_cascade,
          userMap: Render.config.userMap,
          tempMap: Render.config.tempMap,
          departmentMap: {},
        }) as string,
        x + paddingLeft,
        txtY,
      );
    }

    if (fProp.propConfig.type === 'formula') {
      const v = tempValueDisplay({
        propConfig: fProp.propConfig,
        propValue: piNode.tempInfo.prop[fProp.propIndex],
        userMap: {},
        tempMap: {},
        departmentMap: {},
      }) as string;
      this.drawTxt(v || '', x + paddingLeft, txtY);
    }

    if (fProp.propConfig.type === 'attachment') {
      const v = this.getAttachmentValue(fProp.propIndex);

      let g = 10;
      for (let i = 0; i < v.length; i++) {
        const attachment = new Attachment(
          {
            height: 32,
            width: 32,
            x: x + g,
            y: (height - 32) / 2 + y,
          },
          this.moduleInstances,
          v[i],
        );
        attachment.render();
        g += 42;
        // 是否还有空间画下一张图片
        if (width - g < 80 && i !== v.length - 1) {
          // 展示附件数量
          this.drawTxt(`+${v.length}`, x + g, txtY);
          break;
        }
      }
    }
  }
}
