import { atom, useAtomValue, useSetAtom } from 'jotai';
import { createContext, useContext } from 'react';

import {
  useCurrentUser,
  useOrgConnection,
  useOrgInfo,
  useOrgTempMap,
  useOrgUserGroup,
  useOrgUserList,
  useOrgUserMap,
} from '@/hook';
import { useOrgDepartmentNodes } from '@/hook/useOrgStructure';

export const useNodePageModal = () => {
  const currentUser = useCurrentUser();
  const orgConnection = useOrgConnection();
  const [orgInfo] = useOrgInfo();
  const groups = useOrgUserGroup();
  const spaceUsers = useOrgUserList();
  const departmentNodes = useOrgDepartmentNodes();
  const userMap = useOrgUserMap();
  const tempMap = useOrgTempMap();

  return {
    currentUser,
    orgConnection,
    orgInfo,
    groups,
    spaceUsers,
    departmentNodes,
    userMap,
    tempMap,
  };
};

export const NodePageTypeContext = createContext<'theme' | 'view'>('theme');

export const useNodePageType = () => {
  return useContext(NodePageTypeContext);
};

// 强制更新大模型内容页
// TODO 自定义按钮和数据表格都是一层层传 id 调整为 hook 内部获取❓
const refreshNodePage = atom<Record<string, number>>({});

export const useRefreshNodePageKey = (id?: string) => {
  const refreshMap = useAtomValue(refreshNodePage);

  return id ? refreshMap[id] : undefined;
};

export const useRefreshNodePage = () => {
  const refresh = useSetAtom(refreshNodePage);

  return {
    refresh: (id?: string) => {
      if (!id) {
        return;
      }
      refresh((_refreshMap) => {
        const refreshMap = { ..._refreshMap };
        const key = typeof refreshMap[id] === 'number' ? refreshMap[id] : 0;
        refreshMap[id] = (key + 1) % Number.MAX_SAFE_INTEGER;
        return refreshMap;
      });
    },
  };
};
