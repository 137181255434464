import type { positionType } from './_baseComponent';

import BaseComponent from './_baseComponent';

import type { PiGanttModules } from '../core';


class ExportGraphicCodeIcon extends BaseComponent {
  public data: any;
  public type: string = 'exportGraphicCodeIcon';
  public fontSize: number = 14;

  constructor(position: positionType, moduleInstances: PiGanttModules, data: any) {
    super(position, moduleInstances);

    this.data = data;
  }

  public drawMaIcon(x: number, y: number, unicode: string) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;

    ctx.save();
    Draw.attr({
      fillStyle: '#316EF5',
      textBaseline: 'top',
      textAlign: 'left',
      font: `${Draw.npx(this.fontSize)}px iconfont`,
    });

    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  public onClick() {
    this.moduleInstances.Render.config.setGraphicCodeInfo({
      position: this.position,
      node: this.data.node,
      text: this.data.text,
      prop: this.data.prop,
    });
  }

  public render() {
    const { Render } = this.moduleInstances;

    this.drawMaIcon(this.position.x, this.position.y, '&#xe7e8;');
    if (this.isHover) {
      Render.setCursor('pointer');
    }
  }
}

export default ExportGraphicCodeIcon;
