import type { PiGanttModules } from '../core';
import type { positionType } from './_baseComponent';
import BaseComponent from './_baseComponent';

class OpenQuoteListIcon extends BaseComponent {
  public data: any;
  public type: string = 'openQuoteListIcon';
  public fontSize: number = 14;

  constructor(
    position: positionType,
    moduleInstances: PiGanttModules,
    data: any,
  ) {
    super(position, moduleInstances);

    this.data = data;
  }

  public drawQuoteIcon(x: number, y: number, unicode: string) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;

    ctx.save();
    Draw.attr({
      fillStyle:'#316EF5',
      textBaseline: 'top',
      textAlign: 'start',
      font: `${Draw.npx(this.fontSize)}px iconfont`,
    });

    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  public onClick() {
    this.moduleInstances.Draw.config.showQuoteNodes(this.data.propIndex, this.data.node);
  }

  public render() {
    const { Render } = this.moduleInstances;

    this.drawQuoteIcon(this.position.x, this.position.y, '&#xe70f;');
    if (this.isHover) {
      Render.setCursor('pointer');
    }
  }
}

export default OpenQuoteListIcon;
