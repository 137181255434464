import { useControllableValue, useMemoizedFn } from 'ahooks';
import type { SelectProps } from 'antd';
import { Select, Spin } from 'antd';
import { head, isNotNil } from 'ramda';
import { type FC, Suspense, useEffect, useMemo, useState } from 'react';

import { useCurrentOrgId } from '@/hook';
import { fetchDataSource } from '@/utils/dataManager';
import { cn } from '@/utils/utils';

import type { ISelectConfig, SizeType } from '../../GlobalFilterWidget/types';
import { useOptionsConfig } from './hook';

export type ISelectOption = {
  value: string;
  label: string | React.ReactNode;
  templateId: string;
  level: number;
  children?: ISelectOption[];
};

export type ISelectValue = {
  value: string;
  templateId: string;
  level: number;
};

type OptionSelectProps = {
  config: Extract<ISelectConfig, { dataSource: 'option' }>;
  currentNodeId?: string;
  value?: ISelectValue[];
  onChange?: (value?: string[]) => void;
  size?: SizeType;
};

export const OptionSelect: FC<OptionSelectProps> = ({
  config = { multiple: false } as Extract<
    ISelectConfig,
    { dataSource: 'option' }
  >,
  size,
  ...props
}) => {
  const [_value, _onChange] = useControllableValue<string[]>(props);

  const value = useMemo(() => {
    if (!config.multiple) {
      return _value?.[0];
    }
    return _value;
  }, [_value, config.multiple]);

  const onChange = useMemoizedFn((_: string[], selectedOptions: unknown) => {
    if (!config.multiple) {
      const item = selectedOptions as ISelectValue;
      _onChange?.(item ? [item.value] : []);
      return;
    } else {
      const list = selectedOptions as ISelectValue[];
      _onChange?.(list ? list.map((item) => item.value) : []);
    }
  });

  const [options, setOptions] = useState<SelectProps['options']>([]);

  const orgId = useCurrentOrgId();

  useEffect(() => {
    (async () => {
      if (!config.optionSourceConfig?.length) return;
      const optionDataSourceConfig = head(config.optionSourceConfig)!;
      if (
        !optionDataSourceConfig.dataSource ||
        !optionDataSourceConfig.dataSource.conditions.length
      )
        return;

      const { list, status } = await fetchDataSource({
        type: optionDataSourceConfig.dataSource.type,
        parentType: optionDataSourceConfig.dataSource.parentType,
        conditions: optionDataSourceConfig.dataSource.conditions,
        matchings: optionDataSourceConfig.dataSource.matchings,
        orgId,
        page: 1,
        pageSize: 100,
      });

      if (status !== 'ok') return;

      const getLabel = (node: PiNode) => {
        const propIndex = optionDataSourceConfig.labelProp;
        return node.tempInfo.prop[propIndex!];
      };

      const getValue = (node: PiNode) => {
        const propIndex = optionDataSourceConfig.valueProp;
        return node.tempInfo.prop[propIndex!];
      };

      const templateId = optionDataSourceConfig.dataSource.conditions.find(
        (item) => item.key === 'templateId',
      )?.input[0];

      setOptions(
        list
          .map((item) => ({
            value: getValue(item),
            label: getLabel(item),
            templateId,
          }))
          .filter(
            (item, index, array) =>
              isNotNil(item.value) &&
              (!(
                optionDataSourceConfig.labelProp ===
                optionDataSourceConfig.valueProp
              ) ||
                array.findIndex((i) => i.label === item.label) === index),
          ),
      );
    })();
  }, [config, config.optionSourceConfig, orgId]);

  // const showCheckedStrategy: SelectProps['showCheckedStrategy'] =
  //   config.optionSourceConfig.find(
  //     (_, index) => !getCheckable(config, index + 1),
  //   )
  //     ? 'SHOW_CHILD'
  //     : 'SHOW_PARENT';

  return (
    <Select
      className={cn('w-full')}
      size={size}
      value={value}
      onChange={onChange as any}
      mode={config.multiple ? 'multiple' : undefined}
      options={options}
      maxTagCount="responsive"
      placeholder="请选择"
      allowClear
    />
  );
};

const defaultConfig: Extract<ISelectConfig, { dataSource: 'option' }> = {
  multiple: false,
  dataSource: 'option',
};

export const OptionSelectWrapper = (props: OptionSelectProps) => {
  const { config = defaultConfig, ...otherProps } = props;

  const { data } = useOptionsConfig('xiala', config.optionId);

  return (
    <Suspense fallback={<Spin />}>
      <OptionSelect
        {...otherProps}
        config={{
          ...config,
          optionSourceConfig:
            data?.optionSourceConfig ?? config?.optionSourceConfig ?? [],
        }}
      />
    </Suspense>
  );
};
