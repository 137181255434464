import type { WidgetInstanceData } from '@linkpi/core';
import {
  useMountEffect,
  useThrottledEffect,
  useToggle,
} from '@react-hookz/web';
import { useImmerState } from '@shrugsy/use-immer-state';
import { useMemoizedFn } from 'ahooks';
import { DatePicker, Input, Select, Space, Tooltip } from 'antd';
import { freeze } from 'immer';
import { BugPlay } from 'lucide-react';
import { defaultTo, take } from 'ramda';
import { type FC, useEffect, useMemo, useState } from 'react';
import { match, P } from 'ts-pattern';

import { PiButton } from '@/components/Button';
import type { CustomCommandType } from '@/components/CommandPalette';
import { useCustomCommandListRegister } from '@/components/CommandPalette';
import {
  DateRangerPicker,
  EnumSelect,
} from '@/components/ConditionFilterV2/src/components/ValueEditor/editors';
import { parseDataPropKey } from '@/components/ConditionFilterV2/src/utils';
import ErrorBoundary from '@/components/ErrorBoundary';
import { RegularIcon } from '@/components/IconFont';
import { viewJSON } from '@/components/JsonViewer';
import { cn } from '@/utils/utils';

import type {
  GlobalConditionFilterValue,
  IGlobalConditionFilterConfigV1,
  IGlobalConditionFilterConfigV2,
} from '../../../components/GlobalConditionFilter';
import {
  DefaultFontValue,
  FoldButtonId,
  SubmitButtonId,
} from '../../GlobalFilterWidget/constant';
import type { IGlobalConditionFilterConfig } from '../../GlobalFilterWidget/types';
import { migrateConfig } from '../../GlobalFilterWidget/utils';
import { getGlobalConditionConfig, useSetGlobalConditionConfig } from '../hook';
import { migrateConfig as migrateConfigV2 } from '../utils';
import type { ICascaderValue } from './CascaderSelect';
import { CascaderSelectWrapper } from './CascaderSelect';
import NumberInput from './InputNumber';
import SearchFormItemWrapper from './SearchFormItemWrapper';
import type { ISelectValue } from './Select';
import { OptionSelectWrapper } from './Select';
import type { IYearMonthDayPickerValue } from './YearMonthDayPicker';
import { YearMonthDayPicker } from './YearMonthDayPicker';

type GlobalConditionFilterProps = {
  currentNodeId?: string;
  widgetInstance: WidgetInstanceData<IGlobalConditionFilterConfig>;
  value?: GlobalConditionFilterValue;
  onChange?: (value: GlobalConditionFilterValue) => void;
  className?: string;
  readonly?: boolean;
  selected?: string | null;
  onSelected?: (id: string) => void;
};

export const GlobalConditionFilter: FC<GlobalConditionFilterProps> = ({
  currentNodeId,
  widgetInstance,
  onChange,
  value: _value,
  className,
  readonly = false,
  selected,
  onSelected,
}) => {
  const setGlobalConditionConfig = useSetGlobalConditionConfig();
  const config = useMemo(() => {
    if (widgetInstance.config.version === (3 as any)) {
      return widgetInstance.config;
    }
    const configV2 = match(widgetInstance.config as any)
      .with({ version: 1 }, (_config: IGlobalConditionFilterConfigV1) =>
        migrateConfigV2(_config),
      )
      .with({ version: 2 }, (_config) => _config)
      .otherwise(() => config) as IGlobalConditionFilterConfigV2;

    return migrateConfig(configV2);
    // if (widgetInstance.config.version === (1 as any))
    //   return migrateConfig(
    //     widgetInstance.config as unknown as IGlobalConditionFilterConfigV1,
    //   );
    // return widgetInstance.config;
  }, [widgetInstance.config]);

  useEffect(() => {
    setGlobalConditionConfig(widgetInstance.id, config.conditionList);
  }, [widgetInstance.id, config.conditionList, setGlobalConditionConfig]);

  const defaultValue = useMemo(() => {
    // return {};
    const result =
      config.conditionList?.reduce((acc, item) => {
        acc[item.id] = {
          id: item.id,
          propKey: item.propKey!,
          filedType: item.filedType,
          value: item.defaultValue,
        };
        return acc;
      }, {} as GlobalConditionFilterValue) ?? {};
    return freeze(result);
  }, [config.conditionList]);

  const [value, setValue] = useImmerState<GlobalConditionFilterValue>(
    _value || defaultValue,
  );

  const onItemChange = useMemoizedFn((id: string, value: any) => {
    setValue((draft) => {
      if (!draft[id]) {
        draft[id] = {
          id,
          propKey: config.conditionList!.find((item) => item.id === id)!
            .propKey!,
          value: [],
          filedType: config.conditionList!.find((item) => item.id === id)!
            .filedType,
        };
      }
      draft[id].value = value;
    });
  });

  const onSubmit = useMemoizedFn(() => {
    onChange?.(value);
  });

  const [resetKey, setResetKey] = useState(0);

  const onReset = useMemoizedFn(() => {
    setValue(defaultValue);
    onChange?.(defaultValue);
    setResetKey((prev) => prev + 1);
  });

  /**
   * 避免输入框导致频繁提交
   */
  useThrottledEffect(
    () => {
      if (config.enableSubmitButton) return;

      onSubmit();
    },
    [onSubmit, value, config.enableSubmitButton],
    1000,
  );

  useMountEffect(() => {
    onSubmit();
  });

  /**
   * 调试用
   */
  const customCommand: CustomCommandType = useMemo(
    () => ({
      onSelect: () => {
        viewJSON(getGlobalConditionConfig(), {
          title: 'GlobalConditionConfig',
        });

        return true;
      },
      id: 'VIEW_GLOBAL_CONDITION_CONFIG',
      value: '查看 GlobalConditionConfig',
      content: (
        <>
          <BugPlay className="mr-2 h-4 w-4" />
          <span>查看 GlobalConditionConfig</span>
        </>
      ),
    }),

    [],
  );

  useCustomCommandListRegister(customCommand);

  const renderList = useMemo(() => {
    const {
      reset = { ...DefaultFontValue, bgColor: '#FFF', borderColor: '#EBEDF0' },
      submit = {
        ...DefaultFontValue,
        bgColor: '#316EF5',
        borderColor: '#316EF5',
        color: { r: 255, g: 255, b: 255, a: 1 },
      },
      size: buttonSize,
    } = config.submitButtonConfig ?? {};

    return [
      ...defaultTo([])(config.conditionList).map((item) => (
        <ErrorBoundary key={item.id} type="inlineFailed">
          <SearchFormItemWrapper
            key={item.id}
            readonly={readonly}
            onClick={() => {
              if (readonly) {
                onSelected?.(item.id);
              }
            }}
            arrangement={config.arrangement}
            selected={selected === item.id}
          >
            <div
              className={cn('flex items-center gap-x-2 w-full', {
                'pointer-events-none': readonly,
              })}
            >
              {item.showName && (
                <Tooltip title={item.name} placement="top">
                  <div
                    className="shrink-0 whitespace-nowrap max-w-[140px] truncate leading-normal"
                    style={{
                      fontSize: item.fontSize,
                      color: item.color
                        ? `rgba(${item.color.r}, ${item.color.g}, ${item.color.b}, ${item.color.a})`
                        : undefined,
                      fontWeight: item.bold ? 'bold' : 'normal',
                      fontStyle: item.italic ? 'italic' : 'normal',
                      textDecoration: item.underline ? 'underline' : 'none',
                    }}
                  >
                    {item.name}
                  </div>
                </Tooltip>
              )}
              {match(item)
                .with({ filedType: 'text' }, () => (
                  <div className="flex-auto">
                    <Input
                      key={resetKey + item.propKey!}
                      value={value[item.id]?.value as string}
                      onChange={(e) => onItemChange(item.id, [e.target.value])}
                      size={item.size}
                      placeholder="请输入"
                    />
                  </div>
                ))
                .with({ filedType: 'number' }, (i) => (
                  <NumberInput
                    key={resetKey + item.propKey!}
                    size={i.size}
                    placeholder={i.placeholder}
                    templateId={i.numberConfig?.templateId}
                    propIndex={i.numberConfig?.propKey?.split('_')[1]}
                    value={value[item.id]?.value as any}
                    onChange={(v) =>
                      onItemChange(item.id, v ? [+v] : undefined)
                    }
                  />
                ))
                .with({ filedType: 'cascader' }, () => (
                  <CascaderSelectWrapper
                    currentNodeId={currentNodeId}
                    config={item.cascaderConfig!}
                    size={item.size}
                    value={
                      value[item.id]?.value as
                        | ICascaderValue[]
                        | ICascaderValue[][]
                        | undefined
                    }
                    onChange={(v) => onItemChange(item.id, v || [])}
                  />
                ))
                .with(
                  {
                    filedType: 'select',
                    selectConfig: { dataSource: 'option' },
                  },
                  (_item) => (
                    <OptionSelectWrapper
                      currentNodeId={currentNodeId}
                      config={_item.selectConfig}
                      size={item.size}
                      value={
                        value[item.id]?.value as ISelectValue[] | undefined
                      }
                      onChange={(v) => onItemChange(item.id, v || [])}
                    />
                  ),
                )
                .with({ filedType: 'yearMonthDay' }, () => (
                  <YearMonthDayPicker
                    config={item.yearMonthDayConfig!}
                    value={
                      value[item.id]?.value as
                        | IYearMonthDayPickerValue
                        | undefined
                    }
                    onChange={(v) => onItemChange(item.id, v)}
                  />
                ))
                .with(
                  {
                    filedType: 'select',
                    selectConfig: { dataSource: 'template' },
                  },
                  (_item) => (
                    <div className="flex-auto">
                      <EnumSelect
                        key={resetKey + _item.selectConfig.propKey}
                        config={parseDataPropKey(
                          _item.selectConfig.propKey || '',
                        )}
                        templateUsage="fullAccess"
                        templateId={_item.selectConfig.templateId}
                        value={value[item.id]?.value as any[]}
                        onChange={(v) => onItemChange(item.id, v)}
                        disableMultiple={!_item.selectConfig.multiple}
                      />
                    </div>
                  ),
                )
                .with(
                  {
                    filedType: 'dateRangePicker',
                    dateConfig: { templateId: P.string, propKey: P.string },
                  },
                  (_item) => (
                    <div className="flex-auto">
                      <DateRangerPicker
                        key={resetKey + _item.dateConfig.propKey}
                        config={parseDataPropKey(_item.dateConfig.propKey)}
                        templateId={_item.dateConfig.templateId}
                        templateUsage="fullAccess"
                        value={value[item.id]?.value as any}
                        onChange={(v) => onItemChange(item.id, v)}
                        size={item.size}
                      />
                    </div>
                  ),
                )
                .with(
                  {
                    filedType: 'dateRangePicker',
                  },
                  () => (
                    <DatePicker.RangePicker
                      size={item.size}
                      style={{ width: '100%', borderRadius: 8 }}
                      placeholder={['日期', '日期']}
                      value={value[item.id]?.value as any}
                      onChange={(v) => onItemChange(item.id, v)}
                    />
                  ),
                )
                // .with({ filedType: '$$TEMP_BY_PROP', propKey: P.string }, () => (
                //   <div className="flex-auto">
                //     <ConditionFilterValue
                //       key={resetKey + item.propKey!}
                //       templateId={config.templateId}
                //       propKey={item.propKey!}
                //       // TODO 虽然语意上是受控的，但实际不受控
                //       value={value[item.id]?.value as any[]}
                //       onChange={(v) => onItemChange(item.id, v)}
                //       multiple={item.multiple}
                //       onlyShowAuxInEnumProp={item.onlyShowAuxInEnumProp}
                //     />
                //   </div>
                // ))
                .otherwise(() => (
                  <Select
                    placeholder="请选择"
                    className="flex-auto"
                    size={item.size}
                  />
                ))}
            </div>
          </SearchFormItemWrapper>
        </ErrorBoundary>
      )),
      !!config.enableSubmitButton && (
        <SearchFormItemWrapper
          key="submit-button"
          readonly={readonly}
          onClick={() => {
            if (readonly) {
              onSelected?.(SubmitButtonId);
            }
          }}
          arrangement={config.arrangement}
          selected={selected === SubmitButtonId}
        >
          <Space key="submit-button">
            <PiButton
              size={buttonSize}
              style={{
                fontSize: submit.fontSize,
                color: submit.color
                  ? `rgba(${submit.color.r}, ${submit.color.g}, ${submit.color.b}, ${submit.color.a})`
                  : undefined,
                fontWeight: submit.bold ? 'bold' : 'normal',
                fontStyle: submit.italic ? 'italic' : 'normal',
                textDecoration: submit.underline ? 'underline' : 'none',
                backgroundColor: submit.bgColor,
                borderColor: submit.borderColor,
              }}
              onClick={onSubmit}
            >
              <span
                style={{
                  textDecoration: submit.underline ? 'underline' : 'none',
                }}
              >
                查询
              </span>
            </PiButton>
            <PiButton
              size={buttonSize}
              style={{
                fontSize: reset.fontSize,
                color: reset.color
                  ? `rgba(${reset.color.r}, ${reset.color.g}, ${reset.color.b}, ${reset.color.a})`
                  : undefined,
                fontWeight: reset.bold ? 'bold' : 'normal',
                fontStyle: reset.italic ? 'italic' : 'normal',
                textDecoration: reset.underline ? 'underline' : 'none',
                backgroundColor: reset.bgColor,
                borderColor: reset.borderColor,
              }}
              onClick={onReset}
            >
              重置
            </PiButton>
          </Space>
        </SearchFormItemWrapper>
      ),
    ];
  }, [
    config.submitButtonConfig,
    config.conditionList,
    config.enableSubmitButton,
    config.arrangement,
    onSubmit,
    onReset,
    readonly,
    selected,
    onSelected,
    resetKey,
    value,
    onItemChange,
    currentNodeId,
  ]);
  const [isFold, toggleFold] = useToggle(false);

  if (config.enableFold) {
    const foldConfig = defaultTo({
      unfoldButtonText: '更多筛选',
      foldButtonText: '收起',
      foldLines: 1,
      ...DefaultFontValue,
      color: {
        r: 49,
        g: 110,
        b: 245,
        a: 1,
      },
    })(config.foldConfig);

    const count =
      (foldConfig.foldLines || 1) *
      (
        {
          one: 1,
          two: 2,
          three: 3,
        } as const
      )[config.arrangement || 'three'];

    const foldedList = take(count, renderList);

    if (config.enableSubmitButton && count !== 1) {
      foldedList[foldedList.length - 1] = renderList[renderList.length - 1];
    }

    return (
      <div className={cn('flex flex-col items-start gap-1', className)}>
        <div className="flex gap-4 flex-wrap items-center text-[#242d3f] w-full">
          {isFold ? foldedList : renderList}
        </div>
        <SearchFormItemWrapper
          key="fold-button"
          readonly={readonly}
          onClick={() => {
            if (readonly) {
              onSelected?.(FoldButtonId);
            }
          }}
          arrangement={config.arrangement}
          selected={selected === FoldButtonId}
        >
          <PiButton type="link" onClick={toggleFold} className="px-0">
            <span
              className="inline-flex items-center"
              style={{
                display: 'inline-flex',
                fontSize: foldConfig.fontSize,
                color: foldConfig.color
                  ? `rgba(${foldConfig.color.r}, ${foldConfig.color.g}, ${foldConfig.color.b}, ${foldConfig.color.a})`
                  : undefined,
                fontWeight: foldConfig.bold ? 'bold' : 'normal',
                fontStyle: foldConfig.italic ? 'italic' : 'normal',
                textDecoration: foldConfig.underline ? 'underline' : 'none',
              }}
            >
              {isFold ? foldConfig.unfoldButtonText : foldConfig.foldButtonText}
              {isFold ? (
                <RegularIcon type="iconzhankai2" />
              ) : (
                <RegularIcon type="iconzhankai2" className="rotate-180" />
              )}
            </span>
          </PiButton>
        </SearchFormItemWrapper>
      </div>
    );
  }

  return (
    <div
      className={cn(
        'flex gap-y-2 flex-wrap items-center text-[#242d3f]',
        className,
      )}
    >
      {renderList}
    </div>
  );
};
