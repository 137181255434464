import { Collapse } from 'antd';

import type {
  GroupProjectDataItemType,
  GroupProjectType,
} from './useGameInfoNode';

import styles from './styles.less';

export const DisplayGroupsProject = (props: {
  groupProject: GroupProjectType;
}) => {
  const { groupProject } = props;

  const renderCard = (item: GroupProjectDataItemType) => {
    const ageTagValue = () => {
      if (item.ageMin && item.ageMax) {
        return `${item.ageMin}岁 ~ ${item.ageMax}岁`;
      } else if (item.ageMin) {
        return `${item.ageMin}岁 以上`;
      } else if (item.ageMax) {
        return `${item.ageMax}岁 以下`;
      } else {
        return '';
      }
    };

    const showGenderIcon = () => {
      if (item.gender === '男') {
        return 'male';
      } else if (item.gender === '女') {
        return 'female';
      } else {
        return '';
      }
    };

    return (
      <div className={styles.GroupsProjectCard}>
        {item.seqId && groupProject.display.seqId && (
          <div className={styles.seqId}>{item.seqId}</div>
        )}
        <div className={styles.content}>
          <div className={styles.row1}>
            <div className={styles.title}>{item.title}</div>
            {item.level && groupProject.display.level && (
              <div className={styles.level}>{item.level}</div>
            )}
          </div>
          <div className={styles.row2}>
            <div className={styles.tags}>
              {showGenderIcon() && groupProject.display.gender && (
                <div className={styles.tag}>{item.gender}</div>
              )}
              {item.category && groupProject.display.category && (
                <div className={styles.tag}>{item.category}</div>
              )}
              {ageTagValue() && groupProject.display.ageMin && (
                <div className={styles.tag}>{ageTagValue()}</div>
              )}
              {item.personCount && groupProject.display.personCount && (
                <div className={styles.tag}>{item.personCount}人</div>
              )}
            </div>
            {item.fee && groupProject.display.fee && (
              <div className={styles.fee}>¥{item.fee}</div>
            )}
          </div>
          {item.remark && groupProject.display.remark && (
            <div className={styles.row3}>
              <div className={styles.remark}>{item.remark}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (!groupProject || !groupProject.data.length) {
    return '未上传组别';
  }

  return (
    <div className={styles.displayGroupsProject}>
      <Collapse ghost>
        <Collapse.Panel
          header={`共${groupProject.data.length}个组别/项目`}
          key={1}
        >
          {groupProject.data.map(renderCard)}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};
