/* eslint-disable react-refresh/only-export-components */
import { QuestionCircleOutlined } from '@ant-design/icons';
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { assertExists } from '@linkpi/utils';
import { useImmerState } from '@shrugsy/use-immer-state';
import { useDispatch } from '@umijs/max';
import { useMemoizedFn } from 'ahooks';
import { Divider, Modal, Space, Switch, Tooltip, Typography } from 'antd';
import cls from 'classnames';
import { defaultTo } from 'ramda';
import type { FC } from 'react';
import { match, P } from 'ts-pattern';

import { DEFAULT_LAYOUT } from '@/consts';
import { useCurrentUser, useOrgInfo } from '@/hook';
import { organizationSetting } from '@/services/space';

import { ColorPicker } from '../ColorPicker';
import { RegularIcon } from '../IconFont';

import styles from './styles.less';

const layouts = [
  {
    icon: <RegularIcon type="iconNav_Outline_Normal" size={90} />,
    icon_active: <RegularIcon type="iconNav_Outline_Selected" size={90} />,
    title: '大纲模式',
    desc: '大纲树样式，无限查看目录',
    value: 'outline',
  },
  {
    icon: <RegularIcon type="iconNav_Type2_Normal" size={90} />,
    icon_active: <RegularIcon type="iconNav_Type2_Selected" size={90} />,
    title: '经典模式（多列）',
    desc: '多层级菜单，最多可展开 4 级',
    value: 'multipleLevelMenu',
  },
  {
    icon: <RegularIcon type="iconNav_Outline_Normal" size={90} />,
    icon_active: <RegularIcon type="iconNav_Outline_Selected" size={90} />,
    title: '展开收起模式',
    desc: '点击展开按钮，无限查看目录',
    value: 'elecwattMenu',
  },
] as const;

export const DEFAULT_CONFIG = {
  layout: DEFAULT_LAYOUT,
  userLayout: null,
} as const;

const _OrgSidebarModal: FC = () => {
  const modal = useModal();
  const [orgInfo] = useOrgInfo();
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  assertExists(orgInfo);

  const [navModeConfig, setNavModeConfig] = useImmerState(() =>
    match(orgInfo.org_option?.navMode)
      .with({ layout: P.string, userLayout: P.nullish }, (v) => v)
      .with(
        {
          userLayout: P.intersection(P.nonNullable, P.not(P.string)),
        },
        (v) => ({
          ...v,
          userLayout: v.userLayout[currentUser.userid] || null,
        }),
      )
      .with(
        P.string,
        (v) =>
          ({
            layout: v,
            userLayout: null,
          }) as const,
      )
      .with(
        { [currentUser.userid]: P.select() },
        (v) =>
          ({
            layout: 'outline',
            userLayout: v,
          }) as const,
      )
      .otherwise(() => DEFAULT_CONFIG),
  );

  const [pageConfig, setPageConfig] = useImmerState(() =>
    match(orgInfo.org_option?.defaultPageConfig)
      .with(P.nullish, () => ({
        bgColor: '#fff',
      }))
      .otherwise((v) => v),
  );

  const onOk = useMemoizedFn(async () => {
    try {
      const navMode = match(navModeConfig)
        .with({ userLayout: P.nullish }, (v) => v)
        .otherwise((v) =>
          match(orgInfo.org_option?.navMode)
            .with(
              { userLayout: P.intersection(P.nonNullable, P.not(P.string)) },
              (c) => ({
                ...v,
                userLayout: {
                  ...c.userLayout,
                  [currentUser.userid]: v.userLayout,
                },
              }),
            )
            .otherwise(() => ({
              ...v,
              userLayout: { [currentUser.userid]: v.userLayout },
            })),
        );

      await organizationSetting({
        org_id: orgInfo.orgId,
        org_option: {
          ...defaultTo({}, orgInfo.org_option),
          navMode,
          defaultPageConfig: pageConfig,
        },
      });

      await dispatch({
        type: 'space/organizationSetting',
        payload: {
          org_id: orgInfo.orgId,
        },
      });
      modal.hide();
      modal.resolve();
    } catch (error) {
      console.warn(error);
    }
  });

  const onCanel = useMemoizedFn(() => {
    modal.reject();
    modal.hide();
  });

  return (
    <Modal
      {...antdModalV5(modal)}
      width={730}
      onCancel={onCanel}
      centered
      title={'空间布局样式'}
      okText="保存"
      onOk={onOk}
      okButtonProps={{ className: 'rounded-[8px]' }}
      cancelButtonProps={{ className: 'rounded-[8px]' }}
    >
      <Space className="full-w" direction="vertical" size={16}>
        <Typography.Text className="flex items-center gap-1">
          <span className="font-bold">空间默认底色</span>
          <Tooltip
            title="1. 主题类型的内容页装修（新版本）默认使用该底色
2. 创建仪表盘视图时，默认使用该底色"
          >
            <QuestionCircleOutlined style={{ color: '#316ef5' }} />
          </Tooltip>
        </Typography.Text>
        <div className="flex items-center gap-2">
          <span>配置页面底色</span>
          <ColorPicker
            value={pageConfig.bgColor}
            onChange={(v) =>
              setPageConfig((c) => {
                c.bgColor = v as string;
              })
            }
          />
        </div>
        <Divider dashed style={{ margin: 0 }} />
        <Typography.Text className="flex justify-between items-center">
          <span className="font-bold">侧边栏布局</span>
          <div className="flex items-center gap-1">
            个人布局跟随空间布局
            <Switch
              size="small"
              checked={!navModeConfig.userLayout}
              onChange={(e) =>
                setNavModeConfig((c) => {
                  c.userLayout = !e ? 'outline' : null;
                })
              }
            />
          </div>
        </Typography.Text>
        <Space size={40}>
          {layouts.map((item) => (
            <div
              key={item.value}
              className={cls(
                styles.card,
                navModeConfig.layout === item.value && styles.active,
              )}
              onClick={() =>
                setNavModeConfig((c) => {
                  c.layout = item.value;
                })
              }
            >
              {navModeConfig.layout === item.value
                ? item.icon_active
                : item.icon}
              <span className={styles.title}>{item.title}</span>
              <Typography.Text type="secondary">{item.desc}</Typography.Text>
            </div>
          ))}
        </Space>
        {navModeConfig.userLayout && [
          <Typography.Text key="title">我的设置</Typography.Text>,
          <Space size={40} key="layouts">
            {layouts.map((item) => (
              <div
                key={item.value}
                className={cls(
                  styles.card,
                  navModeConfig.userLayout === item.value && styles.active,
                )}
                onClick={() =>
                  setNavModeConfig((c) => {
                    c.userLayout = item.value;
                  })
                }
              >
                {navModeConfig.userLayout === item.value
                  ? item.icon_active
                  : item.icon}
                <span className={styles.title}>{item.title}</span>
                <Typography.Text type="secondary">{item.desc}</Typography.Text>
              </div>
            ))}
          </Space>,
        ]}
      </Space>
    </Modal>
  );
};

const OrgSidebarModal = create(_OrgSidebarModal);

export const showOrgSidebarModal = () => {
  return show(OrgSidebarModal);
};
