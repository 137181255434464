import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { useMemoizedFn } from 'ahooks';
import type { FC } from 'react';

import _DraftsModal, { type DraftsModalProps } from './DraftsModal';

export const DraftsModal = _DraftsModal;

type IDraftNode = Omit<DraftsModalProps, 'visible' | 'onSuccess' | 'onCancel'>;

/**
 * nice 版本的 DraftsModal :P
 */
const _DraftNodeModal: FC<IDraftNode> = (props) => {
  const modal = useModal();

  const onSuccess = useMemoizedFn(
    (draftNodeData: DraftsModalProps['draftsNodeData']) => {
      modal.resolve(draftNodeData);
      modal.hide();
      modal.resolveHide();
    },
  );

  const onCancel = useMemoizedFn(() => {
    modal.hide();
    modal.resolveHide();
    modal.reject();
  });

  return (
    <_DraftsModal
      {...props}
      {...antdModalV5(modal)}
      visible={modal.visible}
      onCancel={onCancel}
      onSuccess={onSuccess}
    />
  );
};

export const DraftNodeModal = create(_DraftNodeModal);
export const DRAFT_NODE_MODAL_ID = '@@DRAFT_NODE_MODAL_ID';

/**
 * 弹窗创建草稿节点
 */
export const addDraftNode = (
  o: IDraftNode,
  id: string = DRAFT_NODE_MODAL_ID,
) => {
  return show(id, o) as Promise<DraftsModalProps['draftsNodeData']>;
};
