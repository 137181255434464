import type {
  CurrentUser,
  CustomButtonOpenConfig,
  TemplateCustomButton,
} from '@linkpi/core';
import {
  filterChecker,
  getQuoteOriginPropInfo,
  NODE_PERMISSION,
  tempValueDisplay,
} from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { delay, notEmpty } from '@linkpi/utils';
import { useMeasure } from '@react-hookz/web';
import { useQuery } from '@tanstack/react-query';
import { useDebounceFn } from 'ahooks';
import { message, Modal, Pagination, Table, Tooltip } from 'antd';
import { cloneDeep, intersection } from 'lodash';
import { isNil } from 'ramda';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { match, P } from 'ts-pattern';

import { NewLineText } from '@/components';
import { conditionV2ConvertCondition } from '@/components/ConditionFilter/utils';
import CustomButton from '@/components/CustomButton';
import type { IWidgetInstanceData } from '@/components/PageModelEditor';
import { migrateConfigV2 } from '@/components/PageModelEditor/src/widgets/ElewattTable/utils';
import { useComponentId, useOrgInfo, useOrgUserMap } from '@/hook';
import {
  use_deprecated_GlobalConditions,
  useGlobalConditions,
} from '@/hook/useGlobalConditions';
import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import { useOrgTempMap } from '@/hook/useTemplate';
import {
  triggerCustomButton,
  TriggerCustomButtonNiceModal,
} from '@/pages/home/components/TriggerCustomButton';
import {
  ViewContentNiceModal,
  viewNodeContent,
} from '@/pages/home/components/View/ViewContent';
import { fetchDataSource } from '@/utils/dataManager';
import {
  getCustomBtn,
  getSysBtn,
  isCustomButtonForbiddenClick,
  notAdminDelNodeAcl,
} from '@/utils/utils';

import { useRefreshNodePage } from '../../hooks';
import AddButtonForView from '../../viewsettingComponents/AddButtonForView';
import DeleteNodes from '../../viewsettingComponents/DeleteNodes';

import styles from './styles.less';

interface IProps {
  getterPiNode: GetterPiNode;
  data: IWidgetInstanceData & {
    widgetId: 'ElewattTable';
  };
  id: string;
  symbolId?: string;
}
const defaultWidth = 120;

export const ElewattTable: FC<IProps> = (props) => {
  const { id, symbolId } = props;
  const { config: widgetInstanceConfig } = props.data;
  const orgInfo = useOrgInfo()[0]!;
  const tempMap = useOrgTempMap(orgInfo.orgId);
  const userMap = useOrgUserMap();
  const departmentNodeMap = useOrgDepartmentNodesMap();
  const customButtonTriggerId = useComponentId();
  const viewNodeContentTriggerId = useComponentId();
  const [domRect = { width: 0, height: 0 }, domRef] = useMeasure();
  const [containerRect = { width: 0, height: 0 }, containerRef] = useMeasure();

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const config = useMemo(
    () => migrateConfigV2(widgetInstanceConfig as any),
    [widgetInstanceConfig],
  );

  const {
    titleStyle,
    contentStyle,
    fixedCol,
    propsConfig: _propsConfig,
    specialProp,
    allowBatchAction,
    tools,
    thmId,
    type,
    matchings,
    parentType,
    conditions: _conditions,
    bgConfig: { titleBg, contentBg, gapBg },
    horizontalBorder,
    verticalBorder,
    actions,
    pagination,
    titleLineHeight = 16,
    contentLineHeight = 16,
  } = config;

  const globalConditions = use_deprecated_GlobalConditions(id);
  const _mergedConditions = useMemo(() => {
    if (!globalConditions) return _conditions;
    if (!globalConditions.length) return _conditions;
    return [..._conditions, ...globalConditions];
  }, [_conditions, globalConditions]);

  const conditions = useGlobalConditions(_mergedConditions, id);

  const { refresh } = useRefreshNodePage();

  const templateId = conditions.find((i: any) => i.key === 'templateId')
    ?.input[0];

  const addButtonConfig = useMemo(
    () => tools?.find((tool: any) => tool.type === 'add'),
    [tools],
  );

  const defaultThmId =
    thmId ?? tempMap[templateId]?.temp_option?.defaultDataMapId;

  const {
    data: { list: dataSource, total },
    refetch,
  } = useQuery({
    queryKey: [
      'fetchDataSource',
      props.getterPiNode.value.id,
      conditions,
      currentPage,
      pagination,
      type,
      defaultThmId,
      parentType,
      orgInfo.orgId,
      matchings,
    ],
    queryFn: () =>
      fetchDataSource({
        orgId: orgInfo.orgId,
        currentNodeId: props.getterPiNode.value.id,
        page: currentPage,
        pageSize: (pagination || {}).size || 999,
        conditions,
        type,
        thmId: defaultThmId,
        parentType,
        matchings,
      }),
    initialData: {
      status: 'ok',
      message: '',
      list: [],
      total: 0,
      page: 1,
    },
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [conditions]);

  const getPropsConfig = () => {
    const prop = tempMap[templateId].prop
      .map((p: any, index: number) => ({ propIndex: index, ...p }))
      .filter((p: any) => p.type)
      .sort((p1, p2) => {
        if ('sort' in p1) return p1.sort - p2.sort;
        return 1;
      });

    return [
      {
        title: '序号',
        key: 'index',
        show: true,
        align: 'left',
        width: 50,
      },
      ...prop.map((p: CurrentUser.TemplateProp & { propIndex: number }) => {
        let propConfig = p;
        if (p.type === 'quote') {
          propConfig = getQuoteOriginPropInfo(
            p,
            tempMap,
          ) as unknown as typeof p;
        }

        const align = match(propConfig)
          .with(
            { type: 'number' },
            { type: 'formula', formulaFormat: 0 },
            () => 'right',
          )
          .otherwise(() => 'left');

        return {
          title: p.name,
          key: p.propIndex,
          show: true,
          align,
          width: undefined,
        };
      }),
    ];
  };

  const freshPropsConfig = () => {
    const prop = tempMap[templateId].prop
      .map((p: any, index: number) => ({ propIndex: index, ...p }))
      .filter((p: any) => p.type)
      .sort((p1: any, p2: any) => {
        if ('sort' in p1) return p1.sort - p2.sort;
        return 1;
      });
    // 新老对比
    const propMap = prop.reduce((res: any, cur: any) => {
      res[cur.propIndex] = cur;
      return res;
    }, {});

    const news: any[] = [];
    prop.forEach((p: any) => {
      let exist = false;

      _propsConfig.forEach((i: any) => {
        if (i.children)
          i.children.forEach((j: any) => {
            if (j.key === p.propIndex) exist = true;
          });
        else if (i.key === p.propIndex) exist = true;
      });

      if (!exist)
        news.push({
          title: p.name,
          key: p.propIndex,
          show: false,
          align: 'left',
          width: undefined,
        });
    });

    // 清除删除的属性
    const newPropsConfig = cloneDeep(_propsConfig.concat(news)).filter(
      (i: any) => String(i.key).length > 4 || propMap[i.key],
    );

    newPropsConfig.forEach((p: any) => {
      if (p.key === 'index') return;
      if (p.children) {
        p.children = p.children.filter((i: any) => propMap[i.key]);
        p.children.forEach((i: any) => {
          i.title = propMap[i.key].name;
        });
      } else {
        p.title = propMap[p.key].name;
      }
    });
    return newPropsConfig;
  };
  const propsConfig = (
    _propsConfig ? freshPropsConfig() : getPropsConfig()
  ).filter((p: any) =>
    p.children
      ? (p.children = p.children.filter((c: any) => c.show)).length
      : p.show,
  );

  const nowTemplate = tempMap[templateId];
  // 属性条件规则
  const propCondition = nowTemplate?.temp_option?.propCondition || [];
  const ablePropCondition = propCondition.filter((p: any) => p.enable);

  const fixedColIndex = propsConfig.findIndex((p: any) => p.key === fixedCol);

  let scrollX = 0;
  propsConfig.forEach((i: any) => {
    if (!i.children) {
      const w = i.width === undefined ? defaultWidth : i.width;
      scrollX += w;
    } else {
      i.children.forEach((c: any) => {
        const w = c.width === undefined ? defaultWidth : c.width;
        scrollX += w;
      });
    }
  });

  const renderTitle = (v: string) => {
    return (
      <div
        className="text-omit"
        style={{
          fontSize: titleStyle.size,
          color: titleStyle.color,
          fontWeight: titleStyle.decoration === 'bold' ? 700 : 400,
          fontStyle: titleStyle.decoration === 'italic' ? 'italic' : 'normal',
          textDecoration:
            titleStyle.decoration === 'underline' ? 'underline' : 'none',
        }}
      >
        {v}
      </div>
    );
  };

  const renderValue = (record: any, p: any, index: number) => {
    const size = (pagination || {}).size;
    if (p.key === 'index')
      return size ? (currentPage - 1) * size + index + 1 : index + 1;

    const v = ablePropCondition.filter((p: any) =>
      filterChecker(p.condition)(record.metadata),
    );

    const map: any = {};
    v.forEach((p: any) => {
      p.results.forEach((i: any) => {
        if (i.type === 'property_color') {
          const { propIndex, propColor, propBg } = i;
          map[propIndex] = { propColor, propBg };
        }
      });
    });

    let propValue = tempValueDisplay({
      propConfig: tempMap[templateId]?.prop[p.key],
      propValue: record.tempInfo.prop[p.key],
      propIndex: p.key,
      sysCascade: record.prop._sys_cascade,
      userMap: userMap,
      tempMap,
      departmentMap: departmentNodeMap,
    });

    if (isNil(propValue) || propValue === '') propValue = '--';

    return (
      <Tooltip overlayClassName={styles.tooltip} title={propValue}>
        <div
          className="text-omit"
          style={{
            fontSize: contentStyle.size,
            color: map[p.key] ? map[p.key].propColor : contentStyle.color,
            fontWeight: contentStyle.decoration === 'bold' ? 700 : 400,
            fontStyle:
              contentStyle.decoration === 'italic' ? 'italic' : 'normal',
            textDecoration:
              contentStyle.decoration === 'underline' ? 'underline' : 'none',
          }}
        >
          {propValue}
        </div>
      </Tooltip>
    );
  };

  propsConfig.forEach((p: any, index: number) => {
    p.title = renderTitle(p.title);
    if (index <= fixedColIndex) p.fixed = 'left';
    if (!p.children) {
      // prop
      p.render = (_: any, v: any, i: number) => renderValue(v, p, i);
      p.width = p.width || defaultWidth;
    } else {
      //group
      p.children.forEach((c: any) => {
        c.title = renderTitle(c.title);
        c.width = c.width || defaultWidth;
        c.render = (_: any, v: any, i: number) => renderValue(v, c, i);
        if (index <= fixedColIndex) c.fixed = 'left';
      });
    }
  });
  const getBtns = () => {
    const sys: string[] = [];
    const notSys: string[] = [];

    const sortMap: any = {
      system_button_delete: 2,
      system_button_edit: 1,
      system_button_view: 0,
    };

    actions.forEach((i: string) => {
      if (i.startsWith('system_button')) sys.push(i);
      else notSys.push(i);
    });

    sys.sort((a, b) => sortMap[a] - sortMap[b]);
    return [...sys, ...notSys]
      .map((id: string) => {
        return id.startsWith('system_button')
          ? getSysBtn(templateId, id, orgInfo.templateList)
          : getCustomBtn(templateId, id, orgInfo.templateList);
      })
      .filter((i: any) => !!i);
  };

  const { run: refreshNodePage } = useDebounceFn(refresh, {
    wait: 500,
  });

  const actionsCol = {
    title: '操作',
    key: 'action',
    fixed: 'right',
    width: domRect.width + 35,
    render: (record: PiNode) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {getBtns()
            .filter(notEmpty)
            .map((btnConfig) =>
              match(btnConfig)
                .with({ id: P.string.startsWith('system_button') }, () => (
                  <div
                    style={{ marginRight: 8 }}
                    onClick={() => {
                      if (
                        btnConfig.id === 'system_button_view' ||
                        btnConfig.id === 'system_button_edit'
                      ) {
                        const ids = dataSource.map((n: any) => n.id);
                        const index = ids.indexOf(record.id);

                        const openConfig =
                          btnConfig.openConfig as CustomButtonOpenConfig;

                        viewNodeContent(
                          {
                            nodeIndex: index,
                            dataList: ids,
                            showNodeSaved: () => {},
                            openType:
                              openConfig?.openType === 'drawer'
                                ? 'drawer'
                                : 'modal',
                            modalConfig: openConfig?.modalConfig,
                            pageModelConfigId: openConfig?.pageModel,
                          },
                          viewNodeContentTriggerId,
                        );
                        return;
                      }

                      if (btnConfig.id === 'system_button_delete') {
                        const allowDelete =
                          notAdminDelNodeAcl(orgInfo, record) &&
                          record.acl === NODE_PERMISSION.管理 &&
                          !record.prop._sys_protect;
                        if (!allowDelete) {
                          message.warning('当前主题无权限删除');
                          return;
                        }
                        Modal.confirm({
                          title: '提示',
                          content: `是否确定删除该数据？`,
                          onOk: async (closeModal) => {
                            props.getterPiNode.value.nodeManager
                              .getNode(record.id)
                              .deleteNode();
                            await delay(666);
                            refetch();
                            closeModal();
                          },
                        });
                        return;
                      }
                      triggerCustomButton(
                        {
                          config: btnConfig as TemplateCustomButton,
                          node: record,
                          force: true,
                        },
                        customButtonTriggerId,
                      );
                    }}
                    key={btnConfig.id}
                  >
                    {btnConfig.id.startsWith('system_button') ? (
                      <span
                        style={{
                          cursor: 'pointer',
                          color:
                            btnConfig.id === 'system_button_delete'
                              ? '#F0665E'
                              : '#316EF5',
                        }}
                      >
                        {btnConfig.text}
                      </span>
                    ) : (
                      <CustomButton
                        loading={false}
                        config={btnConfig}
                        forbiddenClick={false}
                      />
                    )}
                  </div>
                ))
                .otherwise(() => {
                  const node = record;
                  const _btnConfig = btnConfig as TemplateCustomButton;

                  if (!_btnConfig) return null;
                  if (_btnConfig.hiddenWhenAdded) return null;

                  const forbiddenClick = isCustomButtonForbiddenClick(
                    _btnConfig,
                    node,
                  );
                  if (forbiddenClick && _btnConfig.hiddenByDisableCondition)
                    return null;

                  return (
                    <div
                      key={_btnConfig.id}
                      style={{ marginRight: 8 }}
                      onClick={async () => {
                        if (forbiddenClick) return;

                        // const loading = buttonLoadingMap[config.id];

                        await triggerCustomButton(
                          {
                            config: btnConfig as TemplateCustomButton,
                            node,
                            force: true,
                            cb: (opType) => {
                              // 自动化执行后强制更新大模型
                              // https://master.test.mylinkpi.com/share/content?token=BFB17883B63ECA66AF8C2D2FF95ADF26
                              if (opType === 'automationTrigger') {
                                refreshNodePage(symbolId);
                              }
                            },
                          },
                          customButtonTriggerId,
                        );
                      }}
                    >
                      <CustomButton
                        loading={false}
                        config={btnConfig}
                        forbiddenClick={forbiddenClick}
                      />
                    </div>
                  );
                }),
            )}
        </div>
      );
    },
  };

  const columns: any[] = propsConfig;

  if (actions.length) columns.push(actionsCol);

  const delNodes = () => {};

  if (!propsConfig) return null;
  const tableProps: any = {
    expandable: {
      childrenColumnName: '--',
    },
  };
  if (specialProp !== undefined) {
    tableProps.expandable.expandedRowRender = (record: any) => {
      let value = tempValueDisplay({
        propConfig: tempMap[templateId]?.prop[specialProp],
        propValue: record.tempInfo.prop[specialProp],
        propIndex: specialProp,
        sysCascade: record.prop._sys_cascade,
        userMap: userMap,
        tempMap,
        departmentMap: departmentNodeMap,
        sysDisplay: record.prop._sys_display,
      });

      if (isNil(value)) value = '--';

      return <NewLineText value={value.toString()} />;
    };
  }

  const getScrollY = () => {
    let headerHeight = 16;
    let footerHeight = 0;
    const tableHeight = 85;
    if (selectedRowKeys.length > 0 || (tools?.length ?? -1) > 0)
      headerHeight = 48;
    if (pagination) footerHeight = 40;
    if (!containerRect.height) return 9999;

    return containerRect.height - footerHeight - headerHeight - tableHeight;
  };

  return (
    <div style={{ height: '100%' }} ref={containerRef}>
      {actions.length > 0 && (
        <div
          ref={domRef}
          style={{
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            position: 'absolute',
            top: -9999,
          }}
        >
          {getBtns().map((btnConfig: any) => (
            <div style={{ marginRight: 8 }} key={btnConfig.id}>
              {btnConfig.id.startsWith('system_button') ? (
                <span>{btnConfig.text}</span>
              ) : (
                <CustomButton
                  loading={false}
                  config={btnConfig}
                  forbiddenClick={false}
                />
              )}
            </div>
          ))}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          padding: `${selectedRowKeys.length > 0 || (tools?.length ?? -1) > 0 ? '8' : '0'}px 0`,
        }}
      >
        <div>
          {selectedRowKeys.length > 0 && (
            <DeleteNodes
              orgInfo={orgInfo}
              getterPiNode={props.getterPiNode}
              checkId={selectedRowKeys}
              setCheckId={setSelectedRowKeys}
            />
          )}
        </div>
        {addButtonConfig?.show && (
          <AddButtonForView
            waitAuto
            template={tempMap[templateId]}
            curViewData={{
              view_info: {
                condition: conditionV2ConvertCondition(
                  conditions.find((c: any) => c.key === 'ancestor') ===
                    undefined
                    ? conditions.concat({
                        key: 'ancestor',
                        op: 'intersect',
                        input: [props.getterPiNode.value.id],
                      })
                    : conditions,
                ).conditions.filter((c) => c.key),
              },
            }}
            getterPiNode={props.getterPiNode}
            templateList={orgInfo.templateList}
            onSuccess={async () => {
              await delay(800);
              await refetch();
            }}
            name={addButtonConfig?.value}
          />
        )}
      </div>
      <div
        style={
          {
            ['--title-lineHeight' as string]: titleLineHeight + 'px',
            ['--content-lineHeight' as string]: contentLineHeight + 'px',
            ['--title-bg' as string]: titleBg,
            ['--content-bg' as string]: contentBg,
            ['--gap-bg' as string]: gapBg,
            ['--horizontal-thborder' as string]: horizontalBorder || titleBg,
            ['--vertical-thborder' as string]: verticalBorder || titleBg,
            ['--horizontal-tdborder' as string]: horizontalBorder || contentBg,
            ['--vertical-tdborder' as string]: verticalBorder || contentBg,
          } as React.CSSProperties
        }
        className={styles.tableContainer}
      >
        <Table
          pagination={false}
          columns={columns}
          dataSource={dataSource}
          bordered
          sticky
          rowSelection={
            allowBatchAction
              ? {
                  selectedRowKeys,
                  onChange: setSelectedRowKeys,
                }
              : undefined
          }
          {...tableProps}
          rowKey={(v: any) => v.id}
          scroll={{ x: scrollX, y: getScrollY() }}
        />
        {pagination && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              paddingTop: 8,
              alignItems: 'center',
            }}
          >
            <div style={{ color: '#B6B8B9', marginRight: 16 }}>共{total}条</div>
            <Pagination
              showSizeChanger={false}
              onChange={(p) => setCurrentPage(p)}
              current={currentPage}
              total={total}
              pageSize={pagination.size}
            />
          </div>
        )}
      </div>
      {/* @ts-ignore */}
      <TriggerCustomButtonNiceModal id={customButtonTriggerId} />
      {/* 预览 */}
      {/* @ts-ignore */}
      <ViewContentNiceModal id={viewNodeContentTriggerId} />
    </div>
  );
};
