import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { Input, message, Modal } from 'antd';
import { useState } from 'react';

const RejectGameModal = create(() => {
  const modal = useModal();
  const [reason, setReason] = useState('');

  const handleOk = () => {
    if (!reason) {
      message.error('请输入不通过原因');
      return;
    }
    modal.resolve(reason);
    modal.hide();
  };

  return (
    <Modal
      {...antdModalV5(modal)}
      title="请输入不通过原因"
      onOk={handleOk}
      centered
    >
      <Input.TextArea
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        placeholder="请输入不通过原因"
        rows={4}
      />
    </Modal>
  );
});

export const showRejectGameModal = () => {
  return show(RejectGameModal);
};
