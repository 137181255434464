import { assertExists } from '@linkpi/utils';
import { Input, Popover, Switch } from 'antd';

import { RegularIcon } from '@/components/IconFont';
import { useTemplateMap } from '@/hook';
import { cn, defaultTools } from '@/utils/utils';

import { useActiveWidgetInstance, useEditor } from '../hooks';

import Styles from './ToolsSetting.less';

const map: any = {
  search: '搜索',
  condition: '筛选',
  group: '分组',
  sort: '排序',
  displayRow: '显示列',
  lineHeight: '行高',
  treeLevel: '展示树层',
  collapse: '折叠',
  import: '导入表格',
  export: '导出表格',
  addBtn: '新增按钮',
};
export const ToolsSetting = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance();
  assertExists(widgetInstance);

  const tempMap = useTemplateMap();

  const updateConfig = editor.updateActiveWidgetInstanceConfig;

  // TODO addButtonName 应该放在 toolsConfig 中
  let { toolsConfig, conditions, addButtonName } = widgetInstance.config as any;
  const templateId = conditions.find((i: any) => i.key === 'templateId')
    ?.input[0];
  const template = tempMap[templateId];
  toolsConfig = toolsConfig || defaultTools.slice(0, 5);

  const getAllChecked = () => {
    return toolsConfig.length === 11;
  };

  const changeAllChecked = () => {
    updateConfig((config: any) => {
      config.toolsConfig = getAllChecked() ? [] : defaultTools;
    });
  };

  const changeChecked = (tool: string) => {
    updateConfig((config: any) => {
      config.toolsConfig = toolsConfig.includes(tool)
        ? toolsConfig.filter((t: string) => t !== tool)
        : toolsConfig.concat([tool]);
    });
  };

  return (
    <div style={{ marginBottom: 24 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ color: '#242D3F', fontWeight: 700 }}>工具栏</div>
        <Switch checked={getAllChecked()} onChange={changeAllChecked} />
      </div>
      {defaultTools.map((t) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 16,
          }}
          key={t}
        >
          <div style={{ color: '#242D3F' }}>{map[t]}</div>
          <div className="flex items-center">
            <Popover
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode as HTMLElement
              }
              trigger="click"
              placement="bottom"
              destroyTooltipOnHide
              content={
                <div className="w-56" onClick={(e) => e.stopPropagation()}>
                  <p className="mb-2">按钮显示文字</p>
                  <Input
                    defaultValue={addButtonName}
                    onChange={(e) => {
                      const value = e.target.value;
                      updateConfig((config: any) => {
                        config.addButtonName = value;
                      });
                    }}
                    placeholder={`默认为 “新增${template.name}”`}
                  />
                </div>
              }
              overlayClassName={Styles['tool-popover']}
              className={cn({
                hidden: t !== 'addBtn' || !toolsConfig.includes('addBtn'),
              })}
            >
              <div className="mr-4 cursor-pointer">
                <RegularIcon
                  type="iconshaixuanqu-bianji"
                  color="#316EF5"
                  size={16}
                />
              </div>
            </Popover>
            <Switch
              checked={toolsConfig.includes(t)}
              onChange={(checked) => {
                changeChecked(t);
                if (t === 'addBtn' && !checked) {
                  updateConfig((config: any) => {
                    config.addButtonName = undefined;
                  });
                }
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
