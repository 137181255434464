import { request } from '@linkpi/request';

export interface FormulaItem {
  formula_id: string;
  formula_text: string;
  name: string;
  note: string;
}

export const getFormulaList = async () => {
  return request<FormulaItem[]>('/api/formulaFavorites/query', {
    data: {},
  });
};

export interface CreateCustomFormulaParams {
  /**
   * 公式文本
   */
  formula: string;
  /**
   * 公式名称
   */
  name: string;
  /**
   * 公式说明
   */
  note: string;
  org_id: string;
  temp_id: string;
}

export const createCustomFormula = async (
  params: CreateCustomFormulaParams,
) => {
  return request('/api/formulaFavorites/insert', {
    data: params,
  });
};

export interface UpdateCustomFormulaParams {
  formula_id: string;
  /**
   * 公式名称
   */
  name: string;
  /**
   * 公式说明
   */
  note: string;
}

export const updateCustomFormula = async (
  params: UpdateCustomFormulaParams,
) => {
  return request('/api/formulaFavorites/update', {
    data: params,
  });
};

export interface DeleteCustomFormulaParams {
  formula_id: string;
}

export const deleteCustomFormula = async (
  params: DeleteCustomFormulaParams,
) => {
  return request('/api/formulaFavorites/delete', {
    data: params,
  });
};
