import { SaveOutlined } from '@ant-design/icons';
import type { NiceModalHocProps } from '@ebay/nice-modal-react';
import {
  antdModalV5,
  create as ModalCreate,
  show,
  useModal,
} from '@ebay/nice-modal-react';
import { formulaToString, stringToFormula } from '@linkpi/core/web';
import { Button, message, Modal } from 'antd';
import cls from 'classnames';
import type CodeMirror from 'codemirror';
import { useSetAtom } from 'jotai';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import {
  useCurrentOrgId,
  useCurrentTemplateSetting,
  useOrgTemplatesSettingInfoMap,
} from '@/hook';
import { createCustomFormula } from '@/services/formula';
import { cn } from '@/utils/utils';

import { formulaListAtom } from '../store';
import type { TokenType } from './constantTokens';
import { showAddFormula } from './CustomFormulaModal';
import type { FormulaEditorHandlerType } from './FormulaEditor';
import FormulaEditor from './FormulaEditor';
import type { PickerRefHandlerType } from './Picker';
import Picker from './Picker';

import styles from './index.less';

type FormulaInput = {
  type: 'prop' | 'const' | 'formula' | 'cas_prop' | 'cas_prop_n';
  prop?: number;
  const?: string;
  formula?: Formula;
  level?: number;
};

interface Formula {
  op:
    | 'add'
    | 'subtract'
    | 'multiply'
    | 'divide'
    | 'sum'
    | 'max'
    | 'min'
    | 'count'
    | 'random'
    | 'fvv'
    | 'concat'
    | 'date'
    | 'i_to_s'
    | 'nick_name'
    | null;
  input: Array<FormulaInput>;
}

type FormulaProps = {
  formula?: Formula;
  formulaText?: string | null;
};

const FormulaSettingModal: FC<FormulaProps> = (props) => {
  const modal = useModal();
  const { visible } = modal;
  const { formula, formulaText } = props;

  const [currentTemplate] = useCurrentTemplateSetting();
  const [fullscreen, setFullscreen] = useState(false);
  const [error, setError] = useState<string | false>(false);
  const pickRef = useRef<PickerRefHandlerType>(null);
  const editorRef = useRef<FormulaEditorHandlerType>(null);
  const orgId = useCurrentOrgId();

  const refreshFormulaList = useSetAtom(formulaListAtom);
  const tempMap = useOrgTemplatesSettingInfoMap();
  const tempMapRef = useRef(tempMap);
  tempMapRef.current = tempMap;
  const formulaRef = useRef(formula);
  formulaRef.current = formula;
  const formulaTextRef = useRef(formulaText);
  formulaTextRef.current = formulaText;
  const currentTemplateRef = useRef(currentTemplate);
  currentTemplateRef.current = currentTemplate;

  useEffect(() => {
    if (visible) {
      // 解析formula或者formulaText
      let editFormulaText = formulaTextRef.current || '';
      if (!formulaTextRef.current && formula) {
        // 老数据 转为text
        editFormulaText = formulaToString(
          formula,
          Object.values(tempMapRef.current),
          currentTemplateRef.current.template_id,
        );
      }
      // 插入编辑器
      editorRef.current?.getEditor().setValue(editFormulaText as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  // 光标变更 filter pick list
  const onEditorChange = (editor: CodeMirror.Editor) => {
    const cursor = editor.getCursor();
    const token = editor.getTokenAt(cursor);
    const filterStr =
      (token.type === 'variable' || token.type === 'property') && token.string
        ? token.string
        : '';
    console.log({ cursor, token, filterStr });
    pickRef.current?.filter(filterStr);
  };
  // 字符变更 检查代码合法
  const onTextChange = (str: string) => {
    setError(false);
    if (!str) return;

    const [hasErr, newFormula] = getStrFormula(str);
    if (hasErr) {
      setError(hasErr);
      return;
    }
  };

  /**
   * 获取str转换的公式
   */
  const getStrFormula = (str: string): [false | string, any] => {
    let resErrorMessage: false | string = false;
    let resFormula = {};
    try {
      const newFormula = stringToFormula(
        str,
        Object.values(tempMap),
        currentTemplate.template_id,
      );
      console.log(str, 'stringToFormula =>', newFormula);
      // 如果返回undefined 视为异常
      if (newFormula === undefined) {
        resErrorMessage = '公式输入异常';
      } else {
        resFormula = newFormula;
      }
    } catch (_error) {
      console.log('stringToFormula error', _error);
      const error = _error as Error;
      const errorName = error?.name || 'Error';
      const errorMessage = error?.message || '';

      resErrorMessage = '公式输入异常';
      // 处理所有babel的异常
      if (errorName === 'SyntaxError') {
        // if (~errorMessage.indexOf('Unexpected token, expected')) {
        // 1. Unexpected token, expected ","
        //   const errToken = errorMessage.match(/(?<=").+(?=")/)?.[0];
        //   if (errToken) {
        //     resErrorMessage = '未识别的字符: ' + errToken;
        //   }
        // } else if (~errorMessage.indexOf('Unexpected token')) {
        // 2. Unexpected token
        resErrorMessage = '语法异常';
        // }
        // 3. 其他先不处理
      } else {
        // 帆帆抛出的异常 直接显示
        resErrorMessage = error?.message || '语法异常';
      }
    }

    return [resErrorMessage, resFormula];
  };

  const onPick = (token: TokenType, extend: any) => {
    const editor = editorRef.current?.getEditor();
    if (!editor) return;

    // 1. 属性 替换或插入
    // 2. 公式，插入
    // 有光标时，插入光标处
    // 无光标时，插入末尾
    // 重新设置光标位置

    // 插入属性
    const cursor = editor.getCursor();
    // TODO 先直接做成 有光标 插入光标位置
    // 没有光标 插入末尾
    const value = editor.getValue();

    const code =
      token.insert.code instanceof Function
        ? token.insert.code(extend)
        : token.insert.code;
    if (typeof code !== 'string') {
      console.error('insert code 异常');
      return;
    }

    if (!cursor) {
      // 1. 无光标 添加到末尾 并添加光标到末尾
      const newValue = value + code;
      editor.setValue(newValue);
      const newCursorPos = editor.posFromIndex(newValue.length);
      editor.setCursor(newCursorPos);
    } else {
      // 2. 有光标 插入光标位置
      let index = editor.indexFromPos(cursor);
      let beforeValue = value.slice(0, index);
      const afterValue = value.slice(index);

      // 如果当前光标 的token 是 variable 删掉他
      const cursorToken = editor.getTokenAt(cursor);
      console.log(cursorToken);
      if (cursorToken.type === 'variable') {
        const delLen = cursorToken.string.length;
        beforeValue = beforeValue.slice(0, beforeValue.length - delLen);
        index -= delLen;
      }
      const newValue = beforeValue + code + afterValue;
      const newCursorIndex = index + (token.insert.cursor || code.length);
      editor.setValue(newValue);

      const newCursorPos = editor.posFromIndex(newCursorIndex);
      editor.setCursor(newCursorPos);
    }
  };

  const handleSubmit = () => {
    const value = editorRef.current?.getEditor().getValue() || '';
    const [hasErr, newFormula] = getStrFormula(value);

    if (hasErr) {
      return message.error('代码异常，请重新编辑');
    }
    // setFormula(newFormula.formula);
    // setFormulaText(value);
    modal.resolve({
      formula: newFormula.formula,
      formulaText: value,
    });
    modal.hide();
  };

  const handleAddFormula = async () => {
    const value = editorRef.current?.getEditor().getValue() || '';
    const [hasErr, newFormula] = getStrFormula(value);

    if (hasErr) {
      message.error('请输入正确的公式!');
      return;
    }
    const values = await showAddFormula();
    const res = await createCustomFormula({
      org_id: orgId,
      temp_id: currentTemplate.template_id,
      formula: value,
      ...values,
    });
    if (res.status === 'ok') {
      message.success('保存成功!');
      refreshFormulaList();
    } else {
      message.error((res.message as string) || '保存失败!');
    }
  };

  return (
    <Modal
      {...antdModalV5(modal)}
      className={cls(
        styles.formulaSettingModal,
        fullscreen ? styles.fullscreen : '',
      )}
      width={fullscreen ? '100%' : 760} // 全屏的宽度fix
      modalRender={() => {
        return (
          <div className={cls('ant-modal-content', styles.modalContent)}>
            <Picker
              ref={pickRef}
              fullscreen={fullscreen}
              template={currentTemplate}
              onPick={onPick}
            />

            <div
              className={cls(
                styles.editorContainer,
                fullscreen ? styles.full : '',
              )}
            >
              <div className={cn(styles.switchBar, 'flex justify-between')}>
                <span>请输入公式</span>
                <span
                  className="text-[#316EF5] cursor-pointer"
                  onClick={handleAddFormula}
                >
                  <SaveOutlined className="mr-1" />
                  存为自定义
                </span>
              </div>
              <FormulaEditor
                ref={editorRef}
                onTextChange={onTextChange}
                onEditorChange={onEditorChange}
              />
              <div className={styles.error}>{error ? error : ''}</div>
            </div>

            {/* footer */}
            <div className={styles.footer}>
              <div className={styles.leftBtns}>
                <Button type="text" onClick={() => setFullscreen(!fullscreen)}>
                  {fullscreen ? '退出' : '进入'}全屏模式&nbsp;&gt;
                </Button>
              </div>
              <div className={styles.rightBtns}>
                <Button
                  onClick={() => {
                    modal.reject();
                    modal.hide();
                  }}
                >
                  取消
                </Button>
                <Button
                  type="primary"
                  style={{ marginLeft: 24 }}
                  onClick={handleSubmit}
                >
                  确认
                </Button>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

const FormulaSettingNiceModal = ModalCreate(FormulaSettingModal);

export const showFormulaSettingModal = (props: FormulaProps) => {
  return show<
    FormulaProps,
    FormulaProps & NiceModalHocProps,
    Partial<FormulaProps & NiceModalHocProps>
  >(FormulaSettingNiceModal, props);
};
