import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import type { CurrentUser } from '@linkpi/core';
import {
  checkDisabledDate,
  checkNodePropVisibleByGroup,
  convertTimeByModule,
  Date_Format_Options,
  displayCascadeValue,
  generateAddOpId,
  genNumberLimit,
  getDefaultTempProp,
  getEnumOptions,
  getPropGroupDefaultOpenMap,
  getPropHandledValue,
  getPropVisibleMap,
  getQuoteOriginProp,
  getResetPropValueByCondition,
  NODE_PERMISSION,
  PROP_TYPE,
  propIsNull,
  tempValueDisplay,
  textSort,
} from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { assertExists } from '@linkpi/utils';
import { useRerender } from '@react-hookz/web';
import { useSelector } from '@umijs/max';
import { useKeyPress, useMemoizedFn, useToggle, useUnmount } from 'ahooks';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Select,
  Space,
  Tooltip,
} from 'antd';
import cls from 'classnames';
import dayjs from 'dayjs';
import JsBarcode from 'jsbarcode';
import { cloneDeep, isEqual, isNil } from 'lodash';
import QRCode from 'qrcode';
import { omit, uniq } from 'ramda';
import type { FC, ReactNode } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { match } from 'ts-pattern';

import { PiInputNumber, RegularIcon } from '@/components';
import AddressInput from '@/components/AddressInput';
import CascadeSelect from '@/components/CascadeSelect';
import ChooseTagSelect from '@/components/ChooseTagSelect';
import DraftsModal from '@/components/DraftNodeModal/DraftsModal';
import { AutoFocus } from '@/components/EnhanceForm';
import LinkPiDate from '@/components/LinkPiDate';
import {
  DepartmentSelect,
  TemplateTextPropInput,
} from '@/components/LinkPiForm';
import type { LocationSelectRef } from '@/components/LocationSelect';
import LocationSelect, {
  getLocationSelectProps,
} from '@/components/LocationSelect';
import type { IWidgetInstanceData } from '@/components/PageModelEditor';
import SimpleTooltip from '@/components/SimpleTooltip';
import {
  useCurrentOrgId,
  useCurrentUserInfo,
  useOrgConnection,
  useOrgInfo,
  useOrgUserList,
  useOrgUserMap,
  usePageThemeMode,
} from '@/hook';
import {
  useOrgDepartmentNodes,
  useOrgDepartmentNodesMap,
} from '@/hook/useOrgStructure';
import { useOrgTempMap } from '@/hook/useTemplate';
import { updatePropFail } from '@/pages/home/components/Notification';
import QuoteNodes from '@/pages/home/components/QuoteNodes';
import QuoteSelector from '@/pages/home/components/QuoteSelector';
import SplitNumber from '@/pages/home/components/SplitNumber';
import {
  currencyParser,
  validateUsers,
} from '@/pages/home/components/TempStatus';
import { ViewContent } from '@/pages/home/components/View/ViewContent/ViewContent';
import request from '@/utils/request';
import {
  checkAttrDisplay,
  clipboardCopy,
  cn,
  exportGraphicCodes,
  filterQuoteNodesForMatching,
  filterQuoteNodesForQuote,
  filterUsersByPinyin,
  getExportGraphicCodesPropValues,
  getQuoteOptionVisible,
  getQuoteOrMatchingInfo,
  isPropCanEdit,
  isPropSupportGraphicCode,
  strExistSpecialCode,
  uniqueArr,
} from '@/utils/utils';

import AttachmentCell from './AttachmentCell';
import {
  getAttrNameClassName,
  getAttrNameWidth,
  getPropValueClassName,
  getPropValueInputClassName,
  getPropValueLabelClassName,
} from './contentCssUtils';
import DateBeforePresent from './DateBeforePresent';
import { UseUpdateDateBeforePresentMark } from './DateBeforePresent/hook';
import PropRecordModal from './PropRecordModal';

import '@/assets/styles/contentFrom.less';
import './index.less';
import './newContent.less';
import styles from './index.module.less';

type TemplateProp = CurrentUser.TemplateProp;

const { Option } = Select;
const RC_SELECT_PROPS = {
  showAction: ['focus', 'click'],
};

let saveValue: any = null,
  saveTags: string[] = [],
  saveCascadeTitles: string[] = [],
  saveDisplay: string[] | string | undefined = [];
type NodePropsFormProps = {
  node: GetterPiNode;
  currentUser: CurrentUser;
  simpleThemeTreeWidth: number;
  isSimpleThemeTree: boolean;
  isDrafts: boolean;
  draftParentId?: string;
  mode: string;
  nodePropConfig?: (IWidgetInstanceData & {
    widgetId: 'CustomProps';
  })['config'];
  setEditedProps: (...args: any[]) => void;
  editPartPropsInNode?: unknown[];
  readOnly?: boolean;
};

const NodePropsForm: FC<NodePropsFormProps> = (props) => {
  const {
    node,
    currentUser,
    simpleThemeTreeWidth,
    isSimpleThemeTree,
    isDrafts,
    draftParentId,
    mode = 'edit',
    nodePropConfig,
    setEditedProps,
    editPartPropsInNode = null,
    readOnly = false,
  } = props;
  const showFoldButton = nodePropConfig?.showFoldButton ?? true;
  const showSaveButton = (nodePropConfig?.showSaveButton ?? false) && !isDrafts;
  const propPlaceholderMap = nodePropConfig?.propPlaceholderMap ?? {};
  const rerender = useRerender();

  /*
   * 组件卸载时，如果配置了保存按钮，则提交事务
   */
  useUnmount(() => {
    if (showSaveButton) {
      node.value.abortTransaction();
    }
  });

  const [saving, setSaving] = useState(false);

  const currentUserInfo = useCurrentUserInfo();
  const departmentMap = useOrgDepartmentNodesMap();
  const { isDark } = usePageThemeMode();

  const { spaceUserList } = useSelector((state: any) => state.workspace);
  const currentOrgId = useCurrentOrgId();

  const userMap = useOrgUserMap();
  const spaceUsers = useOrgUserList();

  const orgConnection = useOrgConnection(currentOrgId!);
  const propRecordModalRef = useRef();
  const onceRef = useRef(true);

  const [org] = useOrgInfo(currentOrgId!);
  const tempMap = useOrgTempMap();

  const [enumExtend, setEnumExtend] = useState({});
  const [templateProp, setTemplateProp] = useState<CurrentUser.TemplateProp[]>(
    [],
  );
  const [editingId, setEditingId] = useState('');

  const currentTemplateId = useMemo(() => {
    return node.value.tempInfo.id;
  }, [node.value.tempInfo.id]);

  const currentTemplate = useMemo(() => {
    return tempMap[currentTemplateId] || null;
  }, [currentTemplateId, tempMap]);

  /**
   * 是否显示编辑属性提示,
   *
   * 仅在非保存按钮时显示
   */
  const showEditPropMsg =
    (currentTemplate?.temp_option?.showEditPropMsg ?? false) && !showSaveButton;

  const [repeatVisible, setRepeatVisible] = useState(false);
  const [propVisible, setPropVisible] = useState(true);
  const [quoteVisible, setQuoteVisible] = useState(false);
  const [quoteSelectorVisible, setQuoteSelectorVisible] = useState(false);
  const [numberSplitIndex, setNumberSplitIndex] = useState(-1);
  const [quoteIndex, setQuoteIndex] = useState(-1);
  const [originNodes, setOriginNodes] = useState(null);

  // 折叠的组
  const [propGroupFolds, setPropGroupFolds] = useState<string[]>([]);

  // 节点预览
  const [contentVisible, setContentVisible] = useState(false);
  const [currentNodeIndex, setCurrentNodeIndex] = useState(0);
  const [currentDataList, setCurrentDataList] = useState<string[]>([]);

  // 新增主题，用于引用属性
  const [showRecurrenceModal, setShowRecurrenceModal] = useState(false);
  const [nodeParent, setNodeParent] = useState('');
  const [draftsNodeData, setDraftsNodeData] = useState({});

  const [updateDateBeforePresentMark] =
    UseUpdateDateBeforePresentMark(currentTemplate);
  const locationSelectRef = useRef<LocationSelectRef | null>(null);

  const [displayHiddenPropsState, { toggle: toggleDisplayHiddenPropsState }] =
    useToggle();
  const isManger = org?.role === 1 || org?.role === 3;
  const displayHiddenProps = isManger && displayHiddenPropsState;

  // 编辑模式
  const [globalEditable, setGlobalEditable] = useState(
    nodePropConfig?.editMode !== 'toggleEditor',
  );

  const updateProp = useMemoizedFn(
    (_payload: Parameters<PiNode['updateProp']>[0]) => {
      const payload = cloneDeep(_payload);

      /**
       * 属性的条件规则支持隐藏时清空属性值
       */
      if (currentTemplate) {
        const nodeMetaData = cloneDeep(node.value.metadata);
        payload.index.forEach((propIndex, index) => {
          nodeMetaData.e._sys_temp[1][propIndex] = payload.value[index];
        });

        const resetPropValues = getResetPropValueByCondition(
          nodeMetaData,
          currentTemplate,
          currentUserInfo,
        );

        resetPropValues.forEach((i) => {
          payload.index.push(i.propIndex);
          payload.value.push(i.value);
          if (isNil(i.value)) {
            switch (i.propType) {
              case 'enum':
              case 'tag':
                payload.display = omit(
                  [i.propIndex.toString()],
                  payload.display,
                );
                break;
              case 'address':
              case 'positioning':
                payload.location = omit(
                  [i.propIndex.toString()],
                  payload.location,
                );
                break;
              case 'attachment':
                payload.attachment = omit(
                  [i.propIndex.toString()],
                  payload.attachment,
                );
                break;
              case 'cascade':
                payload.cascade = omit(
                  [i.propIndex.toString()],
                  payload.cascade,
                );
                break;

              default:
                break;
            }
          }
        });
      }

      try {
        node.value.updateProp(
          payload,
          showSaveButton ? 'transaction' : undefined,
        );
      } catch (err: any) {
        message.error(err.message);
      }
      return Promise.resolve([null, { status: 'ok' }]);
    },
  );

  useEffect(() => {
    if (editingId) {
      setTimeout(() => {
        document?.getElementById?.(editingId)?.focus();
      }, 100);
    }
  }, [editingId]);

  const getInitPropVisible = () => {
    if (!nodePropConfig || !currentTemplate) return true;

    // 是否全部收起
    const {
      attributesDefaultShow,
      showGroup,
      propGroupFolds = [],
    } = nodePropConfig;

    if (attributesDefaultShow === 'defaultFold') return false;
    if (!showGroup) return true;

    // 是否有组被折叠了
    let prop_groups: any = currentTemplate?.prop_groups;
    if (!prop_groups || prop_groups.length === 0) prop_groups = [{ id: '-1' }];
    let hasFold = false;
    prop_groups.forEach((g) => {
      if (propGroupFolds.includes(g.id)) hasFold = true;
    });
    return !hasFold;
  };

  useEffect(() => {
    setPropVisible(getInitPropVisible());
    if (nodePropConfig && currentTemplate) {
      const propGroupDefaultOpenMap = getPropGroupDefaultOpenMap(
        node.value,
        currentTemplate,
        currentUserInfo,
      );
      let _propGroupFolds: string[] = nodePropConfig.propGroupFolds || [];

      // 条件的权重大
      Object.keys(propGroupDefaultOpenMap).forEach((groupId) => {
        // 折叠
        if (propGroupDefaultOpenMap[groupId] === false)
          _propGroupFolds.push(groupId);

        // 展开
        if (propGroupDefaultOpenMap[groupId] === true)
          _propGroupFolds = _propGroupFolds.filter((i) => i !== groupId);
      });

      setPropGroupFolds(_propGroupFolds);
    }
  }, [node?.value.id, currentUserInfo]);

  // 属性每被更改一次，属性的折叠都需要重新算一下
  useEffect(() => {
    if (onceRef.current) return;

    onceRef.current = false;
    const propGroupDefaultOpenMap = getPropGroupDefaultOpenMap(
      node.value,
      currentTemplate,
      currentUserInfo,
    );
    let newPropGroupFolds = [...propGroupFolds];

    // 条件的权重大
    Object.keys(propGroupDefaultOpenMap).forEach((groupId) => {
      // 折叠
      if (propGroupDefaultOpenMap[groupId] === false)
        newPropGroupFolds.push(groupId);

      // 展开
      if (propGroupDefaultOpenMap[groupId] === true)
        newPropGroupFolds = newPropGroupFolds.filter((i) => i !== groupId);
    });

    setPropGroupFolds(newPropGroupFolds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node?.value.metadata.v]);

  useEffect(() => {
    if (spaceUsers.length && currentTemplate) {
      getTemplateProps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    displayHiddenProps,
    node?.value.prop?._sys_temp,
    spaceUsers,
    editingId,
    currentTemplate,
    simpleThemeTreeWidth,
    isDrafts,
    draftParentId,
    // 时间需要更新的标记
    updateDateBeforePresentMark,
    enumExtend,
    globalEditable,
    currentUserInfo,
  ]);

  useEffect(() => {
    fetchEnumExtend();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTemplate?.template_id, node?.value.id, node?.value.metadata.v]);

  const fetchEnumExtend = async () => {
    if (
      node?.value.id &&
      currentTemplate?.template_id &&
      currentTemplate?.prop.length
    ) {
      const newEnumOptions = await getEnumOptions({
        nodeId: node.value.id,
        orgId: currentOrgId!,
        request: request,
        template: currentTemplate,
      });
      setEnumExtend(newEnumOptions);
    } else {
      setEnumExtend({});
    }
  };

  const nodeReadOnly = !node.value.nodeManager.isEditor(node.value.prop);

  // 找到分组情况下的下一个属性
  const findNextPropForGroup = (now: number) => {
    if (!globalEditable) return undefined;

    const prop_groups = currentTemplate!.prop_groups;

    // 在哪个分组
    const groupIndex = prop_groups.findIndex((g) => g.props.includes(now));

    //
    let next = undefined;

    // find next
    for (let i = groupIndex; i < prop_groups.length; i++) {
      const nowGroup = prop_groups[i];
      if (nowGroup.props.length === 0) continue;

      const canEditProps = nowGroup.props.filter((propIndex) => {
        const p = currentTemplate!.prop[propIndex];
        return !(
          p.type === 'attachment' ||
          p.hide ||
          !p.type ||
          isPropCanEdit(
            p,
            node,
            propIndex,
            org!,
            isDrafts
              ? node.value.nodeManager.findChildren(draftParentId)
              : node.value.parent!,
          )
        );
      });
      if (canEditProps.length === 0) continue;

      const nowIndex = canEditProps.findIndex((j) => j === now);

      if (nowIndex === -1) {
        next = canEditProps[0];
        break;
      } // 下一个分组的第一个属性

      if (nowIndex === canEditProps.length - 1) continue;
      else {
        next = canEditProps[nowIndex + 1];
        break;
      }
    }

    return next;
  };

  // 不分组的下一个属性
  const findNextPropForNotGroup = (now: number) => {
    if (!globalEditable) return undefined;

    const _props = (currentTemplate!.prop || [])
      .map((p, index) => ({ ...p, index }))
      .filter(
        (p) =>
          !(
            p.type === 'attachment' ||
            p.hide ||
            !p.type ||
            isPropCanEdit(
              p,
              node,
              p.index,
              org!,
              isDrafts
                ? node.value.nodeManager.findChildren(draftParentId)
                : node.value.parent!,
            )
          ),
      );

    _props.sort((p1, p2) => {
      if ('sort' in p1) return p1.sort - p2.sort;
      return 1;
    });
    if (now === _props[_props.length - 1].index) return undefined;
    const nowIndex = _props.findIndex((p) => p.index === now);
    return _props[nowIndex + 1].index;
  };

  const handlePressTab = () => {
    if (!editingId) return;
    const { showGroup } = nodePropConfig || {};
    const prop_groups = currentTemplate!.prop_groups;

    // 跳到下一个属性
    const now = Number(editingId.slice(4));
    // save now
    const form = document.getElementById(editingId);
    if (form) form.blur();

    //
    saveValue = null;

    let next = undefined;
    if (showGroup && prop_groups && prop_groups.length)
      next = findNextPropForGroup(now);
    else next = findNextPropForNotGroup(now);

    if (next === undefined) {
      // focus 正文
      document.querySelector('.ql-editor')?.focus?.();
      return setEditingId('');
    }

    // next
    setTimeout(() => {
      setEditingId('temp' + next);
    }, 100);
  };

  // 模拟 tab 切换表单
  useKeyPress('tab', (e) => {
    e.preventDefault();
    handlePressTab();
  });

  const handleAddressConfirm = async (address) => {
    const index = parseInt(editingId.substring(4));
    setEditingId('');
    const newActionReq = {
      node_id: node.value.id,
      org_id: currentOrgId,
      temp_id: node?.value.prop?._sys_temp[0],
      index: [index],
      value: [
        address.prov.concat(
          address.city,
          address.dist,
          address.street,
          address.add,
        ),
      ],
      location: { [index]: [address] },
    };
    if (currentTemplate!.prop[index]?.recordModification && mode === 'edit') {
      newActionReq.commit = { [index]: '' };
      // 未开启requireModificationRemark commit空，否则弹窗拿备注
      if (currentTemplate!.prop[index]?.requireModificationRemark) {
        const [err, commit] = await recordCommit();
        if (err) return;
        newActionReq.commit = { [index]: commit };
      }
    }

    updateProp(newActionReq).then(([err, res]) => {
      if (res?.status === 'ok') {
        setEditedProps && setEditedProps(index);

        if (!isDrafts && showEditPropMsg) message.success('修改成功');
      }
    });
  };

  type SysPropError = Record<string, string>;
  function getFormulaError(error: SysPropError, index: number): string | null {
    if (error.hasOwnProperty(index)) {
      return error[index];
    } else {
      return null;
    }
  }

  //更新模板属性值
  const handleEditConfirm = async (
    e: any,
    _matchingProps = null,
    isSingle?: { type: string; newTags: string[] | string; display?: any },
  ) => {
    console.log('handleEditConfirm', e);
    if (repeatVisible) return;
    if (isSingle) {
      saveValue = e ? e.valueOf() : null;
      if (isSingle.type === 'tag') {
        saveTags =
          Array.isArray(isSingle.newTags) && isSingle.newTags.length
            ? isSingle.newTags
            : [];
      }
    }
    setEditingId('');
    const oldProps = node?.value.prop
      ? JSON.parse(JSON.stringify(node.value.prop))
      : {};
    const newProps = cloneDeep(oldProps);

    const index = parseInt(editingId.substr(4));
    const actionReq = {
      org_id: currentOrgId,
      node: [node.value.key],
      temp_id: node?.value.prop?._sys_temp[0],
    };

    if (editingId.startsWith('temp')) {
      if (
        !Array.isArray(newProps?._sys_temp) ||
        newProps._sys_temp.length < 2
      ) {
        updatePropFail();
        return;
      }
      if (
        !isDrafts &&
        currentTemplate?.prop[index]?.required &&
        propIsNull(saveValue)
      ) {
        // propRequired();
        return;
      }
      if (
        newProps._sys_temp[1][index] === saveValue ||
        JSON.stringify(newProps._sys_temp[1][index]) ===
          JSON.stringify(saveValue)
      ) {
        return;
      }
      newProps._sys_temp[1][index] = saveValue;
      actionReq.temp_prop = newProps._sys_temp[1];
      const propConfig = currentTemplate!.prop[index];
      if (
        propConfig.type === 'tag' &&
        !propConfig.enumNotSave &&
        saveTags.length
      ) {
        request('/docapi/addTempPropTag', {
          method: 'POST',
          data: {
            org_id: currentOrgId,
            temp_id: currentTemplate!.template_id,
            prop_index: [index],
            tag: [saveTags],
          },
        });
      }
    }

    const oldTempProp = oldProps._sys_temp[1];
    const newTempProp = actionReq.temp_prop;
    const diffIndex = newTempProp
      .map((x, i) => {
        return !isEqual(x, oldTempProp[i]) ? i : false;
      })
      .filter((x) => x !== false);
    const diffValue = diffIndex.reduce((a, b) => {
      a[b] = newTempProp[b];
      return a;
    }, {});

    const newActionReq: any = {
      org_id: actionReq.org_id,
      node_id: node.value.id,
      temp_id: actionReq.temp_id,
      index: diffIndex,
      value: Object.values(diffValue),
    };

    const propInfo = currentTemplate!.prop[index];
    // 当前index 是否需要commit
    // TODO 1.6 type
    if (
      propInfo?.recordModification &&
      propInfo?.type !== 'positioning' &&
      mode === 'edit' &&
      e
    ) {
      newActionReq.commit = { [index]: '' };
      // 未开启requireModificationRemark commit空，否则弹窗拿备注
      if (currentTemplate!.prop[index]?.requireModificationRemark) {
        const [err, commit] = await recordCommit();
        if (err) return;
        newActionReq.commit = { [index]: commit };
      }
    }
    if (
      !!propInfo.matchingLabelColumns?.length ||
      !!propInfo.matchingLabelProps?.length
    ) {
      newActionReq.display = { [index]: saveDisplay };
    }

    // 多级选值
    if (propInfo?.type === 'cascade') {
      const cascadeTitles = saveCascadeTitles;
      if (!newActionReq.cascade) newActionReq.cascade = {};
      newActionReq.cascade[index] = cascadeTitles.length ? cascadeTitles : null;
    }
    const hasDiffCascade = diffIndex.filter(
      (x) => currentTemplate!.prop[x].type === 'cascade' && x !== index,
    );
    if (hasDiffCascade.length) {
      if (!newActionReq.cascade) newActionReq.cascade = {};
      hasDiffCascade.map((xIndex) => {
        const cascadeTitles = displayCascadeValue({
          value: saveValue,
          cascadeNodes: currentTemplate!.prop[xIndex].cascade.nodes,
          multiple: currentTemplate!.prop[xIndex].multiple,
          hideRoutes: currentTemplate!.prop[xIndex].hideRoutes,
        });
        newActionReq.cascade[xIndex] = cascadeTitles.length
          ? cascadeTitles
          : null;
      }, {});
    }

    // 是否有其他条件匹配项需要备注
    diffIndex.map((x) => {
      // 跳过当前index
      // TODO 1.6 type
      if (
        x !== index &&
        currentTemplate!.prop[x]?.recordModification &&
        currentTemplate!.prop[x]?.type !== 'positioning'
      ) {
        newActionReq.commit = {
          ...newActionReq.commit,
          [x]: '条件匹配触发自动修改',
        };
      }
    });
    // 改走updateProp接口
    const [err, res] = await updateProp(newActionReq);

    saveValue = null;
    if (err || res?.status !== 'ok') {
      if (res?.node_id)
        return message.error(
          currentTemplate!.prop[index]?.type === 'text'
            ? '内容重复，请重新输入'
            : '内容重复，请重新选择',
        );
      updatePropFail();
    } else {
      setEditedProps && setEditedProps(index);

      if (!isDrafts && showEditPropMsg) message.success('修改成功');
    }
  };

  const [commitForm] = Form.useForm();
  const recordCommit = () => {
    return new Promise((r, f) => {
      const commitModal = Modal.confirm({
        title: '提交修改',
        content: (
          <Form form={commitForm} layout="vertical" autoComplete="off">
            <Form.Item
              rules={[{ required: true, message: '请输入备注' }]}
              name="commit"
              label="请填写备注，否则修改的属性值将无法保存"
            >
              <Input />
            </Form.Item>
          </Form>
        ),
        okText: '提交',
        onOk: (e) => {
          commitForm.validateFields().then((values) => {
            commitModal.destroy();
            r([null, commitForm.getFieldValue('commit')]);
            commitForm.resetFields();
          });
        },
        onCancel: () => {
          commitModal.destroy();
          r([true, null]);
        },
      });
    });
  };

  //模板属性值修改事件
  const handleEditChange = (
    e?: string,
    newTag?: string[],
    newCascadeTitles?: string[],
    newDisplay?: string[] | string,
  ) => {
    saveValue = e ? e.valueOf() : null;
    saveTags = Array.isArray(newTag) && newTag.length ? newTag : [];
    saveCascadeTitles = Array.isArray(newCascadeTitles) ? newCascadeTitles : [];
    saveDisplay = newDisplay;
  };

  //数字或货币类型模板属性值修改事件
  const handleNumberEditChange = (
    e: null | number,
    precision: number,
    numericalFormat = 0,
  ) => {
    saveValue = e;
    if (e === null) {
      saveValue = null;
    } else if (Number.isFinite(saveValue)) {
      if (Number.isFinite(precision)) {
        saveValue = Number(saveValue.toFixed(precision));
      }
      if (numericalFormat) {
        saveValue = saveValue / 100;
      }
    }
  };

  //模板属性值修改事件 input
  const handleEditInputChange = (e) => {
    saveValue = e ? e.target.value : '';
  };

  const departmentNodes = useOrgDepartmentNodes();

  const addQuoteTheme = async (prop: CurrentUser.TemplateProp) => {
    const { conditionProp, quoteProp, matchingTemplate, matchingSource } = prop;
    const id = generateAddOpId();

    // 新节点的属性
    const newProps = getDefaultTempProp(
      {},
      tempMap[matchingTemplate] || null,
      currentUser,
      null,
      userMap,
      departmentNodes,
      null,
    );

    // 条件匹配的值
    (quoteProp || []).forEach((i, index) => {
      const [_, pIndex] = i.split('-');
      newProps._sys_temp[1][Number(pIndex)] =
        node.value.tempInfo.prop[conditionProp[index]];
    });

    // 新建草稿节点
    setDraftsNodeData({
      org_id: currentOrgId,
      parentId: matchingSource,
      siblingId: null,
      draft: true,
      node: {
        node_id: id,
        prop: newProps,
        title: '',
      },
    });

    setNodeParent(matchingSource);
    setShowRecurrenceModal(true);
  };

  // 预览节点
  const onPreviewNodeForFix = (id: string) => {
    setCurrentNodeIndex(0);
    setCurrentDataList([id]);
    setContentVisible(true);
  };

  const findMatchingTemplate = (prop: TemplateProp) =>
    tempMap[prop.matchingTemplate]?.name;
  const renderSelectBottom = (f: boolean, prop: TemplateProp) => {
    if (!f) return null;
    return (
      <div
        style={{
          padding: '8px 12px',
          borderTop: '1px solid #F8F9FB',
          color: '#BFC6D2',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          return false;
        }}
        onClick={() => addQuoteTheme(prop)}
      >
        <i style={{ marginRight: 6 }} className="iconfont iconButton_add" />
        <div style={{ width: 36 }}>新增</div>
        <span
          style={{
            margin: '0 4px',
            backgroundColor: '#F8F9FB',
            padding: 5,
            borderRadius: 8,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {findMatchingTemplate(prop)}
        </span>
        {/* <span>主题</span> */}
      </div>
    );
  };

  const getPropValueStyle = (
    isEditting: boolean,
    style: string,
    disable: boolean,
  ) => {
    const res: any = {};
    if (!['style_1', 'style_2', 'style_3'].includes(style))
      res.backgroundColor = '#f8f9fb';
    if (['style_2', 'style_3'].includes(style))
      res.backgroundColor = disable ? '#F0F1F6' : 'white';
    if (isEditting && ['style_2', 'style_3'].includes(style)) {
      res.border = '1px solid #316EF5';
      res.boxShadow = '0px 0px 4px 0px rgba(49,110,245,0.5)';
    }
    if (disable && ['style_2', 'style_3'].includes(style))
      res.border = '1px solid #ebedf0';
    if (disable && ['style_3', 'style_2'].includes(style))
      res.backgroundColor = '#F7F8FA';
    return res;
  };

  const getAttrStyle = (prop: any, style: string, disable: any) => {
    const res: any = {};
    const quoteOrMatchingInfo = getQuoteOrMatchingInfo(prop);
    if (quoteOrMatchingInfo === 3) {
      res.textDecoration = 'underline';
      res.cursor = 'pointer';
    }
    if (getQuoteOptionVisible(prop, tempMap)) res.maxWidth = '90%';
    if (['style_1', 'style_2', 'style_3'].includes(style) && disable)
      res.color = '#999999';
    if (['style_2'].includes(style) && disable) res.color = '#000000';
    if (quoteOrMatchingInfo === 3 && disable && style === 'style_1')
      res.color = '#999999';
    return res;
  };

  const getPropContainerClassName = (style: string) => {
    const { propGrid } = nodePropConfig || {};

    // 原来的classname
    const originalName = 'prop-item';
    let newName = '';

    // 第二种样式
    if (style === 'style_1') {
      newName = 'style1Prop';
      if (propGrid) newName = 'style1PropLayout';
    }

    // 第三种样式
    if (style === 'style_2') {
      newName = 'style2Prop';
      if (propGrid) newName = 'style2PropLayout';
    }

    // 第三种样式
    if (style === 'style_3') {
      newName = 'style3Prop';
      if (propGrid) newName = 'style3PropLayout';
    }

    return originalName + ' ' + newName;
  };

  const renderSplitContent = (prop: any, style: string, index: any, v: any) => {
    return (
      <Tooltip
        title={
          prop.type === 'number' ? '按属性值拆分主题' : '按属性值逐个拆分主题'
        }
      >
        <i
          className="iconfont iconjianqielinggan iconshuxingchaifen"
          onClick={() => handleSplit(prop, index, v)}
        />
      </Tooltip>
    );
  };

  const handleSplit = (prop: any, index: any, v: any) => {
    if (prop.type === 'number') {
      setNumberSplitIndex(index);
    } else {
      request('/docapi/node/splitNumber', {
        method: 'POST',
        data: {
          org_id: currentOrgId,
          node_id: node.value.id,
          index: index,
          numberList: v,
        },
      }).then((res) => {
        if (res?.status === 'ok') {
          message.success('拆分成功');
        } else {
          message.error('拆分失败');
        }
      });
    }
  };

  // 是否显示拆分
  const showSplit = (prop: any, v: any) => {
    return (
      (prop.type === 'number' && Number.isFinite(v) && v !== 0) ||
      ((prop.type === 'enum' || prop.type === 'tag') &&
        prop.multiple &&
        Array.isArray(v) &&
        v.length > 1)
    );
  };

  const renderAttrNameContent = (style: string, prop: any, icon: string) => {
    // 默认样式
    if (!['style_1', 'style_2', 'style_3'].includes(style))
      return (
        <>
          <i style={{ marginRight: 12 }} className={`iconfont ${icon}`} />
          <div className="text-omit defaultPropNameText">{prop.name}</div>
          {prop.required && <div className="prop-important">*</div>}
          {prop.remark && (
            <Tooltip title={prop.remark}>
              <i
                className="iconfont iconNav_Help"
                style={{ cursor: 'pointer', marginLeft: 4 }}
              />
            </Tooltip>
          )}
        </>
      );
    if (style === 'style_1')
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {prop.required && <div className="prop-important">*</div>}
          <div className="text-omit style1PropNameText">{prop.name}</div>
        </div>
      );
    if (style === 'style_3')
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {prop.required && <div className="prop-important">*</div>}
          <div className="text-omit style3PropNameText">{prop.name}</div>
        </div>
      );
    if (style === 'style_2')
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            className="style2PropNameText"
            style={{ padding: '0 4px', color: '#777777' }}
          >
            {prop.name}
          </div>
          {prop.required && <div className="prop-important">*</div>}
          {prop.remark && (
            <Tooltip title={prop.remark}>
              <i
                className="iconfont iconNav_Help style2AttrNameIcon"
                style={{ marginLeft: 4, fontSize: 12, color: '#777777' }}
              />
            </Tooltip>
          )}
        </div>
      );
  };

  // 值text展示
  const renderValueText = (
    style: string,
    title: any,
    prop: any,
    disable: any,
    index: any,
    display: any,
    v: any,
  ) => {
    const { valueWrap } = nodePropConfig || {};
    const showTooltip = ['style_1', 'style_2', 'style_3'].includes(style);
    let n = valueWrap ? 'valueWrapSpan' : 'text-omit';
    if (style === 'style_1') n += ' style1PropText';
    if (style === 'style_2') n += ' style2PropText';
    if (style === 'style_3') n += ' style3PropText';
    if (!['style_1', 'style_2', 'style_3'].includes(style))
      n += ' defaultPropText';

    return (
      <SimpleTooltip title={title} showTooltip={showTooltip}>
        <span
          className={n}
          style={getAttrStyle(prop, style, disable)}
          onClick={
            getQuoteOrMatchingInfo(prop) === 3
              ? (e) => {
                  e.stopPropagation();
                  setQuoteVisible(true);
                  setQuoteIndex(index);
                }
              : () => {}
          }
        >
          {renderDisplay(display, prop, v, index)}
        </span>
      </SimpleTooltip>
    );
  };

  // 值为空展示
  const renderValueNullContent = (
    prop: any,
    placeHolder: string,
    index: any,
  ) => {
    const common = (
      <span style={{ color: '#BFC6D2' }}>
        {[1, 2].includes(getQuoteOrMatchingInfo(prop)) ? (
          <i
            className="iconfont iconattribute_quote"
            onClick={(e) => {
              e.stopPropagation();
              setQuoteVisible(true);
              setQuoteIndex(index);
            }}
          />
        ) : (
          <span className="placeHolderText" style={{ fontSize: 12 }}>
            {placeHolder}
          </span>
        )}
      </span>
    );

    return common;
  };

  // 值修改记录
  const renderModification = (prop: any, index: any) => {
    return (
      <i
        className="iconfont iconhistorical_state"
        style={{ fontSize: 12, padding: '0 6px' }}
        onClick={(e) => {
          e.stopPropagation();
          propRecordModalRef.current?.show({
            orgId: currentOrgId,
            nodeId: node.value.id,
            propConfig: prop,
            propIndex: index,
          });
        }}
      />
    );
  };

  const fetchQuoteNodes = (propIndex: number) => {
    return request('/docapi/execCalcFormula', {
      method: 'POST',
      data: {
        org_id: currentOrgId,
        node_id: node.value.id,
        index: propIndex,
        getNodeId: true,
      },
    });
  };

  // 匹配过滤
  const findOriginTemplatesForMatching = async (e, propIndex, value, prop) => {
    e.stopPropagation();
    const res = await fetchQuoteNodes(propIndex);
    if (res.status !== 'ok') return message.error('获取引用列表出错');

    const nodes = res.data
      .map((id) => {
        const nodeId =
          typeof id === 'string'
            ? id
            : 'v' in id && typeof id.v === 'string'
              ? id.v
              : '';
        return nodeId ? orgConnection.nodeManager.findChildren(nodeId) : null;
      })
      .filter((n) => !!n);

    // 找到匹配列表里对应值的主题
    const resNodes = filterQuoteNodesForMatching(nodes, prop, tempMap, value);

    if (resNodes.length === 0) return message.warning('没有对应主题');

    // 单条主题，直接打开内容页弹窗
    if (resNodes.length === 1) return onPreviewNodeForFix(resNodes[0].id);
    // 多条，展示引用列表
    if (resNodes.length > 1) {
      setOriginNodes(resNodes);
      setQuoteIndex(propIndex);
      setQuoteVisible(true);
    }
  };

  // 引用过滤
  const findOriginTemplatesForQuote = async (e, propIndex, data, prop) => {
    e.stopPropagation();

    let { index, propValue } = data;
    propValue = Array.isArray(propValue) ? propValue : [propValue];
    const originValue = propValue[index];

    const res = await fetchQuoteNodes(propIndex);
    if (res.status !== 'ok') return message.error('获取引用列表出错');

    const nodes = res.data
      .map((id) => {
        const nodeId =
          typeof id === 'string'
            ? id
            : 'v' in id && typeof id.v === 'string'
              ? id.v
              : '';
        return nodeId ? orgConnection.nodeManager.findChildren(nodeId) : null;
      })
      .filter((n) => !!n);

    // 找到引用列表里对应值的主题
    const resNodes = filterQuoteNodesForQuote(
      nodes,
      prop,
      tempMap,
      originValue,
    );

    if (resNodes.length === 0) return message.warning('没有对应主题');

    // 单条主题，直接打开内容页弹窗
    if (resNodes.length === 1) return onPreviewNodeForFix(resNodes[0].id);
    // 多条，展示引用列表
    if (resNodes.length > 1) {
      setOriginNodes(resNodes);
      setQuoteIndex(propIndex);
      setQuoteVisible(true);
    }
  };

  // 匹配展示主题
  const renderDisplay = (display, prop, propValue, propIndex) => {
    const quoteOptionVisible = getQuoteOptionVisible(prop, tempMap);

    if (!quoteOptionVisible) {
      if (prop.type === 'enum' && prop.extendColorMode) {
        const _propValue = Array.isArray(propValue) ? propValue : [propValue];
        return _propValue.map((v: string) => (
          <span
            key={v}
            className="mr-1 px-2 text-white rounded-full"
            style={{
              backgroundColor:
                prop.extendColor[
                  prop.extend!.findIndex((e: string) => e === v)
                ],
            }}
          >
            {v}
          </span>
        ));
      }

      return display;
    }

    // 引用
    if (quoteOptionVisible === 3) {
      let originProp = getQuoteOriginProp(prop, tempMap);
      if (prop.matchingProp.length === 1 && prop.matchingProp[0] === '-3')
        originProp = '-3';
      const value = getPropHandledValue(
        originProp,
        propIndex,
        propValue,
        spaceUserList[currentOrgId],
        node.value.prop._sys_cascade,
      );
      if (!value) return display;
      if (originProp.type === 'cascade') {
        propValue = uniq(propValue.filter((p) => p[0] === '0'));
      }

      return value.map((v, index) => (
        <span key={index}>
          <span
            onClick={(e) =>
              findOriginTemplatesForQuote(
                e,
                propIndex,
                { index, propValue },
                prop,
              )
            }
            className="allow-to-open-templates"
          >
            {v}
          </span>
          {index !== value.length - 1 ? ';' : ''}
        </span>
      ));
    }

    // 选值匹配
    if (quoteOptionVisible < 3) {
      const value = Array.from(
        new Set(Array.isArray(propValue) ? propValue : [propValue]),
      );
      let valueOpts = value.map((v) => ({
        label: v,
        value: v,
      }));
      // 如果配置显示属性 matchingLabelProps 则展示显示属性
      if (prop.matchingLabelProps) {
        const index = prop.matchingLabelProps[0]?.split('-')?.[1];
        if (!isNaN(index)) {
          valueOpts = value.map((v) => {
            return {
              label:
                enumExtend?.[index]?.find((x) => x.value === v)?.label || v,
              value: v,
            };
          });
        }
      }
      return valueOpts.map((opt, index) => (
        <span key={index}>
          <span
            onClick={(e) =>
              findOriginTemplatesForMatching(e, propIndex, opt.value, prop)
            }
            className="allow-to-open-templates"
          >
            {opt.label}
          </span>
          {index !== value.length - 1 ? ';' : ''}
        </span>
      ));
    }
  };

  const exportPropGraphicCode = async (prop: any) => {
    const propValues = getExportGraphicCodesPropValues([node.value], [prop]);
    await exportGraphicCodes(propValues);
  };
  const renderGraphicCodePopoverContent = (prop: any, display: string) => {
    const { graphicCodeFormat, exportContent } =
      prop.canExportGraphicCodeParams || {
        graphicCodeFormat: '二维码', // 二维码
        exportContent: 0, // 单码
      };
    const graphicCodeCanvas = document.createElement('canvas');
    if (graphicCodeFormat === '二维码')
      QRCode.toCanvas(graphicCodeCanvas, display);
    else {
      if (!strExistSpecialCode(String(display))) {
        JsBarcode(graphicCodeCanvas, display, {
          format: graphicCodeFormat.toUpperCase(),
          displayValue: !!exportContent,
        });
      }
    }
    const src = graphicCodeCanvas.toDataURL('image/png');
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <img style={{ height: 180, width: 180 }} src={src} alt="" />
        <div
          className="text-omit"
          style={{ padding: '0 12px', textAlign: 'center' }}
        >
          {display}
        </div>
        <div style={{ position: 'relative', height: 25 }}>
          <span
            style={{
              position: 'absolute',
              right: 16,
              color: '#316ef5',
              cursor: 'pointer',
              fontSize: 12,
            }}
            onClick={(e) => {
              e.stopPropagation();
              exportPropGraphicCode(prop);
            }}
          >
            导出
          </span>
        </div>
      </div>
    );
  };

  const setEditedProp = (prop, index, disablePosition, disable) => {
    if (prop.type === 'positioning') {
      if (disablePosition || disable) return;
      assertExists(locationSelectRef.current);
      locationSelectRef.current.show(
        getLocationSelectProps({
          node: node.value,
          propIndex: index,
          readOnly: disable || !prop.editable,
          tempMap,
          onSave: async (newData) => {
            const req = {
              org_id: node.value.orgId,
              node_id: node.value.id,
              temp_id: node.value.tempInfo.id,
              index: [index],
              value: [newData.name],
              location: {
                [index]: [newData],
              },
            };
            const [err, res] = await updateProp(req);
            if (err || res.status !== 'ok') {
              message.error('保存失败');
              return false;
            }
          },
        }),
      );
    } else {
      if (disable)
        return message.warn(
          nodeReadOnly ? '当前主题禁止编辑' : '当前属性不可编辑',
        );
      if (
        (prop.type === 'enum' || prop.type === 'tag') &&
        prop.conditionMatching &&
        prop.selectType
      ) {
        // 选值属性主题选择弹窗
        setQuoteSelectorVisible(true);
        setQuoteIndex(index);
      } else {
        setEditingId('temp' + index);
      }
      setRepeatVisible(false);
      saveValue = propIsNull(node.value.prop._sys_temp?.[1]?.[index])
        ? null
        : node.value.prop._sys_temp[1][index];
    }
  };

  const handleCopyClick = (e: React.MouseEvent, text: string | number) => {
    e.stopPropagation();

    clipboardCopy(`${text}`);
    message.success('复制成功!');
  };

  //模板属性
  const getTemplateProps = async () => {
    if (isNil(currentTemplate)) return null;

    const { style, attrNameWidth } = nodePropConfig || {};
    const temp = node.value.prop._sys_temp;
    const children: { sort: number; propIndex: number; dom: ReactNode }[] = [];
    let enumOptions = [];
    if (Array.isArray(temp) && currentTemplate?.template_id === temp[0]) {
      const tempProp = temp[1];
      /**
       * @type {CurrentUser.TemplateProp[]} Template
       */
      const templatePropsConfig = currentTemplate.prop;
      const tempPropDisplay = templatePropsConfig.map((prop, index) =>
        prop?.type
          ? tempValueDisplay({
              propConfig: prop,
              propValue: tempProp[index],
              propIndex: index,
              tempMap: tempMap,
              userMap: userMap,
              sysCascade: node.value.prop._sys_cascade,
              departmentMap,
              sysDisplay: node.value.prop._sys_display,
            })
          : null,
      );
      console.log(templatePropsConfig.map((x) => x.name));
      console.log('tempPropDisplay', tempPropDisplay);

      // NOTE: 属性条件的判断
      const propVisibleMap = getPropVisibleMap(
        node.value,
        currentTemplate,
        currentUserInfo,
      );

      templatePropsConfig?.forEach((prop, index) => {
        const { multiple } = prop;
        let placeHolder = propPlaceholderMap[index] || '请设置属性值',
          icon =
            (PROP_TYPE as any)[prop.type]?.icon ||
            'iconattribute_selected_value';
        if (!prop?.type) return;
        let hide = prop.hide;

        const groupVisible = checkNodePropVisibleByGroup(prop);

        if (groupVisible) {
          const result = propVisibleMap[index];
          if (typeof result !== 'undefined') hide = !result;
        }

        // QUESTION: _sys_prop_visible 会和属性条件冲突么？
        if (!hide) {
          if (!isNil(node.value.prop._sys_prop_visible?.[index]))
            hide = !node.value.prop._sys_prop_visible![index];
        }

        if (displayHiddenProps) {
          hide = false;
        }

        if (
          nodePropConfig &&
          nodePropConfig.propVisibleMap &&
          nodePropConfig.propVisibleMap[index] === 'hidden'
        ) {
          hide = true;
        }

        if (!hide) {
          // 是否可以编辑 quote、公式、定位、 prop.disabled _sys_prop_mn为0 继承属性在符合条件的父节点下 不允许编辑
          const disable =
            !globalEditable ||
            !isPropCanEdit(
              prop,
              node,
              index,
              org!,
              isDrafts
                ? node.value.nodeManager.findChildren(draftParentId!)
                : node.value.parent!,
            );

          let inputDom = null;
          let option = {};
          const domId = 'temp' + index;
          if (!disable) {
            if (prop.multiple) option = { mode: 'multiple' };
            let defaultValue = tempProp[index] || null;
            switch (prop.type) {
              case 'tag':
                placeHolder = propPlaceholderMap[index] || '搜索或添加';
                if (!defaultValue) defaultValue = [];
                enumOptions = prop.extend ? [...prop.extend] : [];
                if (
                  prop.conditionMatching &&
                  Array.isArray(enumExtend[index]) &&
                  enumExtend[index].length
                )
                  enumOptions.push.apply(
                    enumOptions,
                    enumExtend[index].map((x) => x.label),
                  );
                option.mode = 'tags';
                enumOptions = textSort(
                  uniqueArr([...enumOptions]).filter((to) => !!to),
                );
                inputDom = (
                  <ChooseTagSelect
                    autoFocus
                    data={enumOptions}
                    domId={domId}
                    // getPopupContainer={(triggerNode) => triggerNode.parentNode.parentNode}
                    defaultValue={defaultValue}
                    allowClear={isDrafts || !prop.required}
                    onBlur={(e) => handleEditConfirm(e)}
                    onChange={
                      multiple
                        ? handleEditChange
                        : (e, t) =>
                            handleEditConfirm(e, null, {
                              type: 'tag',
                              newTags: t,
                            })
                    }
                    mode={prop.multiple ? 'multiple' : ''}
                    notSave={!!prop.enumNotSave}
                    addQuoteTheme={
                      prop.matchingAdd
                        ? renderSelectBottom(prop.conditionMatching, prop)
                        : null
                    }
                    quoteOptions={enumExtend[index]}
                  />
                );
                break;
              case 'enum':
                // 引用属性
                const {
                  conditionMatching,
                  matchingAdd,
                  extendColorMode,
                  extendColor,
                } = prop;
                if (!defaultValue) defaultValue = prop.multiple ? [] : '';
                enumOptions = (prop.extend || [])
                  .filter((i) => i)
                  .map((x) => ({
                    label: x,
                    value: x,
                  }));
                if (
                  prop.conditionMatching &&
                  Array.isArray(enumExtend[index]) &&
                  enumExtend[index].length
                ) {
                  enumExtend[index].forEach((x) => {
                    if (
                      !prop.extend?.includes(x.label) &&
                      !~enumOptions.findIndex((y) => y.label === x.label)
                    ) {
                      enumOptions.push(x);
                    }
                  });
                }

                const optionLabelProp =
                  prop.matchingLabelProps && prop.matchingLabelProps.length
                    ? 'label'
                    : 'value';

                inputDom = (
                  <div
                    onMouseDown={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  >
                    <Select
                      {...RC_SELECT_PROPS}
                      autoFocus
                      id={domId}
                      name="propValue"
                      className="edit-select"
                      placeholder={placeHolder}
                      defaultValue={defaultValue}
                      allowClear={isDrafts || !prop.required}
                      {...(matchingAdd
                        ? {
                            dropdownRender: (_: any) => (
                              <PerfectScrollbar>
                                {_}
                                {renderSelectBottom(conditionMatching, prop)}
                              </PerfectScrollbar>
                            ),
                            virtual: false,
                          }
                        : {})}
                      popupClassName="enum-prop-select"
                      {...option}
                      showArrow={false}
                      showSearch={true}
                      onBlur={
                        multiple
                          ? (e) => handleEditConfirm(e)
                          : () => {
                              setEditingId('');
                            }
                      }
                      onChange={
                        multiple
                          ? (v, o) => {
                              saveDisplay = Array.isArray(o)
                                ? o.map((i) => i.label as string)
                                : (o?.label as string);
                              handleEditChange(v, [], [], saveDisplay);
                            }
                          : (value, o) => {
                              saveDisplay = Array.isArray(o)
                                ? o.map((i) => i.label as string)
                                : (o?.label as string);
                              handleEditConfirm(value, null, {
                                type: 'enum',
                                newTags: [],
                                display: saveDisplay,
                              });
                            }
                      }
                      optionFilterProp="label"
                      optionLabelProp={optionLabelProp}
                      // getPopupContainer={(triggerNode) => triggerNode.parentNode.parentNode}
                      tagRender={(tagProps) => {
                        return extendColorMode ? (
                          <span
                            className="mr-1 px-2 text-white rounded-full"
                            style={{
                              backgroundColor:
                                extendColor![
                                  prop.extend!.findIndex(
                                    (e: string) => e === tagProps.value,
                                  )
                                ],
                            }}
                          >
                            {tagProps.label}
                          </span>
                        ) : (
                          <span className="mr-1">{tagProps.label}</span>
                        );
                      }}
                    >
                      {enumOptions.map((x: any, _index: number) => (
                        <Option
                          key={_index}
                          value={x.value}
                          label={
                            x.label + (x.aux?.label ? '-' + x.aux.label : '')
                          }
                        >
                          {x.aux ? (
                            x.aux.position ? (
                              <>
                                <p style={{ color: 'rgb(185, 187, 188)' }}>
                                  {x.aux.label}
                                </p>
                                <p>{x.label}</p>
                              </>
                            ) : (
                              <>
                                <p>{x.label}</p>
                                <p style={{ color: 'rgb(185, 187, 188)' }}>
                                  {x.aux.label}
                                </p>
                              </>
                            )
                          ) : extendColorMode ? (
                            <span
                              className="px-2 text-white rounded-full"
                              style={{ backgroundColor: extendColor![_index] }}
                            >
                              {x.label}
                            </span>
                          ) : (
                            x.label
                          )}
                        </Option>
                      ))}
                    </Select>
                  </div>
                );
                break;
              case 'user': {
                placeHolder = propPlaceholderMap[index] || '请选择人员';
                let userSelect = spaceUsers.filter(
                  (x) => !x.group_ids?.includes('-8'),
                );
                if (
                  Array.isArray(prop.extend) &&
                  prop.extend.length &&
                  !~prop.extend.indexOf('-1')
                ) {
                  userSelect = userSelect?.filter(
                    (user) =>
                      Array.isArray(user.group_ids) &&
                      !!user.group_ids?.find((id) => ~prop.extend.indexOf(id)),
                  );
                }
                if (propIsNull(defaultValue)) {
                  defaultValue = prop.multiple ? [] : '';
                } else {
                  defaultValue = validateUsers(defaultValue, prop, userSelect);
                }
                const userSelectMap = userSelect.reduce(
                  (res: any, cur: any) => {
                    res[cur.nick_name] = cur;

                    return res;
                  },
                  {} as any,
                );
                inputDom = (
                  <Select
                    {...RC_SELECT_PROPS}
                    autoFocus
                    id={domId}
                    name="propValue"
                    placeholder={placeHolder}
                    className="edit-select"
                    defaultValue={defaultValue}
                    allowClear={isDrafts || !prop.required}
                    {...option}
                    showArrow={false}
                    showSearch={true}
                    onBlur={
                      multiple
                        ? (e) => handleEditConfirm(e)
                        : (e) => {
                            setEditingId('');
                            handleEditConfirm(e);
                          }
                    }
                    onChange={
                      multiple
                        ? handleEditChange
                        : (e, o) => {
                            if (isNil(e)) handleEditChange(e, o, null);
                          }
                    }
                    onSelect={
                      multiple
                        ? () => {}
                        : (e) => handleEditConfirm(e, null, {})
                    }
                    filterOption={(input, option) =>
                      filterUsersByPinyin(input, option, userSelectMap)
                    }
                  >
                    {userSelect.map((x, index) => {
                      return (
                        <Option key={index} value={x.account_id}>
                          {x.nick_name}
                        </Option>
                      );
                    })}
                  </Select>
                );
                break;
              }
              case 'datetime':
              case 'date': {
                const dateModule =
                  Date_Format_Options.find((x) => x.value === prop.dateFormat)
                    ?.module || (prop.type === 'date' ? 'day' : 'min');

                const _defaultValue =
                  typeof defaultValue === 'string'
                    ? parseInt(defaultValue)
                    : defaultValue;

                inputDom = (
                  <AutoFocus autoFocus defaultOpen>
                    <LinkPiDate
                      dateFormat={
                        prop.dateFormat ||
                        (prop.type === 'date'
                          ? 'YYYY-MM-DD'
                          : 'YYYY-MM-DD HH:mm')
                      }
                      onChange={(e) => {
                        saveValue = convertTimeByModule(e, dateModule);
                      }}
                      onConfirm={handleEditConfirm}
                      defaultValue={_defaultValue ? dayjs(_defaultValue) : null}
                      domId={domId}
                      dateValue={
                        typeof saveValue === 'string'
                          ? parseInt(saveValue)
                          : saveValue
                      }
                      allowClear={isDrafts || !prop.required}
                      disabledDate={(current) =>
                        checkDisabledDate(
                          current,
                          dateModule,
                          prop.numberLimit,
                          tempProp,
                        )
                      }
                    />
                  </AutoFocus>
                );
                break;
              }
              case 'currency':
                placeHolder = propPlaceholderMap[index] || '请输入数值';
                inputDom = (
                  <PiInputNumber
                    id={domId}
                    className="edit-input"
                    defaultValue={saveValue}
                    min={0}
                    // formatter={currencyFormatter(prop.extend)}
                    placeholder={placeHolder}
                    precision={prop.extend?.precision}
                    parser={currencyParser(prop.extend)}
                    onBlur={(e) => handleEditConfirm(e)}
                    onPressEnter={(e) => handleEditConfirm(e)}
                    onChange={(e) =>
                      handleNumberEditChange(e, prop.extend?.precision)
                    }
                    onStep={(e) =>
                      handleNumberEditChange(e, prop.extend?.precision)
                    }
                  />
                );
                break;
              case 'number': {
                placeHolder = '请输入数值';
                let extraClass = '';
                const upper = genNumberLimit(prop, tempProp, 'upper'),
                  lower = genNumberLimit(prop, tempProp, 'lower');

                if (upper !== null)
                  option.max = prop.number?.numericalFormat
                    ? upper * 100
                    : upper;
                if (lower !== null)
                  option.min = prop.number?.numericalFormat
                    ? lower * 100
                    : lower;
                if ('max' in option && 'min' in option) {
                  if (option.min > option.max) {
                    delete option.min;
                    delete option.max;
                  } else {
                    placeHolder = `请输入${option.min.toFixed(
                      prop.number?.precision || 0,
                    )}~${option.max.toFixed(prop.number?.precision || 0)}的数值`;
                  }
                } else if ('max' in option) {
                  placeHolder = `请输入不大于${option.max.toFixed(
                    prop.number?.precision || 0,
                  )}的数值`;
                } else if ('min' in option) {
                  placeHolder = `请输入不小于${option.min.toFixed(
                    prop.number?.precision || 0,
                  )}的数值`;
                }
                if (prop.number?.numericalFormat) {
                  option.addonAfter = '%';
                } else if (prop.number?.unit) {
                  option[
                    prop.number?.unitPosition ? 'addonBefore' : 'addonAfter'
                  ] = prop.number.unit;
                  extraClass = prop.number?.unitPosition
                    ? ' addon-before'
                    : ' addon-after';
                }
                inputDom = (
                  <PiInputNumber
                    id={domId}
                    {...option}
                    className={`edit-input${extraClass}`}
                    defaultValue={
                      defaultValue
                        ? prop.number?.numericalFormat
                          ? defaultValue * 100
                          : defaultValue
                        : null
                    }
                    placeholder={propPlaceholderMap[index] || placeHolder}
                    precision={prop.number?.precision}
                    onBlur={(e) => handleEditConfirm(e)}
                    onPressEnter={(e) => handleEditConfirm(e)}
                    onChange={(e) =>
                      handleNumberEditChange(
                        e,
                        prop.number?.precision,
                        prop.number?.numericalFormat,
                      )
                    }
                    onStep={(e) =>
                      handleNumberEditChange(
                        e,
                        prop.number?.precision,
                        prop.number?.numericalFormat,
                      )
                    }
                  />
                );
                break;
              }
              case 'cascade':
                inputDom = (
                  <CascadeSelect
                    autoFocus
                    node={node.value}
                    prop={prop}
                    defaultValue={defaultValue}
                    handleChange={(newValue, newTitles) =>
                      handleEditChange(newValue, null, newTitles)
                    }
                    multiple={prop.multiple}
                    onBlur={() => handleEditConfirm(null)}
                    changeOnSelect={!!prop.changeOnSelect}
                    allowCreate={!!prop.addOnSelect}
                    template={currentTemplate}
                    propIndex={index}
                    orgId={currentOrgId}
                  />
                );
                break;
              case 'address':
                inputDom = (
                  <AddressInput
                    address={node.value.prop._sys_location?.[index]}
                    prop={prop}
                    onConfirm={handleAddressConfirm}
                    quoteOptions={enumExtend[index]}
                    addQuoteTheme={() =>
                      prop.matchingAdd ? addQuoteTheme(prop) : null
                    }
                    quoteTemplate={findMatchingTemplate(prop)}
                    onCancel={() => setEditingId('')}
                  />
                );
                break;
              case 'department': {
                const value = defaultValue;
                inputDom = (
                  <DepartmentSelect
                    departmentConfig={prop.departmentConfig}
                    placeholder="请设置属性值2"
                    title={prop.name}
                    className="full-w"
                    mode={multiple ? 'multiple' : undefined}
                    autoFocus
                    defaultValue={value}
                    onChange={(e) => {
                      saveValue = e;
                      handleEditConfirm(e);
                    }}
                    onBlur={() => setEditingId('')}
                  />
                );
                break;
              }
              default:
                inputDom = (
                  <TemplateTextPropInput
                    id={domId}
                    defaultValue={saveValue as string}
                    placeholder={placeHolder}
                    allowClear={isDrafts || !prop.required}
                    onBlur={handleEditConfirm}
                    onChange={handleEditInputChange}
                  />
                );
            }
          }
          // 引用属性的源属性类型
          let originPropType = prop.type;

          if (prop.type === 'quote') {
            const originProp = getQuoteOriginProp(prop, tempMap);
            if (originProp) {
              // 引用标题
              if (typeof originProp === 'string') {
                originPropType = 'text';
              } else {
                originPropType = originProp.type;
              }
            }
          }
          // 是否开启修改记录
          const recordModification =
            prop.recordModification && prop.type !== 'positioning';
          // 属性显示
          const display = tempPropDisplay[index];
          const title =
            prop.defAuxProp && tempPropDisplay[prop.defAuxProp.index]
              ? prop.defAuxProp.position
                ? [tempPropDisplay[prop.defAuxProp.index], display].join('-')
                : [display, tempPropDisplay[prop.defAuxProp.index]].join('-')
              : display;
          if (disable) placeHolder = '不可修改';
          if (prop.type === 'formula') {
            if (propIsNull(tempProp[index])) {
              if (node?.value?.prop?._sys_prop_error) {
                const message = getFormulaError(
                  node?.value?.prop?._sys_prop_error,
                  index,
                );
                if (message) {
                  placeHolder = message;
                }
              }
            }
          }
          let disablePosition = false;
          if (prop.type === 'positioning' && !prop.editable) {
            const positionValue = node.value.prop._sys_location?.[index];
            if (!positionValue || !positionValue.length) {
              placeHolder = '不允许编辑';
              disablePosition = true;
            }
          }

          children.push({
            sort: propIsNull(prop.sort) ? index : prop.sort,
            propIndex: index,
            dom: (
              <div
                style={{ cursor: disable ? 'not-allowed' : 'default' }}
                className={getPropContainerClassName(style)}
                key={index}
              >
                <div
                  style={getAttrNameWidth(attrNameWidth, style)}
                  className={getAttrNameClassName(style)}
                  title={prop.name}
                >
                  {renderAttrNameContent(style, prop, icon)}
                </div>
                <div
                  style={getPropValueStyle(editingId === domId, style, disable)}
                  className={getPropValueClassName(
                    disable,
                    style,
                    nodePropConfig,
                  )}
                >
                  {/* editting */}
                  {editingId === domId && (
                    <div className={getPropValueInputClassName(style)}>
                      {inputDom}
                    </div>
                  )}
                  {/* attachment */}
                  {editingId !== domId && originPropType === 'attachment' && (
                    <div
                      className="propValueLabel"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <AttachmentCell
                        config={{
                          ...(getQuoteOriginProp(
                            prop,
                            tempMap,
                          ) as CurrentUser.TemplateProp),
                          ...prop,
                        }}
                        value={tempProp[index] || []}
                        fileSrcArr={node.value.prop._sys_attach?.[index] || []}
                        orgId={currentOrgId}
                        nodeId={node.value.id}
                        tempId={currentTemplate.template_id}
                        propIndex={index}
                        canEdit={
                          (node.value.acl === NODE_PERMISSION.管理 ||
                            node.value.acl === NODE_PERMISSION.编辑) &&
                          !disable
                        }
                        showRecordIcon={!isDrafts}
                        userMap={userMap}
                        customStyle={style}
                        showSourceIcon={
                          [1, 2].includes(getQuoteOrMatchingInfo(prop)) && (
                            <i
                              className={'iconfont iconattribute_quote'}
                              style={{
                                height: 32,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setQuoteVisible(true);
                                setQuoteIndex(index);
                              }}
                            />
                          )
                        }
                      />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {recordModification &&
                          ['style_2', 'style_3'].includes(style) &&
                          renderModification(prop, index)}
                        {prop.remark &&
                          ['style_2', 'style_3'].includes(style) && (
                            <Tooltip title={prop.remark}>
                              <i
                                className="iconfont iconNav_Help"
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: 4,
                                  marginRight: 6,
                                }}
                              />
                            </Tooltip>
                          )}
                      </div>
                    </div>
                  )}
                  {/* not attachment */}
                  {editingId !== domId && originPropType !== 'attachment' && (
                    <div
                      className={getPropValueLabelClassName(style)}
                      onClick={() =>
                        setEditedProp(prop, index, disablePosition, disable)
                      }
                    >
                      {propIsNull(tempProp[index]) ? (
                        renderValueNullContent(prop, placeHolder, index)
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flex: 1,
                              overflow: 'hidden',
                            }}
                          >
                            {renderValueText(
                              style,
                              title,
                              prop,
                              disable,
                              index,
                              display,
                              tempProp[index],
                            )}
                            {/* 日期距今时间差 */}
                            {
                              <DateBeforePresent
                                prop={prop}
                                value={tempProp[index]}
                              />
                            }
                          </div>
                          <div
                            style={{
                              paddingRight: 6,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {/* 查看引用列表 */}
                            {[1, 2].includes(getQuoteOrMatchingInfo(prop)) && (
                              <i
                                className="iconfont iconattribute_quote"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setQuoteVisible(true);
                                  setQuoteIndex(index);
                                }}
                              />
                            )}
                            {/* 修改记录 */}
                            {recordModification &&
                              renderModification(prop, index)}
                            {/* 图像码 */}
                            {isPropSupportGraphicCode(prop) && (
                              <Popover
                                placement="top"
                                content={renderGraphicCodePopoverContent(
                                  { ...prop, index },
                                  display as string,
                                )}
                                overlayClassName="graphicCodePopover"
                              >
                                <i
                                  className="iconfont iconma"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                />
                              </Popover>
                            )}
                            {/* 拆分主题 */}
                            {editingId !== domId &&
                              showSplit(prop, tempProp[index]) &&
                              renderSplitContent(
                                prop,
                                style,
                                index,
                                tempProp[index],
                              )}
                            {/* 地址增加复制文本 ---- 靠右 */}
                            {prop.type === 'address' && display && (
                              <Tooltip title="复制文本">
                                <i
                                  className="iconfont iconfuzhi1"
                                  onClick={(e: React.MouseEvent) =>
                                    handleCopyClick(e, display)
                                  }
                                />
                              </Tooltip>
                            )}
                            {prop.remark &&
                              ['style_3', 'style_1'].includes(style) && (
                                <Tooltip title={prop.remark}>
                                  <i
                                    className="iconfont iconNav_Help"
                                    style={{ cursor: 'pointer', marginLeft: 4 }}
                                  />
                                </Tooltip>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ),
          });
        }
      });
    }
    children.sort((b, a) => b.sort - a.sort);
    setTemplateProp(children.map((x) => ({ dom: x.dom, index: x.propIndex })));
  };

  // 根据属性的显示规则和布局重新调整属性的位置 props 是已经过滤了隐藏的属性
  const arrangeAttrs = (propGrid, props) => {
    const visiblePropsMap = props.reduce((i, j) => {
      i[j.index] = true;
      return i;
    }, {});
    //1. 去掉隐藏的属性
    const newPropGrid = propGrid.filter(
      (p) =>
        currentTemplate?.prop[p.i]?.type &&
        visiblePropsMap[p.i] &&
        checkAttrDisplay(node.value, currentTemplate.prop[p.i], p.i),
    );

    // 新增的属性
    const newProps = props.filter(
      (p) => !newPropGrid.find((i) => i.i == p.index),
    );

    //2.按照位置排序
    newPropGrid.sort((p1, p2) => {
      const v1 = p1.y * 1000 + p1.x;
      const v2 = p2.y * 1000 + p2.x;
      return v1 - v2;
    });

    // 结果
    const res = [];

    // 3. 补位
    newPropGrid.forEach((p, index) => {
      // 第一个肯定排在第一位
      if (index === 0) return res.push({ ...p, x: 0, y: 0 });

      // 上一个布局
      const preP = res[res.length - 1];
      const { x, y, w: preW } = preP;

      // 当前的属性
      const { w } = p;

      // 后面空间还是否有空间
      const restW = 12 - x - preW;

      // 重新计算这个位置，磁吸
      const newX = restW >= w ? x + preW : 0;
      const newY = restW >= w ? y : y + 1;

      res.push({ ...p, x: newX, y: newY });
    });

    // 新增的放在最后
    newProps.forEach((p) => {
      // 上一个布局
      const preP = res[res.length - 1];
      // 这个组全部都被删除了
      if (!preP) return res.push({ x: 0, y: 0, i: p.index, h: 1, w: 4 });
      const { x, y, w: preW } = preP;
      const restW = 12 - x - preW;
      // 重新计算这个位置，磁吸
      const newX = restW >= 4 ? x + preW : 0;
      const newY = restW >= 4 ? y : y + 1;

      res.push({ x: newX, y: newY, i: p.index, h: 1, w: 4 });
    });

    // 4. 返回
    return res;
  };

  const getMargin = (style: string) => {
    if (style === 'style_3') return 22;
    if (style === 'style_2') return 8;
    return 6;
  };
  const getPropValueHeight = (style: string) => {
    if (style === 'style_3') return 40;
    if (style === 'style_2') return 66;
    return 32;
  };

  // 根据属性布局信息， 得到容器的高度，属性的位置
  const getAttrBoundingDetail = (propGrid: any, props: any, style: string) => {
    if (!propGrid) return null;
    propGrid = arrangeAttrs(propGrid, props);
    const margin = getMargin(style);
    const prop_h = getPropValueHeight(style);
    const containerHeight =
      (Math.max(...Object.values(propGrid).map((i) => Number(i.y))) + 1) *
      (prop_h + 2 * margin);
    const attrsRectMap = {};

    propGrid.forEach((g) => {
      const { i, x, y, w, h } = g;
      attrsRectMap[i] = {
        position: 'absolute',
        width: `${(w * 100) / 12}%`,
        height: prop_h,
        top: 6 + y * (prop_h + 2 * margin),
        left: `${(x * 100) / 12}%`,
      };
    });
    return {
      containerHeight,
      attrsRectMap,
      arrangedPropGrid: propGrid,
    };
  };

  const showPropGroups = () => {
    if (!nodePropConfig) return true;

    if (nodePropConfig.attributesDefaultShow === 'defaultFold')
      return propVisible;

    return true;
  };

  const changePropVisible = () => {
    console.log('changePropVisible');
    if (!nodePropConfig) return setPropVisible(!propVisible);
    const { attributesDefaultShow, showGroup } = nodePropConfig;
    const propGroupIds = getPropGroups().map((g: any) => g.id);

    if (attributesDefaultShow !== 'defaultFold' && showGroup)
      setPropGroupFolds(propVisible ? propGroupIds : []);
    setPropVisible(!propVisible);
  };

  const renderGridProps = (
    templateProp: TemplateProp[],
    propGrid: any,
    style: string,
  ) => {
    const { valueWrap } = nodePropConfig || {};
    const attrBoundingDetail = getAttrBoundingDetail(
      propGrid,
      templateProp,
      style,
    );
    const propsContainerStyle = ['style_1', 'style_2', 'style_3'].includes(
      style,
    )
      ? { display: 'flex', flexWrap: 'wrap', width: '100%' }
      : {};

    if (editPartPropsInNode)
      templateProp = templateProp.filter((t: any) =>
        editPartPropsInNode.includes(t.index),
      );

    //
    const templatePropMap = templateProp.reduce((res: any, cur: any) => {
      res[cur.index] = cur;
      return res;
    }, {});

    // 绘制属性
    const drawProps = () => {
      // 如果没有拖动过布局，或者是默认样式，直接遍历即可
      if (!propGrid) return templateProp.map((t: any) => t.dom);

      // 遍历 arrangedPropGrid， 按顺序采用 flex布局
      return attrBoundingDetail.arrangedPropGrid.map((g: any) => {
        const { i } = g;
        const width = attrBoundingDetail.attrsRectMap[i].width;
        let marginBottom = 12;
        if (style === 'style_2') marginBottom = 18;
        if (style === 'style_3') marginBottom = 26;

        return (
          <div key={i} style={{ width, marginBottom }}>
            {templatePropMap[i]?.dom || null}
          </div>
        );
      });
    };

    return (
      <div
        style={propsContainerStyle}
        className={valueWrap ? 'textWrapContainer' : ''}
      >
        {drawProps()}
      </div>
    );
  };

  const collapsePropGroup = (groupId: string) => {
    if (propGroupFolds.includes(groupId))
      setPropGroupFolds(propGroupFolds.filter((p) => p !== groupId));
    else setPropGroupFolds([...propGroupFolds, groupId]);
  };

  const getPropGroups = () => {
    if (!currentTemplate) return [];
    let prop_groups: any = currentTemplate?.prop_groups;
    if (!prop_groups || prop_groups.length === 0)
      prop_groups = [{ id: '-1', name: '默认分组' }];

    return prop_groups;
  };

  const renderProps = () => {
    if (!currentTemplate) return null;
    const { showGroup, style, propGrid } = nodePropConfig || {};
    if (!showGroup)
      return propVisible
        ? renderGridProps(templateProp, propGrid, style)
        : null;
    let className = 'groupNameDefault';
    if (style === 'style_1') className = 'groupNameStyle1';
    if (style === 'style_2') className = 'groupNameStyle2';
    if (style === 'style_3') className = 'groupNameStyle3';

    if (!showPropGroups()) return null;
    // 分组了
    const prop_groups = currentTemplate?.prop_groups;
    if (prop_groups && prop_groups.length)
      return prop_groups.map((group, groupIndex) => {
        const { id, name, props } = group;
        if (props.length === 0) return null;
        const items = templateProp.filter((p) =>
          props.includes(Number(p.index)),
        );
        if (items.length === 0) return null;

        return (
          <div key={id} style={{ marginTop: groupIndex > 0 ? 18 : 0 }}>
            {/* 分组 */}
            <div
              onClick={() => collapsePropGroup(id)}
              className={cn(className, 'cursor-pointer')}
            >
              <span>{name}</span>
              <span>（{items.length}）</span>
              <i
                style={{ color: '#767C88' }}
                className={`iconfont ${
                  !propGroupFolds.includes(id)
                    ? 'iconattribute_fold'
                    : 'iconzhankaishouqi'
                }`}
              />
            </div>
            {!propGroupFolds.includes(id)
              ? renderGridProps(items, (propGrid || {})[id], style)
              : null}
          </div>
        );
      });

    return (
      <div>
        <div onClick={() => collapsePropGroup('-1')} className={className}>
          <span>默认分组</span>
          <span>（{templateProp.length}）</span>
          <i
            style={{ color: '#767C88' }}
            className={`iconfont ${
              !propGroupFolds.includes('-1')
                ? 'iconattribute_fold'
                : 'iconzhankaishouqi'
            }`}
          />
        </div>
        {!propGroupFolds.includes('-1')
          ? renderGridProps(templateProp, (propGrid || {}).default, style)
          : null}
      </div>
    );
  };

  return (
    <div id="NewNodeProp">
      <div
        id={isDark ? 'darknessProps' : ''}
        className={cn('prop-body', saving && 'pointer-events-none')}
        onClickCapture={(e) => {
          if (readOnly || saving) e.stopPropagation();
        }}
      >
        {renderProps()}
        <div className="flex flex-col gap-2">
          {!editPartPropsInNode &&
          currentTemplate &&
          (isManger || (templateProp.length > 1 && showFoldButton)) ? (
            <div
              style={
                isSimpleThemeTree ? { width: simpleThemeTreeWidth - 48 } : {}
              }
              className={cls(
                `prop-setting`,
                templateProp.length && 'hasProp',
                !['style_1', 'style_2', 'style_3'].includes(
                  nodePropConfig?.style,
                ) && 'defaultPropSetting',
              )}
            >
              <div>
                {!isDrafts &&
                  match(nodePropConfig?.editMode)
                    .with('toggleEditor', () => {
                      if (globalEditable)
                        return (
                          <Space
                            className="cursor-pointer select-none ml-4"
                            onClick={() => setGlobalEditable(false)}
                          >
                            <RegularIcon type="icontuichu" size={12} />
                            退出编辑
                          </Space>
                        );

                      return (
                        <Space
                          className="cursor-pointer select-none ml-4"
                          onClick={() => setGlobalEditable(true)}
                        >
                          <RegularIcon type="iconshaixuanqu-bianji" size={12} />
                          编辑属性
                        </Space>
                      );
                    })
                    .otherwise(() => null)}
              </div>
              <Space align="center">
                {isManger && (
                  <Space
                    size={4}
                    className={styles.propVisibleToggle + ' propVisibleToggle'}
                    align="center"
                    onClick={toggleDisplayHiddenPropsState}
                  >
                    {(displayHiddenPropsState ? '' : '不') + '显示隐藏的属性'}
                    <Tooltip title="用于展示因「属性配置」、「自动化规则」以及「属性的条件规则」隐藏的属性（仅对管理员生效）">
                      {displayHiddenPropsState ? (
                        <EyeOutlined />
                      ) : (
                        <EyeInvisibleOutlined />
                      )}
                    </Tooltip>
                  </Space>
                )}
                {templateProp.length > 1 && showFoldButton && (
                  <div
                    className={cls(
                      `prop-setting-collapse`,
                      propVisible && 'visible',
                    )}
                    onClick={() => changePropVisible()}
                  >
                    {propVisible ? '收起' : `展开${templateProp.length}个属性`}
                    <i className={'iconfont iconattribute_fold'} />
                  </div>
                )}
              </Space>
            </div>
          ) : null}
          {showSaveButton && templateProp.length > 1 && (
            <div key="saveButton" className="flex gap-2 pl-4">
              <Button
                type="primary"
                className="rounded-[8px]"
                disabled={nodeReadOnly}
                loading={saving}
                onClick={async () => {
                  setSaving(true);
                  try {
                    await node.value.commitTransaction();
                    message.success('保存成功');
                  } catch (error) {
                    console.error(error);
                    message.error('保存失败');
                  } finally {
                    setSaving(false);
                  }
                }}
              >
                保存
              </Button>
              <Button
                className="rounded-[8px]"
                loading={saving}
                disabled={nodeReadOnly}
                onClick={() => {
                  node.value.abortTransaction();
                  rerender();
                }}
              >
                重置
              </Button>
            </div>
          )}
        </div>
      </div>

      {showRecurrenceModal && (
        <DraftsModal
          visible={true}
          mode="add"
          onCancel={() => setShowRecurrenceModal(false)}
          orgId={currentOrgId}
          draftsNodeData={draftsNodeData}
          initParentId={nodeParent}
          onSuccess={() => {
            setTimeout(async () => {
              await fetchEnumExtend();
              getTemplateProps(true);
            }, 500);
          }}
        />
      )}
      {quoteVisible ? (
        <QuoteNodes
          visible={quoteVisible}
          onCancel={() => setQuoteVisible(false)}
          node={node.value}
          propIndex={quoteIndex}
          orgConnection={orgConnection!}
          orgId={currentOrgId}
          toNode={onPreviewNodeForFix}
          originNodes={originNodes}
        />
      ) : null}
      <PropRecordModal
        nodeId={node.value.id}
        orgId={currentOrgId || ''}
        ref={propRecordModalRef}
        userMap={userMap}
        tempMap={tempMap}
      />

      {contentVisible && (
        <ViewContent
          contentVisible={contentVisible}
          setContentVisible={() => setContentVisible(false)}
          setNodeIndex={(i) => setCurrentNodeIndex(i)}
          nodeIndex={currentNodeIndex}
          dataList={currentDataList}
          showNodeSaved={() => {}}
        />
      )}

      {quoteSelectorVisible && (
        <QuoteSelector
          node={node.value}
          onCancel={() => setQuoteSelectorVisible(false)}
          orgConnection={orgConnection!}
          orgId={currentOrgId!}
          propIndex={quoteIndex}
          mode={mode}
          visible={quoteSelectorVisible}
        />
      )}

      {!!~numberSplitIndex && (
        <SplitNumber
          index={numberSplitIndex}
          onCancel={() => setNumberSplitIndex(-1)}
          node={node.value}
          template={currentTemplate!}
          orgId={currentOrgId!}
        />
      )}

      {/* 编辑定位的弹窗 */}
      <LocationSelect ref={locationSelectRef} />
    </div>
  );
};

export default NodePropsForm;
