import type {
  ApiResponse,
  CurrentUser,
  OrgGroups,
  OrgUser,
  PDFUnitConfig,
  SysButton,
  ViewList,
} from '@linkpi/core';
import {
  ACT,
  checkNodePropVisibleByGroup,
  filterChecker,
  genDefaultFlowSetting,
  generateId,
  genNumberLimit,
  getChildTempAllow,
  getDepartmentNodes,
  getPropVisibleMap,
  getQuoteOriginProp,
  getQuoteOriginPropInfo,
  getStatusRouteConfig,
  produceConditionV2,
  propIsNull,
  tempValueDisplay,
} from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { getShareDBConnectionInstance, sortPiNode } from '@linkpi/core/web';
import type { FileIconTypes as _FileIconTypes } from '@linkpi/quill';
import { FileExtentionConfig } from '@linkpi/quill';
import {
  takeOne as _takeOne,
  toArray as _toArray,
  toRecord as _toRecord,
} from '@linkpi/utils';
import { getDvaApp, history } from '@umijs/max';
import { message } from 'antd';
import { type ClassValue, clsx } from 'clsx';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import type { WritableDraft } from 'immer';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { nanoid } from 'nanoid';
import QRCode from 'qrcode';
import type { ParsedQuery } from 'query-string';
import qs from 'query-string';
import type { ParsedUrlQuery } from 'querystring';
import { parse } from 'querystring';
import type Delta from 'quill-delta';
import {
  curry,
  defaultTo,
  equals,
  find,
  intersection,
  isEmpty,
  isNil,
  keys,
  uniq,
} from 'ramda';
import { lazy } from 'react';
import { isHotkeyPressed } from 'react-hotkeys-hook';
import richText from 'rich-text';
import SparkMD5 from 'spark-md5';
import { twMerge } from 'tailwind-merge';

import { ENV_CONFIG, LINKPI_ORG_ID, ORG_ID_WHITELIST } from '@/consts';
import { getCurrentUserInfo, getUserList } from '@/hook';
import { checkUpload } from '@/services/spaceV2';
import request from '@/utils/request';

import { genModifyKeys } from './hotKeys';

export const toRecord = _toRecord;

type TemplateInfo = CurrentUser.TemplateInfo;

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const toArray = _toArray;
export const takeOne = _takeOne;

const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = (path: string) => reg.test(path);

const ua = window.navigator.userAgent.toLowerCase();
export const isWeiXinBrowser = /MicroMessenger/i.test(ua);

export const getPageQuery = <T extends ParsedUrlQuery>() =>
  parse(window.location.href.split('?')[1]) as T;

const UA = window.navigator.userAgent;

export const isAndroid = /android|adr/gi.test(UA);
export const isIos = /iphone|ipod|ipad/gi.test(UA) && !isAndroid;
export const isChrome = UA.indexOf('Chrome') !== -1;

export const findUser = (
  userId: string,
  spaceId: string,
  spaceUserList: Record<any, any>,
) => {
  if (spaceUserList[spaceId] && spaceUserList[spaceId][userId]) {
    return spaceUserList[spaceId][userId];
  } else {
    //try to find in other space
    for (const space in spaceUserList) {
      if (space !== spaceId) {
        if (spaceUserList[space][userId]) {
          return spaceUserList[space][userId];
        }
      }
    }
    return null;
  }
};

export const PROP_NODISPLAY = ['_sys_user_acl', '_sys_group_acl'];
export const getPropKeyForDisplay = (key: string) => {
  const forDisplay: Record<string, string> = {
    _sys_subscriber: '关注者',
    _sys_user_acl: '权限',
    _sys_creator: '创建人',
    _sys_createTime: '创建时间',
    _sys_updateTime: '修改时间',
    _act_owner: '负责人',
    _act_participant: '参与者',
    _act_completed: '完成情况',
  };
  if (forDisplay[key]) return forDisplay[key];
  else return key;
};

export const isEmptyStr = (str: string) => {
  if (typeof str !== 'string') return false;
  return typeof str == 'undefined' || str == null || str === '';
};

export const getQueryVariable = (variable: string) => {
  const query = window.location.search.substr(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};

export const uniqueArr = (arr: any[]) => {
  const r = [];
  for (let i = 0, l = arr.length; i < l; i++) {
    for (let j = i + 1; j < l; j++) if (arr[i] === arr[j]) j = ++i;
    r.push(arr[i]);
  }
  return r;
};

// hex转rgba
export const hexToRgba = (hex: string, opacity: number | string) => {
  const RGBA =
    'rgba(' +
    parseInt('0x' + hex.slice(1, 3)) +
    ',' +
    parseInt('0x' + hex.slice(3, 5)) +
    ',' +
    parseInt('0x' + hex.slice(5, 7)) +
    ',' +
    opacity +
    ')';
  return {
    red: parseInt('0x' + hex.slice(1, 3)),
    green: parseInt('0x' + hex.slice(3, 5)),
    blue: parseInt('0x' + hex.slice(5, 7)),
    rgba: RGBA,
  };
};

export const isMobile = () => {
  const userAgentInfo = navigator.userAgent;
  const mobileAgents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
  ];
  let mobile_flag = false;
  //根据userAgent判断是否是手机
  for (let v = 0; v < mobileAgents.length; v++) {
    if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
      mobile_flag = true;
      break;
    }
  }
  const screen_width = window.screen.width;
  const screen_height = window.screen.height;
  //根据屏幕分辨率判断是否是手机
  if (screen_width < 500 && screen_height < 800) {
    mobile_flag = true;
  }
  return mobile_flag;
};

export const clipboardCopy = (str: string) => {
  let textareaEl: any = document.createElement('textarea');
  textareaEl.setAttribute('readonly', 'readonly'); // 防止手机上弹出软键盘
  textareaEl.value = str || '';
  document.body.appendChild(textareaEl);
  textareaEl.select();
  document.execCommand('copy');
  document.body.removeChild(textareaEl);
  textareaEl = null;
};

// 获取图片宽高
export const getImgRect = async (src: string) => {
  return new Promise((r) => {
    if (!src) r(null);
    const img = new Image();
    img.src = src;
    if (img.complete) {
      r({ width: img.width, height: img.height, img, src });
    } else {
      img.onload = () => {
        r({ width: img.width, height: img.height, img, src });
      };
    }
  });
};

/**
 * 获取节点的正文
 */
export const getNodeMainText = async (
  nodeId: string,
): Promise<Delta | null> => {
  return new Promise((r, f) => {
    const shareDBConnectionInstance = getShareDBConnectionInstance();
    const query = shareDBConnectionInstance.get('testG1', 'Doc' + nodeId)!;
    query.subscribe((err) => {
      if (err) {
        f(err);
        return;
      } else {
        richText.type.serialize(query.data);
        r(query.data);
        query.destroy();
      }
    });
  });
};

/**
 * 防抖
 */
export function debounce(fn: any, t: number) {
  let flag: ReturnType<typeof setTimeout> = 0 as any;
  return (...args: any[]) => {
    clearTimeout(flag);
    flag = setTimeout(() => {
      fn(args);
    }, t);
  };
}

export function consoleColorTag({
  tag = '',
  color = 'blue',
  messageText = '',
}) {
  console.log(`%c[${tag}]`, `color:${color}`, messageText);
}

// 处理返回的 currentUser
export const parseCurrentUser = (currentUser: ApiResponse.CurrentUser) => {
  // 个人空间
  const userOrg = currentUser.organization.find(
    (x) => x.orgType === -1,
  ) as ApiResponse.CurrentUser.OrgInfo;

  const selectSpace = currentUser.select_space || userOrg.orgId;
  const selectFocus = currentUser.select_root?.toLocaleLowerCase();
  const selectNode = currentUser.select_node?.toLocaleLowerCase();

  return {
    selectNode,
    selectSpace,
    selectFocus,
  };
};

export type UploadFileInOrgNodeResFailType = {
  status: 'fail';
  message: string;
};

export type UploadFileInOrgNodeResSuccessType = {
  status: 'success';
  fileUrl: string;
  fileName: string;
};

export const fileToBuffer = (file: File): Promise<Buffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      resolve(Buffer.from(reader.result as ArrayBuffer));
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

// 通过url获取文件类型
export const getFileTypeByUrl = (url: string): string => {
  const normalFileUrl = url.split('?')[0];
  return normalFileUrl.split('.').pop()?.toLocaleLowerCase() || '';
};

export const uploadFileInOrgNode = async (
  file: File,
  orgId: string,
  nodeId: string,
): Promise<
  UploadFileInOrgNodeResFailType | UploadFileInOrgNodeResSuccessType
> => {
  const fileData = await fileToBuffer(file);
  const strLength = fileData.length;
  const fileLength = Math.floor(strLength - (strLength / 8) * 2);
  // 由字节转换为KB
  const size = (fileLength / 1024).toFixed(2);
  const suffix = getFileTypeByUrl(file.name);
  let path = generateId();

  if (suffix) {
    path += '.' + suffix;
  }
  const fileMd5 = SparkMD5.ArrayBuffer.hash(fileData);
  //验证是否可上传
  const res = await checkUpload({
    org_id: orgId,
    node_id: nodeId,
    file_length: size,
    md5: fileMd5,
  });
  if (res.status === 'error') {
    console.log('check_upload error ' + res.message);
    return { status: 'fail', message: res.message };
  }

  let fileUrl = '';
  if (!res.data) {
    const Oss = await import('ali-oss').then((r) => r.default);
    const client = Oss({
      secure: true,
      bucket: res.bucket,
      region: res.region,
      accessKeyId: res.token.AccessKeyId,
      accessKeySecret: res.token.AccessKeySecret,
      stsToken: res.token.SecurityToken,
      authorizationV4: true,
    });
    const oss_res = await client.put('upload/' + path, fileData);
    if (oss_res) {
      const store_res = await request<string>('/api/file/store', {
        method: 'POST',
        data: {
          org_id: orgId,
          path: path,
          node_id: nodeId,
        },
      });
      if (store_res.status === 'error') {
        console.log('upload store fail ' + store_res.message);
        return { status: 'fail', message: store_res.message };
      }
      fileUrl = store_res.data;
    }
  } else {
    fileUrl = res.data;
  }
  return { status: 'success', fileUrl: fileUrl, fileName: file.name };
};

export type FileIconTypes = _FileIconTypes;
export const getFileTypeIconByUrl = (
  url: string,
): { type: FileIconTypes; src: string } => {
  const fileType = getFileTypeByUrl(url || '');
  let src = 'https://www.mylinkpi.com/icon/';
  let type: FileIconTypes = 'unknown';
  for (let index = 0; index < FileExtentionConfig.length; index++) {
    const config = FileExtentionConfig[index];
    if (find(equals(fileType))(config.ext)) {
      type = config.type;
      if (typeof config.src === 'function') {
        src = config.src(url, src);
      } else {
        src += config.src;
      }
      break;
    }
  }

  if (type === 'unknown') {
    src += 'unknown file.png';
  }
  // console.log(type, src);
  return { src, type };
};

// coverArr 覆盖 baseArr
export const mergeArray = <U>(baseArr: U[], coverArr: U[]) => {
  const length = Math.max(baseArr.length, coverArr.length);
  return new Array(length).fill('').map((x, i) => {
    return coverArr[i] || baseArr[i];
  });
};

export const displayGroupName = (
  data: ViewGroupDataItem | ViewGroupDataItemWidthMetadata,
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>,
  groups: ApiResponse.CurrentUser.orgGroup[],
  templateList: any[] = [],
) => {
  if (data.nameType === 'user') {
    if (userMap[data.name]) {
      return userMap[data.name].nick_name;
    } else if (data.name === '未定义') {
      return data.name;
    } else {
      return '未知成员';
    }
  } else if (data.nameType === 'group') {
    return (
      groups?.find((x) => x.group_id === data.name)?.group_name || '未知分组'
    );
  } else if (data.nameType === 'template') {
    return (
      templateList.find((t) => t.template_id === data.name)?.name ||
      '未知主题类型'
    );
  }
  return data.name;
};

export const setRgbTo16 = (str: string) => {
  const rgbReg = /^(rgb|RGB)/;
  if (!rgbReg.test(str)) {
    return '';
  }
  const arr = str.slice(4, str.length - 1).split(',');
  let color = '#';
  for (let i = 0; i < arr.length; i++) {
    let t = Number(arr[i]).toString(16);
    if (t == '0') {
      //如果为“0”的话，需要补0操作,否则只有5位数
      t = t + '0';
    }
    color += t;
  }
  return color;
};

// 表格装修字体转尺寸给附件属性
export const transformFontSizeToAttachmentSize = (fontSize: any) => {
  return Number(fontSize);
};

// 表格装修单元格边距 文字行距
export const tableFixCellPadding = [10, 12, 10, 12];
export const tableFixLineGap = 10;

export const findFirstView = (_sys_topping_views: any, views: any[]) => {
  const currentConfig: any = {};
  const toppingViews = views
    .filter((v) => v.view_id !== 'content')
    .map((v) => {
      return {
        viewId: v.view_id,
        index: _sys_topping_views[v.view_id],
      };
    });

  if (toppingViews.length) {
    toppingViews.sort((a, b) => a.index - b.index);
    currentConfig.selectViewId = toppingViews[0].viewId;
  } else {
    currentConfig.selectViewId = views[0].view_id;
  }

  return currentConfig;
};

// 表格阴影计算
export const genShades = (startAlpha: number, long: number) => {
  const startShade = (1 - startAlpha) * 255;
  const shades = [];

  const unit = (255 - startShade) / long;

  for (let i = 1; i <= long; i++) {
    shades.push(startShade + unit * i);
  }
  return shades;
};

// delay
export const delay = (time = 3000) =>
  new Promise((rel: any) => {
    setTimeout(() => {
      rel();
    }, time);
  });

export const getAttachmentValue = (node: PiNode, propIndex: number) => {
  const tempInfo = node.tempInfo;
  const fileNames = tempInfo.prop[propIndex] || [];
  const _sys_attach = (node.prop || {})._sys_attach || {};

  return (
    Array.isArray(_sys_attach[propIndex]) ? _sys_attach[propIndex] : []
  ).map((src: string, j: number) => ({
    src,
    fileName: fileNames[j] || '未知文件名',
  }));
};

export const getLinkPiOrg = (
  currentUser: ApiResponse.CurrentUser,
): ApiResponse.CurrentUser.OrgInfo | null => {
  if (!currentUser) return null;

  const organization = currentUser.organization || [];

  const whiteList = [...LINKPI_ORG_ID, ...ORG_ID_WHITELIST];

  const linkPiOrg = organization.find((org: any) =>
    whiteList.includes(org.orgId),
  );

  return linkPiOrg ?? null;
};

// 判断一个用户是不是连接派内部人员，有些功能是内部功能
export const isLinkPiUser = (currentUser: any) => {
  return !!getLinkPiOrg(currentUser);
};

export const isLinkPiAdmin = (currentUser: ApiResponse.CurrentUser) => {
  const linkPiOrg = getLinkPiOrg(currentUser);
  return !!linkPiOrg && isAdmin(linkPiOrg);
};

// 获取某个主题的状态，如果被删除的不期望显示。
export const getTemplateStatus = (template: any, statusIndex: number) => {
  if (!template) return false;
  const status = (template.task_status || [])[statusIndex];

  if (!status || status.delete) return false;

  return status;
};

// [] => {}
export const arrToMap = (arr: any[], key: string) => {
  return arr.reduce((res, cur) => {
    res[cur[key]] = cur;
    return res;
  }, {} as any);
};

// 引用统计、引用源是数字 数字公式 货币
export const isQuotePropCanCount = (
  prop: ApiResponse.CurrentUser.TemplateProp,
  originProp: ReturnType<typeof getQuoteOriginProp>,
): boolean => {
  if (!originProp || typeof originProp === 'string') return false;
  // 数字公式
  const numberFormula =
    originProp.type === 'formula' && originProp.formulaFormat === 0;
  // 数字或货币
  const quoteTempPropIsCanCount =
    originProp.type === 'number' || originProp.type === 'currency';
  // 引用统计
  const propIsCanCount = prop.matchingType > 0;

  return numberFormula || quoteTempPropIsCanCount || propIsCanCount;
};

// 处理 quill ops 实时响应自定义按钮、属性
export const handleQuillOps = (ops: any[], templateList: any[]) => {
  return ops.filter((op) => {
    // 自定义按钮
    if (op.insert && op.insert.CustomBtn) {
      const customBtn = op.insert.CustomBtn;
      const opConfig =
        typeof customBtn === 'string' ? JSON.parse(customBtn) : customBtn;

      const { templateId, btnId } = opConfig;

      // 兼容老的数据
      if (!templateId) return true;

      // 拿到对应的主题
      const template = templateList.find((t) => t.template_id === templateId);
      const custom_button: any[] = template?.custom_button || [];
      const btn = custom_button.find((i) => i.id === btnId);
      return !!btn;
    }

    // 属性
    if (op.insert && op.insert.ThemeAttrSpan) {
      const themeAttrSpan = op.insert.ThemeAttrSpan;
      const opConfig =
        typeof themeAttrSpan === 'string'
          ? JSON.parse(themeAttrSpan)
          : themeAttrSpan;

      const { templateId, index } = opConfig;

      // 兼容老的数据
      if (!templateId || templateId === 'undefined') return true;

      const template = templateList.find((t) => t.template_id === templateId);
      const prop = template?.prop?.[index];
      return prop?.type && true;
    }

    return true;
  });
};

// 获取某个主题的自定义按钮
export const getCustomBtn = (
  templateId: string,
  btnId: string,
  templateList: TemplateInfo[],
) => {
  const template = templateList.find((t) => t.template_id === templateId);
  return (template?.custom_button || []).find((i: any) => i.id === btnId);
};

export const DefaultSysButtons: SysButton[] = [
  {
    type: 'text',
    pictureUrl: '',
    id: 'system_button_create',
    hideWebText: false,
    text: '创建',
    color: '#316ef5',
    icon: null,
    size: 'middle',
    opType: 'addNode',
  },
  {
    type: 'text',
    pictureUrl: '',
    id: 'system_button_view',
    hideWebText: false,
    color: '#316ef5',
    text: '查看',
    icon: null,
    size: 'middle',
    opType: 'viewNode',
  },
  {
    type: 'text',
    pictureUrl: '',
    id: 'system_button_edit',
    hideWebText: false,
    text: '编辑',
    color: '#316ef5',
    icon: null,
    size: 'middle',
    opType: 'viewNode',
  },
  {
    type: 'text',
    pictureUrl: '',
    id: 'system_button_delete',
    hideWebText: false,
    color: '#f5222d',
    text: '删除',
    icon: null,
    size: 'middle',
    opType: 'deleteNode',
  },
];

export const getSysBtn = (
  templateId: string,
  btnId: string,
  templateList: TemplateInfo[],
): SysButton | undefined => {
  const template = templateList.find((t) => t.template_id === templateId);
  return (template?.custom_view?.sys_button || DefaultSysButtons).find(
    (i) => i.id === btnId,
  );
};

// 获取某个主题的属性
export const getTemplateProp = (
  templateId: string,
  index: any,
  templateList: any[],
) => {
  const template = templateList.find((t) => t.template_id === templateId);
  return (template.prop || [])[index] || {};
};

// 判断一个主题的新分享的属性权限是不是所有，需要同步更新属性的配置
export const isSharePropConfigAll = (
  template: ApiResponse.CurrentUser.TemplateInfo,
  spaceKey: string,
  dispatch: any,
) => {
  const { shareConfig } = template;
  if (!shareConfig) return false;

  const { prop } = shareConfig;
  if (!prop) return false;

  const canViewPropIndexs = [];
  const canEditPropIndexs = [];

  Object.keys(prop).forEach((key) => {
    if (prop[key] == 1 || prop[key] == 2) canViewPropIndexs.push(key);
    if (prop[key] == 2) canEditPropIndexs.push(key);
  });

  const showPropsLen = template.prop.filter((p) => p.type).length;

  const values = {
    org_id: spaceKey,
    template_id: template.template_id,
    shareConfig,
  };
  if (canEditPropIndexs.length === showPropsLen) {
    // 所有可编辑
    values.shareConfig.prop = { ...prop, [`${template.prop.length}`]: 2 };
    dispatch({ type: 'space/updateTemplate', payload: values });
    return;
  }

  if (canViewPropIndexs.length === showPropsLen) {
    //所有属性可查看
    values.shareConfig.prop = { ...prop, [`${template.prop.length}`]: 1 };
    dispatch({ type: 'space/updateTemplate', payload: values });
  }
};

// 判断一个节点的属性是否可编辑
export const isPropCanEdit = (
  prop: ApiResponse.CurrentUser.TemplateProp & { disabled?: boolean },
  node: GetterPiNode,
  index = -1,
  org: ApiResponse.CurrentUser.OrgInfo,
  parent: GetterPiNode['value'],
) => {
  // 中间件权限的禁用逻辑
  const nodeAclCanEdit = node.value.nodeManager.isEditor(node.value.prop);
  if (!nodeAclCanEdit) {
    return false;
  }

  // 不可编辑属性类型
  if (
    prop.type === 'quote' ||
    prop.type === 'formula' ||
    prop.type === 'auto_inc'
  ) {
    return false;
  }

  // ?
  if (
    typeof node.value.prop._sys_prop_mn?.[index] === 'number' &&
    node.value.prop._sys_prop_mn?.[index] <= 0
  ) {
    return false;
  }

  // 属性配置禁止编辑
  if (prop.disabled) {
    return false;
  }

  // 子主题继承
  if (Array.isArray(prop.quoteParent)) {
    for (const quoteParent of prop.quoteParent) {
      if (
        quoteParent?.enable &&
        quoteParent?.parentTemp &&
        !quoteParent.allowModify &&
        parent?.tempInfo?.id &&
        quoteParent?.parentTemp === parent?.tempInfo?.id
      ) {
        return false;
      }
    }
  } else if (
    prop.quoteParent?.enable &&
    !!prop.quoteParent?.parentTemp &&
    !prop.quoteParent.allowModify &&
    !!parent?.tempInfo?.id &&
    prop.quoteParent?.parentTemp === parent?.tempInfo?.id
  ) {
    return false;
  }

  // 父主题继承
  const template = org.templateList.find(
    (x) => x.template_id === node.value.tempInfo.id,
  );
  const inherit = template?.childTemp?.inherit;
  if (inherit && inherit.length) {
    /**
     *  属性被设置为父继承子/（任意）
     * 不是p2c 并且 第0位是当前属性Index
     */
    const hasRule = inherit.find((inheritItem) => {
      return (
        inheritItem.enable &&
        inheritItem.propMap.find(
          (x) => x?.[2] && x?.[2] !== 'p2c' && x?.[0] === index,
        )
      );
    });
    if (hasRule) {
      return false;
    }
  }

  // ?
  if (
    Array.isArray(prop.allowManualUpdate) &&
    !prop.allowManualUpdate.includes(node.value.metadata.f)
  ) {
    return false;
  }

  // 可编辑分组
  if (Array.isArray(prop.editGroups) && !prop.editGroups.includes('-1')) {
    if (
      !(
        ~prop.editGroups.indexOf('-2') &&
        (org?.role === 1 || org?.role === 3)
      ) &&
      (!Array.isArray(org?.groupList) ||
        !org.groupList.find((groupId: any) =>
          prop.editGroups.includes(groupId),
        ))
    ) {
      return false;
    }
  }

  return true;
};

// 判断字符串中是否存在中文
export const strExistSpecialCode = (str: string) => !/^[A-Za-z0-9]+$/.test(str);

// 数字属性的提示
export const getNumberOption = (prop: any, tempProp: any) => {
  const number = prop.number || {};
  const { numericalFormat } = number;
  let upperLimit = genNumberLimit(prop, tempProp, 'upper');
  let lowerLimit = genNumberLimit(prop, tempProp, 'lower');

  // 处理倒挂
  if (!isNil(upperLimit) && !isNil(lowerLimit) && lowerLimit > upperLimit) {
    upperLimit = null;
    lowerLimit = null;
  }

  let min = undefined;
  let max = undefined;
  const res: any = {};

  if (typeof upperLimit === 'number') {
    max = numericalFormat ? upperLimit * 100 : upperLimit;
    res.max = max;
  }

  if (typeof lowerLimit === 'number') {
    min = numericalFormat ? lowerLimit * 100 : lowerLimit;
    res.min = min;
  }

  res.placeholder = '请输入数值';
  if (max !== undefined) res.placeholder = `请输入不大于${max}的数值`;
  if (min !== undefined) res.placeholder = `请输入不小于${min}的数值`;
  if (min !== undefined && max !== undefined)
    res.placeholder = `请输入${min}~${max}的数值`;

  return res;
};

// user 可见分组
export const getVisibleUsers = (spaceUsers: any, prop: any) => {
  // 属性可见分组
  if (
    Array.isArray(prop.extend) &&
    prop.extend.length &&
    !prop.extend.includes('-1')
  ) {
    return spaceUsers.filter((u: any) => {
      const group_ids = u.group_ids || [];
      return group_ids.find((id: any) => prop.extend.includes(id));
    });
  }
  return spaceUsers;
};

//
export const parseUserDefault = (
  defaultValue: any,
  prop: any,
  spaceUsers: any,
) => {
  if (!defaultValue) return prop.multiple ? [] : undefined;

  if (prop.multiple) {
    // 过滤掉未知用户
    if (Array.isArray(defaultValue)) {
      return defaultValue.filter((id) =>
        spaceUsers.find((u: any) => u.account_id === id),
      );
    }

    return spaceUsers.find((u: any) => u.account_id === defaultValue)
      ? [defaultValue]
      : [];
  }

  return spaceUsers.find((u: any) => u.account_id === defaultValue)
    ? defaultValue
    : undefined;
};

// quill whiteList
export const genQuillWhiteList = () => {
  const res = [];
  for (let i = 12; i < 65; i++) {
    res.push(i + 'px');
  }
  return res;
};

const filterUnnecessaryWord = (str: string, arr: string[]) => {
  return [...str].filter((s) => !arr.includes(s)).join('');
};

// oss
// 上传一个文件到 oss
export const uploadFileToOss = async (
  file: any,
  spaceKey: string,
): Promise<string | false> => {
  // 1. 转 buffer
  const fileBuffer = await file.arrayBuffer();

  // 2.
  const filePath = `${generateId()}/${filterUnnecessaryWord(file.name, ['(', ')', '（', '）'])}`;

  // 3.
  const fileData = new Uint8Array(fileBuffer);

  // 4. dataStr
  const dataStr = fileData.reduce(
    (res, cur) => res + String.fromCharCode(cur),
    '',
  );

  // 加密
  const fileMd5 = SparkMD5.ArrayBuffer.hash(fileData);

  // 验证是否还有空间可上传
  const res = await checkUpload({
    org_id: spaceKey,
    file_length: Math.ceil(file.size / 1024),
    file_name: file.name,
    md5: fileMd5,
  });

  if (res.status === 'error') return false;

  // 如果上传成功， 直接返回地址
  if (res.status === 'ok' && res.data) return res.data;
  // 上传oss
  // 查看 token
  if (!res.token) return false;

  const Oss = await import('ali-oss').then((r) => r.default);

  const client = Oss({
    secure: true,
    bucket: res.bucket,
    region: res.region,
    accessKeyId: res.token.AccessKeyId,
    accessKeySecret: res.token.AccessKeySecret,
    stsToken: res.token.SecurityToken,
    authorizationV4: true,
  });
  const ossRes = await client.put('upload/' + filePath, file);
  if (!ossRes) return false;

  const storeRes = await request<string>('/api/file/store', {
    method: 'POST',
    data: {
      org_id: spaceKey,
      path: filePath,
    },
  });
  if (storeRes.status === 'error') return false;
  return storeRes.data;
};

// 点击打开文件上传， 执行回调
export const chooseFile = (cb: (e: any) => void) => {
  const input = document.createElement('input');

  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  // input.setAttribute('multiple', 'multiple');
  input.setAttribute('style', 'visibility:hidden;');

  document.body.appendChild(input);
  input.click();

  input.onchange = (e: any) => {
    cb(e);

    // 删除input
    document.body.removeChild(input);
  };
};

// 引用 匹配 需要展示引用主题列表
/*
  1: 匹配属性：展示闪电
  2: 引用原值：展示闪电
  3: 数字类型：下划线
*/
export const getQuoteOrMatchingInfo = (
  prop: ApiResponse.CurrentUser.TemplateProp,
) => {
  const { quoteNodesVisible, matchingType, conditionMatching, type } = prop;
  if (!quoteNodesVisible) return false; // 不展示引用主题列表

  // 匹配属性，显示闪电
  if (conditionMatching && type !== 'quote') return 1;

  // 引用原值
  if (conditionMatching && type === 'quote' && matchingType === 0) return 2;

  // 引用数字类型
  if (conditionMatching && type === 'quote' && matchingType !== 0) return 3;
};

// 可点击选项查看主题
export const getQuoteOptionVisible = (
  prop: ApiResponse.CurrentUser.TemplateProp,
  tempMap: any,
) => {
  const { type, quoteOptionVisible, matchingType, conditionMatching } = prop;

  // 1. 选值属性
  if (type === 'enum' || type === 'tag') {
    const originProp = getQuoteOriginProp({ ...prop, type: 'quote' }, tempMap);
    // 目前只支持 标题 数字 选值 公式 文本
    if (originProp === null) return false;
    if (typeof originProp === 'string') {
      return originProp === '-3';
    }

    if (
      ['number', 'str', 'enum', 'formula', 'text', 'tag'].includes(
        originProp.type,
      )
    ) {
      // 1. 引用原值
      if (conditionMatching && matchingType === 0 && quoteOptionVisible)
        return 1;
      // 2. 获取选值范围
      if (conditionMatching && matchingType === -1 && quoteOptionVisible)
        return 2;
    }
  }

  // 2. 引用原值
  if (
    type === 'quote' &&
    conditionMatching &&
    matchingType === 0 &&
    quoteOptionVisible
  )
    return 3;
  return false;
};

// 从匹配列表中找到对应值的主题
export const filterQuoteNodesForMatching = (
  nodes: PiNode[],
  prop: ApiResponse.CurrentUser.TemplateProp,
  tempMap: any,
  value: any, // 值
) => {
  // 如果是匹配的标题
  const matchingProp = prop.matchingProp || [];
  if (matchingProp[0] === '-3') {
    return nodes.filter((n) => n.title === value);
  }

  // 匹配源的 propIndex
  if (!matchingProp[0]) return [];
  const matchingPropIndex = Number(matchingProp[0].split('-')[1]);

  // 源主题属性的类型，会有不同的处理方式
  const originProp = getQuoteOriginProp({ ...prop, type: 'quote' }, tempMap);
  if (originProp === null || typeof originProp === 'string') return [];

  // 文本 公式 数字 全量匹配
  if (['str', 'text', 'formula', 'number'].includes(originProp.type)) {
    return nodes.filter((n) => n.tempInfo.prop[matchingPropIndex] == value);
  }

  // 选值
  if (['enum', 'tag'].includes(originProp.type)) {
    return nodes.filter((n) => {
      let d = n.tempInfo.prop[matchingPropIndex];
      d = Array.isArray(d) ? d : [d];
      return d.includes(value);
    });
  }
};

// 从引用列表中找到对应值的主题
export const filterQuoteNodesForQuote = (
  nodes: PiNode[],
  prop: ApiResponse.CurrentUser.TemplateProp,
  tempMap: any,
  value: any, // 值
) => {
  //   quote  attachment  cascade

  // 如果是匹配的标题
  const matchingProp = prop.matchingProp || [];
  if (matchingProp[0] === '-3') {
    return nodes.filter((n) => n.title === value);
  }

  // 匹配源的 propIndex
  if (!matchingProp[0]) return [];
  const matchingPropIndex = Number(matchingProp[0].split('-')[1]);

  // 源主题属性的类型，会有不同的处理方式
  const originProp = getQuoteOriginProp(prop, tempMap);
  if (originProp === null || typeof originProp === 'string') return [];

  // 全量匹配的类型
  if (
    [
      'date',
      'datetime',
      'number',
      'text',
      'formula',
      'str',
      'positioning',
      'auto_inc',
      'quote',
    ].includes(originProp.type)
  ) {
    return nodes.filter((n) => n.tempInfo.prop[matchingPropIndex] == value);
  }

  // 包含关系
  if (['enum', 'tag', 'user', 'cascade'].includes(originProp.type)) {
    return nodes.filter((n) => {
      let d = n.tempInfo.prop[matchingPropIndex];
      d = Array.isArray(d) ? d : [d];
      return d.includes(value);
    });
  }

  // 不支持
  if (['attachment', 'quote', 'cascade'].includes(originProp.type)) {
    return [];
  }
};

// 某个主题下允许新增的主题类型
export const getAllowTemplates = (
  orgInfo: ApiResponse.CurrentUser.OrgInfo,
  templateId: string,
  nodeId?: string,
) => {
  // 当前用户的分组
  if (!orgInfo || !Object.keys(orgInfo).length) return [];

  const { role } = orgInfo;

  const groupList = (orgInfo.groupList || []).slice();
  if (role === 1 || role === 3) {
    // 管理员
    groupList.push('-2');
  }

  // 空间主题
  const templateList = orgInfo.templateList;

  // 用户可见的主题
  const userVisibleTemplates = templateList.filter((i) => {
    const { groups, status } = i;
    // 停用的 或者 被删除的主题
    if (status !== 0 && status !== 1) return false;

    // 全部用户分组可见
    if (groups === null || groups.includes('-1')) return true;

    let flag = false;

    // 用户分组可见
    groupList.forEach((group) => {
      if (groups.includes(group)) flag = true;
    });

    return flag;
  });

  // 当前主题允许新增的主题
  const allows = getChildTempAllow({
    tempId: templateId,
    tempList: templateList,
  });

  if (nodeId === orgInfo.rootId) {
    return userVisibleTemplates.map((t) => t.template_id);
  }

  // 允许的还需要过滤用户可见
  return allows.filter((tId) =>
    userVisibleTemplates.find((t) => t.template_id === tId),
  );
};

// delta 转文本
export const convertDeltaToPureText = (delta: any) => {
  if (delta === null) return '';
  let newDelta = delta;
  if ('ops' in newDelta) newDelta = newDelta.ops;
  newDelta = newDelta.filter((item: any) => {
    return !(item.insert === '\n');
  });
  try {
    return newDelta
      .slice()
      .map((op: any) => {
        if (typeof op.insert === 'object' && op.insert !== null) {
          if ('mention' in op.insert) {
            return op.insert.mention.value;
          }
          return '';
        } else {
          return op.insert;
        }
      })
      .join('');
  } catch (e) {
    console.log(e);
    return '';
  }
};

// 从 delta 中拿出 附件
export const getAttachmentFromDelta = (delta: any) => {
  if (delta === null) return [];
  let newDelta = delta;
  if ('ops' in newDelta) newDelta = newDelta.ops;
  try {
    return newDelta
      .slice()
      .map((op: any) => {
        if (
          typeof op.insert === 'object' &&
          op.insert !== null &&
          'image' in op.insert
        )
          return {
            fileName: '未知文件名',
            src: op.insert.image,
          };
        if (
          typeof op.insert === 'object' &&
          op.insert !== null &&
          'fileBlot' in op.insert
        )
          return {
            fileName: op.insert.fileBlot.fileName,
            src: op.insert.fileBlot.href,
          };
        return '';
      })
      .filter((op: any) => op);
  } catch (e) {
    return [];
  }
};

// 得到辅助属性的值
export const getAuxiliaryPropValue = (
  prop: ApiResponse.CurrentUser.TemplateProp,
  template: any,
  node: PiNode,
) => {
  const { defAuxProp } = prop;
  if (!defAuxProp) return false; // 不存在辅助属性

  const { index, position } = defAuxProp;
  if (index === null) return false;

  const auxiliaryPropConfig = template.prop[index];

  return {
    position,
    value: tempValueDisplay({
      propConfig: auxiliaryPropConfig,
      propValue: node.tempInfo.prop[index],
      userMap: {},
      tempMap: {},
      departmentMap: {},
    }),
  };
};

// 自定义按钮是否允许点击
export const isCustomButtonForbiddenClick = (
  btnConfig: CurrentUser.CustomButton,
  node: PiNode,
) => {
  const disableStatus: number[] = btnConfig.disableStatus || [];
  const disableCondition = defaultTo([], btnConfig.disableCondition);
  const enableCondition = defaultTo([], btnConfig.enableCondition);

  const currentUserInfo = getCurrentUserInfo();
  if (btnConfig.disable) {
    const result = filterChecker(enableCondition)(node, {
      roles: currentUserInfo?.group_ids,
    });
    if (result) return false;
    return true;
  }

  if (disableStatus.length === 0 && disableCondition.length === 0) return false;

  if (disableCondition.length) {
    return filterChecker(disableCondition)(node, {
      roles: currentUserInfo?.group_ids,
    });
  }

  // 兼容老的配置
  if (disableStatus.length) return disableStatus.includes(node.tempInfo.status);
};

// 某个节点下允许新增的主题类型
export const getAllowTemplatesAsNodeChilds = (
  node: PiNode,
  templateList: any[],
) => {
  let res: any[] = [];

  // 主题下允许新增的主题类型
  const allows = getChildTempAllow({
    tempId: node.tempInfo.id,
    tempList: templateList,
  });

  res = allows
    .map((t: any) => templateList.find((i) => i.template_id === t))
    .filter((x) => !!x);

  const _sys_child_allow_templates =
    node.prop._sys_child_allow_templates || undefined;

  return res.filter(
    (t) =>
      _sys_child_allow_templates === undefined ||
      _sys_child_allow_templates.includes(t.template_id),
  );
};

const urlToBase64 = (url: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = url;
    let timeout = true;

    setTimeout(() => {
      if (timeout) resolve('超时');
    }, 1500);
    img.onload = () => {
      timeout = false;
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0);
      const data = canvas.toDataURL();
      resolve(data);
    };
  });

const getFormAcode = async (node: PiNode, orgInfo: any) => {
  const payload: any = {
    node_id: node.id,
    org_id: orgInfo.orgId,
    temp_id: node.prop._sys_temp[0],
  };
  const res = await request<any[]>('/docapi/formAcode', {
    method: 'POST',
    data: payload,
  });

  if (res.status === 'ok') {
    const map: any = {};
    const { data = [] } = res;

    const dispatch = async (index: number) => {
      if (!data[index]) return;
      const item = data[index];
      const base64 = await urlToBase64(item.url);
      map[item.form_id] =
        `<img style='height: 100px; width: 100px;' src='${base64}'></img>`;
      await dispatch(index + 1);
    };
    await dispatch(0);
    return map;
  }
  return {};
};

const getNodeCode = async (node: PiNode, orgInfo: any) => {
  const res: any = await request<any[]>('/api/share/token', {
    method: 'POST',
    data: {
      org_id: orgInfo.orgId,
      node_id: node.id,
      acode: true,
    },
  });
  if (res.status === 'ok') {
    const { acode } = res;
    const url = await urlToBase64(acode);
    return `<img style='height: 100px; width: 100px;' src='${url}'></img>`;
  }

  return '获取主题码失败！';
};

const handleVoltRecords = async (node: PiNode, userMap: any, orgInfo: any) => {
  const res = await request<any[]>('/docapi/getNodeStatusLog', {
    method: 'POST',
    data: {
      node_id: node.id,
      org_id: orgInfo.orgId,
      temp_id: node.prop._sys_temp[0],
    },
  });
  if (res.status !== 'ok')
    return {
      ascVoltRecordsHtml: '请求状态日志失败！',
      descVoltRecordsHtml: '请求状态日志失败！',
    };
  const statusLogs: any[] = [];
  // @ts-ignore
  const task_status = node.template.task_status;

  res.data
    .filter((record: any) => 's' in record || 'v' in record)
    .forEach((record: any, rIndex: number) => {
      if (rIndex === res.data.length - 1) return;
      if ('s' in record) {
        const payload: any = {
          status: task_status[record.s].name,
          statusIndex: record.s,
          voltRecords: [],
          ad: record.ad,
          time: record.time,
        };
        if ('s' in res.data[rIndex + 1]) {
          payload.voltRecords = [
            {
              commit: '',
              status: task_status[res.data[rIndex + 1].s].name,
              statusIndex: res.data[rIndex + 1].s,
              ad: res.data[rIndex + 1].ad,
              time: record.time,
            },
          ];
        }
        statusLogs.push(payload);
      }

      if ('v' in record && statusLogs[statusLogs.length - 1]?.voltRecords) {
        statusLogs[statusLogs.length - 1].voltRecords.push({
          commit: record.vc,
          status: task_status[record.v].name,
          statusIndex: record.v,
          ad: record.ad,
          time: record.time,
        });
      }
    });

  const getVoltRecordsHtml = (status_logs: any[]) => {
    let h = '<div style="overflow: hidden">';
    for (let i = 0; i < status_logs.length; i++) {
      const last = i === status_logs.length - 1;
      const nowLog = status_logs[i];

      if (nowLog.voltRecords.length) {
        //   h += `
        //   <div>
        //   审批结果：${preLog.status} -> ${statusLogs[i].status}
        //   </div>
        //  `;

        nowLog.voltRecords.forEach((voltRecord: any, vi: number) => {
          const statusRouteConfig: any =
            getStatusRouteConfig(
              nowLog.statusIndex,
              voltRecord.statusIndex,
              node.template,
            ) || {};
          h += `
          <div>
          ${nowLog.status}
          <span style='padding-left: 4px'>${userMap[voltRecord.ad]?.nick_name}</span>
          <span style='padding-left: 4px'>${dayjs(voltRecord.time).format(
            'YYYY-MM-DD HH:mm',
          )}</span>
          <span style='padding-left: 4px'>${statusRouteConfig.name || ''}</span>
          <span style='padding-left: 4px'>${voltRecord.commit || ''}</span>
          </div>
        `;

          if (vi === nowLog.voltRecords.length - 1 && last) return;
          h += `<div style='height: 1px;width: 100%;border: 1px rgb(36, 45, 63) dashed;transform: scale(2.5, 0.5)'></div>`;
        });

        continue;
      }
    }
    h += '</div>';
    return h;
  };

  return {
    ascVoltRecordsHtml: getVoltRecordsHtml(statusLogs),
    statusLogs: res.data,
    descVoltRecordsHtml: getVoltRecordsHtml(cloneDeep(statusLogs).reverse()),
  };
};
const getStatusFlowPropValue = (
  content: any,
  statusLogs: any,
  node: PiNode,
  userMap: any,
) => {
  const [_, statusIndex, propIndex] = content.value.split('_');
  let v = undefined;
  for (let i = statusLogs.length - 1; i >= 0; i--) {
    if (statusLogs[i].s == statusIndex) {
      v = statusLogs[i];
      break;
    }
  }

  const task_status = node.template?.task_status || [];
  const nodeNowStatus = task_status[node.tempInfo.status];
  const status = task_status[statusIndex]; // 需要展示的状态

  if (nodeNowStatus.sort < status.sort || !v) return '';
  else {
    const propValue = v.p[propIndex];
    if (!propValue) return '';
    if (propIndex == 4) return propValue;
    if (propIndex == 2 || propIndex == 3)
      return propValue ? moment(propValue).format('YYYY年MM月DD日 HH:mm') : '';
    return (Array.isArray(propValue) ? propValue : [propValue])
      .map((i) => userMap[i].nick_name)
      .join('、');
  }
};

// TODO: department
export const exportPDF = async (
  node: PiNode,
  templateList: TemplateInfo[],
  orgInfo: any,
  userMap: any,
  sheetId: string,
) => {
  const departmentMap = getDepartmentMap();
  type FilterNodeConfig = Pick<PDFUnitConfig, 'nodePath' | 'matchings'> & {
    targetTheme: string;
    isRoot: boolean;
    conditions: any[];
  };

  // 先请求节点的会签日志
  // 会签审批意见的处理

  const { ascVoltRecordsHtml, statusLogs, descVoltRecordsHtml } =
    await handleVoltRecords(node, userMap, orgInfo);
  // 拿主题码
  const nodeCodeHtml = await getNodeCode(node, orgInfo);
  // 拿表单码
  const formCodeMap = await getFormAcode(node, orgInfo);

  const getMaxRowAndMaxColumn = (cellsMap: any) => {
    let maxRow = 0;
    let maxColumn = 0;

    Object.keys(cellsMap).forEach((cell) => {
      const _ = cell.split(',');
      const row = Number(_[0]);
      const column = Number(_[1]);
      if (!cellsMap[[row, column].toString()]) return;

      const unit = cellsMap[[row, column].toString()];
      if (!unit.content) return;

      maxRow = Math.max(maxRow, row);
      maxColumn = Math.max(maxColumn, column);
    });

    return { maxRow, maxColumn };
  };

  const getMergedCellsMap = (mergedCells: any) => {
    const mergeMap: Record<
      string,
      {
        rowspan?: number;
        colspan?: number;
        merged?: true;
      }
    > = {};

    mergedCells.forEach((bounds: any) => {
      // rowspan = bottom - top + 1;
      // colspan = right - left + 1;
      const { top, left, bottom, right } = bounds;

      // 左上角的格子需要合并信息
      mergeMap[[top, left].toString()] = {
        rowspan: bottom - top + 1,
        colspan: right - left + 1,
      };

      // 其余格子打上被合并的标记
      for (let i = top; i <= bottom; i++) {
        for (let j = left; j <= right; j++) {
          if (i === top && j === left) continue;
          mergeMap[[i, j].toString()] = { merged: true };
        }
      }
    });
    return mergeMap;
  };

  const getFilterNode = async (
    filterNode: PiNode,
    config: FilterNodeConfig,
  ) => {
    const { nodePath, targetTheme, matchings, isRoot = true } = config;
    let { conditions } = config;

    const tempProps: any[] = [];
    if (Array.isArray(conditions) && conditions.length > 0) {
      // 去除没有给信息的筛选条件
      conditions = conditions.filter((c) => c.key && c.value && c.value.length);
      conditions.forEach((c: any) => {
        const { key, op, value } = c;
        const index = key.split('-')[1];
        tempProps.push(value.map((v: any) => `p${index}-${v}`));
      });
    }

    const groupBy = matchings.map((matching: any) => `p${matching.origin}`);
    const filters: any = {
      groupBy,
      temp_id: targetTheme,
    };
    if (!isRoot) filters.parentId = nodePath;
    if (tempProps.length) filters.tempProps = tempProps;

    return filterNode.nodeManager.getTempFilterNode(filters);
  };

  const loadNodes = async (
    loadNode: PiNode /* 当前节点 */,
    config: FilterNodeConfig,
  ) => {
    const { matchings } = config;
    const groupBy = matchings.map((matching: any) => `p${matching.target}`);

    const filterNode = await getFilterNode(loadNode, config);
    const nodes = filterNode.getMatchNode(loadNode.id, groupBy);

    return nodes;
  };

  const JsBarcode = await import('jsbarcode').then((r) => r.default);

  const parseContent = (
    unit: PDFUnitConfig,
    parseNode: PiNode,
    index: number,
  ) => {
    const { content, grid } = unit || {};
    if (!content) return '';
    if (content.type === 'pureText') return content.value;
    const templateMap = templateList.reduce((res, cur) => {
      res[cur.template_id] = cur;
      return res;
    }, {} as any);

    if (content.value.includes('statusFlow'))
      return getStatusFlowPropValue(content, statusLogs, node, userMap);
    if (content.value === 'volt_records')
      return content.voltRecordsSort === 'desc'
        ? descVoltRecordsHtml
        : ascVoltRecordsHtml;
    if (content.value === 'node_code') return nodeCodeHtml;
    if (content.value[0] === '$')
      return formCodeMap[content.value.slice(1)] || '';

    //序号
    if (content.value === 'index') return index + 1;
    // 节点id
    if (content.value === 'id') return parseNode.prop._sys_node_seq;
    // 主题类型
    if (content.value === 'template_name')
      return templateMap[parseNode.tempInfo.id].name;
    // 标题
    if (content.value === 'title') return parseNode.title || '无标题';
    // 创建人
    if (content.value === 'creator')
      return userMap[parseNode.prop._sys_creator].nick_name;
    // 创建时间
    if (content.value === 'create_time')
      return moment(parseNode.createTime).format('YYYY年MM月DD日');
    // 状态
    if (content.value === 'status') {
      const statusIndex = parseNode.tempInfo.status;
      const statusInfo =
        templateMap[parseNode.tempInfo.id]?.task_status?.[statusIndex] || {};
      return statusInfo.name || '无状态';
    }
    // 负责人
    if (content.value === 'owner') {
      const value = parseNode.tempInfo.statusProp[0];
      return value ? userMap[value].nick_name : '无负责人';
    }
    // 参与者
    if (content.value === 'participants') {
      const value = parseNode.tempInfo.statusProp[1];
      return value && value.length
        ? value.map((userId: string) => userMap[userId].nick_name).join('、')
        : '无参与者';
    }
    // 开始时间
    if (content.value === 'start_time') {
      const value = parseNode.tempInfo.statusProp[2];
      return value ? moment(value).format('YYYY年MM月DD日 HH:mm') : '';
    }
    // 结束时间
    if (content.value === 'end_time') {
      const value = parseNode.tempInfo.statusProp[3];
      return value ? moment(value).format('YYYY年MM月DD日 HH:mm') : '';
    }
    // 备注
    if (content.value === 'commit') {
      const value = parseNode.tempInfo.statusProp[4];
      return value || '无备注';
    }

    const propIndex = Number(content.value.split('_')[1]);
    const template = templateList.find(
      (t) => t.template_id === parseNode.tempInfo.id,
    );

    // 没找到模板
    if (!template) return '';

    const prop = template.prop[propIndex];
    if (!prop) return '';

    const display = tempValueDisplay({
      propConfig: prop,
      propValue: parseNode.tempInfo.prop[propIndex],
      propIndex,
      tempMap: templateMap,
      userMap,
      sysCascade: parseNode.prop._sys_cascade,
      departmentMap,
    });

    // 导出图形码逻辑
    if (content.exportType === 'graphicCode') {
      const canExportGraphicCodeParams = prop.canExportGraphicCodeParams || {
        graphicCodeFormat: 'Code128',
        exportContent: 1, // 单码
      };

      const graphicCodeCanvas = document.createElement('canvas');

      // qrcode
      if (canExportGraphicCodeParams.graphicCodeFormat === '二维码') {
        QRCode.toCanvas(graphicCodeCanvas, display);
        const graphicCodeBase64 = graphicCodeCanvas.toDataURL('image/png');
        return canExportGraphicCodeParams.exportContent
          ? `
        <img style='height: 100px; width: 100px;' src='${graphicCodeBase64}'></img>
        <div style='width: 100%; text-align: center;'>${display}</div>
        `
          : `<img style='height: 100px; width: 100px;' src='${graphicCodeBase64}'></img>`;
      } else {
        if (strExistSpecialCode(String(display))) return '条形码不支持特殊字符';
        // jsbarcode
        JsBarcode(graphicCodeCanvas, String(display), {
          format:
            canExportGraphicCodeParams.graphicCodeFormat?.toUpperCase() ||
            'code128',
          displayValue: !!canExportGraphicCodeParams.exportContent,
        });
        const graphicCodeBase64 = graphicCodeCanvas.toDataURL('image/png');
        return `<img style='height: 50px; width: 200px;' src='${graphicCodeBase64}'></img>`;
      }
    }

    let originProp: any = {};
    if (prop.type === 'quote')
      originProp = getQuoteOriginProp(prop, templateMap) || {};

    if (prop.type === 'attachment' || originProp.type === 'attachment') {
      let attachmentValue = getAttachmentValue(parseNode, propIndex);
      attachmentValue = attachmentValue.filter(
        (i) => getFileTypeIconByUrl(i.src).type === 'image',
      );
      if (attachmentValue.length < 1) return '';

      const attachmentSize = content.attachmentSize || 64;
      let justifyContent = 'flex-start';
      if (grid === 'center') justifyContent = 'center';
      if (grid === 'right') justifyContent = 'flex-end';
      // img html-code
      let imgHtmlCode = `<div style="display: flex;justify-content:${justifyContent}">`;
      attachmentValue.forEach((i) => {
        const style = `
        background-image: url(${i.src});
        background-size: cover;
        background-position: center;
        margin-right: 6px;
        height: ${attachmentSize}px;
        width: ${attachmentSize}px;
        border-radius: 8px;
        `;
        imgHtmlCode += `<div style="${style}"></div>`;
      });
      imgHtmlCode += '</div>';

      return imgHtmlCode;
    }

    return display;
  };

  const getUnitStyle = (unit: any, tdWidth: number) => {
    const baseStyle = [
      'vertical-align: middle',
      'display: table-cell',
      `width: ${tdWidth}%`,
    ];
    const {
      fontSize = 14,
      isBold = false,
      grid = 'left',
      fontColor = 'black',
      backgroundColor = 'white',
      borderColor = 'white',
      borderDirection = {},
    } = unit || {};

    baseStyle.push(`font-size: ${fontSize}px`);
    baseStyle.push(`font-weight: ${isBold ? 'bold' : 400}`);
    baseStyle.push(`color: ${fontColor}`);
    baseStyle.push(`background-color: ${backgroundColor}`);
    baseStyle.push(`text-align: ${grid}`);

    if (borderDirection.left)
      baseStyle.push(`border-left: 1px solid ${borderColor}`);
    if (borderDirection.right)
      baseStyle.push(`border-right: 1px solid ${borderColor}`);
    if (borderDirection.top)
      baseStyle.push(`border-top: 1px solid ${borderColor}`);
    if (borderDirection.bottom)
      baseStyle.push(`border-bottom: 1px solid ${borderColor}`);
    return baseStyle.join(';');
  };

  const getSortInfoByRowIndex = (rowIndex: number, sortMap: any) => {
    let sortInfo: any = null;
    let endRow: number = -1;
    Object.keys(sortMap).forEach((k) => {
      if (!sortMap[k] || sortInfo) return;

      const _k = k.split(',');
      const startRow = Number(_k[0]);

      if (rowIndex === startRow) {
        sortInfo = sortMap[k];
        endRow = Number(_k[1]);
      }
    });
    return { sortInfo, endRow };
  };

  const nodeTemplate = templateList.find(
    (t) => t.template_id === node.tempInfo.id,
  );

  // FIXME zenan
  // @ts-ignore
  const { printSheets } = nodeTemplate?.temp_option || {};

  const sheet = (printSheets || []).find((s: any) => s.id === sheetId);

  if (!sheet) return '';

  const { sheetName, mergedCells, cellsMap, sortMap } = sheet;

  let pdfHtml = '<table cellspacing="0" style="width: 100vw;">';

  const { maxColumn, maxRow } = getMaxRowAndMaxColumn(cellsMap);
  const mergedCellsMap = getMergedCellsMap(mergedCells);
  const trHeight = `44px`;

  const dispatch = async (rowIndex: number) => {
    if (rowIndex > maxRow) return;
    let lineHtml = '';
    // 排序逻辑
    const { sortInfo, endRow } = getSortInfoByRowIndex(rowIndex, sortMap);
    if (sortInfo) {
      // 主题对应行index 的map 后面找起来方便
      const themeToRowIndexMap: any = {};

      // 先找出这几行的 当前主题 渲染，因为存在排序 当前主题插在排序中间肯定是不合理，这其实是一个用户不正确的操作
      for (let startRow = rowIndex; startRow <= endRow; startRow++) {
        let isNowTemplate = true;
        for (let j = 0; j <= maxColumn; j++) {
          const cellConfig = cellsMap[[startRow, j].toString()];
          if (cellConfig && cellConfig.theme !== node.tempInfo.id) {
            isNowTemplate = false;
            themeToRowIndexMap[cellConfig.theme] = startRow;
          }
        }

        // 如果是当前的主题 现在直接处理
        if (isNowTemplate) {
          lineHtml += `<tr style='height:${trHeight}'>`;
          for (let j = 0; j <= maxColumn; j++) {
            const cellConfig = cellsMap[[startRow, j].toString()]; // 当前格子的配置
            const cellMergedConfig =
              mergedCellsMap[[startRow, j].toString()] || {};
            if (cellMergedConfig.merged) continue;
            const cellContent = parseContent(cellConfig, node, -1);
            const tdWidth =
              (100 * (cellMergedConfig.colspan || 1)) / (maxColumn + 1);

            lineHtml += `
              <td style="${getUnitStyle(cellConfig, tdWidth)}" rowspan="${
                cellMergedConfig.rowspan || 1
              }" colspan="${cellMergedConfig.colspan || 1}">${cellContent}</td>
              `;
          }
          lineHtml += '</tr>';
        }
      }

      // 全部的子节点，归纳起来用于排序
      const allChildNodes: any[] = [];

      const dispatchChildNodes = async (row: number) => {
        if (row > endRow) return;
        let otherTemplate: any = false;
        for (let j = 0; j <= maxColumn; j++) {
          const cellConfig = cellsMap[[row, j].toString()];
          if (cellConfig && cellConfig.theme !== node.tempInfo.id)
            otherTemplate = cellConfig;
        }
        if (!otherTemplate) await dispatchChildNodes(row + 1);
        else {
          //求节点
          const childNodes = await loadNodes(node, {
            nodePath: otherTemplate.nodePath,
            matchings: otherTemplate.matchings,
            targetTheme: otherTemplate.theme,
            isRoot: otherTemplate.nodePath === orgInfo.rootId,
            conditions: otherTemplate.conditions || [],
          });

          allChildNodes.push(...childNodes);
          await dispatchChildNodes(row + 1);
        }
      };
      await dispatchChildNodes(rowIndex);

      // 把排序规则和所有节点给后端
      const sortNodes: any[] = sortPiNode(allChildNodes, sortInfo);

      // 开始遍历节点根据节点的主题类型渲染配置
      sortNodes.forEach((sortNode, sortNodeIndex) => {
        lineHtml += `<tr style='height:${trHeight}'>`;
        const nodeTheme = sortNode.tempInfo.id;

        // 这个节点应该渲染第几行的配置
        const row = themeToRowIndexMap[nodeTheme];

        for (let j = 0; j <= maxColumn; j++) {
          const cellConfig = cellsMap[[row, j].toString()];
          // let contentNode = null;
          // if (cellConfig?.theme) {
          //   if (cellConfig.theme !== sortNode.tempInfo.id) {
          //     for (let i = sortNodeIndex - 1; i >= 0; i--) {
          //       if (sortNodes[i].tempInfo.id === cellConfig.theme) {
          //         contentNode = sortNodes[i];
          //       }
          //     }
          //   } else {
          //     contentNode = sortNode;
          //   }
          // }

          // 当前格子的合并信息
          const cellMergedConfig = mergedCellsMap[[row, j].toString()] || {};
          // 被合并的话直接跳过
          if (cellMergedConfig.merged) continue;

          // 格子内容
          const cellContent = parseContent(cellConfig, sortNode, -1);

          if (cellConfig && cellConfig.theme !== node.tempInfo.id) {
            const tdWidth =
              (100 * (cellMergedConfig.colspan || 1)) / (maxColumn + 1);
            lineHtml += `
          <td style="${getUnitStyle(cellConfig, tdWidth)}" rowspan="${
            cellMergedConfig.rowspan || 1
          }" colspan="${cellMergedConfig.colspan || 1}">${cellContent}</td>
          `;
          } else {
            let rowSpan = 1;
            let advance = sortNodeIndex;
            // 需要合并单元格
            if (
              sortNodeIndex === 0 ||
              sortNodes[sortNodeIndex - 1].tempInfo.id !== nodeTheme
            ) {
              while (
                sortNodes[advance + 1] &&
                sortNodes[advance + 1].tempInfo.id === nodeTheme
              ) {
                rowSpan++;
                advance++;
              }
              const tdWidth =
                (100 * (cellMergedConfig.colspan || 1)) / (maxColumn + 1);
              lineHtml += `
          <td style="${getUnitStyle(cellConfig, tdWidth)}" rowspan="${rowSpan}" colspan="${
            cellMergedConfig.colspan || 1
          }">${cellContent}</td>
          `;
            }
          }
        }
        lineHtml += '</tr>';
      });

      pdfHtml += lineHtml;
      await dispatch(endRow + 1);
    }

    if (!sortInfo) {
      let otherTemplateUnit = undefined;

      // 当前行是否存在其他主题类型的格子
      for (let j = 0; j <= maxColumn; j++) {
        const cellConfig = cellsMap[[rowIndex, j].toString()];
        if (cellConfig && cellConfig.theme !== node.tempInfo.id)
          otherTemplateUnit = cellConfig;
      }

      if (otherTemplateUnit) {
        // 存在子主题， 找子节点
        const childNodes = await loadNodes(node, {
          nodePath: otherTemplateUnit.nodePath,
          matchings: otherTemplateUnit.matchings,
          targetTheme: otherTemplateUnit.theme,
          isRoot: otherTemplateUnit.nodePath === orgInfo.rootId,
          conditions: otherTemplateUnit.conditions || [],
        });

        childNodes.forEach((childNode, index) => {
          lineHtml += `<tr style='height:${trHeight}'>`;
          for (let j = 0; j <= maxColumn; j++) {
            const cellConfig = cellsMap[[rowIndex, j].toString()]; // 当前格子的配置
            // 当前格子的合并信息
            const cellMergedConfig =
              mergedCellsMap[[rowIndex, j].toString()] || {};
            // 被合并的话直接跳过
            if (cellMergedConfig.merged) continue;

            // 格子内容
            const cellContent = parseContent(
              cellConfig,
              cellConfig && cellConfig.theme !== node.tempInfo.id
                ? childNode
                : node,
              index,
            );

            if (cellConfig && cellConfig.theme !== node.tempInfo.id) {
              const tdWidth =
                (100 * (cellMergedConfig.colspan || 1)) / (maxColumn + 1);
              lineHtml += `
            <td style="${getUnitStyle(cellConfig, tdWidth)}" rowspan="${
              cellMergedConfig.rowspan || 1
            }" colspan="${cellMergedConfig.colspan || 1}">${cellContent}</td>
            `;
            } else {
              // 需要合并单元格
              if (index < 1) {
                const tdWidth =
                  (100 * (cellMergedConfig.colspan || 1)) / (maxColumn + 1);
                lineHtml += `
            <td style="${getUnitStyle(cellConfig, tdWidth)}" rowspan="${
              childNodes.length
            }" colspan="${cellMergedConfig.colspan || 1}">${cellContent}</td>
            `;
              }
            }
          }
          lineHtml += '</tr>';
        });
      }

      if (!otherTemplateUnit) {
        lineHtml += `<tr style='height:${trHeight}'>`;
        for (let j = 0; j <= maxColumn; j++) {
          // FIXME zenan
          // @ts-ignore
          const cellConfig = cellsMap[[rowIndex, j]]; // 当前格子的配置
          // 当前格子的合并信息
          // FIXME zenan
          // @ts-ignore
          const cellMergedConfig = mergedCellsMap[[rowIndex, j]] || {};
          // 被合并的话直接跳过
          if (cellMergedConfig.merged) continue;
          // 格子内容
          const cellContent = parseContent(cellConfig, node, -1);
          const tdWidth =
            (100 * (cellMergedConfig.colspan || 1)) / (maxColumn + 1);

          lineHtml += `
            <td style="${getUnitStyle(cellConfig, tdWidth)}" rowspan="${
              cellMergedConfig.rowspan || 1
            }" colspan="${cellMergedConfig.colspan || 1}">${cellContent}</td>
            `;
        }
        lineHtml += '</tr>';
      }

      pdfHtml += lineHtml;
      await dispatch(rowIndex + 1);
    }
  };

  await dispatch(0);

  pdfHtml += '</table>';
  return pdfHtml;
};

// 导出pdf 接口
export const genPDF = async (
  template_content: string,
  landscape: boolean,
  file_name: string,
) => {
  const res: any = await request(ENV_CONFIG.EXPORT_PDF, {
    method: 'POST',
    data: {
      template_content,
      landscape,
      file_name,
    },
  });

  if (res.status === 'ok') window.open(res.url, '_blank');
  else message.error('导出pdf失败');
};

export const downLoadFile = (src: string, fileName?: string) => {
  fetch(src)
    .then((res) => res.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      if (fileName) {
        a.setAttribute('download', fileName);
      }
      a.setAttribute('href', url);

      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    });
};

// 返回pdf 导出的默认方向
export const getDefaultExportPDFDirection = (
  node: PiNode,
  templateList: TemplateInfo[],
) => {
  const template = templateList.find((t) => t.template_id === node.tempInfo.id);
  if (!template?.temp_option?.templateExportPDFConfig) return 'vertical';
  return template.temp_option.templateExportPDFConfig.direction || 'vertical';
};

// 时间选择器更改时间获取 value
export const getTimeValue = (
  v: any,
  isMeridiem = false, // 上下午
  onlyTime = false, // 时分类型
  defaultValue: any,
) => {
  if (!v) return null;
  // 时分格式 给后端需要1970年
  if (onlyTime) return (v.hour() * 60 + v.minute()) * 60_000;
  // 上下午
  if (isMeridiem && defaultValue)
    return dayjs(v).hour(dayjs(defaultValue).hour()).minute(0).valueOf();
  return v.valueOf();
};

// 表格批量转换状态的权限控制
export const showTransformStatusBtn = (template: any, userGroupIds: any) => {
  if (!template) return false;
  const { flow_groups } = template; // 限制的分组  -1 全部 -2 管理员

  // 没有任何限制
  if (!flow_groups || flow_groups.length === 0) return false;

  // 全部，所有人都允许
  if (flow_groups.includes('-1')) return true;

  // 用户所在的所有分组都被限制，那么就不显示转换按钮
  const res = (userGroupIds || []).filter((i: any) => flow_groups.includes(i));

  return res.length !== 0;
};

// 表格装修视图的所有子主题
export const getCustomTableChildTemplateIds = (
  customTableId: string,
  template: ApiResponse.CurrentUser.TemplateInfo | null,
) => {
  const { template_id, custom_view } = template || {};
  if (!custom_view) return [];
  const data = custom_view?.data || [];

  // 找装修表格的配置
  const customTable = data.find((d: any) => d.id === customTableId);
  if (!customTable) return [];

  // 子区块
  const { secondaryAreasList } = customTable;

  const res: string[] = [];

  secondaryAreasList.forEach((area) => {
    area.forEach((unit: any) => {
      const { theme } = unit;
      if (theme !== template_id && !res.includes(theme)) res.push(theme);
    });
  });

  return res;
};

// 主题类型可见分组
export const getTemplatesInGroup = (
  user: any,
  templates: any[],
  spaceGroups: any,
) => {
  if (!user) return templates;
  return templates.filter((t) => {
    const { groups } = t;
    if (!groups || spaceGroups.length <= groups.length) return true;
    if (groups.length === 0) return false;

    const { group_ids } = user; // 当前账户在那个分组内

    if (!group_ids || group_ids.length === 0) return false;

    let able = false;

    group_ids.forEach((i: any) => {
      if (groups.includes(i)) able = true;
    });
    return able;
  });
};

// 判断一个主题的状态 是否存在开始结束时间
export const getTemplateStatusInfo = (node: PiNode) => {
  // FIXME zenan
  // @ts-ignore
  const { tempInfo, template } = node;

  if (!template) {
    console.log('node 数据里的template不存在');
    return null;
  }

  const { status: statusIndex } = tempInfo;
  const { task_status } = template;

  const status = task_status[statusIndex];
  if (!status || status.delete) return { delete: true };

  // 状态属性
  const statusProps = status.prop;

  const startTime = statusProps[2];
  const endTime = statusProps[3];

  return {
    delete: false,
    statusStartTime: startTime.display,
    statusEndTime: endTime.display,
  };
};

// 判断一个属性是否显示
export const checkAttrDisplay = (
  node: PiNode,
  propConfig: any,
  propIndex: number,
) => {
  const { prop: nodeProp } = node;

  let { hide } = propConfig;

  const currentUserInfo = getCurrentUserInfo();
  const propVisibleMap = getPropVisibleMap(
    node,
    node.template!,
    currentUserInfo,
  );
  // NOTE: 属性条件的判断
  const groupVisible = checkNodePropVisibleByGroup(propConfig);
  if (groupVisible) {
    const propVisible = propVisibleMap[propIndex];
    if (typeof propVisible !== 'undefined') hide = !propVisible;
  }

  // QUESTION: _sys_prop_visible 会和属性条件冲突么？
  const hideByNodeSysPropVisible =
    nodeProp?._sys_prop_visible?.[propIndex] === false;
  if (hideByNodeSysPropVisible) {
    hide = true;
  }

  return !hide;
};

// 子主题筛选参数转后端需要的 tempProps
export const childConditionTransformToTempProps = (condition: any[]) => {
  const res: any = [];

  condition.forEach((c: any) => {
    const { key, input, index } = c;

    // 主题类型不管
    if (key === 'templateId') return;
    // 没有值
    if (input.length === 0) return;

    // 状态
    if (key === 'status') return res.push(input.map((i: any) => `si-${i}`));

    // 负责人
    if (key === 'statusProp' && index == 0) {
      return res.push(input.map((i: any) => `s0-${i}`));
    }

    // 参与者
    if (key === 'statusProp' && index == 1) {
      return res.push(input.map((i: any) => `s1-${i}`));
    }

    // 普通属性
    if (key === 'prop') {
      return res.push(input.map((i: any) => `p${index}-${i}`));
    }
  });

  return res;
};

// 获取状态的第一个未被删除的状态
export const getTemplateFirstStatus = (
  template: any,
  initialStatus: number,
) => {
  let task_status = (template.task_status || []).map(
    (s: any, index: number) => ({ ...s, index }),
  );
  task_status = task_status.filter((s: any) => !s.delete);

  if (task_status.length < 1) return initialStatus;

  task_status.sort((s1: any, s2: any) => s1.sort - s2.sort);

  const firstStatusIndex = task_status[0].index;

  // 看一下传入的 initialStatus 是否被删除了
  const status = template.task_status[initialStatus];

  if (!status.delete) return initialStatus;

  return firstStatusIndex;
};

export function deprecatedWrapper<T extends (...args: any[]) => any>(cb: T) {
  const deprecatedFn = (...args: Parameters<T>): ReturnType<T> => {
    console.warn('deprecated!!!!!!!!!! 🫵');
    return cb(...args);
  };
  return deprecatedFn;
}

// 根据固定列 调整 显示列的顺序
export const changeAttrsOrder = (
  fixedAttrPool: string[],
  tShowFieldsIndex: any[],
) => {
  // 非固定的属性
  const notFixedAttrs = tShowFieldsIndex.filter(
    (p) => !fixedAttrPool.includes(p.key),
  );

  // 固定的属性
  const fixedAttrs = fixedAttrPool.map((s) =>
    tShowFieldsIndex.find((p) => p.key === s),
  );

  return [...fixedAttrs, ...notFixedAttrs].filter((i) => !!i);
};

type RenameByT<T, U> = {
  [K in keyof U as K extends keyof T
    ? T[K] extends string
      ? T[K]
      : never
    : K]: K extends keyof U ? U[K] : never;
};
export const renameKeys = <
  T extends Record<string, unknown>,
  P extends keyof T,
  U extends Partial<Record<P, string>>,
>(
  keysMap: U,
  obj: T,
) =>
  Object.keys(obj).reduce((acc, key) => {
    if (key in keysMap)
      return {
        ...acc,
        ...{ [keysMap[key as P]!]: obj[key] },
      };
    return {
      ...acc,
      ...{ [key]: obj[key] },
    };
  }, {}) as RenameByT<U, T>;

export const getRealPropConfig = (
  ...args: Parameters<typeof getQuoteOriginPropInfo>
) => {
  const [p] = args;
  if ((p as ApiResponse.CurrentUser.TemplateProp).type !== 'quote') return p;

  return getQuoteOriginPropInfo(...args);
};

export const openNewTab = (options: {
  pathname: string;
  query: ParsedQuery<string>;
}) => {
  if (isHotkeyPressed(genModifyKeys('ctrl'))) {
    /** @ts-ignore */
    history.push({
      pathname: options.pathname,
      search: '?' + qs.stringify(options.query),
    });
    return;
  }

  window.open(
    options.pathname + '?' + qs.stringify(options.query),
    '',
    'noopener',
  );
};

export const canvasToArrayBuffer = (
  canvas: any,
  mimeType = 'image/png',
  quality = 1,
) => {
  return new Promise((resolve, reject) => {
    // Convert the canvas to a data URL
    const dataURL = canvas.toDataURL(mimeType, quality);

    // Convert the data URL to a Blob
    const byteString = atob(dataURL.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeType });

    // Read the Blob as an ArrayBuffer using FileReader
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.error) {
        reject(reader.error);
      } else {
        resolve(reader.result);
      }
    };
    reader.readAsArrayBuffer(blob);
  });
};

export const isPropSupportGraphicCode = (prop: any) => {
  let isPropText = false;
  // 参数没有的默认mock测试
  const canExportGraphicCode =
    prop.canExportGraphicCode === undefined ? false : prop.canExportGraphicCode;
  if (prop.type === 'text') isPropText = true;
  if (prop.type === 'formula' && prop.formulaFormat !== 0) isPropText = true;
  if (['enum', 'tag'].includes(prop.type) && !prop.multiple) isPropText = true;
  return isPropText && canExportGraphicCode;
};

export const getPropsSupportGraphicCode = (template: any) => {
  let customProps = template.prop || [];
  // 1. 处理index
  customProps = customProps.map((p: any, index: number) => ({ ...p, index }));
  // 2. 过滤被删除的属性
  customProps = customProps.filter(
    (p: any) => p.type && isPropSupportGraphicCode(p),
  );
  return customProps;
};

// 导出图形码
export const exportGraphicCodes = async (propValues: any[]) => {
  const { default: JsZip } = await import('jszip');
  const zip = new JsZip();
  const nameCache: any = {};

  const graphicCodeCanvas = document.createElement('canvas');

  const dispatch = async (index: number) => {
    if (propValues.length <= index) return;
    const { value, canExportGraphicCodeParams, nodeTitle } = propValues[index];
    const { graphicCodeFormat, exportContent, title, propValue } =
      canExportGraphicCodeParams;

    if (graphicCodeFormat === '二维码')
      QRCode.toCanvas(graphicCodeCanvas, value);
    else {
      if (!strExistSpecialCode(String(value))) {
        const { default: JsBarcode } = await import('jsbarcode');
        // jsbarcode
        JsBarcode(graphicCodeCanvas, String(value), {
          format: graphicCodeFormat.toUpperCase(),
          displayValue: !!exportContent,
        });
      }
    }
    const arrayBuffer = await canvasToArrayBuffer(graphicCodeCanvas);

    // 处理图形码名字
    let imgName = 'name';

    if (title) imgName = nodeTitle;
    if (propValue) imgName = value;
    if (title && propValue) imgName = `${nodeTitle}-${value}`;

    if (nameCache[imgName]) imgName = imgName + String(index);
    nameCache[imgName] = true;

    imgName = imgName.replaceAll('/', '_');
    // 加入压缩包中
    zip.file(`${imgName}.png`, arrayBuffer as any, { binary: true });
    await dispatch(index + 1);
  };

  await dispatch(0);

  const fileBlob = await zip.generateAsync({ type: 'blob' });
  const url = URL.createObjectURL(fileBlob);
  const a = document.createElement('a');
  a.setAttribute('download', '图形码.zip');
  a.setAttribute('href', url);

  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const getExportGraphicCodesPropValues = (
  nodes: PiNode[],
  checkedProps: any[],
) => {
  const propValues: any[] = [];

  nodes.forEach((node) => {
    checkedProps.forEach((prop) => {
      const canExportGraphicCodeParams = prop.canExportGraphicCodeParams || {
        graphicCodeFormat: 'Code128', // 二维码
        exportContent: 1, // 单码
        title: false,
        propValue: true, // 属性值
      };

      const value = tempValueDisplay({
        propConfig: prop,
        propValue: node.prop._sys_temp[1][prop.index],
        userMap: {},
        tempMap: {},
        departmentMap: {},
      });

      propValues.push({
        canExportGraphicCodeParams,
        value,
        nodeTitle: node.title,
      });
    });
  });
  return propValues;
};
export const isNodeAllowCopy = (orgInfo: any, node: any) => {
  if (!node) return false;
  const rootNode = node.node.nodeManager.getRoot();
  const { _sys_allow_copy, _sys_allow_copy_detail } = rootNode.prop;
  const { _sys_na_banCopy, _sys_banCopy } = node.prop;
  const { role } = orgInfo;
  const isAdmin = [ACT.组织Onwer, ACT.组织编辑].includes(role);

  if (_sys_allow_copy_detail === 'all') {
    // 采用空间配置
    return _sys_allow_copy || isAdmin;
  }

  if (!_sys_banCopy && !_sys_na_banCopy) {
    return _sys_allow_copy || isAdmin;
  }

  // 所有人
  if (_sys_banCopy) return false;
  // 非管理员
  if (_sys_na_banCopy && !isAdmin) return false;

  return true;
};

export const isNodeAllowPaste = (orgInfo: any, node: any) => {
  if (!node) return false;
  const rootNode = node.node.nodeManager.getRoot();
  const { _sys_allow_paste, _sys_allow_paste_detail } = rootNode.prop;
  const { _sys_na_banPaste, _sys_banPaste } = node.prop;
  const { role } = orgInfo;
  const isAdmin = [ACT.组织Onwer, ACT.组织编辑].includes(role);

  if (_sys_allow_paste_detail === 'all') {
    // 采用空间配置
    return _sys_allow_paste || isAdmin;
  }

  if (!_sys_banPaste && !_sys_na_banPaste) {
    return _sys_allow_paste || isAdmin;
  }

  // 所有人
  if (_sys_banPaste) return false;
  // 非管理员
  if (_sys_na_banPaste && !isAdmin) return false;

  return true;
};

// 非管理员禁止删除节点
export const notAdminDelNodeAcl = (orgInfo: any, node: any) => {
  const { role } = orgInfo;
  const isAdmin = [ACT.组织Onwer, ACT.组织编辑].includes(role);
  const { _sys_na_protect } = node.prop;
  if (_sys_na_protect && !isAdmin) return false;
  return true;
};

export const isAdmin = (orgInfo: any) => {
  const { role } = orgInfo;
  return [ACT.组织Onwer, ACT.组织编辑].includes(role);
};

/**
 *
 * toFixed, but without zero :P
 * @param v
 * @param fixedNum
 * @returns
 */
export const toFixed = (v: string | number, fixedNum = 4) => {
  if (!['number', 'string'].includes(typeof v)) return v;

  const r = new Decimal(v).toFixed(fixedNum);
  return Number(r);
};

const EMPTY_OBJ = {} as const;
export function isEmptyObj(v: unknown): v is typeof EMPTY_OBJ {
  return isEmpty(v);
}

export const removeReadonly = <T>(arr: T[] | readonly T[]): T[] => arr as T[];

export const isShowImportMenu = (rootNode: any, orgInfo: any) => {
  if (!rootNode) return false;
  const { role } = orgInfo;
  const { _sys_allow_import } = rootNode.prop;
  const isAdmin = [ACT.组织Onwer, ACT.组织编辑].includes(role);

  if (_sys_allow_import) return true;
  else return isAdmin;
};

export const isTemplateAllowImport = (
  template: any,
  rootNode: any,
  orgInfo: any,
) => {
  if (!rootNode) return true;
  const { role } = orgInfo;
  const { _sys_allow_import, _sys_allow_import_detail } = rootNode.prop;
  const templateImportAcl = template.temp_option?.importAcl;
  const isAdmin = [ACT.组织Onwer, ACT.组织编辑].includes(role);

  if (_sys_allow_import_detail === 'all') {
    // 采用空间配置
    return _sys_allow_import || isAdmin;
  }

  // 读取主题的配置
  if (templateImportAcl)
    return templateImportAcl === 'only_admin' ? isAdmin : true;
  else return _sys_allow_import || isAdmin;
};
export const getUserNickName = (user: OrgUser.OrgUserItem) => {
  return (
    user?.org_nick_name || user?.nick_name || user?.nick_name || '未知用户'
  );
};

const valueInclude = (value: unknown, currentUser: any) => {
  return (
    !propIsNull(value) &&
    (value === currentUser.userid ||
      (Array.isArray(value) && value.includes(currentUser.userid)))
  );
};

// 可转换成员
export const checkTransferable = (
  f: Pick<
    NonNullable<CurrentUser.taskStatus['flowSetting']>[number],
    'transferable'
  >,
  data: PiNode,
  org: CurrentUser.OrgInfo,
  currentUser: CurrentUser,
) => {
  if (!data || !data.template) return false;

  const transferable = f.transferable && [
    ...defaultTo([])(data.template.flow_groups),
    ...f.transferable,
  ];

  if (
    transferable &&
    !transferable.includes('-1') &&
    !(transferable.includes('-2') && (org.role === 1 || org.role === 3)) &&
    !org.groupList?.find((id: any) => f?.transferable?.includes(id))
  ) {
    return (
      (transferable.includes('owner') &&
        valueInclude(data.prop?._sys_task_status?.prop?.[0], currentUser)) ||
      (transferable.includes('participant') &&
        valueInclude(data.prop?._sys_task_status?.prop?.[1], currentUser)) ||
      (transferable.includes('creator') &&
        valueInclude(data.prop?._sys_creator, currentUser)) ||
      (transferable.includes('updater') &&
        valueInclude(data.data?.d, currentUser)) ||
      transferable
        .filter((x) => x.startsWith('prop'))
        .some((x) =>
          valueInclude(
            data.prop?._sys_temp?.[1]?.[Number(x.split('-')?.[1])],
            currentUser,
          ),
        )
    );
  }
  return true;
};

const transformTaskStatus = (task_status: any) => {
  const newTaskStatus = cloneDeep(task_status);
  return (newTaskStatus || []).map((e: any) => {
    return {
      ...e,
      flowSetting: (e.flowSetting || [])
        .map((item: any) => {
          if (item.buttonConditionIndexs) {
            item.buttonConditionIndexs = item.buttonConditionIndexs.filter(
              (index: any) =>
                e.flowSetting.some((setting: any) => setting.index === index),
            );
          }
          return item;
        })
        .filter((item: any) => {
          if (
            item.buttonConditionIndexs &&
            item.buttonConditionIndexs.includes(item.index) &&
            e.flow.includes(item.index)
          ) {
            return true;
          }
          return !e.flowSetting.some(
            (setting: any) =>
              setting.buttonConditionIndexs &&
              setting.buttonConditionIndexs.includes(item.index) &&
              setting.index !== item.index,
          );
        }),
    };
  });
};

export const filterFlowSetting = (
  node: PiNode,
  org: any,
  currentUser: ApiResponse.CurrentUser,
) => {
  const disabledNode = isNodeDisabled(currentUser, node);
  const res: any[] = [];

  // 节点当前状态
  const nodeStatusIndex = node.tempInfo.status;

  // 主题的所有状态 原始数据
  const task_status = node.template?.task_status || [];
  // 转一下的数据
  const transformed_task_status = transformTaskStatus(task_status);

  // 当前状态的flowSetting
  const flowSetting =
    transformed_task_status[nodeStatusIndex]?.flowSetting || [];

  flowSetting.forEach((statusBtn: any) => {
    const { buttonConditionIndexs, index, name } = statusBtn;

    // 原来的数据
    if (!buttonConditionIndexs || buttonConditionIndexs.length < 1) {
      if (
        name &&
        !task_status[index]?.delete &&
        checkTransferable(statusBtn, node, org, currentUser) &&
        !disabledNode
      )
        res.push(statusBtn);
    } else {
      const conditionIndexArr: any[] = [];
      // 目标状态的分支
      const targetStatuses = buttonConditionIndexs.map((i: any) => {
        const v = (task_status[nodeStatusIndex]?.flowSetting || []).find(
          (f) => f.index == i,
        );
        return v;
      });

      targetStatuses.forEach((s: any) => {
        if (!s) return;
        const transferable = checkTransferable(s, node, org, currentUser);
        if (!transferable) return;
        if (!s.condition) return conditionIndexArr.push(s.index);
        if (filterChecker(s.condition)(node)) conditionIndexArr.push(s.index);
      });

      if (conditionIndexArr.length > 0) {
        // 取第一个
        const ableStatusIndex = conditionIndexArr[0];
        if (!task_status[ableStatusIndex]?.delete && !disabledNode)
          res.push({
            ...statusBtn,
            index: ableStatusIndex,
            buttonConditionIndexs: conditionIndexArr,
          });
      }
    }
  });

  return res;
};

export const allowChangeStatus = (
  node: PiNode,
  nowStatusFlowSetting: any[] | undefined,
  targetIndex: number,
) => {
  let allow = false;
  if (!nowStatusFlowSetting || nowStatusFlowSetting.length === 0) return true;

  if (!nowStatusFlowSetting.find((s) => s.index === targetIndex)) return true;

  nowStatusFlowSetting.forEach((statusBtn) => {
    const { index, condition } = statusBtn;
    if (index != targetIndex) return;

    if (!condition || condition.length < 1) {
      allow = true;
    } else {
      if (filterChecker(condition)(node)) allow = true;
    }
  });

  return allow;
};

export const getCountersignStatus = (data: PiNode) => {
  if (
    isNil(data) ||
    isNil(data.tempInfo) ||
    isNil(data.prop._sys_voting_results) ||
    isEmpty(data.prop._sys_voting_results) ||
    isNil(data.template)
  ) {
    return null;
  }
  const currentStatusConfig = data.template.task_status[data.tempInfo.status];
  // 目前仅有一个会签；存在会签时，以会签为主
  const nanimouslyNode = currentStatusConfig?.flowSetting.filter(
    ({ transType }) => transType?.type === 'nanimously',
  )[0];
  const nanimouslyIndex = nanimouslyNode?.index;
  if (typeof nanimouslyIndex !== 'number') {
    return null;
  }

  // const countersignStatusIndex = keys(data.prop._sys_voting_results)[0];
  const statusConfig = data.template.task_status[nanimouslyIndex];

  // const statusResult = values(data.prop._sys_voting_results)[0];
  const statusResult = data.prop._sys_voting_results[nanimouslyIndex];
  const [owner, participant] = data.tempInfo.statusProp;
  const allUsers = uniq([...toArray(owner), ...toArray(participant)]);
  const submitedUsers = intersection(allUsers, keys(statusResult));

  return { allUsers, submitedUsers, statusConfig };
};

export const isNodeDisabled = (currentUser: any, node: PiNode) => {
  const countersignStatus = getCountersignStatus(node);
  return countersignStatus?.statusConfig && currentUser?.userid
    ? countersignStatus.submitedUsers.includes(currentUser.userid)
    : false;
};

export const getCurrentFlowSetting = (
  data: PiNode,
  org: any,
  currentUser: any,
) => {
  if (isNil(data) || isNil(data.template) || !data.template?.task_status) {
    return [];
  }

  const statusIndex = Number(data.tempInfo?.status);
  let flowSetting = genDefaultFlowSetting(
    data.template.task_status,
    statusIndex,
    undefined,
    undefined,
  );
  if (!flowSetting || !flowSetting.length) return [];

  // QUESTION: what's flow?
  // @ts-ignore
  if (!data.template.flow) {
    flowSetting = flowSetting.filter((f) =>
      checkTransferable(f, data, org, currentUser),
    );
  }
  return flowSetting.filter((f) => !isNodeDisabled(currentUser, data));
};

export const checkPropType = curry(
  (
    type: CurrentUser.propType | null | undefined,
    allows: CurrentUser.propType[] | CurrentUser.propType,
  ) => {
    if (!type) return false;

    const list = toArray(allows);
    return list.includes(type);
  },
);

export const getDepartmentMap = () => {
  const { space } = getDvaApp()?._store?.getState() || {};

  const departments = space?.orgStruct || [];

  const orgUserList = getUserList();
  const orgUserMap = toRecord((u: OrgUser.OrgUserItem) => ({
    [u.account_id]: u,
  }))(orgUserList);

  const departmentNodes: ReturnType<typeof getDepartmentNodes> =
    getDepartmentNodes(departments, orgUserMap);

  const departmentNodeMap = toRecord<OrgGroups.OrgDepartmentNodeInfo>((o) => ({
    [o.id]: o,
  }))(departmentNodes);

  return departmentNodeMap;
};

export const getFilterNode = async (
  node: PiNode,
  c: any & { currentUserId: string },
) => {
  const { parentType, conditions, matchings = [], currentUserId } = c;

  let conditionV2 =
    parentType === 'current'
      ? conditions.concat({
          key: 'ancestor',
          op: 'intersect',
          input: [node.id],
        })
      : conditions;
  conditionV2 = convertGetTempFilterNodeConditionV2({
    conditionV2,
    currentUserId,
  });
  const groupBy = matchings.map((matching: any) => `p${matching.origin}`);

  const filters = {
    groupBy,
    conditionV2,
    draft: node.metadata.f === -2,
  };
  console.log(matchings, node, filters, '看这里，帆哥');

  // getTempFilterNode返回 临时过滤器节点
  return node.nodeManager.getTempFilterNode(filters);
};

export const convertGetTempFilterNodeConditionV2Item = <
  T extends WritableDraft<ViewList.ViewconditionV2Item>,
>({
  condition,
  currentUserId,
}: {
  condition: T;
  currentUserId: string;
}) => {
  const currentUserConditionSymbol = null;

  // 替换 当前账户标识
  if (
    condition.key === 'sysCreator' ||
    condition.key === 'templateStatusOwnerAndParticipant' ||
    (condition.extends && condition.extends.type === 'user')
  ) {
    if (condition.input.length) {
      const replaceInput = condition.input.map((x: any) =>
        x === currentUserConditionSymbol ? currentUserId : x,
      );

      condition.input = replaceInput;
    }
  }
};

// 处理getTempFilterNode 接收的 conditionV2 里的特殊数据
export const convertGetTempFilterNodeConditionV2 = (props: {
  conditionV2: ViewList.ViewconditionV2;
  currentUserId: string;
}) => {
  const newConditionV2 = produceConditionV2(props.conditionV2, (draft) => {
    convertGetTempFilterNodeConditionV2Item({
      condition: draft,
      currentUserId: props.currentUserId,
    });

    return true;
  });

  return newConditionV2;
};

export const loadNodes = async (
  node: PiNode /* 当前节点 */,
  c: { currentUserId: string } & Record<string, any>,
) => {
  // matchings 大模型的字段 匹配
  const { matchings = [] } = c;
  // 匹配的 配置
  const groupBy = matchings.map((matching: any) => `p${matching.target}`);

  const filterNode = await getFilterNode(node, c);
  // getMatchNode 遍历树 匹配节点
  const nodes = filterNode.getMatchNode(
    node.id,
    groupBy,
    [],
    node.metadata.f === -2,
  );

  return nodes as PiNode[];
};

export const autoModifyFontSize = (
  text: string,
  size: { width: number; height: number },
) => {
  const NUM = text.length;

  const W = size.width;
  const H = size.height;
  const minFontSize = 12;
  const maxFontSize = 30;
  if (NUM < 10) {
    return maxFontSize;
  }
  // 边界最大最小值， 三个不等式 H - fs <= row * fs <= H    W - fs <= column * fs <= W     row * column >= NUM(当成等于处理)
  // let fontSizeMax = Math.sqrt(W * H / NUM);
  // console.log('Max', fontSizeMax)
  const fontSizeMin =
    (Math.sqrt((W + H) * (W + H) + 4 * W * H * NUM - 4 * W * H) - W - H) /
    (2 * NUM - 2);

  let signState = true;
  let fontSize = fontSizeMin;
  while (signState) {
    if (Math.ceil(NUM / Math.floor(W / fontSize)) * fontSize > H) {
      // 中止
      signState = false;
      break;
    } else {
      // 继续
      signState = true;
    }
    fontSize = fontSize + 1;
  }
  fontSize = fontSize - 1;

  if (fontSize >= maxFontSize) {
    fontSize = maxFontSize;
  }
  if (fontSize <= minFontSize) {
    fontSize = minFontSize;
  }
  return fontSize;
};

const NormalView = [0, 1, 2, 4, 6] as const;
type NormalViewType = (typeof NormalView)[number];
type NotNormalViewType = Exclude<ViewList.ViewType, NormalViewType>;
export function assertNormalView(
  v: ViewList.ViewListItem,
): asserts v is ViewList.ViewListItem<NormalViewType> {
  // @ts-ignore
  if (!NormalView.includes(Number(v.view_type))) {
    throw Error('视图类型不正确');
  }
}

export const getGroupName = (groupByKey: string, orgInfo: any) => {
  let groupName = '';
  if (!groupByKey) return groupName;
  if (groupByKey === 'templateStatus') {
    groupName = '状态';
  } else if (groupByKey === 'sysCreator') {
    groupName = '创建人';
  } else if (groupByKey.startsWith('templateStatusPropBase_')) {
    let [mark, statusPropIndex] = groupByKey.split('_');
    // @ts-ignore
    statusPropIndex = Number(statusPropIndex);
    // @ts-ignore
    groupName = statusPropIndex === 0 ? '负责人' : '参与者';
  } else if (groupByKey.startsWith('templateProp_')) {
    //模板属性
    const [mark, tempId, index] = groupByKey.split('_');
    const propIndex = Number(index);
    const tempData = orgInfo.templateList?.find(
      (x) => x.template_id === tempId,
    );
    if (tempData && Array.isArray(tempData.prop)) {
      groupName = tempData.prop[propIndex]?.name || '';
    }
  } else if (groupByKey.startsWith('templateStatusPropCustom_')) {
    //状态属性
    let [mark, tempId, statusIndex, statusPropIndex] = groupByKey.split('_');
    // @ts-ignore
    statusIndex = Number(statusIndex);
    const propIndex = Number(statusPropIndex);
    const tempData = orgInfo.templateList?.find(
      (x) => x.template_id === tempId,
    );
    if (
      tempData &&
      // @ts-ignore
      tempData.task_status[statusIndex] &&
      // @ts-ignore
      Array.isArray(tempData.task_status[statusIndex].prop)
    ) {
      // @ts-ignore
      groupName = tempData.task_status[statusIndex].prop[propIndex]?.name || '';
    }
  }
  return groupName;
};

export const dashboardCardContentStatNumColors = [
  'linear-gradient(135deg, #7547F3 0%, #AD8BFF 100%)',
  'linear-gradient(135deg, #F8656A 0%, #FE878D 100%)',
  'linear-gradient(135deg, #5065F7 0%, #8198FF 100%)',
  'linear-gradient(135deg, #FF9E37 0%, #F8CC84 100%)',
  'linear-gradient(135deg, #50A6F5 0%, #74DCFF 100%)',
  'linear-gradient(135deg, #17B5B5 0%, #85EFEF 100%)',
  'linear-gradient(135deg, #41C45F 0%, #ACF3A0 100%)',
  'linear-gradient(135deg, #F66C47 0%, #FF9879 100%)',
];

export function lazyImport<
  T extends React.ComponentType<any>,
  I extends { [K2 in K]: T },
  K extends keyof I,
>(factory: () => Promise<I>, name: K): I {
  return Object.create({
    [name]: lazy(() => factory().then((module) => ({ default: module[name] }))),
  });
}

export const getRealPropInfo = (
  propConfig: CurrentUser.TemplateProp,
  tempMap: Record<string, TemplateInfo> = {},
) => {
  if (propConfig?.type === 'quote')
    return getQuoteOriginPropInfo(propConfig, tempMap);
  return propConfig;
};

export const isDateProp = (
  propConfig: CurrentUser.TemplateProp,
  tempMap: Record<string, TemplateInfo> = {},
) => {
  const type = getRealPropInfo(propConfig, tempMap)?.type || undefined;
  return checkPropType(type)(['datetime', 'date']);
};

export const filterUsersByPinyin = (s: string, option: any, userMap: any) => {
  const nickName = option?.children?.toLowerCase() || '';
  const u = userMap[option.children] || {};
  const pin = u.pinyin || [];
  const allPin = (pin[0] || '').toLowerCase();
  const firstPin = (pin[1] || '').toLowerCase();

  const check1 = nickName.indexOf(s.toLowerCase()) >= 0;
  const check2 = allPin.indexOf(s.toLowerCase()) >= 0;
  const check3 = firstPin.indexOf(s.toLowerCase()) >= 0;

  return check1 || check2 || check3;
};

export function concurrentPromiseRunner<T>(
  tasks: (() => Promise<T>)[],
  concurrency: number,
): Promise<T[]> {
  let running = 0; // 正在执行的任务数
  const results: T[] = []; // 存储所有结果的数组
  const executing: Promise<any>[] = []; // 正在执行的Promise数组

  return new Promise((resolve, reject) => {
    const executeTask = () => {
      // 没有任务了，且所有正在执行的任务都完成了
      if (tasks.length === 0 && running === 0) {
        Promise.all(executing)
          .then(() => resolve(results))
          .catch(reject);
        return;
      }

      while (running < concurrency && tasks.length > 0) {
        const task = tasks.shift(); // 获取一个任务
        if (task) {
          const promise = task()
            .then((result) => {
              results.push(result); // 任务完成，存储结果
              running--; // 正在执行任务数减一
              executeTask(); // 尝试执行下一个任务
            })
            .catch(reject); // 如果任务失败，则整个PromiseRunner失败

          executing.push(promise);
          running++; // 正在执行的任务数加一
        }
      }
    };

    executeTask(); // 开始执行任务
  });
}

export const genCondition = (
  condition: any[],
  match: any[],
  table: any,
  temp_id: string,
) => {
  if (!table) return [];
  const res: any[] = [];

  condition.forEach((c: any) => {
    const { op, field, value } = c;
    if (!op || value === undefined) return;
    res.push({
      op,
      key: 'const',
      value,
      col1: table.header_map[field].column,
      field,
    });
  });

  match.forEach((m: any) => {
    const { op, field, left } = m;
    if (!op || !field) return;

    let value: any = '';
    if (left === 'title') value = '-3';
    else if (left === 'creator') value = '-1';
    else value = [Number(left)];

    res.push({
      op,
      key: 'self_match',
      col1: table.header_map[field]?.column,
      value,
      self_temp_id: temp_id,
      field,
    });
  });

  return res;
};

export const parseParamsToConditionsAndMatchings = (params: any) => {
  if (params.conditionV2) {
    return {
      conditions: params.conditionV2.map((condition: any) => ({
        ...condition,
        id: nanoid(),
      })),
      matchings: [],
    };
  }

  if (!params.formula)
    return {
      conditions: [],
      matchings: [],
    };

  const input = params.formula.input || [];
  const data: any[] = input[0]?.condition || [];

  const conditions: any[] = [];
  const matchings: any[] = [];

  data.forEach((i) => {
    const { key, op, field, value } = i;

    if (key === 'const') {
      // condition
      conditions.push({
        op,
        field,
        value,
        id: nanoid(),
      });
    }

    if (key === 'self_match') {
      matchings.push({
        op,
        id: nanoid(),
        left: value === '-3' ? 'title' : value === '-1' ? 'creator' : value,
        field,
      });
    }
  });

  return {
    conditions,
    matchings,
  };
};

// 大模型 子表格 工具栏配置
export const defaultTools = [
  'search',
  'condition',
  'group',
  'sort',
  'displayRow',
  'lineHeight',
  'treeLevel',
  'collapse',
  'import',
  'export',
  'addBtn',
];

// 针对 电瓦特 专门的样式
export const elecwattConfig = {
  tableHeaderBgColor: 'rgba(244,249,255)',
  tableRowHoverBg: '#F5F7FA',
};
