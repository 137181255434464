import { DEFAULT_AVATAR } from '@linkpi/core';

import { getFileTypeIconByUrl } from '@/utils/utils';
import type { PiGanttModules } from '../core';

import type { positionType } from './_baseComponent';
import BaseComponent from './_baseComponent';


class Attachment extends BaseComponent {
  public file: any;

  constructor(position: positionType, moduleInstances: PiGanttModules, file: any) {
    super(position, moduleInstances);
    this.file = file;
  }

  public drawImg(src: string, x: number, y: number) {
    const { Draw, ImageLoader } = this.moduleInstances;

    const img = ImageLoader.loadImage(src || DEFAULT_AVATAR, 'attachment');
    if(!img) return;
    // 图片居中裁剪
    const h = img.height;
    const w = img.width;
    const min = Math.min(h, w);
    const max = Math.max(h, w);
    if (min !== max) {
      Draw.drawRoundImg(
        0,
        x,
        y,
        32,
        32,
        img,
        h >= w ? 0 : (max - min) / 2, // x 裁剪位置
        h >= w ? (max - min) / 2 : 0, // y 裁剪位置
        min,
        min,
      );
    } else Draw.drawRoundImg(0, x, y, 32, 32, img);
  }

  public render() {
    const { x, y } = this.position;

    const s = getFileTypeIconByUrl(this.file.src).src;
    this.drawImg(s, x, y);
  }
}

export default Attachment;
