import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { usePiNode } from '@linkpi/core/web';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  message,
  Modal,
  Popconfirm,
  Row,
} from 'antd';
import cls from 'classnames';
import dayjs from 'dayjs';
import ReactQuill from 'react-quill';
import request from 'umi-request';

import yitigame1 from '@/assets/img/yitigame1.png';
import yitigame2 from '@/assets/img/yitigame2.png';
import yitigame3 from '@/assets/img/yitigame3.png';
import { useOrgConnection } from '@/hook';
import { StatusMenu } from '@/pages/home/components/StatusMenu/StatusMenu';

import { DisplayGroupsProject } from './DisplayGroupsProject';
import { showRejectGameModal } from './RejectGameModal';
import {
  useGameInfoForm,
  YitiGameRegistrationMethod,
  YitiGameStatusMap,
} from './useGameInfoNode';

import styles from './styles.less';
/**
 * 在自定义按钮里 通过modal打开
 */
const YITIGameInfoModal = (props: { nodeId: string }) => {
  const { nodeId } = props;
  const orgConnection = useOrgConnection();
  const [getterNode] = usePiNode(orgConnection, nodeId);
  const modal = useModal();
  const modalProps = antdModalV5(modal);

  const { gameData, statusConfig } = useGameInfoForm(getterNode);
  console.log(gameData);
  const displayDate = (time: number, format: string = 'YYYY/MM/DD') => {
    return time ? dayjs(time).format(format) : '-';
  };

  const handleReject = async () => {
    if (!getterNode) return;

    const reason = await showRejectGameModal();
    if (!reason) return;

    getterNode.value.setAction({
      org_id: getterNode.value.orgId,
      node: [getterNode.value.id],
      temp_id: getterNode.value.tempInfo.id,
      status: YitiGameStatusMap.rejected,
      owner: null,
      user: null,
      startTime: null,
      endTime: null,
      statusCommit: reason,
    });

    message.success('操作成功');
  };

  const handleApproved = async () => {
    if (!getterNode) return;

    getterNode.value.setAction({
      org_id: getterNode.value.orgId,
      node: [getterNode.value.id],
      temp_id: getterNode.value.tempInfo.id,
      status: YitiGameStatusMap.approved,
      owner: null,
      user: null,
      startTime: null,
      endTime: null,
    });

    message.success('操作成功');
  };

  const handleWithdraw = async () => {
    if (!getterNode) return;

    getterNode.value.setAction({
      org_id: getterNode.value.orgId,
      node: [getterNode.value.id],
      temp_id: getterNode.value.tempInfo.id,
      status: YitiGameStatusMap.withdrawn,
      owner: null,
      user: null,
      startTime: null,
      endTime: null,
    });

    message.success('操作成功');
  };

  const handleIsVipChange = async (e: any) => {
    if (!getterNode) return;
    const checked = e.target.checked;
    // 用中间件更新
    getterNode.value.updateProp({
      index: [30],
      value: [checked ? '是' : '否'],
      temp_id: getterNode.value.tempInfo.id,
    });
    message.success('操作成功');
  };

  const renderHandleBtns = () => {
    if (!statusConfig || !getterNode) return null;

    const btns = [];

    // 审核中
    if (getterNode.value.tempInfo.status === YitiGameStatusMap.pendingReview) {
      btns.push(
        ...[
          <Button key="reject" danger onClick={handleReject}>
            不通过
          </Button>,
          <Popconfirm
            key="audit"
            title="确定通过吗？"
            onConfirm={handleApproved}
          >
            <Button type="primary">通过</Button>
          </Popconfirm>,
        ],
      );
    }

    if (gameData.isPublish) {
      btns.push(
        <Popconfirm
          key="withdraw"
          title="确定撤回吗？"
          onConfirm={handleWithdraw}
        >
          <Button danger>撤回</Button>
        </Popconfirm>,
      );
    }

    return btns;
  };

  if (!getterNode) return null;

  return (
    <Modal
      {...modalProps}
      centered
      footer={false}
      title={false}
      width={1024}
      wrapClassName={styles.yitiModal}
    >
      <div className={styles.header}>
        <div className=" flex items-center">
          <div className={styles.title}>赛事详情</div>
          <div className={styles.createTime}>
            发布时间：
            {dayjs(getterNode.value.createTime).format('YYYY/MM/DD HH:mm:ss')}
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <Collapse
          bordered={false}
          defaultActiveKey={['1', '2', '3']}
          expandIconPosition="end"
          className={styles.collapse}
        >
          <Collapse.Panel
            header={
              <div className={styles.collapseHeader}>
                <img src={yitigame1} alt="" />
                基本信息
              </div>
            }
            key="1"
          >
            <Card>
              <Row>
                <Col span={12}>
                  <div className={styles.formLabel}>承办方确认函</div>
                  <DisplayFile files={gameData.confirmationFile} />
                </Col>
              </Row>
            </Card>
            <div className={styles.ptitle}>赛事基本信息</div>
            <Card>
              <Row gutter={[0, 28]}>
                <Col span={8}>
                  <div className={styles.formLabel}>活动类型</div>
                  <div className={styles.formValue}>
                    {gameData.activityType}
                  </div>
                </Col>
                <Col span={8}>
                  <div className={styles.formLabel}>赛事活动名称</div>
                  <div className={styles.formValue}>{gameData.gameName}</div>
                </Col>
                <Col span={8}>
                  <div className={styles.formLabel}>赛事活动时间</div>
                  <div className={styles.formValue}>
                    {displayDate(gameData.gameTime[0])}-
                    {displayDate(gameData.gameTime[1])}
                  </div>
                </Col>
                <Col span={8}>
                  <div className={styles.formLabel}>报名时间</div>
                  <div className={styles.formValue}>
                    {displayDate(gameData.registrationTime[0])}-
                    {displayDate(gameData.registrationTime[1])}
                  </div>
                </Col>
                <Col span={8}>
                  <div className={styles.formLabel}>举办城市</div>
                  <div className={styles.formValue}>{gameData.gameCity}</div>
                </Col>
                <Col span={8}>
                  <div className={styles.formLabel}>举办场地</div>
                  <div className={styles.formValue}>{gameData.gameVenue}</div>
                </Col>
              </Row>
            </Card>
            <div className={styles.ptitle}>主办方信息</div>
            <Card>
              <Row>
                <Col span={8}>
                  <div className={styles.formLabel}>主办单位</div>
                  <div className={styles.formValue}>{gameData.organizer}</div>
                </Col>
                <Col span={8}>
                  <div className={styles.formLabel}>承办单位</div>
                  <div className={styles.formValue}>
                    {gameData.issuingAuthority}
                  </div>
                </Col>
                <Col span={8}>
                  <div className={styles.formLabel}>咨询电话</div>
                  <div className={styles.formValue}>{gameData.phone}</div>
                </Col>
              </Row>
            </Card>
            <div className={styles.ptitle}>展示信息</div>
            <Card>
              <Row>
                <Col span={8}>
                  <div className={styles.formLabel}>赛事活动标签</div>
                  <div className={styles.formValue}>
                    {gameData.gameTags.join('、')}
                  </div>
                </Col>
                <Col span={8}>
                  <div className={styles.formLabel}>封面图片</div>
                  <DisplayImg imgs={gameData.cover} />
                </Col>
              </Row>
            </Card>
            <div className={styles.ptitle}>其他</div>
            <Card>
              <div className={styles.formLabel}>备注</div>
              <div className={styles.formValue}>{gameData.remark}</div>
            </Card>
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <div className={styles.collapseHeader}>
                <img src={yitigame2} alt="" />
                竞赛说明
              </div>
            }
            key="2"
          >
            <Card>
              <div className={styles.formLabel}>赛事活动通知</div>
              <div className={styles.formValue}>
                <ReactQuill
                  value={gameData.gameRules}
                  readOnly
                  modules={{ toolbar: false }}
                />
              </div>
            </Card>
            <Card style={{ margin: '16px 0' }}>
              <Row>
                <Col span={12}>
                  <div className={styles.formLabel}>赛程/流程</div>
                  <div className={styles.formValue}>
                    <ReactQuill
                      value={gameData.gameSchedule}
                      readOnly
                      modules={{ toolbar: false }}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            {gameData.registrationMethod ===
            YitiGameRegistrationMethod.平台报名系统 ? (
              <Card>
                <div className={styles.formLabel}>组别/项目</div>
                <div className={styles.formValue}>
                  <DisplayGroupsProject groupProject={gameData.groupsProject} />
                </div>
              </Card>
            ) : (
              <Card>
                <div className={styles.formLabel}>组别/项目（其他报名）</div>
                <div className={styles.formValue}>
                  <DisplayFile files={gameData.groupsProjectFile} />
                </div>
              </Card>
            )}
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <div className={styles.collapseHeader}>
                <img src={yitigame3} alt="" />
                报名信息
              </div>
            }
            key="3"
          >
            <Card>
              <div className={styles.formLabel}>报名方式</div>
              <div className={styles.formValue}>
                {gameData.registrationMethod}
              </div>
            </Card>
            <div className={styles.ptitle}>{gameData.registrationMethod}</div>

            {gameData.registrationMethod ===
              YitiGameRegistrationMethod.其他报名系统 && (
              <Card>
                <div className={styles.formLabel}>报名链接</div>
                <div className={styles.formValue}>
                  {gameData.registrationLink}
                </div>
              </Card>
            )}
            {gameData.registrationMethod ===
              YitiGameRegistrationMethod.平台报名系统 && (
              <Card>
                <Row>
                  <Col span={8}>
                    <div className={styles.formLabel}>收款二维码</div>
                    <div className={styles.formValue}>
                      <DisplayImg
                        imgs={gameData.paymentQR}
                        imgStyle={{ width: 80, height: 80 }}
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className={styles.formLabel}>收款备注</div>
                    <div className={styles.formValue}>
                      {gameData.paymentRemark}
                    </div>
                  </Col>
                </Row>
              </Card>
            )}
          </Collapse.Panel>
        </Collapse>
      </div>
      <div className={styles.footer}>
        <div className=" flex items-center gap-[30px]">
          <StatusMenu
            btnClassName={styles.noneStatusMenu}
            spaceId={getterNode.value.orgId}
            data={getterNode.value}
            entrance="contentPage"
            trigger={[]}
            statusName={statusConfig?.name}
            type="list"
          />
          <div>
            <Checkbox checked={gameData.isVip} onChange={handleIsVipChange}>
              是否设置为会员优享
            </Checkbox>
          </div>
        </div>
        <div style={{ display: 'flex', gap: 16 }}>{renderHandleBtns()}</div>
      </div>
    </Modal>
  );
};

const YITIGameInfoModalNice = create(YITIGameInfoModal);

export const showYITIGameInfoModal = (props: { nodeId: string }) => {
  show(YITIGameInfoModalNice, props);
};

const DisplayFile = (props: { files: { name: string; src: string }[] }) => {
  const { files } = props;

  if (!files.length) return <div className={styles.displayFile}>无文件</div>;

  return files.map((file, i) => (
    <div className={styles.displayFile} key={i}>
      <div className={styles.bg}></div>
      <i className="iconfont iconAttachment"></i>
      <div className={cls(styles.fileName, 'text-omit')}>{file.name}</div>
      <Button
        type="link"
        className={styles.downloadBtn}
        onClick={() => {
          window.open(file.src);
        }}
      >
        下载
      </Button>
    </div>
  ));
};

const DisplayImg = (props: {
  imgs: { name: string; src: string }[];
  imgStyle?: React.CSSProperties;
}) => {
  const { imgs, imgStyle } = props;

  if (!imgs.length) return <div className={styles.displayImg}>无图片</div>;

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
      {imgs.map((img, i) => (
        <div key={i} className={styles.displayImg} style={imgStyle}>
          <img src={img.src} alt="" />
        </div>
      ))}
    </div>
  );
};
