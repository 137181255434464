import { match, P } from 'ts-pattern';

import { DATA_TABLE_LATEST_VERSION } from './constants';

type ITool = 'add';

interface IConfig {
  tools?: ITool[];
  version: undefined;
  [key: string]: any;
}

export interface ToolConfigV2 {
  type: ITool;
  value?: string;
  show: boolean;
}
interface IConfigV2 extends Omit<IConfig, 'version' | 'tools'> {
  tools?: ToolConfigV2[];
  version: typeof DATA_TABLE_LATEST_VERSION;
}

export const migrateConfigV2 = (config: IConfig | IConfigV2): IConfigV2 =>
  match(config)
    .with({ tools: P.array(P.string) }, (_config) => {
      return {
        ..._config,
        version: DATA_TABLE_LATEST_VERSION,
        tools: _config.tools.map((t) => ({
          type: t,
          value: undefined,
          show: true,
        })),
      };
    })
    .otherwise((_c) => _c as IConfigV2);
