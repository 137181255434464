import type { PiGanttModules } from '../core';
import type { nodeRowData } from '../modules/nodeRowManager';
import type { positionType } from './_baseComponent';
import BaseComponent from './_baseComponent';
import GanttCell from './ganttCell';
import FixedCol from './fixedCol';

export default class NodeRow extends BaseComponent {
  public data: nodeRowData<'node'>;

  constructor(position: positionType, moduleInstances: PiGanttModules, data: nodeRowData<'node'>) {
    super(position, moduleInstances);
    this.data = data;
  }

  public render() {
    const { Draw, Render, Event } = this.moduleInstances;
    Draw.save();

    // cell
    const tempConfig = this.moduleInstances.NodeRowManager.config.tempConfig;
    const { status } = this.data.node.tempInfo;
    const statusConfig = tempConfig && tempConfig.task_status && tempConfig.task_status[status];
    if (statusConfig) {
      const cell = new GanttCell(this.moduleInstances, this.data, statusConfig, this);
      cell.render();
    }

    // bg
    Draw.fillRect(
      0,
      this.position.y + 1,
      Render.leftPanelStyle.width - 1 + Render.getFixedWidth(),
      this.position.height,
      Draw.style.backgroundColor,
    );

    // bottom line
    Draw.line(
      [
        [0, this.position.y + this.position.height],
        [Render.leftPanelStyle.width + Render.getFixedWidth(), this.position.y + this.position.height],
      ],
      Draw.style.borderColor,
    );

    const paddingLeft = 30;
    let paddingRight = 20;
    if (this.isHover) {
      // hover bg
      Draw.fillRect(
        0,
        this.position.y,
        Draw.canvasWidth,
        this.position.height,
        Draw.style.node.hoverBackgroundColor,
      );

      // 预览
      const previewButton = new PreviewButton(
        {
          width: 25,
          height: 25,
          x: Render.leftPanelStyle.width - 25 - paddingRight,
          y: this.position.y + (this.position.height - 25) / 2,
        },
        this.moduleInstances,
        this,
      );
      previewButton.render();

      paddingRight += 35;

      // render drag line
      if (Event.isDragCell) {
        const dragLineY =
          window.__PI__GANTT.mousePosition.y < this.position.y + this.position.height / 2
            ? this.position.y
            : this.position.y + this.position.height;
        Draw.line(
          [
            [0, dragLineY],
            [Draw.canvasWidth, dragLineY],
          ],
          '#316ef5',
        );
      }
    }

    Draw.attr({ fillStyle: Draw.style.node.color });
    Draw.ctx.textBaseline = 'middle';
    Draw.ctx.textAlign = 'left';
    Draw.ctx.font = `400 ${Draw.npx(14)}px  sans-serif`;

    // nodeSeq
    const nodeSeq = this.data.indexInGroup + 1;
    Draw.fillText(String(nodeSeq), paddingLeft, this.position.y + this.position.height / 2);
    // title
    const title = Draw.fittingString(
      this.data.node.title || '无标题',
      Render.leftPanelStyle.width - 62 - paddingLeft - paddingRight,
    );
    Draw.fillText(title, paddingLeft + 62, this.position.y + this.position.height / 2);

    Draw.restore();


    // render 固定列的属性值
    const _fixedCol = Render.config.curViewData.view_info.fixedCol;
    if (!_fixedCol || !_fixedCol.length) return;
    _fixedCol.forEach((f: string, index: number) => {
      const fixedCol = new FixedCol(
        {
          x: Render.leftPanelStyle.width +index*Render.fixedColWidth,
          y: this.position.y,
          width: Render.fixedColWidth,
          height: this.position.height,
        },
        this.moduleInstances,
        { f, node:this.data },
      )
      fixedCol.render()
    })
    
  }
}

class PreviewButton extends BaseComponent {
  private nodeRow: NodeRow;

  constructor(position: positionType, moduleInstances: PiGanttModules, nodeRow: NodeRow) {
    super(position, moduleInstances);
    this.nodeRow = nodeRow;
  }
  public onClick() {
    this.moduleInstances.NodeRowManager.config.onPreview(
      this.nodeRow.data.node.id,
      this.moduleInstances.NodeRowManager.virtualNodeIdList,
    );
  }

  public render() {
    const { Draw, Render } = this.moduleInstances;

    Draw.save();

    let textColor = Draw.style.previewButton.color;
    let fillColor = Draw.style.previewButton.backgroundColor;
    if (this.isHover) {
      Render.setCursor('pointer');
      textColor = Draw.style.previewButton.hoverColor;
      fillColor = Draw.style.previewButton.hoverBackgroundColor;
    }

    Draw.fillRoundRect(this.position, 25 / 2, fillColor);

    Draw.attr({ fillStyle: textColor });
    Draw.ctx.textBaseline = 'middle';
    Draw.ctx.textAlign = 'center';
    Draw.ctx.font = `${Draw.npx(13)}px  iconfont`;
    Draw.fillText(
      Draw.iconFont('&#xe708;'),
      this.position.x + this.position.width / 2,
      this.position.y + this.position.height / 2,
    );

    Draw.restore();
  }
}
