/* eslint-disable react-refresh/only-export-components */
import {
  convertTimeByModule,
  Date_Format_Options,
  displayCascadeValue,
  editNode as updateNode,
  generateAddOpId,
  getDefaultTempProp,
  getEnumOptions,
  setNodeAction,
  tempValueDisplay,
  textSort,
} from '@linkpi/core';
import { useDispatch, useSelector } from '@umijs/max';
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popover,
  Select,
  Spin,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { isNil } from 'ramda';
import { useEffect, useRef, useState } from 'react';

import AddressInput from '@/components/AddressInput';
import CascadeSelect from '@/components/CascadeSelect';
import ChooseTagSelect from '@/components/ChooseTagSelect';
import CustomDate from '@/components/CustomDate';
import LinkPiDate from '@/components/LinkPiDate';
import { DepartmentSelect } from '@/components/LinkPiForm';
import { useOrgUserList, useOrgUserMap } from '@/hook';
import { useOrgDepartmentNodes } from '@/hook/useOrgStructure';
import RepeatTask from '@/pages/home/components/NewNodeProp/RepeatTask';
import SysTag from '@/pages/home/components/NodeProp/SysTag';
import { StatusMenu } from '@/pages/home/components/StatusMenu/StatusMenu';
import request from '@/utils/request';
import {
  getNumberOption,
  getVisibleUsers,
  isPropCanEdit,
  parseUserDefault,
  uniqueArr,
} from '@/utils/utils';

import { addDraftNode } from '../DraftNodeModal';

import styles from './styles.less';

interface IProps {
  cellInfo: any;
  orgId: string;
  setCellInfo: any;
  template: any;
  currentUser: any;
  templateList: any;
  onDraftNodeAdd: (draftsNodeData: unknown) => void;
  orgInfo: any;
  reRender: () => void;
  ignoreComment: boolean;
  isChildTable: boolean;
}

const padding = '0 12px 0 9px';
export default (props: IProps) => {
  const {
    onDraftNodeAdd,
    cellInfo,
    orgId,
    setCellInfo,
    template,
    currentUser,
    templateList,
    orgInfo,
    reRender,
    ignoreComment,
    isChildTable,
  } = props;
  if (!cellInfo) return null;

  const { cellConfig, editNode, position } = cellInfo;

  const fetchEnumOptions = async () => {
    const enumOptions = await getEnumOptions({
      nodeId: editNode.id,
      orgId,
      request,
      template,
    });
    setEnumOptions(enumOptions);
  };

  const isCellCanEdit = (cell: any) => {
    const isTemplateProp = cell.key.slice(0, 4) === 'prop';
    if (isTemplateProp) {
      const prop = cell.config;
      // 附件
      if (prop.type === 'attachment') return false;

      // 属性是否可编辑
      if (
        !isPropCanEdit(
          prop,
          { value: editNode },
          cell.key.split('_')[1],
          orgInfo,
          editNode.parent,
        )
      )
        return false;
    }
    if (cell.key === 'title') {
      return editNode.nodeManager.isEditor(editNode.prop);
    }
    return true;
  };

  // 不可编辑 附件不允许编辑
  if (!isCellCanEdit(cellConfig)) return setCellInfo(null, false);

  const [commentForm] = Form.useForm();
  const dispatch = useDispatch();
  const [enumOptions, setEnumOptions] = useState<any>({});
  const [users, setUsers] = useState<any>(null);
  const [addTags, setAddTags] = useState<any>([]);
  const [saveDisplay, setSaveDisplay] = useState<string[] | string | undefined>(
    undefined,
  );
  const spaceUsers = useOrgUserList();
  const { spaceTags } = useSelector((state: any) => state.space);
  const Option = Select.Option;

  useEffect(() => {
    fetchEnumOptions();
  }, [editNode.id]);

  // 正在修改备注
  const modifyComment = useRef(false);

  // 属性修改备注
  const changeComment = () => {
    modifyComment.current = true;
    return new Promise((rel, rej) => {
      const commentModal = Modal.confirm({
        title: '提交修改',
        okText: '提交',
        content: (
          <Form form={commentForm} layout="vertical" autoComplete="off">
            <Form.Item
              rules={[{ required: true, message: '请输入备注' }]}
              name="comment"
              label="请填写备注，否则修改的属性值将无法保存"
            >
              <Input />
            </Form.Item>
          </Form>
        ),
        onOk: () => {
          commentForm.validateFields().then((value) => {
            commentModal.destroy();
            rel(value.comment);
          });
        },
        onCancel: () => {
          commentModal.destroy();
          rel(null);
        },
      });
    });
  };
  // title
  const renderTitleInput = () => {
    const changeTitle = async (e: any, toNext: boolean) => {
      const v = e.target.value;
      setCellInfo(null, toNext);

      await updateNode(request, {
        org_id: orgId,
        node: {
          node_id: editNode.id,
          title: v,
        },
      });
    };
    return (
      <Input
        style={{ height: '36px', width: '100%' }}
        defaultValue={editNode.title}
        autoFocus
        onPressEnter={(e) => changeTitle(e, true)}
        onBlur={(e) => changeTitle(e, false)}
      />
    );
  };

  // 更改节点的属性值
  const changeNodeProp = async (
    v: any,
    prop: any,
    propIndex: any,
    defaultValue: any,
    isCascade?: boolean,
    isAddress?: boolean,
  ) => {
    setCellInfo(null, !prop.isBlurAction);
    setUsers(null);
    if (isCascade) {
      console.log({ v, prop, propIndex, defaultValue, isCascade, isAddress });
      // TODO 多级选值 回车创建新选项有问题，窗口会直接关闭，导致修改和创建失败
      // 并且 需要处理handleOnChange 这个才是触发选值改变
    }

    if (modifyComment.current) return;
    // 新旧一样
    if (defaultValue === v) return setCellInfo(null, !prop.isBlurAction);
    // 属性必填，值为空
    if (prop.required && !v) return message.error('属性必填，值不能为空！');

    const payload: any = {
      org_id: orgId,
      node_id: editNode.id,
      index: [Number(propIndex)],
      temp_id: editNode.tempInfo.id,
      value: [v],
    };

    // 属性备注
    if (prop.recordModification && prop.type) {
      payload.commit = { [propIndex]: '' };
      // 属性备注必填
      if (prop.requireModificationRemark && !ignoreComment) {
        const res = await changeComment();
        modifyComment.current = false;
        if (!res) {
          setCellInfo(null, !prop.isBlurAction);
          setUsers(null);
          return;
        }
        payload.commit = { [propIndex]: res };
      }
    }

    // 添加display参数
    if (
      (prop.type === 'enum' || prop.type === 'tag') &&
      (prop.matchingLabelColumns?.length || prop.matchingLabelProps?.length) &&
      saveDisplay
    ) {
      payload.display = { [propIndex]: saveDisplay };
    }

    // 多级选值
    if (isCascade) {
      const cascadeTexts = displayCascadeValue({
        value: v,
        cascadeNodes: prop.cascade.nodes,
        multiple: prop.multiple,
        hideRoutes: prop.hideRoutes,
      });
      payload.cascade = {};
      payload.cascade[propIndex] = cascadeTexts.length ? cascadeTexts : null;
    }
    // 地址属性
    if (isAddress) {
      payload.value = [v.prov.concat(v.city, v.dist, v.street, v.add)];
      payload.location = { [propIndex]: [v] };
    }

    try {
      editNode.updateProp(payload); // 表格更改属性 值立即回显
      reRender();
    } catch (error: any) {
      message.error(error.message);
    }
    return;
  };

  // 文本属性
  const renderText = (prop: any, key: string) => {
    const propIndex = Number(key.split('_')[1]);
    const defaultValue = tempValueDisplay({
      propConfig: prop,
      propValue: editNode.tempInfo.prop[propIndex],
      userMap: {},
      tempMap: {},
      departmentMap: {},
    });
    return (
      <Input
        onPressEnter={(e: any) =>
          changeNodeProp(e.target.value, prop, propIndex, defaultValue)
        }
        onBlur={(e: any) =>
          changeNodeProp(
            e.target.value,
            { ...prop, isBlurAction: true },
            propIndex,
            defaultValue,
          )
        }
        style={{ height: '36px', width: '100%' }}
        defaultValue={defaultValue}
        autoFocus
      />
    );
  };

  // number
  const checkLimit = (v: any, option: any) => {
    if (!isNil(option.max)) {
      if (v > option.max) {
        message.error('超出范围');
        return false;
      }
    }

    if (!isNil(option.min)) {
      if (v < option.min) {
        message.error('超出范围');
        return false;
      }
    }
    return true;
  };
  const renderNumber = (prop: any, key: string) => {
    const propIndex = Number(key.split('_')[1]);
    const option = getNumberOption(prop, editNode.tempInfo.prop);
    if (prop.number?.unit) {
      option.addonAfter = <div>{prop.number.unit}</div>;
    }
    let defaultValue = editNode.tempInfo.prop[propIndex];
    if (defaultValue === undefined || defaultValue === null)
      defaultValue = null;
    else
      defaultValue = prop.number?.numericalFormat
        ? defaultValue * 100
        : defaultValue;

    return (
      <InputNumber
        style={{ height: '36px', width: '100%' }}
        {...option}
        defaultValue={defaultValue}
        precision={prop.number?.precision}
        onPressEnter={(e: any) => {
          let v = e.target.value;
          if (!checkLimit(v, option)) return setCellInfo(null);
          v = prop.number?.numericalFormat ? v / 100 : v;
          changeNodeProp(Number(v), prop, propIndex, defaultValue);
        }}
        onBlur={(e: any) => {
          let v = e.target.value;
          if (!checkLimit(v, option)) return setCellInfo(null, false);
          v = prop.number?.numericalFormat ? v / 100 : v;
          changeNodeProp(
            Number(v),
            { ...prop, isBlurAction: true },
            propIndex,
            defaultValue,
          );
        }}
        autoFocus
      />
    );
  };

  // time
  const renderTime = (prop: any, key: string) => {
    const propIndex = Number(key.split('_')[1]);
    const defaultValue = editNode.tempInfo.prop[propIndex];
    const dateModule =
      Date_Format_Options.find((x) => x.value === prop.dateFormat)?.module ||
      (prop.type === 'date' ? 'day' : 'min');
    return (
      <LinkPiDate
        dateFormat={
          prop.dateFormat ||
          (prop.type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm')
        }
        onChange={(e: any) =>
          changeNodeProp(
            convertTimeByModule(e, dateModule),
            prop,
            propIndex,
            defaultValue,
          )
        }
        defaultValue={defaultValue ? dayjs(defaultValue) : null}
        dateValue={defaultValue}
        allowClear={!prop.required}
        open={true}
        autoFocus={true}
        onConfirm={() => setCellInfo(null)}
      />
    );
  };

  // cascade
  const renderCascade = (prop: any, key: string) => {
    const propIndex = Number(key.split('_')[1]);
    const defaultValue = editNode.tempInfo.prop[propIndex];

    return (
      <CascadeSelect
        autoFocus
        prop={prop}
        node={editNode.value}
        defaultValue={defaultValue}
        multiple={prop.multiple}
        changeOnSelect={!!prop.changeOnSelect}
        allowCreate={!!prop.addOnSelect}
        template={template}
        propIndex={propIndex}
        orgId={orgId}
        handleChange={() => {}}
        onPressEnter={(v: any) => {
          changeNodeProp(v, prop, propIndex, defaultValue, true);
        }}
        onBlur={(v: any) => {
          changeNodeProp(
            v,
            { ...prop, isBlurAction: true },
            propIndex,
            defaultValue,
            true,
          );
        }}
      />
    );
  };

  // user
  const renderUser = (prop: any, key: string) => {
    const propIndex = Number(key.split('_')[1]);
    let defaultValue = editNode.tempInfo.prop[propIndex];
    let visibleUsers = getVisibleUsers(spaceUsers, prop); // 可见用户
    defaultValue = parseUserDefault(defaultValue, prop, spaceUsers);
    const option: any = {};
    if (prop.multiple) option.mode = 'multiple';

    visibleUsers = visibleUsers.filter((u: any) => u.role != '8');
    return (
      <Select
        autoFocus
        open
        defaultValue={defaultValue}
        allowClear={!prop.required}
        getPopupContainer={(node) => node.parentNode as HTMLElement}
        {...option}
        style={{ height: '36px', width: '100%' }}
        showArrow={false}
        showSearch={true}
        filterOption={(input: any, option: any) =>
          option?.children?.toLowerCase?.()?.indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e) => {
          if (!prop.multiple) changeNodeProp(e, prop, propIndex, defaultValue);
          else setUsers(e);
        }}
        onInputKeyDown={(e) => {
          const { nativeEvent } = e;
          const { isComposing } = nativeEvent;

          if (e.keyCode === 13 && !isComposing && prop.multiple && users) {
            changeNodeProp(users, prop, propIndex, defaultValue);
          }
        }}
        onBlur={() => {
          if (users && users.length && prop.multiple)
            return changeNodeProp(
              users,
              { ...prop, isBlurAction: true },
              propIndex,
              defaultValue,
            );
          return setCellInfo(null, false);
        }}
      >
        {visibleUsers.map((u: any) => (
          <Option key={u.account_id} value={u.account_id}>
            {u.nick_name}
          </Option>
        ))}
      </Select>
    );
  };

  const changeStatusProp = async (
    v: any,
    statusIndex: number,
    toNext = true,
  ) => {
    setCellInfo(null, toNext);
    setUsers(null);

    const payload: any = {
      org_id: orgId,
      node: [editNode.key],
      temp_id: editNode.tempInfo.id,
    };
    if (statusIndex === 0) payload.owner = v;
    if (statusIndex === 1) payload.user = v;
    if (statusIndex === 4) payload.statusCommit = v;
    await setNodeAction(request, payload);
  };

  // owner
  const renderOwner = (config: any) => {
    if (!config) return null;
    let defaultValue = editNode.tempInfo.statusProp[0];
    let visibleUsers = getVisibleUsers(spaceUsers, config); // 可见用户
    defaultValue = parseUserDefault(defaultValue, config, spaceUsers);
    visibleUsers = visibleUsers.filter((u: any) => u.role != '8');
    return (
      <Select
        autoFocus
        open
        defaultValue={defaultValue}
        getPopupContainer={(node) => node.parentNode as HTMLElement}
        allowClear={!config.required}
        style={{ height: '36px', width: '100%' }}
        showArrow={false}
        showSearch={true}
        filterOption={(input: any, option: any) =>
          option?.children?.toLowerCase?.()?.indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e) => {
          changeStatusProp(e, 0);
        }}
        onBlur={() => setCellInfo(null, false)}
      >
        {visibleUsers.map((u: any) => (
          <Option key={u.account_id} value={u.account_id}>
            {u.nick_name}
          </Option>
        ))}
      </Select>
    );
  };

  // 参与者
  const renderParticipants = (config: any) => {
    if (!config) return null;
    let defaultValue = editNode.tempInfo.statusProp[1];
    let visibleUsers = getVisibleUsers(spaceUsers, config); // 可见用户
    defaultValue = parseUserDefault(defaultValue, config, spaceUsers);
    const option: any = {};
    if (config.multiple) option.mode = 'multiple';

    visibleUsers = visibleUsers.filter((u: any) => u.role != '8');
    return (
      <Select
        autoFocus
        open
        {...option}
        defaultValue={defaultValue}
        getPopupContainer={(node) => node.parentNode as HTMLElement}
        allowClear={!config.required}
        style={{ height: '36px', width: '100%' }}
        showArrow={false}
        showSearch={true}
        filterOption={(input: any, option: any) =>
          option?.children?.toLowerCase?.()?.indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e) => {
          if (!config.multiple) changeStatusProp(e, 1);
          else setUsers(e);
        }}
        onInputKeyDown={(e) => {
          const { nativeEvent } = e;
          const { isComposing } = nativeEvent;

          if (e.keyCode === 13 && !isComposing && users && config.multiple) {
            changeStatusProp(users, 1);
          }
        }}
        onBlur={() => {
          if (users && users.length && config.multiple)
            return changeStatusProp(users, 1, false);
          return setCellInfo(null, false);
        }}
      >
        {visibleUsers.map((u: any) => (
          <Option key={u.account_id} value={u.account_id}>
            {u.nick_name}
          </Option>
        ))}
      </Select>
    );
  };

  const changeTimeRequest = async (
    start: number | null,
    end: number | null,
  ) => {
    setCellInfo(null);

    const payload = {
      org_id: orgId,
      node: [editNode.key],
      temp_id: editNode.tempInfo.id,
      endTime: end,
      startTime: start,
    };

    await setNodeAction(request, payload);
  };

  const cancel = async (i: number, _v: number, old?: number) => {
    if (i === 2) {
      changeTimeRequest(_v, moment(_v).add(1, 'hours').valueOf());
    }

    if (i === 2.5 && typeof old === 'number') {
      changeTimeRequest(_v, old);
    }

    if (i === 3) {
      changeTimeRequest(moment(_v).subtract(1, 'hours').valueOf(), _v);
    }

    message.destroy();
    message.success('已取消');
  };

  const handleDateChange = async (index: number, e: any) => {
    const saveValue = e ? e.valueOf() : null;
    // 开始 结束时间
    let oldStartTime = editNode.tempInfo.statusProp[2];
    let oldEndTime = editNode.tempInfo.statusProp[3];

    if (!saveValue) {
      changeTimeRequest(
        index === 2 ? -1 : oldStartTime,
        index === 3 ? -1 : oldEndTime,
      );
      return;
    }

    oldStartTime = oldStartTime ? moment(oldStartTime).valueOf() : null;
    oldEndTime = oldEndTime ? moment(oldEndTime).valueOf() : null;
    const diff =
      oldStartTime && oldEndTime
        ? moment(oldEndTime).diff(moment(oldStartTime))
        : null;

    // 开始时间
    if (index === 2) {
      // 开始时间晚于上次结束时间
      if (diff && saveValue >= oldEndTime) {
        await changeTimeRequest(
          saveValue,
          moment(saveValue).add(diff).valueOf(),
        );

        // 取消顺延
        message.info(
          <>
            <span>结束时间已自动顺延</span>
            <Button
              type="link"
              onClick={() => cancel(2, saveValue)}
              children="取消本次顺延"
            />
          </>,
        );
      }

      // 开始时间早于上次结束时间，但晚于上次开始时间
      if (diff && saveValue < oldEndTime && saveValue > oldStartTime) {
        await changeTimeRequest(
          saveValue,
          moment(saveValue).add(diff).valueOf(),
        );

        // 取消顺延
        message.info(
          <>
            <span>结束时间已自动顺延</span>
            <Button
              type="link"
              onClick={() => cancel(2.5, saveValue, oldEndTime)}
              children="取消本次顺延"
            />
          </>,
        );
      }

      // 开始时间早于上次设置的时间
      if (diff && saveValue < oldStartTime) {
        await changeTimeRequest(
          saveValue,
          moment(saveValue).add(diff).valueOf(),
        );

        // 取消顺延
        message.info(
          <>
            <span>结束时间已自动提前</span>
            <Button
              type="link"
              onClick={() => cancel(2.5, saveValue, oldEndTime)}
              children="取消本次提前"
            />
          </>,
        );
      }

      if (!oldEndTime) changeTimeRequest(saveValue, null);
    }

    // 结束时间
    if (index === 3) {
      // 结束时间早于开始时间
      if (diff && saveValue <= oldStartTime) {
        await changeTimeRequest(
          moment(saveValue).subtract(diff).valueOf(),
          saveValue,
        );

        // 取消顺延
        message.info(
          <>
            <span>开始时间已自动提前</span>
            <Button
              type="link"
              onClick={() => cancel(3, saveValue)}
              children="取消本次提前"
            />
          </>,
        );
      }

      if (!oldEndTime && saveValue <= oldStartTime) {
        message.warning('结束时间必须大于开始时间');
      }

      if (saveValue > oldStartTime) {
        changeTimeRequest(oldStartTime, saveValue);
      }
    }
  };

  const renderStatusTime = (config: any) => {
    if (!config) return null;
    const statusIndex = Number(cellConfig.key.split('_')[2]);
    const getValue = (time: any) => (time ? moment(time) : null);
    const isDate = config.type === 'date';

    return (
      <CustomDate
        // 定制
        type={statusIndex === 3 ? 'end' : 'start'}
        isDate={isDate}
        // 基础
        allowClear={!config.required}
        getPopupContainer={(node: any) => node.parentNode as HTMLElement}
        inputReadOnly={true}
        open={true}
        showToday={false}
        onChange={(e: any) => handleDateChange(statusIndex, e)}
        autoFocus
        onBlur={() => setCellInfo(null, false)}
        // 交互
        value={getValue(editNode.tempInfo.statusProp[statusIndex])}
      />
    );
  };

  const userMap = useOrgUserMap();
  const departmentNodes = useOrgDepartmentNodes();

  const addQuoteTheme = async (prop: any) => {
    const { conditionProp, quoteProp, matchingTemplate, matchingSource } = prop;
    const id = generateAddOpId();

    // 新节点的属性
    const newProps = getDefaultTempProp(
      {},
      templateList.find((t: any) => t.template_id === matchingTemplate) || null,
      currentUser,
      null,
      userMap,
      departmentNodes,
      {},
    );

    // 条件匹配的值
    (quoteProp || []).forEach((i: string, index: number) => {
      const [_, pIndex] = i.split('-');
      newProps._sys_temp[1][Number(pIndex)] =
        editNode.tempInfo.prop[conditionProp[index]];
    });

    const draftsNodeData = {
      org_id: orgId,
      parentId: matchingSource,
      siblingId: null,
      draft: true,
      node: {
        node_id: id,
        prop: newProps,
        title: '',
      },
    } as const;

    await addDraftNode({
      mode: 'add',
      orgId,
      draftsNodeData,
      initParentId: matchingSource,
    });

    onDraftNodeAdd(draftsNodeData);
  };

  const findMatchingTemplate = (prop: any) =>
    templateList.find((t: any) => t.template_id === prop.matchingTemplate).name;

  const renderSelectBottom = (f: any, prop: any) => {
    if (!f) return null;
    return (
      <div
        style={{
          padding: '8px 12px',
          borderTop: '1px solid #F8F9FB',
          color: '#BFC6D2',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => addQuoteTheme(prop)}
      >
        <i style={{ marginRight: 6 }} className="iconfont iconButton_add" />
        <div style={{ width: 36 }}>新增</div>
        <span
          style={{
            margin: '0 4px',
            backgroundColor: '#F8F9FB',
            padding: 5,
            borderRadius: 8,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {findMatchingTemplate(prop)}
        </span>
      </div>
    );
  };

  // enum
  const renderEnum = (prop: any, key: string) => {
    const { conditionMatching, matchingAdd, extendColorMode, extendColor } =
      prop;
    const propIndex = Number(key.split('_')[1]);
    let defaultValue = editNode.tempInfo.prop[propIndex];
    if (!defaultValue) defaultValue = prop.multiple ? [] : '';
    const option: any = {};
    if (prop.multiple) option.mode = 'multiple';
    let enums = (prop.extend ? [...prop.extend] : []).map((i: any) => ({
      value: i,
      label: i,
    }));
    if (Array.isArray(enumOptions[propIndex])) {
      enumOptions[propIndex].forEach((i: any) => {
        if (!prop.extend?.includes(i.label)) enums.push(i);
      });
    }
    // 去重、排序
    enums = textSort(uniqueArr([...enums]).filter((i) => i));

    if (isChildTable) {
      option.placement = 'topLeft';
    }
    option.notFoundContent = enums.length < 1 && <Spin size="default" />;

    return (
      <div
        onMouseDown={(e) => {
          e.preventDefault();
          return false;
        }}
        style={{ width: '100%' }}
      >
        <Select
          autoFocus
          open
          defaultValue={defaultValue}
          getPopupContainer={(node) => node.parentNode as HTMLElement}
          allowClear={!prop.required}
          style={{ height: 36, width: '100%' }}
          {...option}
          dropdownRender={(_) => (
            <>
              {_}
              {matchingAdd && renderSelectBottom(conditionMatching, prop)}
            </>
          )}
          filterSort={(a, b) => {
            if (!a.value || !b.value) return 1;

            return String(a.value).length - String(b.value).length;
          }}
          dropdownStyle={{ minWidth: 180 }}
          showArrow={false}
          showSearch={true}
          filterOption={(input, option: any) =>
            option.props.searchValue
              ?.toLowerCase?.()
              ?.indexOf(input.toLowerCase()) >= 0
          }
          onChange={(e, options) => {
            // 设置 display 值用于更新
            if (
              prop.matchingLabelColumns?.length ||
              prop.matchingLabelProps?.length
            ) {
              const labels = Array.isArray(options)
                ? options.map((opt) => String(opt.label))
                : String(options?.label);
              setSaveDisplay(labels);
            } else {
              setSaveDisplay(undefined);
            }
            if (!prop.multiple)
              changeNodeProp(e, prop, propIndex, defaultValue);
            else setUsers(e);
          }}
          onInputKeyDown={(e) => {
            const { nativeEvent } = e;
            const { isComposing } = nativeEvent;

            if (e.keyCode === 13 && !isComposing && users && prop.multiple) {
              changeNodeProp(users, prop, propIndex, defaultValue);
            }
          }}
          onBlur={() => {
            if (users && prop.multiple)
              return changeNodeProp(
                users,
                { ...prop, isBlurAction: true },
                propIndex,
                defaultValue,
              );
            return setCellInfo(null, false);
          }}
          tagRender={(tagProps) => {
            return extendColorMode ? (
              <span
                className="mr-1 px-2 text-white rounded-full"
                style={{
                  backgroundColor:
                    extendColor![
                      prop.extend!.findIndex(
                        (e: string) => e === tagProps.value,
                      )
                    ],
                }}
              >
                {tagProps.label}
              </span>
            ) : (
              <span className="mr-1 ">{tagProps.label}</span>
            );
          }}
        >
          {enums.map((i: any, index: number) => (
            <Option
              key={i.value}
              value={i.value}
              searchValue={i.aux ? i.aux.label + i.label : i.label}
            >
              {i.aux ? (
                i.aux.position === 0 ? (
                  <>
                    <p>{i.label}</p>
                    <p style={{ color: 'rgb(185, 187, 188)' }}>{i.aux.label}</p>
                  </>
                ) : (
                  <>
                    <p style={{ color: 'rgb(185, 187, 188)' }}>{i.aux.label}</p>
                    <p>{i.label}</p>
                  </>
                )
              ) : extendColorMode ? (
                <span
                  className="px-2 text-white rounded-full"
                  style={{ backgroundColor: extendColor[index] }}
                >
                  {i.label}
                </span>
              ) : (
                i.label
              )}
            </Option>
          ))}
        </Select>
      </div>
    );
  };

  // comment
  const renderComment = (config: any) => {
    if (!config) return null;
    const defaultValue = editNode.tempInfo.statusProp[4];

    return (
      <Input
        onPressEnter={(e: any) => changeStatusProp(e.target.value, 4)}
        onBlur={(e: any) => changeStatusProp(e.target.value, 4, false)}
        style={{ height: '36px', width: '100%' }}
        defaultValue={defaultValue}
        autoFocus
      />
    );
  };

  // 新增 tag 选项
  const addNewTags = async (tagProp: any, newTags: any, propIndex: number) => {
    if (!tagProp.enumNotSave && newTags.length) {
      return request('/docapi/addTempPropTag', {
        method: 'POST',
        data: {
          org_id: orgId,
          temp_id: editNode.tempInfo.id,
          prop_index: [propIndex],
          tag: [newTags],
        },
      });
    }
  };

  // tag
  const renderTag = (prop: any, key: string) => {
    const propIndex = Number(key.split('_')[1]);
    let defaultValue = editNode.tempInfo.prop[propIndex];
    if (!defaultValue) defaultValue = prop.multiple ? [] : '';
    const option: any = {};
    if (prop.multiple) option.mode = 'multiple';
    let enums = prop.extend ? [...prop.extend] : [];
    if (Array.isArray(enumOptions[propIndex]))
      enums.push(...enumOptions[propIndex].map((i: any) => i.label));

    // 去重、排序
    enums = textSort(uniqueArr([...enums]).filter((i) => i));
    return (
      <ChooseTagSelect
        autoFocus
        data={enums}
        defaultValue={defaultValue}
        allowClear={!prop.required}
        {...option}
        notSave={!!prop.enumNotSave}
        addQuoteTheme={
          prop.matchingAdd
            ? renderSelectBottom(prop.conditionMatching, prop)
            : null
        }
        onChange={async (selects: any, newTags: any) => {
          if (!prop.multiple) {
            await addNewTags(prop, newTags, propIndex);
            changeNodeProp(selects, prop, propIndex, defaultValue);
          } else {
            setUsers(selects);
            setAddTags(newTags);
          }
        }}
        onBlur={async () => {
          if (prop.multiple) {
            await addNewTags(prop, addTags, propIndex);
            changeNodeProp(
              users,
              { ...prop, isBlurAction: true },
              propIndex,
              defaultValue,
            );
            return setAddTags([]);
          }
          return setCellInfo(null, false);
        }}
      />
    );
  };

  // repeatTask
  const renderRepeatTask = () => {
    return (
      <Popover
        open
        placement="bottom"
        getPopupContainer={(node) => node.parentNode as HTMLElement}
        content={
          <RepeatTask
            node={{ value: editNode }}
            orgId={orgId}
            visible
            templateList={templateList}
            onCancel={() => setCellInfo(null)}
            onOk={() => setCellInfo(null)}
          />
        }
        children={<div />}
        trigger={['click']}
        onOpenChange={(v) => {
          if (!v) setCellInfo(null);
        }}
      />
    );
  };

  // sysTag
  const renderSysTag = () => {
    const addSysTag = async (value: string, del = false, isOld = true) => {
      dispatch({
        type: 'space/addSpaceTag',
        payload: {
          org_id: orgId,
          node_id: editNode.id,
          tag: value,
          delete: del,
          isOld: isOld,
        },
      });
      setCellInfo(null);
    };
    const editSysTag = async (newTag: string, oldTag: string) => {
      dispatch({
        type: 'space/modifySpaceTag',
        payload: {
          org_id: orgId,
          tag: oldTag,
          new_tag: newTag,
        },
      });
    };
    const delSysTag = async (value: string) => {
      dispatch({
        type: 'space/delSpaceTag',
        payload: { org_id: orgId, tag: value },
      });
    };
    return (
      <SysTag
        node={{ value: editNode }}
        tagAdd={addSysTag}
        tags={spaceTags[orgId] || []}
        insideDom={<div style={{ height: '100%' }} />}
        tagDel={delSysTag}
        tagEdit={editSysTag}
        placement="bottom"
        defaultVisible
        onCancel={() => setCellInfo(null)}
      />
    );
  };

  const renderStatus = () => {
    return (
      <StatusMenu
        btnClassName={styles.noneStatusMenu}
        spaceId={orgId}
        data={editNode}
        autoOpen={true}
        trigger="click"
        onCancel={() => setCellInfo(null)}
        type="list"
      />
    );
  };

  const renderAddress = (prop: any, key: string) => {
    const propIndex = Number(key.split('_')[1]);
    return (
      <AddressInput
        address={editNode.prop._sys_location?.[propIndex]}
        prop={prop}
        onConfirm={(address: any) => {
          changeNodeProp(address, prop, propIndex, '', false, true);
        }}
        onCancel={() => setCellInfo(null)}
        style={{
          backgroundColor: '#f0f6ff',
          height: '70%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          left: '-8px',
        }}
      />
    );
  };

  const renderDepartment = (prop: any, key: string) => {
    const propIndex = Number(key.split('_')[1]);
    return (
      <DepartmentSelect
        maxTagCount={'responsive'}
        style={{ width: '100%', height: 36 }}
        title={prop.name}
        defaultValue={editNode.tempInfo.prop[propIndex]}
        onChange={(v: any) => {
          changeNodeProp(v, prop, propIndex, editNode.tempInfo.prop[propIndex]);
        }}
        onBlur={() => {
          setCellInfo(null, false);
        }}
        autoFocus
        departmentConfig={prop.departmentConfig}
        mode={prop.multiple ? 'multiple' : undefined}
      />
    );
  };

  const renderForm = () => {
    // 更改标题
    if (cellConfig.key === 'title') return renderTitleInput();

    // 属性
    if (cellConfig.key.slice(0, 4) === 'prop') {
      const prop = cellConfig.config;
      // 1. 文本属性0
      if (prop.type === 'text') return renderText(prop, cellConfig.key);
      // 2. 数字属性0
      if (prop.type === 'number') return renderNumber(prop, cellConfig.key);
      // 3. 时间属性0
      if (prop.type === 'date' || prop.type === 'datetime')
        return renderTime(prop, cellConfig.key);
      // 4. 多级属性0
      if (prop.type === 'cascade') return renderCascade(prop, cellConfig.key);
      // 5. 人员属性0
      if (prop.type === 'user') return renderUser(prop, cellConfig.key);
      // 6. enum0
      if (prop.type === 'enum')
        return renderEnum(
          prop,
          cellConfig.key,
          editNode.tempInfo.prop[cellConfig.key.split('_')[1]],
        );
      // 7. tag0
      if (prop.type === 'tag') return renderTag(prop, cellConfig.key);
      // address
      if (prop.type === 'address') return renderAddress(prop, cellConfig.key);
      // 部门属性
      if (prop.type === 'department')
        return renderDepartment(prop, cellConfig.key);
    }
    // 负责人0
    if (cellConfig.key === 'statusProp_common_0')
      return renderOwner(cellConfig.config(editNode));
    // 参与者0
    if (cellConfig.key === 'statusProp_common_1')
      return renderParticipants(cellConfig.config(editNode));
    // 开始时间、结束时间0
    if (['statusProp_common_2', 'statusProp_common_3'].includes(cellConfig.key))
      return renderStatusTime(cellConfig.config(editNode));
    // 备注0
    if (cellConfig.key === 'statusProp_common_4')
      return renderComment(cellConfig.config(editNode));
    // 重复
    if (cellConfig.key === 'repeatTask') return renderRepeatTask();
    // 系统标签
    if (cellConfig.key === 'sysTag') return renderSysTag();
    // status
    if (cellConfig.key === 'status') return renderStatus();

    return null;
  };

  // log
  // console.log(cellConfig, position);

  // 属性可编辑，渲染表单
  return (
    <div
      style={{
        position: 'absolute',
        padding,
        height: cellConfig.height,
        width: cellConfig.width,
        display: 'flex',
        alignItems: 'center',
        top: position.y < 10 ? 10 : position.y,
        left: position.x < 10 ? 10 : position.x, // scroll
      }}
    >
      {renderForm()}
    </div>
  );
};
