import { isElecwattDev, isElecwattProd } from '@linkpi/elecwatt';

export const ENV_CONFIG = {
  CLOUD_FN_URL: __CLOUD_FN_URL__,
  CLOUD_FN_URL_PROD: 'https://fc-http-g-fc-pyjmofcrlb.cn-hangzhou.fcapp.run',
  CLOUD_FN_URL_DEV: 'https://fc-http-g-fc-qdpkoecdnb.cn-hangzhou.fcapp.run',
  CLOUD_FN_URL_ISO1: 'https://lab.mylinkpi.com/api/mockFc',
  EXPORT_VIEW_NODES_FOR_MP:
    __EXPORT_VIEW_NODES_FOR_MP__ ||
    'https://1199845172035500.cn-hangzhou.fc.aliyuncs.com/2016-08-15/proxy/fc01/exportViewNodesForMP/',
  WX_SET_UNLIMITED:
    __WX_SET_UNLIMITED__ ||
    'https://1199845172035500.cn-hangzhou.fc.aliyuncs.com/2016-08-15/proxy/fc-public/wxSetUnlimited/',
  DING_TALK_WARNING:
    __DING_TALK_WARNING__ ||
    'https://1199845172035500.cn-hangzhou.fc.aliyuncs.com/2016-08-15/proxy/fc-public/dingTalkWarning/',
  EXPORT_PDF:
    __EXPORT_PDF__ ||
    'https://pdf-exporter-fc-public-fqnbrtywli.cn-hangzhou.fcapp.run',
};

export const ORG_ID_WHITELIST = ['4FE935A56A97D32ECA9BB76F31C24F6F'];
export const LINKPI_ORG_ID = [
  isElecwattDev() || isElecwattProd()
    ? 'AEAFED52C0D76BE9369FD617B218B48A'
    : 'B6835ADFF64711EA9F1D7085C2D76860',
];

export const SPACE_PAGE_DEFAULT_TAB_KEY = 'template';

export const DEFAULT_LAYOUT = 'elecwattMenu';

export const DEFAULT_CONTENT_NAVBAR_TYPE = 'tab';
