import type {
  ApiResponse,
  CustomButtonOpenConfig,
  OrgGroups,
  ViewList,
} from '@linkpi/core';
import type { ViewGroupDataItem, ViewGroupDataType } from '@linkpi/core/web';

import type {
  statConfigType,
  statDataType,
} from '@/pages/home/components/View/ListTable/useStat';

import ChildNodesLoader from './modules/childNodesLoader';
import DataManager from './modules/dataManager';
import Draw from './modules/draw';
import Event from './modules/event';
import ImageLoader from './modules/imageLoader';
import Listeners from './modules/listeners';
import Render from './modules/render';
import Scrollbar from './modules/Scrollbar';
import type { GridAttrItem } from './react/useGridAttrs';

export interface CalcProps {
  stat: string[];
  groupKey: string;
  cellType: string;
}

export interface CalcPosition {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface configType {
  container: string;
  groupData: ViewGroupDataType;
  freeze: number;
  tableAttrs: GridAttrItem[];
  nodeHeight: undefined | number;
  checkId: string[];
  mode: 'default' | 'custom';
  templateList: ApiResponse.CurrentUser.TemplateInfo[];

  // TODO @zenan
  // 节点选中事件 返回已选中的节点
  onCheck: (nodeId: string | 'all' | string[], forGroupCheck?: boolean) => void;
  // 点击排序 TODO type
  onSort: (sortInfo: any) => void;
  // 新增
  onAdd: (
    groupItem: ViewGroupDataItem | null,
    groupKey: string,
    prevNodeId: string | null,
  ) => void;
  showCalcPopover: (
    data: CalcProps,
    position: CalcPosition,
    placement: 'top' | 'bottom',
  ) => void;
  onPreviewNode: (id: string, openConfig?: CustomButtonOpenConfig) => void;
  onPreviewNodeForFix: (
    id: string,
    parentNode: PiNode | undefined | false,
  ) => void;
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>;
  departmentMap: Record<string, ApiResponse.OrgGroups.OrgDepartmentNodeInfo>;
  localTableConfig: ApiResponse.ViewList.ViewTableShowFieldItem[];
  showLinkNodes: (
    nodes: PiNode[],
    template: ApiResponse.CurrentUser.TemplateInfo,
  ) => void;
  toNode: (id: string) => void;
  viewTemplate: ApiResponse.CurrentUser.TemplateInfo;
  showQuoteNodes: (propIndex: string | number, node: PiNode) => void;
  showBigImg: (src: string) => void;
  onStatusClick: (node: PiNode, position: any, isButtonStyle: boolean) => void;
  groups: ApiResponse.CurrentUser.orgGroup[];
  contentPageGeneralTableConfig: {
    maxTableHeight?: number;
    tab?: any;
    refresh?: () => void;
    isDrafts: boolean;
    containerBgColor?: string;
  };
  setShowHeaderMoreActions: (header: any) => void;
  tableCellEdit: (cellInfo: any) => void;
  changeTableCellEditPosition: (x: number, y: number) => void;
  setCheckedNodesDetail: (detail: any) => void;
  setCustomTableNodeMap: (map: any) => void;
  handleStatusChange: (node: any, payload: any) => void;
  setShowAttachmentActions: (
    payload: {
      node: PiNode;
      position: any;
      propIndex: number;
      file: any;
      propConfig: any;
    } | null,
  ) => void;
  showUnitMoreDetail: (payload: any) => void;
  setAttrGroupConfig: (payload: {
    node: PiNode;
    unitPosition: any;
    groupProps: any[];
  }) => void;
  setNodeMainbodyAttachments: (
    payload: {
      position: any;
      attachments: Array<{ src: string; fileName: string }>;
    } | null,
  ) => void;
  setNodeMainbodyContent: (
    payload: { position: any; content: string } | null,
  ) => void;
  allowToOpenNode: (data: {
    type: 'quote' | 'matching';
    propIndex: number;
    prop: ApiResponse.CurrentUser.TemplateProp;
    originValue: any;
    node: PiNode;
  }) => void;
  handleViewDoc: (v: any) => void;
  attrContentAlign: any;
  viewInfo: ViewList.ViewInfo; // 视图内容
  saveTableConfig: (v: any, key: any) => void;
  orgInfo: ApiResponse.CurrentUser.OrgInfo;
  setCellInfo: (v: any) => void;
  setGraphicCodeInfo: (v: any) => void;
  userInfo: any;
  forbiddenAddNode: boolean;
  departmentMapRef: {
    current: Record<string, OrgGroups.OrgDepartmentNodeInfo>;
  };
  styleMode: string;
  afterCollapseGroup: (v: boolean) => void;
  storageGroupCollapse: (v: any) => void;
  hiddenFooter?: boolean;
  onNodeDelete: (id: string) => void;
  navMode?: string;
}

export interface PiGridModules {
  Render: Render;
  Listeners: Listeners;
  Draw: Draw;
  DataManager: DataManager;
  VerticalScrollbar: Scrollbar;
  HorizontalScrollbar: Scrollbar;
  Event: Event;
  ImageLoader: ImageLoader;
  ChildNodesLoader: ChildNodesLoader;
  [key: string]: any;
}

export default class Core {
  public config: configType;
  // @ts-ignore
  public moduleInstances: PiGridModules = {};

  constructor(config: configType) {
    this.config = config;

    this.init();
    this.start();

    this.moduleInstances.ImageLoader.bootstrap();
  }

  private init() {
    this.instantiationModules();
    this.injectModules();
  }

  /**
   * 初始化所有模块
   */
  private instantiationModules() {
    this.moduleInstances.Render = new Render(this);
    this.moduleInstances.Listeners = new Listeners(this);
    this.moduleInstances.Draw = new Draw(this);
    this.moduleInstances.VerticalScrollbar = new Scrollbar(this, 'vertical');
    this.moduleInstances.HorizontalScrollbar = new Scrollbar(
      this,
      'horizontal',
    );
    this.moduleInstances.DataManager = new DataManager(this);
    this.moduleInstances.Event = new Event(this);
    this.moduleInstances.ImageLoader = new ImageLoader(this);
    this.moduleInstances.ChildNodesLoader = new ChildNodesLoader(this);
  }
  /**
   * 注入moduleInstances到所有模块
   */
  private injectModules() {
    for (const name in this.moduleInstances) {
      if (this.moduleInstances[name].inject) {
        this.moduleInstances[name].inject(this.moduleInstances);
      }
    }
  }

  private start() {
    Object.keys(this.moduleInstances).map((moduleKey) => {
      if (this.moduleInstances[moduleKey].prepare) {
        this.moduleInstances[moduleKey].prepare();
      }
    });
  }

  public destroy() {
    Object.values(this.moduleInstances).forEach((moduleInstance) => {
      if (moduleInstance.destroy) {
        moduleInstance.destroy();
      }
    });
  }

  /**
   * handle
   */
  public onGroupDataChange(
    groupData: ViewGroupDataType,
    viewTemplate: ApiResponse.CurrentUser.TemplateInfo,
    viewInfo: ApiResponse.ViewList.ViewInfo,
  ) {
    this.config.groupData = groupData;
    this.config.mode = viewInfo.tableStyle === 'custom' ? 'custom' : 'default';

    this.config.viewTemplate = viewTemplate;
    this.moduleInstances.DataManager.updateData();
  }

  public onTableAttrsChange(tableAttrs: GridAttrItem[]) {
    this.config.tableAttrs = tableAttrs;
    this.moduleInstances.DataManager.updateTableAttrs();
  }

  public onNodeHeightChange(nodeHeight: number) {
    this.config.nodeHeight = nodeHeight;
    this.moduleInstances.DataManager.updateNodeHeight();
  }

  public onCollapse(collapse: boolean) {
    this.moduleInstances.DataManager.updateCollapse(collapse);
  }

  public onCheckIdChange(checkId: string[]) {
    this.config.checkId = checkId;
    this.moduleInstances.DataManager.updateCheckId();
  }

  public getNodeIds() {
    return this.moduleInstances.DataManager.nodeIds;
  }

  public getChildIdMap() {
    return this.moduleInstances.DataManager.childIdMap;
  }

  public onStatDataChange(statData: statDataType, statConfig: statConfigType) {
    this.moduleInstances.DataManager.updateStatData(statData, statConfig);
  }

  public changeSelectNodeAttr(direction: 'up' | 'left' | 'down' | 'right') {
    this.moduleInstances.DataManager.changeSelectNodeAttr(direction);
  }

  public edittingProp() {
    this.moduleInstances.DataManager.edittingProp();
  }

  public onLocalTableConfigChange(
    localTableConfig: ApiResponse.ViewList.ViewTableShowFieldItem[],
  ) {
    this.config.localTableConfig = localTableConfig;
    this.moduleInstances.DataManager.updateLocalTableConfig();
  }

  public onAttrContentAlignChange(attrContentAlign: any) {
    this.moduleInstances.DataManager.config.attrContentAlign = attrContentAlign;
    this.moduleInstances.Render.render();
  }

  public updateViewInfo(info: any) {
    if (!info.showLevelInFirstCol) {
      this.moduleInstances.DataManager.hideNodesMap = {};
      this.moduleInstances.DataManager.foldNodesPool = [];
    }
    this.moduleInstances.DataManager.config.viewInfo = info;
    if (this.moduleInstances.DataManager.freshHeaderCellWidthMap) {
      this.moduleInstances.DataManager.headerCellWidthMap = info.attrsWidth
        ? { ...info.attrsWidth }
        : {};
      this.moduleInstances.DataManager.freshHeaderCellWidthMap = false;
    }

    const fixedAttrPool = info.fixedAttrPool || ['order'];

    const visibles = fixedAttrPool.filter((key: string) => {
      const tShowFieldsIndex = info.tShowFieldsIndex || [
        { key: 'order', disable: false },
      ];
      const attr = tShowFieldsIndex.find((p: any) => p.key == key) || {
        disable: true,
      };
      return !attr.disable;
    });

    this.config.freeze = visibles.length;
    this.moduleInstances.Render.render();
  }

  public updateStyleMode(styleMode: any) {
    this.moduleInstances.DataManager.config.styleMode = styleMode;
    this.moduleInstances.Render.render();
  }

  public refreshChildNodes() {
    this.moduleInstances.DataManager.refreshChildNodes();
  }

  public onGroupsChange(groups: ApiResponse.CurrentUser.orgGroup[]) {
    this.moduleInstances.DataManager.updateGroups(groups);
  }

  public splitPaneChange() {
    this.moduleInstances.Render.render();
  }

  public handleFooterHidden(hidden: boolean) {
    this.config.hiddenFooter = hidden;
    this.moduleInstances.Render.render();
  }
}
