import { assertExists } from '@linkpi/utils';
import { useMountEffect, useUnmountEffect } from '@react-hookz/web';
import { useDispatch, useSelector } from '@umijs/max';
import { useMemoizedFn } from 'ahooks';
import { atom, getDefaultStore, useAtomValue } from 'jotai';
import { Building2, Moon, Sun, Tags } from 'lucide-react';
import { last } from 'ramda';
import type { ReactNode } from 'react';
import {
  cloneElement,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { match } from 'ts-pattern';

import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { useCurrentSelection, useDev, useOrgList } from '@/hook';
import type { DARK_MODE } from '@/pages/home/components/LayoutModal';
import { genModifyKeys } from '@/utils/hotKeys';

import {
  ENV_ICON_MAP,
  ENV_MAP_OPTIONS,
  switchEnv,
  useEnvSelectVisible,
} from '../EnvSelect';

const useDarkMode = () => {
  const { isDark } = useSelector(
    (state: any) => state.user.currentUser,
  ) as DARK_MODE;
  const dispatch = useDispatch();

  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove('light', 'dark');

    root.classList.add(isDark ? 'dark' : 'light');
  }, [isDark]);

  const toggle = () => {
    dispatch({
      type: 'user/updateLayoutProperty',
      payload: { key: 'isDark', value: !isDark },
    });
  };
  return { isDark, toggle };
};
export type CustomCommandType = {
  id: string;
  content: ReactNode;
  value: string;
  /**
   * 返回 true 时，自动关闭
   */
  onSelect?: () => void | true;
  type?: 'menu';
  options?: { label: string; value: string }[];
  onOptionSelect?: (value: string) => void | true;
};

const customCommandListAtom = atom<CustomCommandType[]>([]);

export const registerCustomCommandList = (customCommand: CustomCommandType) => {
  const globalStore = getDefaultStore();

  globalStore.set(customCommandListAtom, (list) => {
    if (list.find((i) => i.id === customCommand.id)) {
      return list.map((i) => (i.id === customCommand.id ? customCommand : i));
    }
    return [...list, customCommand];
  });
};

/**
 * 注册自定义的命令
 */
export const useCustomCommandListRegister = (
  customCommand: CustomCommandType,
) => {
  const globalStore = getDefaultStore();

  useMountEffect(() => {
    globalStore.set(customCommandListAtom, (list) => {
      if (list.find((i) => i.id === customCommand.id)) {
        return list;
      }
      return [...list, customCommand];
    });
  });

  useUnmountEffect(() => {
    globalStore.set(customCommandListAtom, (list) => {
      return list.filter((i) => i.id !== customCommand.id);
    });
  });
};

const DevGlobalCommandPalette = memo(() => {
  const [pages, setPages] = useState<string[]>([]);
  const page = last(pages);

  const [open, setOpen] = useState<boolean>(false);

  /**
   * 关闭时，回到首页
   */
  useEffect(() => {
    if (!open) {
      setPages([]);
    }
  }, [open]);

  useHotkeys(
    genModifyKeys('ctrl+k'),
    () => {
      setOpen(true);
    },
    { preventDefault: true },
  );

  const { toggle, isDark } = useDarkMode();

  const orgList = useOrgList();

  const orgItems = useMemo(() => {
    return orgList.map((orgInfo) => ({
      id: orgInfo.orgId,
      label: orgInfo.orgName,
      rootId: orgInfo.rootId,
    }));
  }, [orgList]);

  const currentSelection = useCurrentSelection();
  const dispatch = useDispatch();

  const switchOrg = useMemoizedFn((orgInfo: (typeof orgItems)[number]) => {
    if (currentSelection.selectSpace == orgInfo.id) return;

    const firstPath = location.pathname.split('/')?.[1];
    match(firstPath)
      .with('home', () => {
        dispatch({
          type: 'workspace/setCurrentSelection',
          payload: {
            selectSpace: orgInfo.id,
            selectFocus: orgInfo.rootId,
            selectNode: orgInfo.rootId,
          },
        });
        setOpen(false);
        setPages([]);
      })
      .with('space', () => {
        window.location.href =
          '/space?functionTab=automation&spaceId=' + orgInfo.id;
      })
      .run();
  });
  const searchRef = useRef<HTMLInputElement>(null);

  const customCommandList = useAtomValue(customCommandListAtom);

  const isDev = useEnvSelectVisible();

  return (
    <CommandDialog
      open={open}
      onOpenChange={setOpen}
      commandProps={{
        onKeyDown: (e) => {
          const search = searchRef.current?.value;
          // Escape goes to previous page
          // Backspace goes to previous page when search is empty
          if (e.key === 'Escape' || (e.key === 'Backspace' && !search)) {
            if (!pages.length) return;

            e.preventDefault();
            setPages((_pages) => _pages.slice(0, -1));
            setTimeout(() => {
              searchRef.current?.focus();
            }, 30);
          }
        },
      }}
    >
      <CommandInput ref={searchRef} placeholder="输入命令或搜索..." />
      <CommandList>
        <CommandEmpty>暂无结果.</CommandEmpty>
        {match(page)
          .with('org', () => (
            <CommandGroup heading="选择空间">
              {orgItems.map((orgItem) => (
                <CommandItem
                  key={orgItem.id}
                  onSelect={() => switchOrg(orgItem)}
                >
                  {orgItem.label}
                </CommandItem>
              ))}
            </CommandGroup>
          ))
          .with('env', () => (
            <CommandGroup heading="选择环境">
              {ENV_MAP_OPTIONS.map((envOption) => (
                <CommandItem
                  key={envOption.value}
                  value={envOption.label}
                  onSelect={() => switchEnv(envOption.value)}
                >
                  {/* @ts-ignore */}
                  {cloneElement(ENV_ICON_MAP[envOption.value], {
                    className: 'mr-2',
                  })}
                  <span>{envOption.label}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          ))
          .when(
            (_page) => {
              const menuList = customCommandList.filter(
                (c) => c.type === 'menu',
              );
              return menuList.find((item) => item.id === _page);
            },
            (_page) => {
              const menuList = customCommandList.filter(
                (c) => c.type === 'menu',
              );
              const targetMenu = menuList.find((item) => item.id === _page);
              assertExists(targetMenu);
              return (
                <CommandGroup heading={targetMenu.value}>
                  {targetMenu.options!.map((o) => (
                    <CommandItem
                      key={o.value}
                      value={o.label}
                      onSelect={() => {
                        const result = targetMenu.onOptionSelect!(o.value);
                        if (result) {
                          setPages([]);
                          setOpen(false);
                        }
                      }}
                    >
                      {o.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              );
            },
          )
          .otherwise(() => (
            <CommandGroup heading="常用">
              {customCommandList.map((item) => (
                <CommandItem
                  key={item.id}
                  onSelect={() => {
                    if (!item.onSelect) {
                      if (item.type === 'menu') {
                        setPages((v) => [...v, item.id]);
                      }
                      return;
                    }
                    const result = item.onSelect();
                    if (result) setOpen(false);
                  }}
                  value={item.value}
                >
                  {item.content}
                </CommandItem>
              ))}
              <CommandItem
                onSelect={() => setPages((v) => [...v, 'org'])}
                value="切换空间"
              >
                <Building2 className="mr-2 h-4 w-4" />
                <span>切换空间</span>
              </CommandItem>
              {isDev && (
                <CommandItem
                  onSelect={() => setPages((v) => [...v, 'env'])}
                  value="切换环境"
                >
                  <Tags className="mr-2 h-4 w-4" />
                  <span>切换环境</span>
                </CommandItem>
              )}
              <CommandItem onSelect={toggle} value="切换深色模式">
                {!isDark ? (
                  <Moon className="mr-2 h-4 w-4" />
                ) : (
                  <Sun className="mr-2 h-4 w-4" />
                )}
                <span>切换{isDark ? '浅' : '深'}色模式</span>
              </CommandItem>
            </CommandGroup>
          ))}
      </CommandList>
    </CommandDialog>
  );
});

export const GlobalCommandPalette = memo(() => {
  const { isPlatformDev } = useDev();

  if (isPlatformDev) return <DevGlobalCommandPalette />;

  return null;
});
