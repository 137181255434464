import { useSelector } from '@umijs/max';
import { createContext, useContext } from 'react';

import type { IWidgetInstanceData } from '@/components/PageModelEditor';
// TODO 类型迁移到这个文件
import type { DARK_MODE } from '@/pages/home/components/LayoutModal';
import { ModelStatus } from '@/pages/home/components/LayoutModal';

export const PageThemeModeContext = createContext<'light' | 'dark'>('light');

/**
 * @returns 返回是否页面配置模型深色模式
 */
export const usePageThemeMode = () => {
  const themeMode = useContext(PageThemeModeContext) || 'light';
  return { themeMode, isDark: themeMode === 'dark' };
};

export const usePageLayout = () => {
  const { layoutMode = ModelStatus.Simple } = useSelector(
    (state: any) => state.user.currentUser,
  ) as DARK_MODE;

  return layoutMode;
};
type SectionWidgetInstanceData = IWidgetInstanceData & {
  widgetId: 'GeneralSection' | 'TabSection' | 'DynamicHeightSection';
};

export const SectionInstanceConfigContext =
  createContext<SectionWidgetInstanceData | null>(null);
export const useSectionInstanceConfig = () =>
  useContext(SectionInstanceConfigContext) as SectionWidgetInstanceData;
