import type { ProColumns } from '@ant-design/pro-table';
import type { ApiResponse } from '@linkpi/core';
import { updateNodeSysProp } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { useDebounceFn, useMemoizedFn, useRequest } from 'ahooks';
import { Spin } from 'antd';
import { type FC, Suspense } from 'react';

import { useCurrentOrgId } from '@/hook';
import request from '@/utils/request';
import { lazyImport } from '@/utils/utils';

const { DragSortTable } = lazyImport(
  () => import('@ant-design/pro-table'),
  'DragSortTable',
);

const columns: ProColumns[] = [
  {
    title: '排序',
    dataIndex: 'sort',
    editable: false,
    width: 60,
    className: 'drag-visible',
  },
  {
    title: '按钮名称（上 - 下：左 - 右）',
    editable: false,
    dataIndex: 'name',
  },
  {
    title: '设置主次按钮',
    valueType: 'radio',
    valueEnum: {
      default: '次按钮',
      primary: '主按钮',
    },
    dataIndex: 'buttonType',
  },
  {
    title: '按钮显示',
    valueType: 'switch',
    dataIndex: 'visible',
    align: 'center',
  },
];

export const defaultButtonSetting: ApiResponse.OrgConfig.IButtonSettingType[] =
  [
    {
      key: '1',
      name: '取消',
      buttonType: 'default',
      visible: true,
      index: 0,
    },
    {
      key: '2',
      name: '保存并新增',
      buttonType: 'default',
      visible: true,
      index: 1,
    },
    {
      key: '3',
      name: '保存并复制新增',
      buttonType: 'default',
      visible: false,
      index: 2,
    },
    {
      key: '4',
      name: '暂存',
      buttonType: 'default',
      visible: false,
      index: 3,
    },
    {
      key: '5',
      name: '下一个状态',
      buttonType: 'primary',
      visible: false,
      index: 4,
    },
    {
      key: '6',
      name: '保存',
      buttonType: 'primary',
      visible: true,
      index: 5,
    },
  ];

export const DraftModalButtonSetting: FC<{ rootNode: GetterPiNode }> = ({
  rootNode,
}) => {
  const orgId = useCurrentOrgId();

  const { data, loading, mutate } = useRequest(async () => {
    return rootNode.value.prop._sys_draft_button || defaultButtonSetting;
  });

  const { run: updateSetting } = useDebounceFn(
    async (v: ApiResponse.OrgConfig.IButtonSettingType[]) => {
      const [, res] = await updateNodeSysProp(request, {
        org_id: orgId,
        node_id: rootNode.value.node_id,
        key: ['_sys_draft_button'],
        value: [v],
      });
      return res;
    },
    { wait: 2000 },
  );

  const mutateSetting = useMemoizedFn(
    (v: ApiResponse.OrgConfig.IButtonSettingType[]) => {
      mutate(v);
      updateSetting(v);
    },
  );

  return (
    <>
      <div className="setting-title">新增弹窗按钮配置</div>
      <Suspense fallback={<Spin />}>
        <DragSortTable
          tableStyle={{ maxWidth: 1024 }}
          editable={{
            type: 'multiple',
            editableKeys: data?.map((i) => i.key),
            actionRender: () => {
              return [];
            },
            onValuesChange: (_, v) => mutateSetting(v),
          }}
          rowKey="key"
          dragSortKey="sort"
          columns={columns}
          loading={loading}
          dataSource={data}
          toolbar={{}}
          toolBarRender={false}
          onDragSortEnd={mutateSetting}
          search={false}
          pagination={false}
        />
      </Suspense>
    </>
  );
};
