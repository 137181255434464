import type { ApiResponse, CustomButtonOpenConfig } from '@linkpi/core';
import {
  DEFAULT_TEMPLATE,
  generateAddOpId,
  getDefaultTempProp,
  NODE_PERMISSION,
  updateProp,
} from '@linkpi/core';
import type { ViewGroupDataType } from '@linkpi/core/web';
import { GetterPiNode } from '@linkpi/core/web';
import { delay } from '@linkpi/utils';
import { useDispatch, useSelector } from '@umijs/max';
import { useMemoizedFn, useThrottleEffect } from 'ahooks';
import type { PaginationProps } from 'antd';
import { Affix, message, Modal, Progress } from 'antd';
import { cloneDeep, debounce } from 'lodash';
import type { ForwardRefRenderFunction } from 'react';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { previewImages, previewWebOffice } from '@/components';
import AttachmentActions from '@/components/AttachmentActions';
import CalcPopover from '@/components/CalcPopover';
import ChooseExportPdfDirection from '@/components/ChooseExportPdfDirection';
import { addDraftNode, DraftNodeModal } from '@/components/DraftNodeModal';
// 编辑属性组的属性
import EditAttrGroupProps from '@/components/EditAttrGroupProps';
import GraphicCodePopover from '@/components/GraphicCodePopover';
import HeaderMoreActions from '@/components/HeaderMoreActions';
import LocationSelect, {
  getLocationSelectProps,
} from '@/components/LocationSelect';
import NodeMainbodyAttachments from '@/components/NodeMainbodyAttachments';
import NodeMainbodyContent from '@/components/NodeMainbodyContent';
import TableCellEdit from '@/components/TableCellEdit';
// 查看单元格更多内容
import UnitMoreDetail from '@/components/UnitMoreDetail';
import {
  useComponentId,
  useCurrentUserInfo,
  useNavMode,
  useOrgConnection,
  useOrgUserMap,
} from '@/hook';
import useGlobalHotKey from '@/hook/useGlobalHotKey';
import {
  useOrgDepartmentNodes,
  useOrgDepartmentNodesMap,
} from '@/hook/useOrgStructure';
import useViewDataSort from '@/hook/useViewDataSort';
import useViewRecordSort from '@/hook/useViewRecordSort';
import NodesModal from '@/pages/home/components/NodesModal';
import QuoteNodes from '@/pages/home/components/QuoteNodes';
import { StatusMenu } from '@/pages/home/components/StatusMenu/StatusMenu';
import { TriggerCustomButtonNiceModal } from '@/pages/home/components/TriggerCustomButton';
import NodeStatusModal from '@/pages/home/components/View/ListTable/NodeStatusModal';
import PropsEditModal from '@/pages/home/components/View/ListTable/PropsEditModal';
import useStat from '@/pages/home/components/View/ListTable/useStat';
import {
  ViewContentNiceModal,
  viewNodeContent as _viewNodeContent,
} from '@/pages/home/components/View/ViewContent';
import { updateViewConfig } from '@/services/view';
import request from '@/utils/request';
import {
  changeAttrsOrder,
  exportPDF,
  filterQuoteNodesForMatching,
  filterQuoteNodesForQuote,
  genPDF,
  getDefaultExportPDFDirection,
  getTemplateFirstStatus,
  getUserNickName,
  notAdminDelNodeAcl,
} from '@/utils/utils';

import useFilterTemplateList from '../../../../hook/useFilterTemplateList';
import type { CalcPosition, CalcProps } from '../core';
import PiGrid from '../index';
import WattPagination from './pagination';
import { getAddInitData } from './service';
import Tabs from './tabs';
import type { GridAttrItem } from './useGridAttrs';
import useGridAttrs from './useGridAttrs';

import './index.less';
import styles from './styles.less';

type ContentPageGeneralTableConfig = {
  maxTableHeight?: number;
  tab?: any;
  refresh?: () => void;
  isDrafts?: boolean;
  containerBgColor?: string;
};

type ReactPiGridPropsType = {
  node: GetterPiNode;
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>;
  userList: ApiResponse.OrgUser;
  orgInfo: ApiResponse.CurrentUser.OrgInfo;
  templateList: ApiResponse.CurrentUser.TemplateInfo[];
  groupData: ViewGroupDataType;
  curViewData: ApiResponse.ViewList.ViewListItem<2>;
  checkId: string[];
  searchTitle: string;
  setCheckId: (ids: string[]) => void;
  showNodeSaved: () => void;
  getViewList: () => void;
  setGridAttrs: (gridAttrs: any) => void;
  groups: ApiResponse.CurrentUser.orgGroup[];
  leftCollapse: boolean;
  leftWidth: number;
  contentPageGeneralTableConfig?: ContentPageGeneralTableConfig;
  uniqueCanvasId?: string;
  setCheckedNodesDetail?: any;
  setCustomTableNodeMap?: any;
  onDraftAddOk?: () => void;
  forbiddenAddNode?: boolean;
  changeGeneralTableLoading?: (b: boolean) => void;
  styleMode: string;
  // 滚动容器 id
  containerId?: string;
  afterCollapseGroup?: (v: boolean) => void;
  pagination?: boolean;
  nodeOpenType?: string;
};

type ReactPiGridRefType = {
  onNodeHeightChange: (nodeHeight: number) => void;
  onCollapse: (collapse: boolean) => void;
  gridAttrs: GridAttrItem[];
  onGridAttrsChange: (newGridAttrs: GridAttrItem[]) => void;
  onBatchChangeStatus: (ids: string[]) => void;
  onEditTempProps: (propSymbol: string, ids: string[]) => void;
};

const ReactPiGrid: ForwardRefRenderFunction<
  ReactPiGridRefType,
  ReactPiGridPropsType
> = (props, ref) => {
  useImperativeHandle(ref, () => ({
    onNodeHeightChange(h) {
      piGridRef.current?.onNodeHeightChange(h);
      saveTableConfig(h, 'tLineheight');
    },
    onCollapse(e) {
      piGridRef.current?.onCollapse(e);
    },
    gridAttrs: gridAttrs,
    onGridAttrsChange(e: GridAttrItem[]) {
      const newShowFields = e.map((x) => ({
        key: x.key,
        disable: x.disable,
      }));
      setLocalTableConfig(newShowFields);
      saveTableConfig(newShowFields, 'tShowFieldsIndex');
    },
    onBatchChangeStatus: onBatchChangeStatus,
    onEditTempProps: onEditTempProps,
  }));
  const orgUserMap = useOrgUserMap();
  const { confirm } = Modal;

  const {
    node,
    userMap,
    templateList,
    groupData,
    curViewData,
    checkId,
    setCheckId,
    searchTitle,
    showNodeSaved,
    setGridAttrs,
    getViewList,
    orgInfo,
    groups = [],
    leftCollapse,
    leftWidth,
    contentPageGeneralTableConfig = {},
    uniqueCanvasId = 'piGrid',
    setCheckedNodesDetail = () => {},
    setCustomTableNodeMap = () => {},
    onDraftAddOk,
    forbiddenAddNode = false,
    styleMode,
    containerId,
    afterCollapseGroup = () => {},
    pagination = false,
  } = props;
  const checkRef = useRef(checkId);
  checkRef.current = checkId;

  const componentId = useComponentId();
  const draftId = 'DRAFT_ID_' + componentId;
  const viewNodeId = 'VIEW_NODE_MODAL_ID_' + componentId;

  const viewNodeContent = useMemoizedFn(
    (options: Parameters<typeof _viewNodeContent>[0]) => {
      _viewNodeContent(options, viewNodeId);
    },
  );

  const { currentSelection } = useSelector((state: any) => state.workspace);
  const dispatch = useDispatch();
  const currentUser: ApiResponse.CurrentUser = useSelector(
    (rootState: any) => rootState.user.currentUser,
  );
  const orgConnection: any = useOrgConnection(currentSelection.selectSpace);
  const filterTemplateList = useFilterTemplateList(orgInfo);

  const piGridRef = useRef<PiGrid>();
  const nodeStatusModalRef = useRef<any>();
  const locationSelectRef = useRef<any>();
  const [calcPopover, setCalcPopover] = useState<null | React.ReactElement>(
    null,
  );
  const [showQuoteNodesModal, setShowQuoteNodesModal] =
    useState<boolean>(false);
  const [propIndex, setPropIndex] = useState<string | number>('');
  const [nowNode, setNowNode] = useState<any>(null);

  const [nodesModalConfig, setNodesModalConfig] = useState<{
    nodes: PiNode[];
    template: ApiResponse.CurrentUser.TemplateInfo;
  } | null>(null);
  const [statusMenu, setStatusMenu] = useState<JSX.Element | null>(null);

  // 表头更多的功能
  const [showHeaderMoreActions, setShowHeaderMoreActions] = useState<any>(null);
  // 单元格编辑
  const [cellInfo, setCellInfo] = useState<any>(null);
  const cellInfoRef = useRef<any>(cellInfo);
  cellInfoRef.current = cellInfo;

  // 附件编辑
  const [showAttachmentActions, setShowAttachmentActions] = useState<any>(null);

  // 导出图形码信息
  const [graphicCodeInfo, setGraphicCodeInfo] = useState<any>(null);

  // 表格正文附件
  const [nodeMainbodyAttachments, setNodeMainbodyAttachments] =
    useState<any>(null);

  // 表格正文内容
  const [nodeMainbodyContent, setNodeMainbodyContent] = useState<any>(null);

  // 查看引用主题
  const [originNodes, setOriginNodes] = useState<any>(null);

  const departmentMap = useOrgDepartmentNodesMap();
  // 部门属性
  const departmentMapRef = useRef<any>(departmentMap);
  departmentMapRef.current = departmentMap;

  // 导出方向modal
  const [
    showChooseExportPdfDirectionModal,
    setShowChooseExportPdfDirectionModal,
  ] = useState(false);
  const [genPDFLoading, setGenPDFLoading] = useState(false);
  const [pdfNode, setPdfNode] = useState<any>(null);

  // 单元格的信息
  const [unitDetail, setUnitDetail] = useState<any>(null);
  // 编辑属性组
  const [attrGroupConfig, setAttrGroupConfig] = useState<any>(null);

  // 节点批量操作的进度
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [progressFinished, setProgressFinished] = useState(0);
  const [progressTotal, setProgressTotal] = useState(0);
  const [progressOver, setProgressOver] = useState(false);

  const propsEditRef = useRef<any>();

  const [viewInfo, condition] = useMemo(() => {
    const viewInfo = curViewData.view_info || {};
    const condition = viewInfo.condition || {};
    return [viewInfo, condition];
  }, [curViewData]);

  const viewTempId = useMemo(() => {
    const viewInfo = curViewData.view_info || {};
    const condition = viewInfo.condition || [];
    const tempId = condition.find((x) => x.key === 'templateId');
    return tempId ? tempId.value : DEFAULT_TEMPLATE;
  }, [templateList, curViewData]);

  const viewTemplate = useMemo(() => {
    return templateList.find(
      (x) => x.template_id === viewTempId,
    ) as ApiResponse.CurrentUser.TemplateInfo;
  }, [templateList, viewTempId]);

  // 闭包问题
  const viewTemplateRef = useRef(viewTemplate);
  viewTemplateRef.current = viewTemplate;
  const curViewDataRef = useRef(curViewData);
  curViewDataRef.current = curViewData;

  // 节点改变状态
  const statusChangeRef = useRef<any>(null);
  const [statusChangeNode, setStatusChangeNode] = useState<any>(null);

  const templateMap = useMemo(
    () =>
      templateList.reduce((res, cur) => {
        res[cur.template_id] = cloneDeep(cur);
        return res;
      }, {} as any),
    [templateList],
  );

  // 显示列配置
  const [localTableConfig, setLocalTableConfig] = useState<
    ApiResponse.ViewList.ViewTableShowFieldItem[]
  >([]);
  const [attrContentAlign, setAttrContentAlign] = useState<any>({});
  const [attrsStyle, setAttrsStyle] = useState<any>({});
  const [fixedAttrPool, setFixedAttrPool] = useState<any>([]);
  const userInfo = useCurrentUserInfo();

  const navMode = useNavMode();

  useEffect(() => {
    if (viewInfo) {
      let tShowFieldsIndex = viewInfo.tShowFieldsIndex || [];
      if (!viewInfo.fixedAttrPool)
        tShowFieldsIndex = changeAttrsOrder(['order'], tShowFieldsIndex);
      setLocalTableConfig(tShowFieldsIndex);
      batchSetStatConfig(viewInfo.stat || {});
      setAttrContentAlign(viewInfo.attrContentAlign || {});
      setAttrsStyle(viewInfo.attrsStyle || {});
      setFixedAttrPool(viewInfo.fixedAttrPool || ['order']);
    }
  }, [viewInfo]);

  const onceRef = useRef(false);
  const initCollapseRef = useRef(false);
  useEffect(() => {
    if (
      !curViewData ||
      !piGridRef.current ||
      onceRef.current ||
      !curViewData.view_info
    )
      return;

    const {
      view_id,
      view_info: { defaultCollapsed, group },
    } = curViewData;

    if (
      initCollapseRef.current === false &&
      defaultCollapsed === 'defaultFold'
    ) {
      piGridRef.current.onCollapse(false);
      afterCollapseGroup(false);
    }
    initCollapseRef.current = true;

    if (
      !group ||
      !view_id ||
      ['defaultFold', 'defaultExpand'].includes(defaultCollapsed)
    )
      return;

    const tableGroupCollapse = JSON.parse(
      localStorage.getItem('tableGroupCollapse') || '{}',
    );
    if (!tableGroupCollapse[view_id]) return;
    if (!tableGroupCollapse[view_id][group]) return;
    onceRef.current = true;

    piGridRef.current?.editor?.moduleInstances.DataManager.setCollapseState(
      tableGroupCollapse[view_id][group],
    );
  }, [curViewData, piGridRef.current]);

  useEffect(() => {
    if (!node) return;

    // 节点新增/删除 触发渲染
    const refreshTable = () => {
      setTimeout(() => {
        // 内容页表格渲染
        if (
          contentPageGeneralTableConfig &&
          contentPageGeneralTableConfig.refresh
        ) {
          contentPageGeneralTableConfig.refresh();
        }

        // 装修表格渲染
        if (viewInfo.tableStyle === 'custom') {
          piGridRef.current?.refreshChildNodes();
        }
      }, 1666);
    };

    node.value.nodeManager.listen('change', refreshTable);
    return () => {
      node.value.nodeManager.unListen('change', refreshTable);
    };
  }, []);

  useEffect(() => {
    dispatch({
      type: 'space/fetchSpaceTags',
      payload: { org_id: currentSelection.selectSpace },
    });
  }, [currentSelection.selectSpace]);

  // 显示列
  const [gridAttrs] = useGridAttrs(
    templateList,
    curViewData,
    localTableConfig,
    userMap,
    departmentMapRef.current,
    currentSelection.selectSpace,
  );
  useEffect(() => {
    setGridAttrs(gridAttrs);
  }, [gridAttrs]);
  const gridAttrsRef = useRef(gridAttrs);
  gridAttrsRef.current = gridAttrs;
  // 排序记录
  const [recordSorts, patchUpdateRecordSorts, fullUpdateRecordSorts] =
    useViewRecordSort(
      currentSelection.selectSpace,
      currentSelection.selectNode,
      currentSelection.selectViewId,
      () => (piGridRef.current ? piGridRef.current.getNodeIds() : []),
    );
  const isCustomView = useMemo(() => {
    return viewInfo.tableStyle === 'custom';
  }, [curViewData]);
  // 数据排序
  const [sortData, sortDataIds, getSortDataByOrder] = useViewDataSort(
    groupData,
    recordSorts,
    isCustomView,
  );
  // 统计
  const [statData, statConfig, setStatConfig, batchSetStatConfig] = useStat(
    groupData,
    viewTemplate,
    templateMap,
  );

  // 持久化表格配置
  const saveTableConfig = useCallback(
    debounce(async (value: any, key: string, extra?: any) => {
      // 子表格属性拖拽
      if (contentPageGeneralTableConfig.refresh && key === 'attrsWidth') {
        const t = viewTemplateRef.current;
        const data = {
          temp_id: t.template_id,
          org_id: t.org_id,
          attrsWidth: value,
        };

        await request('/api/template/attrsWidth', {
          method: 'POST',
          data,
        });
        return;
      }
      // 权限问题
      const payload: any = { ...curViewDataRef.current.view_info };
      payload[key] = value;

      if (extra) {
        Object.keys(extra).forEach((k) => {
          payload[k] = extra[k];
        });
      }

      const req = {
        view_id: curViewDataRef.current.view_id,
        view_info: payload,
      };

      await updateViewConfig(req);
      getViewList();
    }, 1000),
    [viewInfo],
  );

  // 计算统计
  const showCalcPopover = (
    data: CalcProps,
    position: CalcPosition,
    placement: 'top' | 'bottom',
  ) => {
    setCalcPopover(null);
    setCalcPopover(
      <CalcPopover
        placement={placement}
        setStatConfig={setStatConfig}
        calcPosition={position}
        calcProps={data}
        setCalcPopover={setCalcPopover}
        saveTableConfig={saveTableConfig}
      />,
    );
  };

  // 查看单元格更多的信息
  const showUnitMoreDetail = (unitMoreDetail: any) => {
    setUnitDetail(unitMoreDetail);
  };

  const initProgress = () => {
    setProgressFinished(0);
    setProgressTotal(0);
    setProgressOver(false);
  };

  // 节点跳转
  const toNode = (id: string, viewRedirect?: boolean) => {
    if (!id) return;
    dispatch({
      type: 'workspace/setCurrentSelection',
      payload: {
        selectNode: id,
        viewRedirect: !!viewRedirect,
      },
    });
  };

  // 展示节点
  const showLinkNodes = (
    nodes: PiNode[],
    template: ApiResponse.CurrentUser.TemplateInfo,
  ) => {
    setNodesModalConfig({ nodes, template });
  };

  // 文档预览
  const handleViewDoc = (docInfo: { src: string; name: string }) => {
    previewWebOffice({
      url: docInfo.src,
      fileName: docInfo.name,
      orgId: currentSelection.selectSpace,
    });
  };

  // 展示条件匹配的节点
  const showQuoteNodes = (propIndex: string | number, node: PiNode) => {
    setPropIndex(propIndex);
    setNowNode(node);
    setShowQuoteNodesModal(true);
  };

  // 图片预览
  const showBigImg = (src: string) => {
    previewImages({
      images: [{ src, alt: '' }],
      noNavbar: true,
      changeable: false,
      attribute: false,
      scalable: false,
      noClose: true,
    });
  };

  const showImgs = (imgs: string[], index: number) => {
    previewImages({
      images: imgs.map((i) => ({ src: i })),
      activeIndex: index,
    });
  };

  // 查看引用匹配主题
  const fetchQuoteNodes = (propIndex: number, node: PiNode) => {
    return request('/docapi/execCalcFormula', {
      method: 'POST',
      data: {
        org_id: currentSelection.selectSpace,
        node_id: node.id,
        index: propIndex,
        getNodeId: true,
      },
    });
  };

  // 选值匹配 引用 允许查看主题
  const allowToOpenNode = async (data: {
    type: 'quote' | 'matching';
    propIndex: number;
    prop: ApiResponse.CurrentUser.TemplateProp;
    originValue: any;
    node: PiNode;
  }) => {
    const { originValue, prop, propIndex, type, node } = data;
    const res = await fetchQuoteNodes(propIndex, node);
    if (res.status !== 'ok') return message.error('获取引用列表出错');
    const nodes = res.data
      .map((id) => {
        const nodeId =
          typeof id === 'string'
            ? id
            : 'v' in id && typeof id.v === 'string'
              ? id.v
              : '';
        return nodeId ? node.nodeManager.findChildren(nodeId) : null;
      })
      .filter((n: any) => !!n);

    let resNodes =
      type === 'matching'
        ? filterQuoteNodesForMatching(nodes, prop, templateMap, originValue)
        : filterQuoteNodesForQuote(nodes, prop, templateMap, originValue);
    resNodes = resNodes || [];

    if (resNodes.length === 0) return message.warning('没有对应主题');

    // 单条主题，直接打开内容页弹窗
    if (resNodes.length === 1) {
      viewNodeContent({
        nodeIndex: 0,
        dataList: [resNodes[0].id],
        showNodeSaved,
      });
    }
    // 多条，展示引用列表
    if (resNodes.length > 1) {
      setOriginNodes(resNodes);
      setPropIndex(propIndex);
      setShowQuoteNodesModal(true);
    }
  };

  // 预览
  const onPreviewNode = (id: string, openConfig?: CustomButtonOpenConfig) => {
    const nodeOpenType =
      curViewDataRef.current.view_info.nodeOpenType || 'modal';
    if (nodeOpenType === 'content')
      return dispatch({
        type: 'workspace/setCurrentSelection',
        payload: {
          selectNode: id,
          viewRedirect: true,
        },
      });

    if (nodeOpenType === 'modal') {
      const ids = piGridRef.current ? piGridRef.current.getNodeIds() : [];
      const index = ids.indexOf(id);

      viewNodeContent({
        nodeIndex: index,
        dataList: ids,
        showNodeSaved,
        openType: openConfig?.openType as 'modal' | 'drawer' | undefined,
        modalConfig: openConfig?.modalConfig,
        pageModelConfigId: openConfig?.pageModel,
      });
      return;
    }

    if (nodeOpenType === 'newWindow')
      window.open(
        `${window.location.origin}/home/${orgInfo.orgId}/${id}/${id}`,
        '',
        'noopener',
      );
  };

  // 表格装修预览节点
  const onPreviewNodeForFix = (
    id: string,
    parentNode: PiNode | undefined | false,
  ) => {
    if (parentNode === false) {
      // 不允许左右切换
      viewNodeContent({
        nodeIndex: 0,
        dataList: [id],
        showNodeSaved,
      });
      return;
    }

    if (parentNode === undefined) {
      // 在父节点之间切换
      const parentIds = piGridRef.current ? piGridRef.current.getNodeIds() : [];
      const index = parentIds.indexOf(id);

      viewNodeContent({
        nodeIndex: index,
        dataList: parentIds,
        showNodeSaved,
      });
      return;
    }

    if (parentNode) {
      // 子节点间切换
      const childIdMap = piGridRef.current
        ? piGridRef.current.getChildIdMap()
        : null;
      if (!childIdMap) return;
      const childIds = childIdMap[parentNode.id];
      const index = childIds.indexOf(id);
      let nodeIndex = 0;
      let nodeList = [id];
      if (index !== -1) {
        nodeIndex = index;
        nodeList = childIds;
      }

      viewNodeContent({ nodeIndex, showNodeSaved, dataList: nodeList });
    }
  };

  const onPreviewNodeForModal = (id: string) => {
    viewNodeContent({ nodeIndex: 0, showNodeSaved, dataList: [id] });
  };

  // 删除
  const onNodeDelete = (id: string) => {
    const n = node.value.nodeManager.findChildren(id);
    const parentNode = n.parent;
    if (parentNode) {
      const prop: any = parentNode.prop || {};
      const _sys_periodic_history = prop._sys_periodic_history || [];
      const repeatTaskNodes = _sys_periodic_history.reduce(
        (res: any, cur: any) => {
          return res.concat(cur.node_id);
        },
        [] as any,
      );

      if (repeatTaskNodes.includes(id)) {
        confirm({
          content: '确定删除该主题吗？此为重复任务生成。',
          okType: 'danger',
          okText: '仅删除此主题',
          onOk() {
            node.value.nodeManager.getNode(id).deleteNode();
          },
          cancelText: '删除所有将来主题',
          async onCancel() {
            const res = await request('/docapi/removePeriodicNodes', {
              method: 'POST',
              data: {
                org_id: orgInfo.orgId,
                node_id: id,
              },
            });

            if (res.status === 'ok') message.success('操作成功');
          },
        });

        return;
      }
    }

    const allowDelete =
      notAdminDelNodeAcl(orgInfo, n) &&
      n.acl === NODE_PERMISSION.管理 &&
      !n.prop._sys_protect;
    if (!allowDelete) {
      message.warning('当前选中的主题无权限删除');
      return;
    }
    Modal.confirm({
      title: '提示',
      content: `是否确定删除该数据？`,
      onOk: async (closeModal) => {
        node.value.nodeManager.getNode(id).deleteNode();
        closeModal();
      },
    });
  };

  const onStatusClick = (
    node: PiNode,
    position: any,
    isButtonStyle: boolean,
  ) => {
    const getNode = new GetterPiNode(node);
    setStatusMenu(
      <div
        className={styles.statusMenuWrap}
        style={{ left: position.x, top: position.y }}
      >
        <StatusMenu
          btnClassName="status-btn"
          spaceId={orgInfo.orgId}
          data={getNode.value}
          autoOpen={true}
          trigger="click"
          onCancel={() => setStatusMenu(null)}
          type={isButtonStyle ? 'button' : 'list'}
        />
      </div>,
    );
  };

  const handleStatusChange = (node: any, payload: any) => {
    setStatusChangeNode(node);
    setTimeout(() => {
      statusChangeRef.current.setStatus(
        payload.task_status,
        payload.index,
        payload.type,
        payload.currentTemplate,
      );
    }, 0);
  };

  const tableCellEdit = (cellInfo: any) => {
    // 编辑定位属性
    console.log(cellInfo);
    if (cellInfo.cellConfig.type === 'positioning') {
      const positionValue =
        cellInfo.editNode.prop._sys_location?.[cellInfo.cellConfig.propIndex];
      const editable = cellInfo.cellConfig.config.editable;
      if (!editable && (!positionValue || !positionValue.length)) {
        return message.error('不允许编辑');
      }

      locationSelectRef.current.show(
        getLocationSelectProps({
          node: cellInfo.editNode,
          propIndex: cellInfo.cellConfig.propIndex,
          readOnly:
            cellInfo.cellConfig.disable || !cellInfo.cellConfig.config.editable,
          tempMap: templateMap,
          onSave: async (newData: any) => {
            const req = {
              org_id: cellInfo.editNode.orgId,
              node_id: cellInfo.editNode.id,
              temp_id: cellInfo.editNode.tempInfo.id,
              index: [cellInfo.cellConfig.propIndex],
              value: [newData.name],
              location: {
                [cellInfo.cellConfig.propIndex]: [newData],
              },
            };
            const [err, res] = await updateProp(request, req);
            if (err || res.status !== 'ok') {
              message.error('保存失败');
              return false;
            }
          },
        }),
      );
      return;
    }

    setCellInfo(cellInfo);
  };

  // 改变表单的位置
  const changeTableCellEditPosition = (x: number, y: number) => {
    if (!cellInfoRef.current) return;
    const newCellInfo = { ...cellInfoRef.current };

    newCellInfo.position.x = x;
    newCellInfo.position.y = y;
    setCellInfo(newCellInfo);
  };

  const storageGroupCollapse = (collapseState: any) => {
    const { view_id, view_info } = curViewDataRef.current;
    const { group } = view_info;
    if (!view_id || !group) return;

    const tableGroupCollapse = JSON.parse(
      localStorage.getItem('tableGroupCollapse') || '{}',
    );
    if (tableGroupCollapse[view_id])
      tableGroupCollapse[view_id][group] = collapseState;
    else {
      tableGroupCollapse[view_id] = {};
      tableGroupCollapse[view_id][group] = collapseState;
    }

    localStorage.setItem(
      'tableGroupCollapse',
      JSON.stringify(tableGroupCollapse),
    );
  };

  const gridIsEdittingProp =
    piGridRef.current?.editor?.moduleInstances.DataManager.isEdittingProp;
  const gridSelectRow =
    piGridRef.current?.editor?.moduleInstances.DataManager.selectRow;
  // key event
  useGlobalHotKey(
    38,
    (e: any) => {
      // 未选中 return
      if (!gridSelectRow) return;
      // 编辑时也return
      if (gridIsEdittingProp) return;

      e.preventDefault();
      e.stopPropagation();
      piGridRef.current && piGridRef.current.changeSelectNodeAttr('up');
    },
    { useCapture: true, exact: true, element: document.body },
  );

  useGlobalHotKey(
    40,
    (e: any) => {
      if (!gridSelectRow) return;
      if (gridIsEdittingProp) return;
      e.preventDefault();
      e.stopPropagation();
      piGridRef.current && piGridRef.current.changeSelectNodeAttr('down');
    },
    { useCapture: true, exact: true, element: document.body },
  );

  useGlobalHotKey(
    37,
    (e: any) => {
      if (!gridSelectRow) return;
      if (gridIsEdittingProp) return;
      e.preventDefault();
      e.stopPropagation();
      piGridRef.current && piGridRef.current.changeSelectNodeAttr('left');
    },
    { useCapture: true, exact: true, element: document.body },
  );

  useGlobalHotKey(
    39,
    (e: any) => {
      if (!gridSelectRow) return;
      if (gridIsEdittingProp) return;
      e.preventDefault();
      e.stopPropagation();
      piGridRef.current && piGridRef.current.changeSelectNodeAttr('right');
    },
    { useCapture: true, exact: true, element: document.body },
  );

  useGlobalHotKey(
    'enter',
    (e: any) => {
      if (!gridSelectRow) return;
      if (e.target?.tagName === 'INPUT') return;

      // e.stopPropagation();
      e.preventDefault();
      piGridRef.current && piGridRef.current.edittingProp();
    },
    { useCapture: true, exact: true, element: document.body },
  );

  useGlobalHotKey(
    'shift+enter',
    (e: any) => {
      if (!gridSelectRow) return;
      e.stopPropagation();
      e.preventDefault();
      if (!piGridRef.current) return;
      const selectNodeAttr =
        piGridRef.current.editor?.moduleInstances.DataManager.selectNodeAttr;
      const virtualList =
        piGridRef.current.editor?.moduleInstances.DataManager.virtualList;
      if (!selectNodeAttr || !virtualList || !selectNodeAttr.groupKey) return;

      const { groupKey, indexInGroup } = selectNodeAttr;

      const groupItem = groupData[groupKey];
      const row = virtualList.find(
        (v: any) =>
          v.groupKey === groupKey &&
          v.index === indexInGroup &&
          v.type === 'node',
      );
      //@ts-ignore
      const prevNodeId = row.node.id;

      onAdd(groupItem, groupKey as string, prevNodeId);
    },
    { useCapture: true, exact: true, element: document.body },
  );

  useGlobalHotKey(
    'tab',
    (e: any) => {
      if (!gridSelectRow) return;
      e.stopPropagation();
      e.preventDefault();
      piGridRef.current && piGridRef.current.changeSelectNodeAttr('right');
    },
    { useCapture: true, exact: true, element: document.body },
  );

  useGlobalHotKey(
    'shift+tab',
    (e: any) => {
      if (!gridSelectRow) return;
      e.stopPropagation();
      e.preventDefault();
      piGridRef.current && piGridRef.current.changeSelectNodeAttr('left');
    },
    { useCapture: true, exact: true, element: document.body },
  );

  // 数据变化 清空checkid
  useEffect(() => {
    if (contentPageGeneralTableConfig && contentPageGeneralTableConfig.refresh)
      return;
    setCheckId([]);
  }, [viewInfo.group, condition, searchTitle]);

  const hasGroup = useMemo(
    () =>
      !(
        Object.keys(sortData).length === 1 &&
        sortData.default &&
        !sortData.default.name
      ),
    [sortData],
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const handlePageChange: PaginationProps['onChange'] = (page, _pageSize) => {
    setCurrentPage(page);
    setPageSize(_pageSize);
  };
  const [tabActive, setTabActive] = useState(0);

  const sortKeyList = useMemo(
    () =>
      Object.keys(sortData).sort((a, b) => sortData[a].sort - sortData[b].sort),
    [sortData],
  );

  const _list = useMemo(() => {
    if (hasGroup) {
      return sortData[sortKeyList[tabActive]]?.list || [];
    } else {
      return sortKeyList.reduce<PiNode[]>((result, item) => {
        return [...result, ...sortData[item].list];
      }, []);
    }
  }, [hasGroup, sortData, sortKeyList, tabActive]);

  const _data = useMemo<ViewGroupDataType>(() => {
    if (pagination) {
      const start = (currentPage - 1) * pageSize;
      const end = start + pageSize - 1;
      const list =
        sortData[hasGroup ? sortKeyList[tabActive] : 'default']?.list.filter(
          (_, index) => index >= start && index <= end,
        ) ?? [];
      return {
        default: {
          list,
          name: '',
          sort: 0,
        },
      };
    }
    return sortData;
  }, [
    currentPage,
    hasGroup,
    pageSize,
    pagination,
    sortData,
    sortKeyList,
    tabActive,
  ]);

  const total = useMemo(() => {
    return _list.length;
  }, [_list.length]);

  const paginationModeGroup = useMemo<
    {
      key: string;
      color?: string;
    }[]
  >(() => {
    if (pagination && hasGroup) {
      return sortKeyList.map((key) => {
        const item = sortData[key];
        const value = {
          key: item.name,
        };
        if (key === 'default') {
          return value;
        }
        if (item.nameType === 'user') {
          return {
            key: getUserNickName(userMap[item.name]),
          };
        }
        if (item.nameType === 'enum' && item.info?.prop) {
          const { extendColorMode, extendColor, extend } = item.info.prop;
          const index = extend.findIndex((e: string) => e === item.name);
          if (extendColorMode) {
            return {
              ...value,
              color: extendColor[index],
            };
          }
        }
        return value;
      });
    } else {
      return [];
    }
  }, [hasGroup, pagination, sortData, sortKeyList, userMap]);

  // new
  useEffect(() => {
    piGridRef.current = new PiGrid({
      container: `#${uniqueCanvasId}`,
      groupData: _data,
      tableAttrs: gridAttrs,
      freeze: 2,
      nodeHeight: curViewData?.view_info?.tLineheight,
      checkId: checkId,
      onAdd: onAdd,
      onCheck: onCheck,
      onSort: onSort,
      showCalcPopover,
      onPreviewNode,
      onNodeDelete,
      userMap,
      departmentMap,
      localTableConfig,
      mode: viewInfo.tableStyle === 'custom' ? 'custom' : 'default',
      templateList,
      showLinkNodes,
      toNode,
      viewTemplate,
      showQuoteNodes,
      showBigImg,
      onStatusClick,
      onPreviewNodeForFix,
      groups,
      contentPageGeneralTableConfig,
      orgInfo,
      setShowHeaderMoreActions,
      tableCellEdit,
      changeTableCellEditPosition,
      setCheckedNodesDetail,
      setCustomTableNodeMap,
      handleStatusChange,
      setShowAttachmentActions,
      setNodeMainbodyAttachments,
      setNodeMainbodyContent,
      allowToOpenNode,
      handleViewDoc,
      attrContentAlign,
      viewInfo,
      saveTableConfig,
      showUnitMoreDetail,
      setAttrGroupConfig,
      setCellInfo,
      setGraphicCodeInfo,
      userInfo,
      forbiddenAddNode,
      departmentMapRef,
      styleMode,
      afterCollapseGroup,
      storageGroupCollapse,
      hiddenFooter: pagination,
      navMode,
    });

    return () => destroyPiGrid();
  }, []);

  /**
   * grid depend change
   */
  useThrottleEffect(
    () => {
      piGridRef.current && piGridRef.current.onCheckIdChange(checkId);
    },
    [checkId],
    { wait: 200 },
  );
  useThrottleEffect(
    () => {
      piGridRef.current &&
        piGridRef.current.onGroupDataChange(_data, viewTemplate, viewInfo);
    },
    [_data],
    /**
     * NOTE
     *
     * 节省时间
     */
    { wait: 500 },
  );
  useThrottleEffect(
    () => {
      piGridRef.current &&
        typeof viewInfo.tPagination === 'boolean' &&
        piGridRef.current.handleFooterHidden(!!viewInfo.tPagination);
    },
    [viewInfo.tPagination],
    { wait: 1000 },
  );
  // useThrottleEffect(
  //   () => {
  //     piGridRef.current && piGridRef.current.onGroupDataChange(sortData, viewTemplate, viewInfo);
  //   },
  //   [sortData],
  //   { wait: 1000 },
  // );
  useThrottleEffect(
    () => {
      piGridRef.current &&
        piGridRef.current.onLocalTableConfigChange(localTableConfig);
    },
    [localTableConfig],
    { wait: 1000 },
  );

  useThrottleEffect(
    () => {
      piGridRef.current && piGridRef.current.updateViewInfo(viewInfo);
    },
    [viewInfo],
    { wait: 1000 },
  );

  useThrottleEffect(
    () => {
      piGridRef.current && piGridRef.current.updateStyleMode(styleMode);
    },
    [styleMode],
    { wait: 500 },
  );

  useThrottleEffect(
    () => {
      piGridRef.current && piGridRef.current?.refreshChildNodes();
    },
    [viewInfo.childCondition, viewInfo.childOrderBy],
    { wait: 500 },
  );

  useThrottleEffect(
    () => {
      piGridRef.current &&
        piGridRef.current.onAttrContentAlignChange(attrContentAlign);
    },
    [attrContentAlign],
    { wait: 1000 },
  );

  useThrottleEffect(
    () => {
      piGridRef.current && piGridRef.current.splitPaneChange();
    },
    [leftCollapse, leftWidth],
    { wait: 100 },
  );

  useThrottleEffect(
    () => {
      piGridRef.current && piGridRef.current.onGroupsChange(groups);
    },
    [groups],
    { wait: 1000 },
  );

  useThrottleEffect(
    () => {
      piGridRef.current &&
        piGridRef.current.onStatDataChange(statData, statConfig);
    },
    [statData, statConfig],
    { wait: 1000 },
  );

  useThrottleEffect(
    () => {
      piGridRef.current && piGridRef.current.onTableAttrsChange(gridAttrs);
    },
    [gridAttrs],
    { wait: 100 },
  );

  /**
   *  handle
   */

  const destroyPiGrid = () => {
    if (piGridRef.current) {
      piGridRef.current.destroy();
    }
  };
  const onAdd = (
    groupItem: ViewGroupDataItem | null,
    groupKey: string,
    prevNodeId: string | null,
  ) => {
    if (
      !filterTemplateList.find(
        (t) => t.template_id === viewTemplateRef.current.template_id,
      )
    ) {
      return message.error('你不在当前主题类型的可见分组内，无法新建');
    }
    // handleAddOnGrid(groupItem, groupKey, prevNodeId);
    handleAddOnPopup(groupItem, groupKey, prevNodeId);
  };

  // const handleAddOnGrid = async (
  //   groupItem: ViewGroupDataItem,
  //   groupKey: string,
  //   prevNodeId: string,
  // ) => {
  //   addOnGrid({
  //     node: node,
  //     currentSelection: currentSelection,
  //     prevNodeId,
  //     curViewData,
  //     groupItem,
  //     viewTemplate,
  //     templateList,
  //     groupKey,
  //     matchProps,
  //     userMap,
  //     userList,
  //     userId: currentUser.userid,
  //     patchUpdateRecordSorts,
  //   });
  // };

  const checkIsAddFinish = async (newId: string): Promise<PiNode> => {
    const has = orgConnection?.nodeManager.hasData(newId) as PiNode;
    if (has) {
      return has;
    } else {
      await delay(20);
      return checkIsAddFinish(newId);
    }
  };

  const departmentNodes = useOrgDepartmentNodes();

  const onDraftNodeAdd = (draftsNodeData: any) => {
    patchUpdateRecordSorts(
      draftsNodeData.node.node_id,
      handleAddPrevNodeId.current,
    );

    onDraftAddOk && onDraftAddOk();
  };

  // 新增节点到草稿箱
  const handleDraftNode = async (
    currentTemplate: ApiResponse.CurrentUser.TemplateInfo,
    viewInitData: any,
    parentId: string,
  ) => {
    const newProps = getDefaultTempProp(
      {},
      currentTemplate,
      currentUser,
      null,
      orgUserMap,
      departmentNodes,
      viewInitData,
    );

    const id = generateAddOpId();
    newProps._sys_task_status.index = getTemplateFirstStatus(
      templateMap[currentTemplate.template_id],
      newProps._sys_task_status.index,
    );

    const initDraftsNodeData: any = {
      org_id: currentSelection.selectSpace,
      parentId,
      siblingId: null,
      draft: true,
      node: {
        node_id: id,
        prop: newProps,
        title: '',
      },
    };

    // 正文模版
    if (!initDraftsNodeData.node.content) {
      if (
        currentTemplate?.temp_option?.contentTemplate?.open &&
        currentTemplate.temp_option.contentTemplate.temp
      ) {
        initDraftsNodeData.node.content =
          currentTemplate.temp_option.contentTemplate.temp;
      }
    }

    // 子表格节点行内新增
    const { tabConfig } = contentPageGeneralTableConfig.tab || {};
    if (tabConfig && tabConfig.addType === 'inline') {
      const parentNode = orgConnection.nodeManager.getNode(
        initDraftsNodeData.parentId,
      );
      await parentNode.insertNode(
        0,
        { ...initDraftsNodeData.node, id: initDraftsNodeData.node.node_id },
        null,
        contentPageGeneralTableConfig.isDrafts,
      );
      // 内容页表格渲染
      if (
        contentPageGeneralTableConfig &&
        contentPageGeneralTableConfig.refresh
      ) {
        contentPageGeneralTableConfig.refresh();
      }

      return;
    }

    await addDraftNode(
      {
        mode: 'add',
        orgId: currentSelection.selectSpace,
        draftsNodeData: initDraftsNodeData,
        initParentId: parentId,
      },
      draftId,
    );

    onDraftNodeAdd(initDraftsNodeData);
  };

  const chooseExportPdfDirectionOk = async (
    direction: string,
    sheetId: string,
  ) => {
    setGenPDFLoading(true);

    const html = await exportPDF(
      pdfNode,
      templateList,
      orgInfo,
      userMap,
      sheetId,
    );
    await genPDF(html, direction === 'horizontal', pdfNode.title || '无标题');

    setShowChooseExportPdfDirectionModal(false);
    setGenPDFLoading(false);
    setPdfNode(null);
  };

  const handleAddPrevNodeId = useRef<string | null>('');
  const handleAddOnPopup = (
    groupItem: ViewGroupDataItem | null,
    groupKey: string,
    prevNodeId: string | null,
  ) => {
    console.log('handleAddOnPopup', groupItem, groupKey, prevNodeId);
    // 默认数据
    const { addInitData } = getAddInitData({
      node: node,
      currentSelection: currentSelection,
      prevNodeId,
      curViewData: curViewDataRef.current,
      groupItem,
      viewTemplate: viewTemplateRef.current,
      templateList,
      groupKey,
      userMap,
      userId: currentUser.userid,
      departmentMap,
    });

    // 内容页表格新增
    const { tab } = contentPageGeneralTableConfig;
    if (tab) {
      tab.tabConfig.matchings.forEach((matching: any) => {
        addInitData.initProp[matching.origin] =
          node.value.tempInfo.prop[matching.target];
      });
    }

    // 弹窗
    handleAddPrevNodeId.current = prevNodeId;
    handleDraftNode(viewTemplateRef.current, addInitData, node.value.id);
  };

  const onCheck = (
    nodeId: string | 'all' | string[],
    forGroupCheck?: boolean,
  ) => {
    if (forGroupCheck) {
      setCheckId(nodeId as string[]);
      return;
    }

    if (nodeId === 'all') {
      const currentIds = piGridRef.current?.getNodeIds() || [];
      if (currentIds.length === checkRef.current.length) {
        setCheckId([]);
      } else {
        setCheckId(currentIds);
      }
    } else {
      const ids = new Set(checkRef.current);
      if (Array.isArray(nodeId)) {
        nodeId.forEach((i) => {
          if (!ids.has(i)) ids.add(i);
        });
      } else {
        if (ids.has(nodeId)) ids.delete(nodeId);
        else ids.add(nodeId);
      }
      setCheckId(Array.from(ids));
    }
  };

  const getProgress = async (
    async_id: string | number,
    total: number,
    type: string,
  ) => {
    const res: any = await request('/docapi/getBatchProgress', {
      method: 'POST',
      data: { type, async_id, total },
    });

    const { status, data } = res;
    if (status === 'ok') {
      setProgressFinished(data);
      if (data < total) {
        setTimeout(() => {
          getProgress(async_id, total, type);
        }, 1200);
      } else {
        // 已经全部处理结束
        setProgressOver(true);
        setCheckId([]);
        setTimeout(() => {
          setShowProgressModal(false);
        }, 400);
      }
    } else {
      setTimeout(() => {
        getProgress(async_id, total, type);
      }, 1200);
    }
  };

  const batchUpdateProp = async (payload: any) => {
    const res: any = await request('/docapi/batchUpdateProp', {
      method: 'POST',
      data: payload,
    });

    const { total, status, async_id } = res;
    if (status === 'ok') {
      setShowProgressModal(true);
      setProgressTotal(total);
      setTimeout(() => {
        getProgress(async_id, total, 'update');
      }, 1200);
    }
  };

  const getFirstEditNode = () => {
    if (checkId && checkId.length) {
      return new GetterPiNode(node.value.nodeManager.findChildren(checkId[0]));
    }

    return node;
  };

  const onBatchChangeStatus = (ids: string[]) => {
    if (nodeStatusModalRef.current) {
      nodeStatusModalRef.current.open(ids, viewTemplate, node);
    }
  };
  const onEditTempProps = (propSymbol: string, ids: string[]) => {
    propsEditRef.current.open(propSymbol, ids);
  };

  const onSort = (sortInfo: any) => {
    if (Object.keys(sortInfo).length) {
      const newsortIds = getSortDataByOrder(
        groupData,
        sortInfo,
        gridAttrsRef.current,
      );
      fullUpdateRecordSorts(newsortIds);
    }
  };

  // 草稿 && 行内新增时 忽略备注
  const { tabConfig } = contentPageGeneralTableConfig.tab || {};
  const ignoreComment =
    contentPageGeneralTableConfig?.isDrafts &&
    tabConfig &&
    tabConfig.addType === 'inline';
  // console.log('fuck', document.querySelector('#fuckoff'));

  return (
    <div className="flex flex-col flex-1 w-full h-full">
      <Tabs
        list={paginationModeGroup}
        active={tabActive}
        onChange={setTabActive}
      />
      <div
        id="react-pi-grid"
        style={{
          height: `calc(100% - ${paginationModeGroup.length ? 30 + 16 + 32 : 0}px - ${pagination ? 32 : 0}px)`,
        }}
        className="flex-1"
      >
        <div style={{ width: '100%', height: '100%' }} id={uniqueCanvasId} />
        <div className="react-pi-overlayer" />

        <Affix
          offsetBottom={0}
          target={
            containerId
              ? () => document.querySelector('#' + containerId)
              : undefined
          }
        >
          <div id={uniqueCanvasId + '-horizontal-scrollbar'} />
        </Affix>

        {/* 表格单元格编辑 */}
        <TableCellEdit
          cellInfo={cellInfo}
          orgId={currentSelection.selectSpace}
          setCellInfo={(v: any, toNext = true) => {
            setCellInfo(v);
            //@ts-ignore
            piGridRef.current.editor.moduleInstances.DataManager.isEdittingProp =
              false;
            setTimeout(() => {
              // @ts-ignore
              if (toNext) piGridRef.current.changeSelectNodeAttr('down');
            }, 0);
          }}
          template={viewTemplate}
          currentUser={currentUser}
          templateList={templateList}
          orgInfo={orgInfo}
          reRender={() => {
            piGridRef.current?.editor?.moduleInstances.Render.render();
          }}
          ignoreComment={ignoreComment}
          isChildTable={
            contentPageGeneralTableConfig &&
            contentPageGeneralTableConfig.maxTableHeight
          }
          onDraftNodeAdd={onDraftNodeAdd}
        />
        {calcPopover}
        <HeaderMoreActions
          setShowHeaderMoreActions={setShowHeaderMoreActions}
          showHeaderMoreActions={showHeaderMoreActions}
          // 显示列配置
          propDisplayConfig={localTableConfig}
          setPropDisplayConfig={setLocalTableConfig}
          saveTableConfig={saveTableConfig}
          selectSpace={currentSelection.selectSpace}
          template={viewTemplate}
          onPropUpdate={() => {
            // 表格重新渲染一下
            piGridRef.current?.editor?.moduleInstances.Render.render();
          }}
          attrContentAlign={attrContentAlign}
          setAttrContentAlign={setAttrContentAlign}
          attrsStyle={attrsStyle}
          setAttrsStyle={setAttrsStyle}
          setFixedAttrPool={setFixedAttrPool}
          fixedAttrPool={fixedAttrPool}
        />
        <AttachmentActions
          setShowAttachmentActions={setShowAttachmentActions}
          showAttachmentActions={showAttachmentActions}
          showBigImg={showImgs}
          orgInfo={orgInfo}
          handleViewDoc={handleViewDoc}
        />
        <GraphicCodePopover
          setGraphicCodeInfo={setGraphicCodeInfo}
          graphicCodeInfo={graphicCodeInfo}
        />
        <UnitMoreDetail
          templateList={templateList}
          templateMap={templateMap}
          userMap={userMap}
          unitDetail={unitDetail}
          setUnitDetail={setUnitDetail}
        />
        <EditAttrGroupProps
          attrGroupConfig={attrGroupConfig}
          setAttrGroupConfig={setAttrGroupConfig}
          currentUser={currentUser}
        />
        <NodeMainbodyAttachments
          setNodeMainbodyAttachments={setNodeMainbodyAttachments}
          nodeMainbodyAttachments={nodeMainbodyAttachments}
          showBigImg={showBigImg}
          handleViewDoc={handleViewDoc}
        />
        <NodeMainbodyContent
          setNodeMainbodyContent={setNodeMainbodyContent}
          nodeMainbodyContent={nodeMainbodyContent}
        />
        <NodeStatusModal
          ref={nodeStatusModalRef}
          userMap={userMap}
          orgId={currentSelection.selectSpace}
          onHandle={() => setCheckId([])}
        />
        {showChooseExportPdfDirectionModal && (
          <ChooseExportPdfDirection
            closeModal={() => setShowChooseExportPdfDirectionModal(false)}
            loading={genPDFLoading}
            ok={chooseExportPdfDirectionOk}
            defaultDirection={getDefaultExportPDFDirection(
              pdfNode,
              templateList,
            )}
            node={pdfNode}
            templateList={templateList}
          />
        )}
        {/* 新建 */}
        {/* @ts-ignore */}
        <DraftNodeModal id={draftId} />
        {/* 预览 */}
        {/* @ts-ignore */}
        <ViewContentNiceModal id={viewNodeId} />
        {/* 模板属性 */}
        <PropsEditModal
          ref={propsEditRef}
          tempId={viewTemplate?.template_id || null}
          tempProps={viewTemplate?.prop || []}
          userMap={userMap}
          node={getFirstEditNode()}
          orgId={currentSelection.selectSpace}
          userId={currentUser.userid}
          onHandle={batchUpdateProp}
        />
        {/* 展示节点 */}
        {nodesModalConfig && (
          <NodesModal
            closeModal={() => setNodesModalConfig(null)}
            config={nodesModalConfig}
            userMap={userMap}
            templateList={templateList}
            toNode={onPreviewNodeForModal}
          />
        )}
        {/* 展示条件匹配到的节点 */}
        {showQuoteNodesModal && orgConnection && (
          <QuoteNodes
            visible={true}
            onCancel={() => setShowQuoteNodesModal(false)}
            node={nowNode}
            propIndex={Number(propIndex)}
            templateList={templateList}
            orgConnection={orgConnection}
            orgId={currentSelection.selectSpace}
            toNode={onPreviewNodeForModal}
            addQuoteNodeCallback={piGridRef.current?.refreshChildNodes}
            originNodes={originNodes}
          />
        )}
        {statusMenu}
        <StatusMenu
          ref={statusChangeRef}
          useModal={true}
          spaceId={orgInfo.orgId}
          data={statusChangeNode}
        />
        {/* 编辑定位属性 */}
        <LocationSelect ref={locationSelectRef} />
        <Modal
          footer={null}
          open={showProgressModal}
          closable={progressOver}
          maskClosable={false}
          onCancel={() => {
            setShowProgressModal(false);
            initProgress();
          }}
        >
          <div style={{ padding: '24px 0 12px 0' }}>
            <div>{progressOver ? '修改完成' : '修改中'}</div>
            <Progress
              percent={Math.ceil((progressFinished * 100) / progressTotal)}
            />
          </div>
        </Modal>
      </div>
      {pagination && (
        <WattPagination
          checkNum={checkId.length}
          total={total}
          current={currentPage}
          pageSize={pageSize}
          onChange={handlePageChange}
        />
      )}
      {/* @ts-ignore */}
      <TriggerCustomButtonNiceModal id={uniqueCanvasId} />
    </div>
  );
};

export default memo(forwardRef(ReactPiGrid));
