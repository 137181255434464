import type { PiGridModules } from '../core';
import type { RowType } from '../modules/dataManager';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';

class OpenIcon extends GridBaseComponent {
  public data: RowType<'node'>;
  public type: string = 'openIcon';
  public captrue: boolean = false;

  public STYLE = {
    bg: '#eff1f5',
  };
  constructor(
    position: PositionType,
    moduleInstances: PiGridModules,
    data: RowType<'node'>,
  ) {
    super(position, moduleInstances);

    this.data = data;
  }

  public click() {
    this.moduleInstances.DataManager.config.onPreviewNode(this.data.node.id);
  }

  public render() {
    const { Draw, Render } = this.moduleInstances;
    const styleMode = Draw.config.styleMode;
    let iconColor = '#97a1b5';
    if (styleMode === 'darkness') iconColor = 'white';

    Draw.fillRoundRect(
      this.position,
      this.position.width / 2,
      styleMode === 'darkness' ? '#316EF5' : this.STYLE.bg,
    );

    Draw.attr({
      fillStyle: iconColor,
      textBaseline: 'middle',
      textAlign: 'center',
      font: `${Draw.npx(14)}px iconfont`,
    });

    const startY = this.position.y + this.position.height / 2;
    const startX = this.position.x + this.position.width / 2;

    // icon
    Draw.fillText(Draw.iconFont('&#xe708;'), startX, startY);

    if (this.isHover) {
      Render.setCursor('pointer');
    }
  }
}

export default OpenIcon;
