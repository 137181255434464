import { useControllableValue } from 'ahooks';
import type { InputNumberProps } from 'antd';
import { useMemo } from 'react';

import { PiInputNumber } from '@/components/InputNumber';
import { useTemplateMap } from '@/hook';

interface IProps {
  value?: string[];
  onChange?: (value?: string[]) => void;
  templateId?: string;
  propIndex?: string;
  size?: InputNumberProps['size'];
  placeholder?: string;
}

const NumberInput = (props: IProps) => {
  const { templateId, propIndex, size, placeholder, ...other } = props;
  const [_value, _onChange] = useControllableValue<string | null>(other);
  const templateMap = useTemplateMap();

  const prop = useMemo(() => {
    if (templateId && propIndex) {
      return templateMap[templateId].prop[+propIndex];
    }
    return null;
  }, [propIndex, templateId, templateMap]);

  const option: InputNumberProps = {};
  let extraClass = '';
  if (prop?.number?.numericalFormat) {
    option.addonAfter = '%';
  } else if (prop?.number?.unit) {
    option[prop?.number?.unitPosition ? 'addonBefore' : 'addonAfter'] =
      prop.number.unit;
    extraClass = prop.number?.unitPosition ? ' addon-before' : ' addon-after';
  }

  const handleNumberEditChange = (
    e: null | string,
    precision?: number,
    numericalFormat = 0,
  ) => {
    let saveValue = e;
    if (e === null) {
      saveValue = null;
    } else if (Number.isFinite(saveValue)) {
      if (Number.isFinite(precision)) {
        saveValue = (+saveValue!).toFixed(precision);
      }
      if (numericalFormat) {
        saveValue = `${+saveValue! / 100}`;
      }
    }
    _onChange(saveValue);
  };

  return (
    <PiInputNumber
      size={size}
      {...option}
      className={`edit-input${extraClass}`}
      style={{ width: '100%' }}
      value={
        _value ? (prop?.number?.numericalFormat ? +_value * 100 : _value) : null
      }
      placeholder={placeholder}
      precision={prop?.number?.precision}
      onChange={(e: any) =>
        handleNumberEditChange(
          e,
          prop?.number?.precision,
          prop?.number?.numericalFormat,
        )
      }
      onStep={(e: any) =>
        handleNumberEditChange(
          e,
          prop?.number?.precision,
          prop?.number?.numericalFormat,
        )
      }
    />
  );
};

export default NumberInput;
