import { assertExists, generateHtmlAttributes } from '@linkpi/utils';
import { useMountEffect, useUnmountEffect } from '@react-hookz/web';
import { Tabs } from 'antd';
import cls from 'clsx';
import type { GridStackNode } from 'gridstack';
import { GridStack } from 'gridstack';
import { defaultTo } from 'ramda';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';

import { RegularIcon } from '@/components/IconFont';
import SimpleTooltip from '@/components/SimpleTooltip';
import { useCurrentTemplate } from '@/hook/useCurrentTemplate';

import { GridComponentProvider } from '../../components';
import {
  useActiveWidgetInstance,
  useEditor,
  useWidgetInstance,
} from '../../hooks';
import type { LayoutPositionType } from '../../models/Store';
import { getRoot, getWidgetElement, limitWarn, setupDragIn } from '../../utils';
import type { ITabSectionConfig } from '../../widgets';
import type { ITabSectionContentSetting } from '../../widgets/TabSectionWidget/ContentSetting';
import { WidgetComponent } from './WidgetComponent';

import styles from './styles.less';

const TabpaneGridStack: FC<{
  tabSectionId: string;
  config: ITabSectionContentSetting['tabs'][number];
  tabId: string;
  type?: LayoutPositionType;
}> = ({ config, tabSectionId, type }) => {
  const { id: tabId } = config;
  const children = defaultTo([], config.children);

  const editor = useEditor();

  const gridStackContainerRef = useRef<any>({});
  const gridStackRef = useRef<GridStack>();

  const comps = (children || []).map((i: string) =>
    editor.store.getWidgetInstance(i),
  ); // 基础组件
  const compsKey = children?.join('@') || '';

  const compsMap = useRef<any>({});
  const compsRef = useRef<any>([]);
  compsRef.current = comps;

  const addEvents = () => {
    const gridStack = gridStackRef.current!;

    gridStack.on('added change', (event: any, items: GridStackNode[]) => {
      items.forEach(async (item) => {
        const { x, y, w, h, el } = item;

        if (!('id' in item)) {
          gridStack.removeWidget(el!, true, false);

          // left
          try {
            const v = await editor.addWidgetInstanceAsync(el!.dataset.widget!, {
              x: x!,
              y: y!,
              parentId: tabSectionId,
            })!;
            assertExists(v);
            editor.store.updateWidgetInstanceConfig<ITabSectionConfig>(
              tabSectionId,
              (c) => {
                c.tabs.find((t) => t.id === tabId)?.children.push(v.id);
              },
            );
          } catch (error) {
            console.log('🏮 取消新建');
          }
          return;
        }

        if (!compsRef.current.find((i: any) => i.id === item.id)) {
          // 别的容器过去的comp
          const v = editor.addWidgetInstance(item.widgetId, {
            id: item.id,
            x,
            y,
            w,
            h,
            parentId: tabSectionId,
          })!;
          editor.store.updateWidgetInstanceConfig<ITabSectionConfig>(
            tabSectionId,
            (c) => {
              c.tabs.find((t) => t.id === tabId)?.children.push(v?.id);
            },
          );
          gridStack.removeWidget(el!, true, false);
          return;
        }

        // change
        editor.store.resizeWidgetInstance(item.id!, { x, y, w, h });
      });
    });

    // 删除事件
    gridStack.on('removed', (event: any, items: any[]) => {
      const item = items[0];
      compsMap.current[item.id] = false;
      editor.store.updateWidgetInstanceConfig<ITabSectionConfig>(
        tabSectionId,
        (c) => {
          const tab = c.tabs.find((t) => t.id === tabId);
          if (!tab) return;
          tab.children = tab.children.filter((i) => i !== item.id);
        },
      );
      // remove
      editor.removeWidgetInstance(item.id, { type });
    });
  };

  useMountEffect(() => {
    gridStackRef.current = GridStack.addGrid(gridStackContainerRef.current, {
      margin: 5,
      acceptWidgets: (el: any) => {
        const widgetElement = getWidgetElement(el);
        if (widgetElement?.dataset?.nested === 'false') return true;

        return false;
      },
      cellHeight: 50, // 120 - masterMargin * 2 - padding * 2
      disableOneColumnMode: true,
      column: 12,
      sizeToContent: false,
    });
    setupDragIn();
    addEvents();
  });

  useUnmountEffect(() => {
    gridStackRef.current?.offAll();
    gridStackRef.current?.destroy();
  });

  const currentTemplate = useCurrentTemplate();

  useEffect(() => {
    comps.forEach((i: any) => {
      if (compsMap.current[i.id]) return;

      compsMap.current[i.id] = true;

      const widgetConfig = editor.widgetManager.getWidget(i.widgetId);
      // FIXME： widgetConfig 可能存在不存在
      const basic = widgetConfig?.basic || {};

      const htmlAttrs = generateHtmlAttributes({
        'data-nested': 'false',
        class: cls('comp', styles.compContainer),
        id: i.id,
        'data-dynamic-height': basic.dynamicHeight,
      });

      // demo div
      const content = `<div ${htmlAttrs}></div>`;

      const payload = { ...i, ...i.rect, content };
      if (basic.maxHeight) payload.maxH = basic.maxHeight;
      if (basic.maxWidth) payload.maxW = basic.maxWidth;
      if (basic.minHeight) payload.minH = basic.minHeight;
      if (basic.minWidth) payload.minW = basic.minWidth;

      gridStackRef.current!.addWidget(payload);

      setTimeout(() => {
        const root = getRoot(i.id);
        root.render(
          <GridComponentProvider currentTemplate={currentTemplate}>
            <WidgetComponent
              parentGridStack={gridStackRef.current!}
              compId={i.id}
            />
          </GridComponentProvider>,
        );
      }, 100);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compsKey]);

  return (
    <div
      style={
        children?.length
          ? {}
          : { height: '100%', display: 'flex', alignItems: 'center' }
      }
      ref={gridStackContainerRef}
      className={cls('grid-stack', 'min-h-full')}
    >
      {children.length < 1 && (
        <div className={styles.tip}>请从左侧选择组件拖入</div>
      )}
    </div>
  );
};

// 选项卡
export const TabSection: FC<{
  gridId: string;
  parentGridStack: GridStack;
  type: LayoutPositionType;
}> = (props) => {
  const { parentGridStack, gridId, type } = props;

  const item = useWidgetInstance<ITabSectionConfig>(gridId)!;

  const editor = useEditor();
  // 当前激活的类
  const activeWidgetInstance = useActiveWidgetInstance<ITabSectionConfig>();

  if (!item) return null;

  const selectTabSection = () => {
    editor.setActiveWidgetInstance(gridId);
  };

  const delTabSection = () => {
    editor.removeWidgetInstance(gridId, { type });
    const parentElement =
      document.getElementById(gridId)?.parentElement?.parentElement;
    if (parentElement) parentGridStack.removeWidget(parentElement, true);
  };

  const copyTabSection = () => {
    if (editor.cloneWidgetInstance) editor.cloneWidgetInstance(gridId);
  };

  const renderTools = () => {
    if (activeWidgetInstance?.id !== gridId) return null;
    const canClone = editor.checkWidgetClonable(gridId);
    const extraClassName = canClone ? '' : ' ' + styles.notClone;

    return (
      <div className={styles.tabSectionToolsContainer}>
        <SimpleTooltip title={limitWarn} showTooltip={!canClone}>
          <div
            onClick={() => {
              if (canClone) copyTabSection();
            }}
            className={styles.copy + extraClassName}
          >
            <RegularIcon type="iconfuzhi2" color="#6B7A96" />
            <span className="text-[#6B7A96] pl-1">复制</span>
          </div>
        </SimpleTooltip>
        <div onClick={delTabSection} className={cls(styles.del, 'ml-[6px]')}>
          <RegularIcon type="iconshanchu2" color="#6B7A96" />
        </div>
      </div>
    );
  };

  return (
    <div
      className={cls(
        activeWidgetInstance?.id === gridId ? styles.activeTabSection : '',
        styles.tabGridStack,
      )}
      style={{
        ['--bg-color' as any]: item.config.bgColor,
      }}
      onClick={selectTabSection}
    >
      {renderTools()}
      <Tabs
        type="card"
        items={item.config.tabs?.map((t) => ({
          label: (
            <div className="flex gap-1 items-center">
              {t.iconImg && (
                <img
                  className="h-5 w-5 object-scale-down"
                  src={t.iconImg}
                  alt=""
                />
              )}
              <span className="h-5">{t.name}</span>
            </div>
          ),
          key: item.id + t.id,
          children: (
            <TabpaneGridStack
              config={t}
              tabId={item.id}
              tabSectionId={gridId}
              type={type}
            />
          ),
        }))}
      />
    </div>
  );
};
