import type { ToolConfigV2 } from './utils';

/**
 * 数据表格最新版本
 *
 * version: 2
 * tools type: string[] -> ToolConfigV2[]
 */
export const DATA_TABLE_LATEST_VERSION = 2 as const;

export const DefaultTools: ToolConfigV2[] = [
  {
    type: 'add',
    show: false,
    value: '',
  },
];

export const ToolLabelMap: Record<ToolConfigV2['type'], string> = {
  add: '新增按钮',
};
