import type { GetterPiNode } from '@linkpi/core/web';
import Delta from 'quill-delta';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useTemplateInfo } from '@/hook';
import { getAttachmentValue } from '@/utils/utils';

export const YitiGameStatusMap = {
  /** 已撤回 */
  withdrawn: 0,
  /** 待审核 */
  pendingReview: 4,
  /** 已通过 */
  approved: 1,
  /** 不通过 */
  rejected: 3,
};

export const YitiGameRegistrationMethod = {
  平台报名系统: '平台报名系统',
  其他报名系统: '其他报名系统',
};

export type GroupProjectType = {
  data: GroupProjectDataItemType[];
  display: GroupProjectDisplayType;
};

export type GroupProjectDataItemType = {
  /** 编号 */
  seqId?: string;
  /** 项目/组别名称 */
  title: string;
  /** 性别 */
  gender?: '男' | '女' | '不限';
  /** 分类 */
  category?: string;
  /** 年龄下限 */
  ageMin?: number;
  /** 年龄上限 */
  ageMax?: number;
  /** 收费 */
  fee?: string;
  /** 级别 */
  level?: string;
  /** 人数 */
  personCount?: number;
  /** 备注 */
  remark?: string;
};

export type GroupProjectDisplayType = {
  [K in keyof GroupProjectDataItemType]?: boolean;
};

export const YitiGamePropMap = {
  // 默认分组
  /** 赛事ID */
  gameId: 0,
  /** 是否线上发布 */
  isPublish: 38,
  /** 赛事编号 */
  gameNumber: 1,
  /** 赛事成绩 */
  gameScore: 10,
  /** 赛事成绩开关示 */
  gameScoreToggle: 11,
  /** 活动照片 */
  gamePhotos: 12,
  /** 活动照片开关 */
  gamePhotosToggle: 13,
  /** 提交时间 */
  submissionTime: 19,
  /** 发布时间 */
  publishTime: 21,
  // 基本信息
  /** 确认函 */
  confirmationFile: 6,
  /** 活动类型 */
  activityType: 29,
  /** 赛事名称 */
  gameName: 1,
  /** 活动时间开始时间 */
  gameStartDate: 16,
  /** 活动时间结束时间 */
  gameEndDate: 17,
  /** 报名时间开始时间 */
  registrationStartDate: 14,
  /** 报名时间结束时间 */
  registrationEndDate: 15,
  /** 举办城市 */
  gameCity: 9,
  /** 举办场地 */
  gameVenue: 25,
  /** 主办单位 */
  organizer: 5,
  /** 承办单位 */
  issuingAuthority: 2,
  /** 咨询电话 */
  phone: 24,
  /** 赛事标签 */
  gameTags: 7,
  /** 赛事封面 */
  cover: 8,
  /** 备注（选填） */
  remark: 18,
  // 竞赛说明
  /** 赛事活动通知 */
  gameRules: 3,
  /** 组别项目 */
  groupsProject: 23,
  /** 组别项目file（其他报名） */
  groupsProjectFile: 37,
  /** 赛程/流程 */
  gameSchedule: 4,
  /** 报名方式 */
  registrationMethod: 26,
  /** 外部报名链接 */
  registrationLink: 22,
  /** 收款二维码 */
  paymentQR: 27,
  /** 收款备注 */
  paymentRemark: 28,
  /** 是否会员优享 */
  isVip: 30,
};

export const GameActivityOptions = {
  赛事: '赛事',
  集训: '集训',
};

export type YitiGameFormDataType = {
  /** 确认函 */
  confirmationFile: { name: string; src: string }[];
  /** 活动类型 */
  activityType: keyof typeof GameActivityOptions;
  /** 赛事名称 */
  gameName: string;
  /** 赛事活动时间 */
  gameTime: number[];
  /** 报名时间 */
  registrationTime: number[];
  /** 举办城市 */
  gameCity: string;
  /** 举办城市code */
  gameCityCode: string;
  /** 举办场地 */
  gameVenue: string;
  /** 主办单位 */
  organizer: string;
  /** 承办单位 */
  issuingAuthority: string;
  /** 咨询电话 */
  phone: string;
  /** 赛事标签 */
  gameTags: string[];
  /** 赛事封面 */
  cover: { name: string; src: string }[];
  /** 备注（选填） */
  remark: string;
  /** 赛事活动通知 */
  gameRules: any;
  /** 组别项目 */
  groupsProject: GroupProjectType;
  /** 组别项目file（其他报名） */
  groupsProjectFile: { name: string; src: string }[];
  /** 赛程/流程 */
  gameSchedule: any;

  /** 报名 */
  /** 报名方式 */
  registrationMethod: string;
  /** 报名链接 */
  registrationLink: string;
  /** 收款二维码 */
  paymentQR: { name: string; src: string }[];
  /** 收款备注 */
  paymentRemark: string;

  /** 发布结果相关信息 */
  /** 赛事成绩开关 */
  gameScoreToggle: boolean;
  /** 赛事成绩链接地址 */
  gameScore: string;
  /** 活动照片开关 */
  gamePhotosToggle: boolean;
  /** 活动照片链接地址 */
  gamePhotos: string;
  /** 是否会员优享 */
  isVip: boolean;
  /** 是否发布 */
  isPublish: boolean;
};

const getNormalGroupProject = (jsondata?: any): GroupProjectType => {
  const defaultData = {
    data: [],
    display: {
      seqId: true,
      title: true,
      gender: true,
      category: true,
      ageMin: true,
      ageMax: true,
      fee: true,
      level: true,
      personCount: true,
      remark: true,
    },
  };
  if (!jsondata) {
    return defaultData;
  }
  try {
    const resData = JSON.parse(jsondata);
    // 读一下data和display 确保数据是正常的
    return resData;
  } catch (error) {
    return defaultData;
  }
};

const getDefaultFormData = (): YitiGameFormDataType => {
  return {
    confirmationFile: [],
    activityType: GameActivityOptions.赛事 as keyof typeof GameActivityOptions,
    gameName: '',
    gameTime: [],
    registrationTime: [],
    gameCity: '',
    gameCityCode: '',
    gameVenue: '',
    organizer: '',
    issuingAuthority: '',
    phone: '',
    gameTags: [],
    cover: [],
    remark: '',
    gameRules: new Delta(),
    groupsProject: getNormalGroupProject(),
    groupsProjectFile: [],
    gameSchedule: new Delta(),
    registrationMethod: YitiGameRegistrationMethod.平台报名系统,
    registrationLink: '',
    paymentQR: [],
    paymentRemark: '',
    gameScoreToggle: false,
    gameScore: '',
    gamePhotosToggle: false,
    gamePhotos: '',
    isVip: false,
    isPublish: false,
  };
};

export const useGameInfoForm = (getterNode: GetterPiNode | null) => {
  const [gameData, setGameData] =
    useState<YitiGameFormDataType>(getDefaultFormData());
  const tempInfo = useTemplateInfo(getterNode?.value.tempInfo.id || '');

  useEffect(() => {
    refreshGameData();
  }, [getterNode]);

  const statusConfig = useMemo(() => {
    const index = getterNode?.value.tempInfo.status;
    if (!tempInfo || isNaN(Number(index))) return null;
    return tempInfo.task_status[Number(index)];
  }, [getterNode, tempInfo]);

  const _getAttachmentValueToValue = (
    file: { fileName: string; src: string }[],
  ) => {
    return file.map((item) => {
      return {
        name: item.fileName,
        src: item.src,
      };
    });
  };

  const refreshGameData = useCallback(() => {
    if (!getterNode) return;
    const gameNode = Object.entries(YitiGamePropMap).reduce(
      (acc, [key, index]) => {
        acc[key as keyof typeof YitiGamePropMap] =
          getterNode.value.tempInfo.prop[index];
        return acc;
      },
      {} as Record<keyof typeof YitiGamePropMap, any>,
    );
    const confirmationFileData = _getAttachmentValueToValue(
      getAttachmentValue(getterNode.value, YitiGamePropMap.confirmationFile),
    );

    const coverData = _getAttachmentValueToValue(
      getAttachmentValue(getterNode.value, YitiGamePropMap.cover),
    );

    const gameRulesDelta = convertDeltaStrToDelta(gameNode.gameRules);

    const gameScheduleData = convertDeltaStrToDelta(gameNode.gameSchedule);

    const gamePaymentQRData = _getAttachmentValueToValue(
      getAttachmentValue(getterNode.value, YitiGamePropMap.paymentQR),
    );

    const groupsProjectFileData = _getAttachmentValueToValue(
      getAttachmentValue(getterNode.value, YitiGamePropMap.groupsProjectFile),
    );

    setGameData({
      confirmationFile: confirmationFileData,
      activityType: gameNode.activityType || GameActivityOptions.赛事,
      gameName: gameNode.gameName,
      gameTime: [gameNode.gameStartDate, gameNode.gameEndDate],
      registrationTime: [
        gameNode.registrationStartDate,
        gameNode.registrationEndDate,
      ],
      gameCity: gameNode.gameCity,
      gameCityCode:
        getterNode.value.prop._sys_location?.[YitiGamePropMap.gameCity]?.[0]
          .value || '',
      gameVenue: gameNode.gameVenue,
      organizer: gameNode.organizer,
      issuingAuthority: gameNode.issuingAuthority,
      phone: gameNode.phone,
      gameTags: gameNode.gameTags,
      cover: coverData,
      remark: gameNode.remark,
      gameRules: gameRulesDelta,
      groupsProject: getNormalGroupProject(gameNode.groupsProject),
      groupsProjectFile: groupsProjectFileData,
      gameSchedule: gameScheduleData,
      registrationMethod:
        gameNode.registrationMethod || YitiGameRegistrationMethod.其他报名系统,
      registrationLink: gameNode.registrationLink,
      paymentQR: gamePaymentQRData,
      paymentRemark: gameNode.paymentRemark,

      gameScoreToggle: gameNode.gameScoreToggle === '开' || false,
      gamePhotosToggle: gameNode.gamePhotosToggle === '开' || false,
      gameScore: gameNode.gameScore,
      gamePhotos: gameNode.gamePhotos,
      isVip: gameNode.isVip === '是' || false,
      isPublish: gameNode.isPublish === '是' || false,
    });
  }, []);

  return {
    gameData,
    statusConfig,
  };
};

function convertDeltaStrToDelta(deltaStr: string | null) {
  try {
    if (!deltaStr) return new Delta();
    const deltaJson = JSON.parse(deltaStr);
    return new Delta(deltaJson.ops);
  } catch (e) {
    return new Delta();
  }
}
