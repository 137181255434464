import { useSessionStorageValue } from '@react-hookz/web';
import { match, P } from 'ts-pattern';

import { DEFAULT_LAYOUT } from '@/consts';

import { useOrgInfo } from './useOrg';
import { useCurrentUser } from './useOrgUser';

export const useNavMode = () => {
  const [orgInfo] = useOrgInfo();
  const currentUser = useCurrentUser();

  const { value: layoutFromNavMode } = useSessionStorageValue('nav_mode');

  if (layoutFromNavMode) {
    return layoutFromNavMode as string;
  }

  if (!orgInfo) return DEFAULT_LAYOUT;

  const layout = match(orgInfo.org_option?.navMode)
    .with({ layout: P.string, userLayout: P.nullish }, (v) => v.layout)
    .with(
      { userLayout: { [currentUser.userid]: P.string } },
      (v) => v.userLayout[currentUser.userid],
    )
    .with({ layout: P.string }, (v) => v.layout)
    .with(P.string, (v) => v)
    .with({ [currentUser.userid]: P.select() }, (v) => v)
    .otherwise(() => DEFAULT_LAYOUT);

  return layout;
};

export const useSetNavMode = () => {
  const { set } = useSessionStorageValue('nav_mode');

  return set;
};
