import type { ApiResponse } from '@linkpi/core';
import { toRecord } from '@linkpi/utils';
import { message, Modal } from 'antd';
import cls from 'classnames';
import { useAtom } from 'jotai';
import type { ForwardRefRenderFunction } from 'react';
import {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import type { FormulaItem } from '@/services/formula';
import { deleteCustomFormula, updateCustomFormula } from '@/services/formula';

import { formulaListAtom } from '../../store';
import type { TabKey, TokenType } from '../constantTokens';
import {
  COMMON_FUNCTION,
  getTempPropTokens,
  LOGIC_FUNCTION,
  NUMBER_FUNCTION,
  STATUS_RELATED__PROPS,
  STRING_FUNCTION,
  SYSTEM_PROPS,
} from '../constantTokens';
import type { ConvertCodeToHtmlRefHandleType } from '../ConvertCodeToHtml';
import ConvertCodeToHtml from '../ConvertCodeToHtml';
import { showEditFormula } from '../CustomFormulaModal';
import PickItem from './PickItem';

import styles from './styles.less';

const TAB_LIST: {
  name: string;
  type: TabKey;
}[] = [
  { name: '全部', type: 'all' },
  { name: '属性', type: 'props' },
  { name: '函数', type: 'function' },
  { name: '自定义', type: 'custom' },
];

export type PickerRefHandlerType = {
  filter: (key: string) => void;
};

type PropsType = {
  template: ApiResponse.CurrentUser.TemplateInfo;
  onPick: (token: TokenType, extend: any) => void;
  fullscreen: boolean;
};

const Picker: ForwardRefRenderFunction<PickerRefHandlerType, PropsType> = (
  props,
  ref,
) => {
  useImperativeHandle(ref, () => ({
    filter(key: string) {
      setFilterOption({
        type: 'all',
        filterKey: key,
      });
    },
  }));

  const { template, onPick, fullscreen } = props;

  const [formulaList, refresh] = useAtom(formulaListAtom);

  const customFormulaGroup = useMemo(() => {
    return [
      {
        groupName: '自定义公式',
        list: formulaList.map<TokenType>((f) => ({
          name: f.name,
          icon: 'icongongshi',
          example: {
            result: f.formula_text,
          },
          desc: f.note,
          insert: {
            code: f.formula_text,
          },
          key: f.formula_id,
        })),
      },
    ];
  }, [formulaList]);

  const customFormulaMap = useMemo(
    () =>
      toRecord<FormulaItem>((x) => ({
        [x.formula_id]: x,
      }))(formulaList),
    [formulaList],
  );

  // 过滤的option
  const [filterOption, setFilterOption] = useState<{
    type: TabKey;
    filterKey: string;
  }>({
    type: 'all',
    filterKey: '',
  });
  // 当前选中token的key
  const [pickTokenKey, setPickTokenKey] = useState<string>('');
  // 转换code为预览的html
  const ConvertCodeToHtmlRef = useRef<ConvertCodeToHtmlRefHandleType>(null);
  // code的html
  const [pickCodeHtml, setPickCodeHtml] = useState('');

  const [displayTokenGroups, tokensMap]: [
    { groupName: string; list: TokenType[] }[],
    Record<string, TokenType>,
  ] = useMemo(() => {
    /**
     * 属性分组
     */
    const tempPropTokens = getTempPropTokens(template);
    const PROPS_GROUPS = [
      { groupName: '系统属性', list: SYSTEM_PROPS },
      { groupName: '状态相关', list: STATUS_RELATED__PROPS },
      { groupName: '主题属性', list: tempPropTokens },
    ];
    /**
     * 函数分组
     */
    const FUNCTION_GROUPS = [
      { groupName: '数字函数', list: NUMBER_FUNCTION },
      { groupName: '文本函数', list: STRING_FUNCTION },
      { groupName: '逻辑函数', list: LOGIC_FUNCTION },
      { groupName: '通用函数', list: COMMON_FUNCTION },
    ];

    let displayTokenGroups = [];
    if (filterOption.type === 'all' || filterOption.type === 'props') {
      displayTokenGroups.push(...PROPS_GROUPS);
    }
    if (filterOption.type === 'all' || filterOption.type === 'function') {
      displayTokenGroups.push(...FUNCTION_GROUPS);
    }
    if (filterOption.type === 'all' || filterOption.type === 'custom') {
      displayTokenGroups.push(...customFormulaGroup);
    }

    if (filterOption.filterKey) {
      // 直接过滤
      displayTokenGroups = displayTokenGroups.reduce(
        (res, group) => {
          const item = { ...group };
          item.list = [...item.list].filter((x) => {
            /**
             * 有自定义过滤方法的 token 判断
             * @example 随机编号, 当前时间
             */
            if (typeof x.filterFn === 'function') {
              return x.filterFn(filterOption);
            }

            if (!x.name) return false;

            // 通过名字过滤
            return ~x.name
              .toLocaleLowerCase()
              .indexOf(filterOption.filterKey.toLocaleLowerCase());
          });
          if (item.list.length) {
            res.push(item);
          }
          return res;
        },
        [] as { groupName: string; list: TokenType[] }[],
      );

      // 对每组遍历 匹配度
      // displayTokenGroups = displayTokenGroups
      //   .map((group) => {
      //     // 分组 匹配度
      //     let groupMaxSimilarity = 0;
      //     const list = [...group.list]
      //       .map((token) => {
      //         // token 匹配度
      //         const tokenSimilarity = getSimilarity(filterOption.filterKey, token.name);
      //         groupMaxSimilarity = Math.max(groupMaxSimilarity, tokenSimilarity);
      //         return {
      //           ...token,
      //           similarity: tokenSimilarity,
      //         };
      //       })
      //       .filter((x) => x.similarity > 0.2)
      //       .sort((a, b) => b.similarity - a.similarity);
      //     return {
      //       ...group,
      //       list,
      //       groupMaxSimilarity,
      //     };
      //   })
      //   .filter((x) => x.groupMaxSimilarity > 0.2 && x.list.length)
      //   .sort((a, b) => b.groupMaxSimilarity - a.groupMaxSimilarity);
    }

    // key map
    const tokensMap = displayTokenGroups.reduce(
      (a, b) => {
        b.list.map((x) => {
          a[x.name] = x;
        });
        return a;
      },
      {} as Record<string, TokenType>,
    );

    // 变化后 设置pickTokenKey为第一个
    const newTokenKey = displayTokenGroups?.[0]?.list?.[0]?.name || '';
    setPickTokenKey(newTokenKey);

    return [displayTokenGroups, tokensMap];
  }, [template, filterOption, customFormulaGroup]);

  // 当前选中token
  const currentPick = tokensMap[pickTokenKey];

  useEffect(() => {
    let codeHtml = '';
    const constItem = tokensMap[pickTokenKey];
    if (constItem?.example?.code) {
      codeHtml =
        ConvertCodeToHtmlRef.current?.getHtml(constItem.example.code) || '';
    }
    setPickCodeHtml(codeHtml);
  }, [pickTokenKey]);

  /** handle */

  // 点击tab
  const onTabClick = (type: TabKey) => {
    setFilterOption({
      type: type,
      filterKey: '',
    });
  };

  // 鼠标移入
  const onItemHover = (key: string) => {
    if (pickTokenKey === key) return;
    setPickTokenKey(key);
  };

  const onMouseDown = (e: any) => {
    e.preventDefault();
  };

  const handleFormulaEdit = async (id: string) => {
    const customFormula = customFormulaMap[id];
    const values = await showEditFormula({
      note: customFormula.note,
      name: customFormula.name,
    });
    const res = await updateCustomFormula({
      formula_id: id,
      ...values,
    });
    if (res.status === 'ok') {
      message.success('修改成功!');
      refresh();
    } else {
      message.error((res.message as string) || '修改失败!');
    }
  };

  const handleFormulaDelete = async (id: string) => {
    const customFormula = customFormulaMap[id];
    Modal.confirm({
      title: `确定删除自定义公式「${customFormula.name}」?`,
      onOk: async () => {
        const res = await deleteCustomFormula({
          formula_id: id,
        });
        if (res.status === 'ok') {
          message.success('删除成功!');
          refresh();
        } else {
          message.error((res.message as string) || '删除失败!');
        }
      },
    });
  };

  return (
    <div className={cls(styles.picker, fullscreen ? styles.full : '')}>
      {/* list tab */}
      <div className={styles.tab}>
        {TAB_LIST.map((x) => (
          <div
            key={x.type}
            className={cls(
              styles.tabItem,
              filterOption.type === x.type ? styles.tabActive : '',
            )}
            onClick={(_) => onTabClick(x.type)}
          >
            {x.name}
          </div>
        ))}
      </div>

      {/* pick list */}
      <PerfectScrollbar
        className={cls(
          styles.list,
          filterOption.type === 'all' ? styles.listFixRadius : '',
          filterOption.type === 'custom' ? 'rounded-tr-none' : '',
        )}
        onMouseDown={onMouseDown}
      >
        {displayTokenGroups.map((group, groupIndex) => (
          <Fragment key={groupIndex}>
            <div className={styles.groupName}>{group.groupName}</div>
            {group.list.map((x) => (
              <PickItem
                key={x.name}
                token={x}
                active={pickTokenKey === x.name}
                onItemHover={() => onItemHover(x.name)}
                onItemClick={(extendInfo: any) => onPick(x, extendInfo)}
                // 目前只有自定义公式有编辑和删除，key 是对应的 id
                onEdit={x.key ? () => handleFormulaEdit(x.key!) : undefined}
                onDelete={x.key ? () => handleFormulaDelete(x.key!) : undefined}
              />
            ))}
          </Fragment>
        ))}
        {!displayTokenGroups.length ? (
          <div className={styles.listPlaceholder}>暂无搜索结果</div>
        ) : null}
      </PerfectScrollbar>

      {/* pick preview */}
      <PerfectScrollbar className={styles.preview}>
        {currentPick ? (
          <>
            <div className={styles.name}>{currentPick.name}</div>
            <div className={styles.subTitle}>示例</div>
            <div className={styles.code}>
              <p
                className={cls(styles.codePrev, 'cm-s-default')}
                dangerouslySetInnerHTML={{ __html: pickCodeHtml }}
              />
              <p className={styles.codeRes}>{currentPick.example.result}</p>
            </div>
            <div className={styles.subTitle}>说明</div>
            <div className={styles.code}>{currentPick.desc}</div>
          </>
        ) : (
          <div className={styles.previewPlaceholder}>
            请选择左侧的函数或属性查看
          </div>
        )}
      </PerfectScrollbar>

      {/* 转换example code的编辑器 */}
      <ConvertCodeToHtml ref={ConvertCodeToHtmlRef} />
    </div>
  );
};

export default forwardRef(Picker);
