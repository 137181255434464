import type { ProFormColumnsType } from '@ant-design/pro-form';
import ProForm, { BetaSchemaForm } from '@ant-design/pro-form';
import type { NiceModalHocProps } from '@ebay/nice-modal-react';
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { Modal } from 'antd';
import { useMemo, useState } from 'react';
import { match } from 'ts-pattern';

interface FormulaFormValues {
  name: string;
  note: string;
}

type IProps =
  | {
      type: 'add';
    }
  | {
      type: 'edit';
      value: FormulaFormValues;
    };

const layout = {
  labelCol: { span: 4 },
  // wrapperCol: { span: 16 },
};

const CustomFormulaModal = create((props: IProps) => {
  const modal = useModal();
  const modalV5 = antdModalV5(modal);
  const title = useMemo(
    () =>
      match(props)
        .with({ type: 'add' }, () => '新建自定义公式')
        .with({ type: 'edit' }, () => '修改自定义公式')
        .exhaustive(),
    [props],
  );
  const [form] = ProForm.useForm<FormulaFormValues>();
  const columns: ProFormColumnsType<FormulaFormValues>[] = [
    {
      title: '公式标题',
      dataIndex: 'name',
      formItemProps: {
        rules: [{ required: true, message: '请输入公式标题' }],
      },
      fieldProps: {
        placeholder: '请输入公式标题',
        maxLength: 50,
      },
    },
    {
      title: '公式说明',
      dataIndex: 'note',
      valueType: 'textarea',
      fieldProps: {
        placeholder: '请输入公式说明',
        maxLength: 200,
      },
    },
  ];
  const initialValues = useMemo(
    () =>
      match(props)
        .with({ type: 'add' }, () => undefined)
        .with({ type: 'edit' }, (p) => p.value)
        .exhaustive(),
    [props],
  );

  const [submitLoading, toggleSubmitLoading] = useState(false);
  const handleOk = async () => {
    toggleSubmitLoading(true);
    try {
      const values = await form.validateFields();
      modal.resolve(values);
      modalV5.onCancel();
    } catch (error) {
      console.log(error);
    } finally {
      toggleSubmitLoading(false);
    }
  };

  return (
    <Modal
      {...modalV5}
      title={title}
      width={520}
      onOk={handleOk}
      confirmLoading={submitLoading}
    >
      <BetaSchemaForm<FormulaFormValues>
        layout="horizontal"
        form={form}
        columns={columns}
        submitter={false}
        initialValues={initialValues}
        {...layout}
      />
    </Modal>
  );
});
export const showAddFormula = () =>
  show<FormulaFormValues, IProps & NiceModalHocProps, IProps>(
    CustomFormulaModal,
    {
      type: 'add',
    },
  );
export const showEditFormula = (value: FormulaFormValues) =>
  show<FormulaFormValues, IProps & NiceModalHocProps, IProps>(
    CustomFormulaModal,
    { type: 'edit', value },
  );
