import type { CurrentUser } from '@linkpi/core';
import { DEFAULT_TEMPLATE } from '@linkpi/core';
import { toRecord } from '@linkpi/utils';
import { useWhyDidYouUpdate } from 'ahooks';
import { memoize } from 'lodash';
import { useEffect, useMemo } from 'react';
import { match } from 'ts-pattern';

import { useFilterTemplateMap } from './useFilterTemplateList';
import { useOrgInfo } from './useOrg';
import { useOrgTemplatesSettingInfoMap } from './useOrgSetting';

export type TemplateMapUsage =
  | 'fullAccess'
  | 'filteredProps'
  | 'userRestrictedAccess';

const getFilteredPropsTemlateList = memoize(
  (orgInfo: CurrentUser.OrgInfo | null) => {
    if (!orgInfo || !Array.isArray(orgInfo.templateList)) return [];
    return match(orgInfo)
      .with({ newDefault: 1 }, ({ templateList }) =>
        templateList?.filter((x) => x.template_id !== DEFAULT_TEMPLATE),
      )
      .otherwise(({ templateList }) => templateList);
  },
);

export const useTemplateList = (orgId?: string) => {
  if (location.pathname.includes('/space')) {
    console.warn(
      '获取了应用测的主题类型配置，应该使用配置测的主题类型配置 useOrgSetting',
    );
  }

  const [orgInfo] = useOrgInfo(orgId);

  const list = useMemo(() => getFilteredPropsTemlateList(orgInfo), [orgInfo]);

  return list;
};

const buildTempMap = memoize(
  toRecord((t: CurrentUser.TemplateInfo) => ({ [t.template_id]: t })),
);

/**
 * 获取所有主题类型配置，
 * 主题类型的属性可见权限根据当前用户在空间中的角色决定
 */
export const useTemplateMap = (orgId?: string) => {
  const list = useTemplateList(orgId);

  const map = useMemo(() => buildTempMap(list), [list]);

  return map;
};

/**
 * 获取所有主题类型配置，
 * 主题类型的属性可见权限根据当前用户在空间中的角色决定
 */
export const useOrgTempMap = useTemplateMap;

export const useTemplateInfo = (templateId: string) => {
  const templateMap = useTemplateMap();

  return templateMap[templateId];
};

/**
 * 根据不同场景获取主题类型配置
 *
 * @param usage 使用场景
 * - 'fullAccess': 可以访问所有主题类型及其完整配置
 * - 'filteredAttributes': 可以访问所有主题类型，但属性会根据用户权限过滤
 * - 'userRestrictedAccess': 根据用户权限限制可见的主题类型和属性
 */
export const useUnsafeTemplateMap = (usage: TemplateMapUsage) => {
  useEffect(() => {
    console.warn('useUnsafeTemplateMap 参数应该是静态值');
  }, [usage]);

  useWhyDidYouUpdate('useUnsafeTemplateMap', [usage]);

  if (usage === 'fullAccess') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useOrgTemplatesSettingInfoMap();
  }

  if (usage === 'filteredProps') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useTemplateMap();
  }

  if (usage === 'userRestrictedAccess') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useFilterTemplateMap();
  }

  throw new Error('无效的类型');
};
