import {
  ACT,
  NODE_ACL_MAP,
  NODE_PERMISSION,
  setNodeAcl,
  updateNodeSysProp,
} from '@linkpi/core';
import { GetterPiNode } from '@linkpi/core/web';
import { useSelector } from '@umijs/max';
import { Button, Checkbox, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';

import { fetchUserList, queryGroups } from '@/services/space';
import request from '@/utils/request';

import Group from './Group';
import StructureAcl from './StructureAcl';

import './index.less';

function getAclKey(acl) {
  const str = acl ? JSON.stringify(Object.keys(acl).map(Number)) : '';
  let resKey = null;
  Object.keys(NODE_ACL_MAP).map((key) => {
    if (JSON.stringify(NODE_ACL_MAP[key]) === str) {
      resKey = key;
    }
  });
  return resKey;
}

const PermissionModal = (props) => {
  let { data, visible, onCancel, batchNodes } = props;
  if (batchNodes && batchNodes.length) data = new GetterPiNode(batchNodes[0]);

  const [tabIndex, setTabIndex] = useState(0);
  const [memberIndex, setMemberIndex] = useState(0);

  // 分组权限
  const [nodeGroupAclData, setNodeGroupAclData] = useState({});
  const [changedGroupAclData, setChangedGroupAclData] = useState({});
  const [nodeUserAclData, setNodeUserAclData] = useState({});
  const [changedUserAclData, setChangedUserAclData] = useState({});
  // 权限数据
  const [nodeAclData, setNodeAclData] = useState({});
  const [changedAcl, setChangedAcl] = useState({});
  const [loading, setLoading] = useState(false);

  const { currentSelection } = useSelector((state) => state.workspace);
  const { currentUser } = useSelector((state) => state.user);

  const init = async () => {
    if (!data) return;
    const _data = data.node;
    let groupList = [];
    let users = [];
    const res = await queryGroups({ org_id: currentSelection.selectSpace });
    const user_res = await fetchUserList({
      org_id: currentSelection.selectSpace,
    });
    if (res.status === 'ok') groupList = res.data;
    if (user_res.status === 'ok')
      users = user_res.data.filter(
        (x) => x.account_id !== currentUser.userid && x.role !== 8,
      );

    const nodeGroupAclData = {};
    const nodeUserAclData = {};
    // 获取分组权限数据
    groupList.forEach((g) => {
      const { group_id } = g;

      // 管理员 就是管理员权限
      if (group_id === '-2') {
        // nodeGroupAclData[group_id] = String(NODE_PERMISSION.管理);
        return;
      }

      nodeGroupAclData[group_id] = _data.prop._sys_group_acl
        ? getAclKey(_data.prop._sys_group_acl[group_id])
        : _data.prop._sys_user_acl
          ? getAclKey(_data.prop._sys_user_acl[group_id])
          : null;
    });

    // 人员权限数据
    users.forEach((u) => {
      const { account_id, role } = u;

      if (role === ACT.组织Onwer || role === ACT.组织编辑) {
        nodeUserAclData[account_id] = String(NODE_PERMISSION.管理);
        return;
      }

      nodeUserAclData[account_id] = _data.prop._sys_user_acl
        ? getAclKey(_data.prop._sys_user_acl[account_id])
        : null;
    });

    setNodeGroupAclData(nodeGroupAclData);
    setNodeUserAclData(nodeUserAclData);
    setNodeAclData({
      _sys_public: _data?.prop._sys_public,
      _sys_na_readonly: _data?.prop._sys_na_readonly,
      _sys_readonly: _data?.prop._sys_readonly,
      _sys_protect: _data?.prop._sys_protect,
      _sys_na_protect: _data?.prop._sys_na_protect,
      _sys_na_banCopy: _data?.prop._sys_na_banCopy,
      _sys_banCopy: _data?.prop._sys_banCopy,
      _sys_na_banPaste: _data?.prop._sys_na_banPaste,
      _sys_banPaste: _data?.prop._sys_banPaste,
      _sys_statusonly: _data?.prop._sys_statusonly,
      _sys_na_statusonly: _data?.prop._sys_na_statusonly,
      _sys_hide: _data?.prop._sys_hide,
      _sys_hide_default: _data?.prop._sys_hide_default,
    });
  };
  useEffect(() => {
    init();
  }, []);

  let titleTab;
  if (data) {
    const title =
      batchNodes && batchNodes.length
        ? '批量'
        : data.key
          ? data.title
          : data.value.title || '无标题';
    titleTab = (
      <div className="modal-title">
        <p>权限设置</p>
        <p className="text-omit" title={title}>
          {title}
        </p>
      </div>
    );
  }

  const handleNodeAcl = async (value, key) => {
    setNodeAclData({ ...nodeAclData, [key]: value });
    setChangedAcl({ ...changedAcl, [key]: value });
  };

  const renderTip = (type) => {
    if (!data) return null;
    const rootNode = data.node.nodeManager.getRoot();
    const allow =
      type === 'copy'
        ? rootNode.prop._sys_allow_copy
        : rootNode.prop._sys_allow_paste;
    const detail =
      type === 'copy'
        ? rootNode.prop._sys_allow_copy_detail
        : rootNode.prop._sys_allow_paste_detail;
    if (detail === 'all') {
      return (
        <span style={{ paddingLeft: 36, color: '#BFC6D2' }}>
          {`当前默认使用空间粒度的配置「${allow ? '所有人' : '仅管理员'}可${
            type === 'copy' ? '复制' : '粘贴'
          }」`}
        </span>
      );
    }
    return null;
  };

  const modalContent = (
    <div>
      <div className="tabs">
        <div
          className={tabIndex === 0 ? 'active' : ''}
          onClick={() => setTabIndex(0)}
        >
          节点设置
        </div>
        <div
          className={tabIndex === 1 ? 'active' : ''}
          onClick={() => setTabIndex(1)}
        >
          成员权限
        </div>
      </div>
      <div className="tabContent">
        {tabIndex === 0 ? (
          <div className="node-tab">
            <div className={'node-tab-item'}>
              <div className="node-tab-item-description">节点可见</div>
              <Checkbox
                checked={nodeAclData._sys_public}
                onChange={(e) => handleNodeAcl(e.target.checked, '_sys_public')}
              >
                所有人
              </Checkbox>
              <Checkbox
                checked={nodeAclData._sys_hide}
                onChange={(e) => handleNodeAcl(e.target.checked, '_sys_hide')}
              >
                仅管理员
              </Checkbox>
            </div>
            <div className={'node-tab-item'}>
              <div className="node-tab-item-description">节点隐藏</div>
              <Checkbox
                checked={nodeAclData._sys_hide_default}
                onChange={(e) =>
                  handleNodeAcl(e.target.checked, '_sys_hide_default')
                }
              ></Checkbox>
            </div>
            <div className={'node-tab-item'}>
              <div className="node-tab-item-description">禁止编辑</div>
              <Checkbox
                checked={nodeAclData._sys_na_readonly}
                onChange={(e) =>
                  handleNodeAcl(e.target.checked, '_sys_na_readonly')
                }
              >
                非管理员
              </Checkbox>
              <Checkbox
                checked={nodeAclData._sys_readonly}
                onChange={(e) =>
                  handleNodeAcl(e.target.checked, '_sys_readonly')
                }
              >
                所有人
              </Checkbox>
            </div>
            <div className={'node-tab-item'}>
              <div className="node-tab-item-description">仅允许编辑状态</div>
              <Checkbox
                checked={nodeAclData._sys_na_statusonly}
                onChange={(e) =>
                  handleNodeAcl(e.target.checked, '_sys_na_statusonly')
                }
              >
                非管理员
              </Checkbox>
              <Checkbox
                checked={nodeAclData._sys_statusonly}
                onChange={(e) =>
                  handleNodeAcl(e.target.checked, '_sys_statusonly')
                }
              >
                所有人
              </Checkbox>
            </div>
            <div className={'node-tab-item'}>
              <div className="node-tab-item-description">禁止删除</div>
              <Checkbox
                checked={nodeAclData._sys_na_protect}
                onChange={(e) =>
                  handleNodeAcl(e.target.checked, '_sys_na_protect')
                }
              >
                非管理员
              </Checkbox>
              <Checkbox
                checked={nodeAclData._sys_protect}
                onChange={(e) =>
                  handleNodeAcl(e.target.checked, '_sys_protect')
                }
              >
                所有人
              </Checkbox>
            </div>
            <div className={'node-tab-item'}>
              <div className="node-tab-item-description">禁止复制</div>
              <Checkbox
                checked={nodeAclData._sys_na_banCopy}
                onChange={(e) =>
                  handleNodeAcl(e.target.checked, '_sys_na_banCopy')
                }
              >
                非管理员
              </Checkbox>
              <Checkbox
                checked={nodeAclData._sys_banCopy}
                onChange={(e) =>
                  handleNodeAcl(e.target.checked, '_sys_banCopy')
                }
              >
                所有人
              </Checkbox>
              {renderTip('copy')}
            </div>
            <div className={'node-tab-item'}>
              <div className="node-tab-item-description">禁止粘贴</div>
              <Checkbox
                checked={nodeAclData._sys_na_banPaste}
                onChange={(e) =>
                  handleNodeAcl(e.target.checked, '_sys_na_banPaste')
                }
              >
                非管理员
              </Checkbox>
              <Checkbox
                checked={nodeAclData._sys_banPaste}
                onChange={(e) =>
                  handleNodeAcl(e.target.checked, '_sys_banPaste')
                }
              >
                所有人
              </Checkbox>
              {renderTip('paste')}
            </div>
          </div>
        ) : (
          <div className="member-tab">
            <div className="member-tab-left">
              <div
                className={memberIndex === 1 ? 'active' : ''}
                onClick={() => setMemberIndex(1)}
              >
                组织架构
              </div>
              <div
                className={memberIndex === 0 ? 'active' : ''}
                onClick={() => setMemberIndex(0)}
              >
                分组
              </div>
            </div>
            <div className="member-tab-right">
              {memberIndex === 0 ? (
                <Group
                  setNodeGroupAclData={setNodeGroupAclData}
                  setChangedGroupAclData={setChangedGroupAclData}
                  nodeGroupAclData={nodeGroupAclData}
                  changedGroupAclData={changedGroupAclData}
                  data={data}
                />
              ) : (
                <StructureAcl
                  setNodeGroupAclData={setNodeGroupAclData}
                  setChangedGroupAclData={setChangedGroupAclData}
                  nodeGroupAclData={nodeGroupAclData}
                  changedGroupAclData={changedGroupAclData}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  // 单个节点更改权限，需要走俩接口
  const changeAclForNode = async (nodeId) => {
    // 分组权限
    const groupAclPayload = Object.keys(changedGroupAclData).map((groupId) => {
      const aclArr = NODE_ACL_MAP[changedGroupAclData[groupId]];
      if (!aclArr) return { [groupId]: null };

      return {
        [groupId]: aclArr.reduce((a, b) => {
          a[b] = true;
          return a;
        }, {}),
      };
    });

    // 成员权限
    let userAclPayload = Object.keys(changedUserAclData).map((userId) => {
      const aclArr = NODE_ACL_MAP[changedUserAclData[userId]];
      if (!aclArr) return { [userId]: null };

      return {
        [userId]: aclArr.reduce((a, b) => {
          a[b] = true;
          return a;
        }, {}),
      };
    });

    // 该节点创建者的权限是管理员，不能置为null
    // ?? 批量编辑权限 节点创建人
    const creatorId = data.node.prop._sys_creator;
    userAclPayload = userAclPayload.filter((i) => {
      if (creatorId in i) {
        const v = i[creatorId];
        if (v === null) return false;
        return true;
      }
      return true;
    });

    if (Object.keys(changedAcl).length) {
      // 节点权限
      await updateNodeSysProp(request, {
        org_id: currentSelection.selectSpace,
        node_id: nodeId,
        key: Object.keys(changedAcl),
        value: Object.values(changedAcl).map((i) => !!i),
      });
    }

    const aclPayload = groupAclPayload.concat(userAclPayload);
    if (aclPayload.length) {
      // 成员 和 分组权限
      await setNodeAcl(request, {
        org_id: currentSelection.selectSpace,
        node_id: nodeId,
        acl: aclPayload,
        applyChild: aclPayload.map(() => false),
      });
    }
  };

  const ok = async () => {
    setLoading(true);
    if (!batchNodes || batchNodes.length === 0) {
      await changeAclForNode(data.node.id);
      return onCancel();
    }

    // 批量修改权限
    if (batchNodes && batchNodes.length) {
      const dispatch = async (index) => {
        if (!batchNodes[index]) return;
        await changeAclForNode(batchNodes[index].id);
        await dispatch(index + 1);
      };

      await dispatch(0);
      onCancel();
    }
  };

  const showClearAcl = () => {
    return tabIndex === 1 && memberIndex === 0;
  };

  const clearAcl = () => {
    const newNodeGroupAclData = { ...nodeGroupAclData };
    Object.keys(newNodeGroupAclData).forEach((k) => {
      if (!['-1', '-2'].includes(k)) {
        newNodeGroupAclData[k] = null;
      }
    });

    setNodeGroupAclData(newNodeGroupAclData);
    setChangedGroupAclData(newNodeGroupAclData);
  };

  return (
    <Modal
      width={665}
      wrapClassName="permission-modal"
      title={titleTab}
      open={visible}
      onCancel={onCancel}
      footer={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          {showClearAcl() && (
            <div onClick={clearAcl} className="clearAcl">
              清空权限设置
            </div>
          )}
          <Button onClick={onCancel} type="default">
            取消
          </Button>
          <Button loading={loading} onClick={ok} type="primary">
            确定
          </Button>
        </div>
      }
    >
      {modalContent}
    </Modal>
  );
};

export default memo(PermissionModal);
