import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Base_Date_Format_Options as Date_Format_Options } from '@linkpi/core';
import cls from 'classnames';
import { useState } from 'react';

import LinkPiPop from '@/components/LinkPiPop';

import type { TokenType } from '../constantTokens';

import styles from './styles.less';

type PropsType = {
  token: TokenType;
  active: boolean;
  onItemHover: () => void;
  onItemClick: (extend?: any) => void;
  onEdit?: () => void;
  onDelete?: () => void;
};
const PickItem = (props: PropsType) => {
  const { token, active, onItemHover, onItemClick, onEdit, onDelete } = props;
  const [pickTypeVisible, setPickTypeVisible] = useState(false);

  const pickItemProps: any = {
    key: token.name,
    className: cls(styles.listItem, active ? styles.listItemActive : ''),
    onMouseEnter: onItemHover,
  };

  const inner = (
    <>
      {token.icon ? (
        <div className={styles.icon}>
          <i className={cls(token.icon, 'iconfont')} />
        </div>
      ) : null}
      <div
        className={cls(
          styles.pickItemName,
          'text-omit flex items-center justify-between group',
        )}
      >
        <span className="text-omit">{token.name}</span>
        <div className="mr-2 hidden group-hover:block">
          {onEdit && (
            <EditOutlined
              className="mr-1 text-[#b7bac0]"
              onClick={(e) => {
                e.stopPropagation();
                onEdit();
              }}
            />
          )}
          {onDelete && (
            <DeleteOutlined
              className="text-[#F0655F]"
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            />
          )}
        </div>
      </div>
      {token.pickType ? <div className={styles.triangle} /> : null}
    </>
  );

  if (!token.pickType) {
    pickItemProps.onClick = () => onItemClick(undefined);
    return <div {...pickItemProps}>{inner}</div>;
  }

  const popupProps = {
    visible: pickTypeVisible,
    onVisibleChange: setPickTypeVisible,
    trigger: ['click'],
    getPopupContainer: false,
    menuWidth: 220,
    placement: 'right',
    insideDom: <div {...pickItemProps}>{inner}</div>,
  };

  if (token.pickType === 'time') {
    // 时间选择 DATE(value,label)
    return (
      <LinkPiPop
        menuHeight={314}
        {...popupProps}
        menus={[{ label: '时间戳' }, ...Date_Format_Options].map((x) => ({
          label: x.label,
          handler: () => {
            setPickTypeVisible(false);
            onItemClick(x.label);
          },
        }))}
      />
    );
  }
  if (token.pickType === 'cascade') {
    // 多选选值 类型
    return (
      <LinkPiPop
        menus={[{ label: '完整路径' }, { label: '最后一级' }].map((x) => ({
          label: x.label,
          handler: () => {
            setPickTypeVisible(false);
            onItemClick(x.label);
          },
        }))}
        {...popupProps}
      />
    );
  }
  if (token.pickType === 'randomNumber') {
    // 随机数字
    return (
      <LinkPiPop
        {...popupProps}
        menus={new Array(11).fill('').map((x, i) => ({
          label: `${i + 2}位数字`,
          handler: () => {
            setPickTypeVisible(false);
            onItemClick(i + 2);
          },
        }))}
      />
    );
  }

  console.error('未配置 token pickType');
  return null;
};

export default PickItem;
