import { Cascader, DatePicker, Input, InputNumber, Select } from 'antd';
import { match } from 'ts-pattern';

import { cn } from '@/utils/utils';

import { YearMonthDayPicker } from '../../../../GlobalConditionFilterWidget/GlobalConditionFilter';
import type { FilterItemType } from '../../../types';

interface ItemCardProps {
  type: FilterItemType;
  name: string;
  showName: boolean;
  size?: 'small' | 'middle' | 'large';
  className?: string;
}

const ItemCard = (props: ItemCardProps) => {
  const { name, showName, type, size = 'middle', className } = props;

  const item = match(type)
    .with('text', () => {
      return <Input size={size} placeholder="请输入" />;
    })
    .with('number', () => {
      return (
        <InputNumber
          size={size}
          placeholder="请输入"
          style={{ width: '100%' }}
        />
      );
    })
    .with('select', () => {
      return <Select size={size} placeholder="请选择" />;
    })
    .with('dateRangePicker', () => {
      return (
        <DatePicker
          size={size}
          style={{ width: '100%', borderRadius: 8 }}
          placeholder="日期 — 日期"
        />
      );
    })
    .with('cascader', () => {
      return <Cascader size={size} placeholder="请选择" />;
    })
    .with('yearMonthDay', () => {
      // @ts-ignore
      return <YearMonthDayPicker noPadding />;
    })
    .otherwise(() => {
      return null;
    });

  return (
    <div className={cn('flex items-center', className)}>
      {showName && (
        <span className="text-sm text-[#242D3F] truncate mr-2 flex-shrink-0">
          {name}
        </span>
      )}
      <div className="flex-1">{item}</div>
    </div>
  );
};

export default ItemCard;
