import { atom } from 'jotai';
import { loadable } from 'jotai/utils';

import { getFormulaList } from '@/services/formula';

export const updateFormulaListAtom = atom(0);

export const loadableFormulaListAtom = loadable(
  atom(async (get) => {
    get(updateFormulaListAtom);

    const res = await getFormulaList();

    return res.status === 'ok' ? res.data : [];
  }),
);

export const formulaListAtom = atom(
  (get) => {
    const loadableValue = get(loadableFormulaListAtom);

    return loadableValue.state === 'hasData' ? loadableValue.data : [];
  },
  (get, set) => {
    set(updateFormulaListAtom, (n) => n + 1);
  },
);
